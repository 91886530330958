.hero{
  position: relative;
  overflow: hidden;
  .img-wrapper{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: var(--hero-bg-color, var(--c8y-palette-yellow-60));
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      filter: grayscale(1) invert(.3);
      mix-blend-mode: luminosity;
      transform: translate3d(0,0,0);
    }
  }

  div:has(>.dashboard-wrapper){
    aspect-ratio: 720/500;
    z-index: 10;
    @include media-breakpoint-up('lg'){
      aspect-ratio: unset;
    }
  }
  .dashboard-wrapper{
    position: absolute;
    transform: translateY(6%);
    top: 0;
    img{
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
    }

    @include media-breakpoint-up('lg'){
      top: auto;
      bottom: 0;
      padding: 0 0 0 2rem;
    }
  }

  &-content__container{
    max-width: $global-max-width;
    margin: 0 auto;
    width: 100%;
    padding: 3rem $global-padding;
    position: relative;
    color: var(--hero-color, var(--c8y-palette-gray-100));
    .section-title-separator{
      top: .4rem;
      height: 1.85rem;
    }
    @include media-breakpoint-up('lg'){
      padding: 8rem $global-padding;
    }
  }

  .panel__container{  
    padding: 2rem $global-padding 8rem;
    @include media-breakpoint-up('lg'){
      padding: 8rem $global-padding;
    }
  }

  &--home{
    .hero-content__container{
      padding: 4rem $global-padding;
      --bits-top: 6px;
      --bits-scale: 1.4;
      --bits-height: 1.43ch;
      h1{
        min-height: 123px;
        font-size: 1.85rem;
        color: var(--c8y-palette-high);
        #words{
          font-size: 1.3em;
          min-width: 1px;
          color: var(--c8y-palette-yellow-60);
        }
      }
      @include media-breakpoint-up('lg'){
        padding: 8rem $global-padding 8rem;
        --bits-top: 8px;
        --bits-height: 1.46ch;
        h1{
          min-height: 148px;
          font-size: clamp(2.5rem, 2.8vw, 2.75rem);
        }
      }
    }
  }


  &--blue{
    --hero-bg-color: var(--c8y-palette-blue-30);
    --hero-color: var(--c8y-palette-gray-100);
    --bits-top: 2px;
    --bits-scale: 1.04;
    --bits-height: calc(1em);
    display: flex;
    align-items: center;
    position: -webkit-sticky;
    position: sticky;
    top: 64px
  }

  &--yellow{
    --link-underline: var(--c8y-palette-gray-10);
    --hero-bg-color: #ffa600;
  }
}


.hero--home {
  +.panel > .panel__container{
    padding: 4rem $global-padding 6rem;
  }

}


.title-bits{
  .last-word{
    display: inline-flex;
    gap: 8px;
    .bits-line::before{
      height: 100%;
    }
  }
  min-width: 1px;
}
