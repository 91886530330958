@font-face {
  font-weight: normal;
  font-style: normal;
  font-family: 'dlt-c8y-icons';
  src: url('./fonts/dlt-c8y-icons.ttf') format('truetype'),
    url('./fonts/dlt-c8y-icons.woff') format('woff'),
    url('./fonts/dlt-c8y-icons.svg#dlt-c8y-icons') format('svg');

  font-display: block;
}

[class^='dlt-c8y-icon-'],
[class*=' dlt-c8y-icon-'],
.dlt-c8y-icon {
  display: inline-block;
  text-transform: none;
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
  font-family: 'dlt-c8y-icons' !important;
  line-height: 1;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  &::before,
  &::after {
    font-size: 1.1em;
  }
  .btn &{
    line-height: inherit;
  }
}

/* ==========================
 ** ICON UTILS
 =========================== */

/* makes the font 33% larger relative to the icon container */
.icon-lg {
  vertical-align: -15%;
  font-size: 1.33333333em;
  line-height: 0.75em;
}
.icon-2x {
  font-size: 2em;
}
.icon-3x {
  font-size: 3em;
}
.icon-4x {
  font-size: 4em;
}
.icon-5x {
  font-size: 5em;
}
.icon-fw {
  width: 1.28571429em;
  text-align: center;
}

.icon-ul {
  margin-left: 2.14285714em;
  padding-left: 0;
  list-style-type: none;
}
.icon-ul > li {
  position: relative;
}
.icon-li {
  position: absolute;
  top: 0.14285714em;
  left: -2.14285714em;
  width: 2.14285714em;
  text-align: center;
}
.icon-li.icon-lg {
  left: -1.85714286em;
}
.icon-border {
  padding: 0.2em 0.25em 0.15em;
  border: solid 0.08em #eeeeee;
  border-radius: 0.1em;
}
.icon-pull-left {
  float: left;
}
.icon-pull-right {
  float: right;
}
.icon-pull-left {
  margin-right: 0.3em;
}
.icon-pull-right {
  margin-left: 0.3em;
}

.icon-spin {
  -webkit-animation: icon-spin 2s infinite linear;
  animation: icon-spin 2s infinite linear;
}
.icon-pulse {
  -webkit-animation: icon-spin 1s infinite steps(8);
  animation: icon-spin 1s infinite steps(8);
}
@-webkit-keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
.icon-rotate-90 {
  -ms-filter: 'progid:DXImageTransform.Microsoft.BasicImage(rotation=1)';
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.icon-rotate-180 {
  -ms-filter: 'progid:DXImageTransform.Microsoft.BasicImage(rotation=2)';
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.icon-rotate-270 {
  -ms-filter: 'progid:DXImageTransform.Microsoft.BasicImage(rotation=3)';
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}
.icon-flip-horizontal {
  -ms-filter: 'progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)';
  -webkit-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  transform: scale(-1, 1);
}
.icon-flip-vertical {
  -ms-filter: 'progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)';
  -webkit-transform: scale(1, -1);
  -ms-transform: scale(1, -1);
  transform: scale(1, -1);
}
:root .icon-rotate-90,
:root .icon-rotate-180,
:root .icon-rotate-270,
:root .icon-flip-horizontal,
:root .icon-flip-vertical {
  filter: none;
}
.icon-stack {
  position: relative;
  display: inline-block;
  width: 2em;
  height: 2em;
  vertical-align: middle;
  line-height: 2em;
}
.icon-stack-1x,
.icon-stack-2x {
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center;
}
.icon-stack-1x {
  line-height: inherit;
}
.icon-stack-2x {
  font-size: 2em;
}
.icon-inverse {
  color: #ffffff;
}

/* ==========================
  ** ICONS
  =========================== */
.dlt-c8y-icon-collapse-arrow {
  &:before {
    content: $dlt-c8y-icon-collapse-arrow;
  }
}
.dlt-c8y-icon-angle-up {
  &:before {
    content: $dlt-c8y-icon-angle-up;
  }
}
.dlt-c8y-icon-chevron-up {
  &:before {
    content: $dlt-c8y-icon-chevron-up;
  }
}
.dlt-c8y-icon-forward {
  &:before {
    content: $dlt-c8y-icon-forward;
  }
}
.dlt-c8y-icon-angle-right {
  &:before {
    content: $dlt-c8y-icon-angle-right;
  }
}
.dlt-c8y-icon-chevron-right {
  &:before {
    content: $dlt-c8y-icon-chevron-right;
  }
}
.dlt-c8y-icon-chevron-breadcrumb {
  &:before {
    content: $dlt-c8y-icon-chevron-breadcrumb;
  }
}
.dlt-c8y-icon-expand-arrow {
  &:before {
    content: $dlt-c8y-icon-expand-arrow;
  }
}
.dlt-c8y-icon-angle-down {
  &:before {
    content: $dlt-c8y-icon-angle-down;
  }
}
.dlt-c8y-icon-chevron-down {
  &:before {
    content: $dlt-c8y-icon-chevron-down;
  }
}
.dlt-c8y-icon-caret-back {
  &:before {
    content: $dlt-c8y-icon-caret-back;
  }
}
.dlt-c8y-icon-angle-left {
  &:before {
    content: $dlt-c8y-icon-angle-left;
  }
}
.dlt-c8y-icon-chevron-left {
  &:before {
    content: $dlt-c8y-icon-chevron-left;
  }
}
.dlt-c8y-icon-chevron-double-left {
  &:before {
    content: $dlt-c8y-icon-chevron-double-left;
  }
}
.dlt-c8y-icon-chevron-double-right {
  &:before {
    content: $dlt-c8y-icon-chevron-double-right;
  }
}
.dlt-c8y-icon-chevron-double-up {
  &:before {
    content: $dlt-c8y-icon-chevron-double-up;
  }
}
.dlt-c8y-icon-chevron-double-down {
  &:before {
    content: $dlt-c8y-icon-chevron-double-down;
  }
}
.dlt-c8y-icon-chevron-circle-up {
  &:before {
    content: $dlt-c8y-icon-chevron-circle-up;
  }
}
.dlt-c8y-icon-chevron-circle-right {
  &:before {
    content: $dlt-c8y-icon-chevron-circle-right;
  }
}
.dlt-c8y-icon-chevron-circle-down {
  &:before {
    content: $dlt-c8y-icon-chevron-circle-down;
  }
}
.dlt-c8y-icon-chevron-circle-left {
  &:before {
    content: $dlt-c8y-icon-chevron-circle-left;
  }
}
.dlt-c8y-icon-arrow-up {
  &:before {
    content: $dlt-c8y-icon-arrow-up;
  }
}
.dlt-c8y-icon-arrow-right {
  &:before {
    content: $dlt-c8y-icon-arrow-right;
  }
}
.dlt-c8y-icon-arrow-down {
  &:before {
    content: $dlt-c8y-icon-arrow-down;
  }
}
.dlt-c8y-icon-arrow-left {
  &:before {
    content: $dlt-c8y-icon-arrow-left;
  }
}
.dlt-c8y-icon-panel-control-up {
  &:before {
    content: $dlt-c8y-icon-panel-control-up;
  }
}
.dlt-c8y-icon-angle-double-up {
  &:before {
    content: $dlt-c8y-icon-angle-double-up;
  }
}
.dlt-c8y-icon-panel-control-right {
  &:before {
    content: $dlt-c8y-icon-panel-control-right;
  }
}
.dlt-c8y-icon-angle-double-right {
  &:before {
    content: $dlt-c8y-icon-angle-double-right;
  }
}
.dlt-c8y-icon-panel-control-down {
  &:before {
    content: $dlt-c8y-icon-panel-control-down;
  }
}
.dlt-c8y-icon-angle-double-down {
  &:before {
    content: $dlt-c8y-icon-angle-double-down;
  }
}
.dlt-c8y-icon-panel-control-left {
  &:before {
    content: $dlt-c8y-icon-panel-control-left;
  }
}
.dlt-c8y-icon-angle-double-left {
  &:before {
    content: $dlt-c8y-icon-angle-double-left;
  }
}
.dlt-c8y-icon-download {
  &:before {
    content: $dlt-c8y-icon-download;
  }
}
.dlt-c8y-icon-upload {
  &:before {
    content: $dlt-c8y-icon-upload;
  }
}
.dlt-c8y-icon-internal {
  &:before {
    content: $dlt-c8y-icon-internal;
  }
}
.dlt-c8y-icon-external {
  &:before {
    content: $dlt-c8y-icon-external;
  }
}
.dlt-c8y-icon-undo {
  &:before {
    content: $dlt-c8y-icon-undo;
  }
}
.dlt-c8y-icon-redo {
  &:before {
    content: $dlt-c8y-icon-redo;
  }
}
.dlt-c8y-icon-signout {
  &:before {
    content: $dlt-c8y-icon-signout;
  }
}
.dlt-c8y-icon-signin {
  &:before {
    content: $dlt-c8y-icon-signin;
  }
}
.dlt-c8y-icon-login {
  &:before {
    content: $dlt-c8y-icon-login;
  }
}
.dlt-c8y-icon-sign-in {
  &:before {
    content: $dlt-c8y-icon-sign-in;
  }
}
.dlt-c8y-icon-logout {
  &:before {
    content: $dlt-c8y-icon-logout;
  }
}
.dlt-c8y-icon-sign-out {
  &:before {
    content: $dlt-c8y-icon-sign-out;
  }
}
.dlt-c8y-icon-arrow-circle-up {
  &:before {
    content: $dlt-c8y-icon-arrow-circle-up;
  }
}
.dlt-c8y-icon-arrow-circle-o-up {
  &:before {
    content: $dlt-c8y-icon-arrow-circle-o-up;
  }
}
.dlt-c8y-icon-arrow-circle-right {
  &:before {
    content: $dlt-c8y-icon-arrow-circle-right;
  }
}
.dlt-c8y-icon-arrow-circle-o-right {
  &:before {
    content: $dlt-c8y-icon-arrow-circle-o-right;
  }
}
.dlt-c8y-icon-arrow-circle-down {
  &:before {
    content: $dlt-c8y-icon-arrow-circle-down;
  }
}
.dlt-c8y-icon-arrow-circle-o-down {
  &:before {
    content: $dlt-c8y-icon-arrow-circle-o-down;
  }
}
.dlt-c8y-icon-arrow-circle-left {
  &:before {
    content: $dlt-c8y-icon-arrow-circle-left;
  }
}
.dlt-c8y-icon-arrow-circle-o-left {
  &:before {
    content: $dlt-c8y-icon-arrow-circle-o-left;
  }
}
.dlt-c8y-icon-arrow-circle-up-left {
  &:before {
    content: $dlt-c8y-icon-arrow-circle-up-left;
  }
}
.dlt-c8y-icon-arrow-circle-down-left {
  &:before {
    content: $dlt-c8y-icon-arrow-circle-down-left;
  }
}
.dlt-c8y-icon-arrow-circle-up-right {
  &:before {
    content: $dlt-c8y-icon-arrow-circle-up-right;
  }
}
.dlt-c8y-icon-arrow-circle-bottom-right {
  &:before {
    content: $dlt-c8y-icon-arrow-circle-bottom-right;
  }
}
.dlt-c8y-icon-arrow-circle-diameter {
  &:before {
    content: $dlt-c8y-icon-arrow-circle-diameter;
  }
}
.dlt-c8y-icon-arrow-circle-divide-horizontal {
  &:before {
    content: $dlt-c8y-icon-arrow-circle-divide-horizontal;
  }
}
.dlt-c8y-icon-arrow-circle-minimize-horizontal {
  &:before {
    content: $dlt-c8y-icon-arrow-circle-minimize-horizontal;
  }
}
.dlt-c8y-icon-caret-square-o-up {
  &:before {
    content: $dlt-c8y-icon-caret-square-o-up;
  }
}
.dlt-c8y-icon-toggle-up {
  &:before {
    content: $dlt-c8y-icon-toggle-up;
  }
}
.dlt-c8y-icon-caret-square-o-down {
  &:before {
    content: $dlt-c8y-icon-caret-square-o-down;
  }
}
.dlt-c8y-icon-toggle-down {
  &:before {
    content: $dlt-c8y-icon-toggle-down;
  }
}
.dlt-c8y-icon-caret-square-o-left {
  &:before {
    content: $dlt-c8y-icon-caret-square-o-left;
  }
}
.dlt-c8y-icon-toggle-left {
  &:before {
    content: $dlt-c8y-icon-toggle-left;
  }
}
.dlt-c8y-icon-caret-square-o-right {
  &:before {
    content: $dlt-c8y-icon-caret-square-o-right;
  }
}
.dlt-c8y-icon-toggle-right {
  &:before {
    content: $dlt-c8y-icon-toggle-right;
  }
}
.dlt-c8y-icon-enter-bottom {
  &:before {
    content: $dlt-c8y-icon-enter-bottom;
  }
}
.dlt-c8y-icon-enter-right {
  &:before {
    content: $dlt-c8y-icon-enter-right;
  }
}
.dlt-c8y-icon-enter-top {
  &:before {
    content: $dlt-c8y-icon-enter-top;
  }
}
.dlt-c8y-icon-enter-left {
  &:before {
    content: $dlt-c8y-icon-enter-left;
  }
}
.dlt-c8y-icon-exit-top {
  &:before {
    content: $dlt-c8y-icon-exit-top;
  }
}
.dlt-c8y-icon-exit-right {
  &:before {
    content: $dlt-c8y-icon-exit-right;
  }
}
.dlt-c8y-icon-exit-bottom {
  &:before {
    content: $dlt-c8y-icon-exit-bottom;
  }
}
.dlt-c8y-icon-exit-left {
  &:before {
    content: $dlt-c8y-icon-exit-left;
  }
}
.dlt-c8y-icon-input-output {
  &:before {
    content: $dlt-c8y-icon-input-output;
  }
}
.dlt-c8y-icon-rotate-right {
  &:before {
    content: $dlt-c8y-icon-rotate-right;
  }
}
.dlt-c8y-icon-rotate {
  &:before {
    content: $dlt-c8y-icon-rotate;
  }
}
.dlt-c8y-icon-repeat {
  &:before {
    content: $dlt-c8y-icon-repeat;
  }
}
.dlt-c8y-icon-reset {
  &:before {
    content: $dlt-c8y-icon-reset;
  }
}
.dlt-c8y-icon-rotate-left {
  &:before {
    content: $dlt-c8y-icon-rotate-left;
  }
}
.dlt-c8y-icon-restore {
  &:before {
    content: $dlt-c8y-icon-restore;
  }
}
.dlt-c8y-icon-refresh {
  &:before {
    content: $dlt-c8y-icon-refresh;
  }
}
.dlt-c8y-icon-process {
  &:before {
    content: $dlt-c8y-icon-process;
  }
}
.dlt-c8y-icon-recycle {
  &:before {
    content: $dlt-c8y-icon-recycle;
  }
}
.dlt-c8y-icon-refresh-exception {
  &:before {
    content: $dlt-c8y-icon-refresh-exception;
  }
}
.dlt-c8y-icon-lock-orientation {
  &:before {
    content: $dlt-c8y-icon-lock-orientation;
  }
}
.dlt-c8y-icon-arrow-dotted-up {
  &:before {
    content: $dlt-c8y-icon-arrow-dotted-up;
  }
}
.dlt-c8y-icon-arrow-dotted-right {
  &:before {
    content: $dlt-c8y-icon-arrow-dotted-right;
  }
}
.dlt-c8y-icon-arrow-advance {
  &:before {
    content: $dlt-c8y-icon-arrow-advance;
  }
}
.dlt-c8y-icon-arrow-dotted-down {
  &:before {
    content: $dlt-c8y-icon-arrow-dotted-down;
  }
}
.dlt-c8y-icon-arrow-dotted-left {
  &:before {
    content: $dlt-c8y-icon-arrow-dotted-left;
  }
}
.dlt-c8y-icon-arrows-dotted-left-right {
  &:before {
    content: $dlt-c8y-icon-arrows-dotted-left-right;
  }
}
.dlt-c8y-icon-arrows-dotted-up-down {
  &:before {
    content: $dlt-c8y-icon-arrows-dotted-up-down;
  }
}
.dlt-c8y-icon-up-down-arrows {
  &:before {
    content: $dlt-c8y-icon-up-down-arrows;
  }
}
.dlt-c8y-icon-collect {
  &:before {
    content: $dlt-c8y-icon-collect;
  }
}
.dlt-c8y-icon-merge-horizontal {
  &:before {
    content: $dlt-c8y-icon-merge-horizontal;
  }
}
.dlt-c8y-icon-split-horizontal {
  &:before {
    content: $dlt-c8y-icon-split-horizontal;
  }
}
.dlt-c8y-icon-merge-vertical {
  &:before {
    content: $dlt-c8y-icon-merge-vertical;
  }
}
.dlt-c8y-icon-divider {
  &:before {
    content: $dlt-c8y-icon-divider;
  }
}
.dlt-c8y-icon-split-vertical {
  &:before {
    content: $dlt-c8y-icon-split-vertical;
  }
}
.dlt-c8y-icon-drag {
  &:before {
    content: $dlt-c8y-icon-drag;
  }
}
.dlt-c8y-icon-arrows {
  &:before {
    content: $dlt-c8y-icon-arrows;
  }
}
.dlt-c8y-icon-move-step {
  &:before {
    content: $dlt-c8y-icon-move-step;
  }
}
.dlt-c8y-icon-resize-expand {
  &:before {
    content: $dlt-c8y-icon-resize-expand;
  }
}
.dlt-c8y-icon-arrows-alt {
  &:before {
    content: $dlt-c8y-icon-arrows-alt;
  }
}
.dlt-c8y-icon-expand {
  &:before {
    content: $dlt-c8y-icon-expand;
  }
}
.dlt-c8y-icon-compress {
  &:before {
    content: $dlt-c8y-icon-compress;
  }
}
.dlt-c8y-icon-resize-collapse {
  &:before {
    content: $dlt-c8y-icon-resize-collapse;
  }
}
.dlt-c8y-icon-collapse {
  &:before {
    content: $dlt-c8y-icon-collapse;
  }
}
.dlt-c8y-icon-fullscreen {
  &:before {
    content: $dlt-c8y-icon-fullscreen;
  }
}
.dlt-c8y-icon-fullscreen-exit {
  &:before {
    content: $dlt-c8y-icon-fullscreen-exit;
  }
}
.dlt-c8y-icon-long-arrow-up {
  &:before {
    content: $dlt-c8y-icon-long-arrow-up;
  }
}
.dlt-c8y-icon-long-arrow-right {
  &:before {
    content: $dlt-c8y-icon-long-arrow-right;
  }
}
.dlt-c8y-icon-long-arrow-down {
  &:before {
    content: $dlt-c8y-icon-long-arrow-down;
  }
}
.dlt-c8y-icon-long-arrow-left {
  &:before {
    content: $dlt-c8y-icon-long-arrow-left;
  }
}
.dlt-c8y-icon-arrows-v {
  &:before {
    content: $dlt-c8y-icon-arrows-v;
  }
}
.dlt-c8y-icon-arrows-h {
  &:before {
    content: $dlt-c8y-icon-arrows-h;
  }
}
.dlt-c8y-icon-enlarge {
  &:before {
    content: $dlt-c8y-icon-enlarge;
  }
}
.dlt-c8y-icon-data-transfer {
  &:before {
    content: $dlt-c8y-icon-data-transfer;
  }
}
.dlt-c8y-icon-exchange {
  &:before {
    content: $dlt-c8y-icon-exchange;
  }
}
.dlt-c8y-icon-sort-arrow {
  &:before {
    content: $dlt-c8y-icon-sort-arrow;
  }
}
.dlt-c8y-icon-arrows-left-right-diagonal {
  &:before {
    content: $dlt-c8y-icon-arrows-left-right-diagonal;
  }
}
.dlt-c8y-icon-level-up {
  &:before {
    content: $dlt-c8y-icon-level-up;
  }
}
.dlt-c8y-icon-level-down {
  &:before {
    content: $dlt-c8y-icon-level-down;
  }
}
.dlt-c8y-icon-u-turn {
  &:before {
    content: $dlt-c8y-icon-u-turn;
  }
}
.dlt-c8y-icon-u-turn-right {
  &:before {
    content: $dlt-c8y-icon-u-turn-right;
  }
}
.dlt-c8y-icon-u-turn-left {
  &:before {
    content: $dlt-c8y-icon-u-turn-left;
  }
}
.dlt-c8y-icon-undo1 {
  &:before {
    content: $dlt-c8y-icon-undo1;
  }
}
.dlt-c8y-icon-redo1 {
  &:before {
    content: $dlt-c8y-icon-redo1;
  }
}
.dlt-c8y-icon-sort {
  &:before {
    content: $dlt-c8y-icon-sort;
  }
}
.dlt-c8y-icon-unsorted {
  &:before {
    content: $dlt-c8y-icon-unsorted;
  }
}
.dlt-c8y-icon-sort-down {
  &:before {
    content: $dlt-c8y-icon-sort-down;
  }
}
.dlt-c8y-icon-sort-desc {
  &:before {
    content: $dlt-c8y-icon-sort-desc;
  }
}
.dlt-c8y-icon-sort-up {
  &:before {
    content: $dlt-c8y-icon-sort-up;
  }
}
.dlt-c8y-icon-sort-asc {
  &:before {
    content: $dlt-c8y-icon-sort-asc;
  }
}
.dlt-c8y-icon-caret-up {
  &:before {
    content: $dlt-c8y-icon-caret-up;
  }
}
.dlt-c8y-icon-caret-right {
  &:before {
    content: $dlt-c8y-icon-caret-right;
  }
}
.dlt-c8y-icon-caret-down {
  &:before {
    content: $dlt-c8y-icon-caret-down;
  }
}
.dlt-c8y-icon-caret-left {
  &:before {
    content: $dlt-c8y-icon-caret-left;
  }
}
.dlt-c8y-icon-squiggly-arrow {
  &:before {
    content: $dlt-c8y-icon-squiggly-arrow;
  }
}
.dlt-c8y-icon-curly-arrow {
  &:before {
    content: $dlt-c8y-icon-curly-arrow;
  }
}
.dlt-c8y-icon-swap {
  &:before {
    content: $dlt-c8y-icon-swap;
  }
}
.dlt-c8y-icon-environment {
  &:before {
    content: $dlt-c8y-icon-environment;
  }
}
.dlt-c8y-icon-shuffle {
  &:before {
    content: $dlt-c8y-icon-shuffle;
  }
}
.dlt-c8y-icon-random {
  &:before {
    content: $dlt-c8y-icon-random;
  }
}
.dlt-c8y-icon-swipe-up {
  &:before {
    content: $dlt-c8y-icon-swipe-up;
  }
}
.dlt-c8y-icon-swipe-right {
  &:before {
    content: $dlt-c8y-icon-swipe-right;
  }
}
.dlt-c8y-icon-swipe-down {
  &:before {
    content: $dlt-c8y-icon-swipe-down;
  }
}
.dlt-c8y-icon-swipe-left {
  &:before {
    content: $dlt-c8y-icon-swipe-left;
  }
}
.dlt-c8y-icon-two-finger-swipe-up {
  &:before {
    content: $dlt-c8y-icon-two-finger-swipe-up;
  }
}
.dlt-c8y-icon-two-finger-swipe-right {
  &:before {
    content: $dlt-c8y-icon-two-finger-swipe-right;
  }
}
.dlt-c8y-icon-two-finger-swipe-down {
  &:before {
    content: $dlt-c8y-icon-two-finger-swipe-down;
  }
}
.dlt-c8y-icon-two-finger-swipe-left {
  &:before {
    content: $dlt-c8y-icon-two-finger-swipe-left;
  }
}
.dlt-c8y-icon-pinch {
  &:before {
    content: $dlt-c8y-icon-pinch;
  }
}
.dlt-c8y-icon-hospital-o {
  &:before {
    content: $dlt-c8y-icon-hospital-o;
  }
}
.dlt-c8y-icon-h-square {
  &:before {
    content: $dlt-c8y-icon-h-square;
  }
}
.dlt-c8y-icon-university {
  &:before {
    content: $dlt-c8y-icon-university;
  }
}
.dlt-c8y-icon-institution {
  &:before {
    content: $dlt-c8y-icon-institution;
  }
}
.dlt-c8y-icon-bank {
  &:before {
    content: $dlt-c8y-icon-bank;
  }
}
.dlt-c8y-icon-ios-themes {
  &:before {
    content: $dlt-c8y-icon-ios-themes;
  }
}
.dlt-c8y-icon-building {
  &:before {
    content: $dlt-c8y-icon-building;
  }
}
.dlt-c8y-icon-building-o {
  &:before {
    content: $dlt-c8y-icon-building-o;
  }
}
.dlt-c8y-icon-apartment {
  &:before {
    content: $dlt-c8y-icon-apartment;
  }
}
.dlt-c8y-icon-department1 {
  &:before {
    content: $dlt-c8y-icon-department1;
  }
}
.dlt-c8y-icon-organization {
  &:before {
    content: $dlt-c8y-icon-organization;
  }
}
.dlt-c8y-icon-office {
  &:before {
    content: $dlt-c8y-icon-office;
  }
}
.dlt-c8y-icon-building-with-rooftop-terrace {
  &:before {
    content: $dlt-c8y-icon-building-with-rooftop-terrace;
  }
}
.dlt-c8y-icon-company {
  &:before {
    content: $dlt-c8y-icon-company;
  }
}
.dlt-c8y-icon-warehouse {
  &:before {
    content: $dlt-c8y-icon-warehouse;
  }
}
.dlt-c8y-icon-shop {
  &:before {
    content: $dlt-c8y-icon-shop;
  }
}
.dlt-c8y-icon-work-from-home {
  &:before {
    content: $dlt-c8y-icon-work-from-home;
  }
}
.dlt-c8y-icon-house {
  &:before {
    content: $dlt-c8y-icon-house;
  }
}
.dlt-c8y-icon-dog-house {
  &:before {
    content: $dlt-c8y-icon-dog-house;
  }
}
.dlt-c8y-icon-home-automation {
  &:before {
    content: $dlt-c8y-icon-home-automation;
  }
}
.dlt-c8y-icon-garage-door {
  &:before {
    content: $dlt-c8y-icon-garage-door;
  }
}
.dlt-c8y-icon-depot {
  &:before {
    content: $dlt-c8y-icon-depot;
  }
}
.dlt-c8y-icon-garage {
  &:before {
    content: $dlt-c8y-icon-garage;
  }
}
.dlt-c8y-icon-open-garage-door {
  &:before {
    content: $dlt-c8y-icon-open-garage-door;
  }
}
.dlt-c8y-icon-close-garage-door {
  &:before {
    content: $dlt-c8y-icon-close-garage-door;
  }
}
.dlt-c8y-icon-barn {
  &:before {
    content: $dlt-c8y-icon-barn;
  }
}
.dlt-c8y-icon-bungalow {
  &:before {
    content: $dlt-c8y-icon-bungalow;
  }
}
.dlt-c8y-icon-exterior {
  &:before {
    content: $dlt-c8y-icon-exterior;
  }
}
.dlt-c8y-icon-equal-housing-opportunity {
  &:before {
    content: $dlt-c8y-icon-equal-housing-opportunity;
  }
}
.dlt-c8y-icon-heating-room {
  &:before {
    content: $dlt-c8y-icon-heating-room;
  }
}
.dlt-c8y-icon-mortgage {
  &:before {
    content: $dlt-c8y-icon-mortgage;
  }
}
.dlt-c8y-icon-home-safety {
  &:before {
    content: $dlt-c8y-icon-home-safety;
  }
}
.dlt-c8y-icon-enterprise-resource-planning {
  &:before {
    content: $dlt-c8y-icon-enterprise-resource-planning;
  }
}
.dlt-c8y-icon-travel-agency {
  &:before {
    content: $dlt-c8y-icon-travel-agency;
  }
}
.dlt-c8y-icon-department-shop {
  &:before {
    content: $dlt-c8y-icon-department-shop;
  }
}
.dlt-c8y-icon-book-shelf {
  &:before {
    content: $dlt-c8y-icon-book-shelf;
  }
}
.dlt-c8y-icon-emergency-exit {
  &:before {
    content: $dlt-c8y-icon-emergency-exit;
  }
}
.dlt-c8y-icon-stairs {
  &:before {
    content: $dlt-c8y-icon-stairs;
  }
}
.dlt-c8y-icon-stairs-down {
  &:before {
    content: $dlt-c8y-icon-stairs-down;
  }
}
.dlt-c8y-icon-stairs-up {
  &:before {
    content: $dlt-c8y-icon-stairs-up;
  }
}
.dlt-c8y-icon-temperature-inside {
  &:before {
    content: $dlt-c8y-icon-temperature-inside;
  }
}
.dlt-c8y-icon-treehouse {
  &:before {
    content: $dlt-c8y-icon-treehouse;
  }
}
.dlt-c8y-icon-tree {
  &:before {
    content: $dlt-c8y-icon-tree;
  }
}
.dlt-c8y-icon-water-heater {
  &:before {
    content: $dlt-c8y-icon-water-heater;
  }
}
.dlt-c8y-icon-clean {
  &:before {
    content: $dlt-c8y-icon-clean;
  }
}
.dlt-c8y-icon-magic {
  &:before {
    content: $dlt-c8y-icon-magic;
  }
}
.dlt-c8y-icon-storage {
  &:before {
    content: $dlt-c8y-icon-storage;
  }
}
.dlt-c8y-icon-60-degrees {
  &:before {
    content: $dlt-c8y-icon-60-degrees;
  }
}
.dlt-c8y-icon-120-degrees {
  &:before {
    content: $dlt-c8y-icon-120-degrees;
  }
}
.dlt-c8y-icon-225-degrees {
  &:before {
    content: $dlt-c8y-icon-225-degrees;
  }
}
.dlt-c8y-icon-300-degrees {
  &:before {
    content: $dlt-c8y-icon-300-degrees;
  }
}
.dlt-c8y-icon-360-degrees {
  &:before {
    content: $dlt-c8y-icon-360-degrees;
  }
}
.dlt-c8y-icon-pie-chart {
  &:before {
    content: $dlt-c8y-icon-pie-chart;
  }
}
.dlt-c8y-icon-pie-chart {
  &:before {
    content: $dlt-c8y-icon-pie-chart;
  }
}
.dlt-c8y-icon-bar-chart {
  &:before {
    content: $dlt-c8y-icon-bar-chart;
  }
}
.dlt-c8y-icon-bar-chart-o {
  &:before {
    content: $dlt-c8y-icon-bar-chart-o;
  }
}
.dlt-c8y-icon-data-account {
  &:before {
    content: $dlt-c8y-icon-data-account;
  }
}
.dlt-c8y-icon-data-decline {
  &:before {
    content: $dlt-c8y-icon-data-decline;
  }
}
.dlt-c8y-icon-increase {
  &:before {
    content: $dlt-c8y-icon-increase;
  }
}
.dlt-c8y-icon-neutral-trading {
  &:before {
    content: $dlt-c8y-icon-neutral-trading;
  }
}
.dlt-c8y-icon-decrease {
  &:before {
    content: $dlt-c8y-icon-decrease;
  }
}
.dlt-c8y-icon-timeline {
  &:before {
    content: $dlt-c8y-icon-timeline;
  }
}
.dlt-c8y-icon-increase-profits {
  &:before {
    content: $dlt-c8y-icon-increase-profits;
  }
}
.dlt-c8y-icon-combo-chart {
  &:before {
    content: $dlt-c8y-icon-combo-chart;
  }
}
.dlt-c8y-icon-line-chart {
  &:before {
    content: $dlt-c8y-icon-line-chart;
  }
}
.dlt-c8y-icon-graph {
  &:before {
    content: $dlt-c8y-icon-graph;
  }
}
.dlt-c8y-icon-area-chart {
  &:before {
    content: $dlt-c8y-icon-area-chart;
  }
}
.dlt-c8y-icon-area-chart {
  &:before {
    content: $dlt-c8y-icon-area-chart;
  }
}
.dlt-c8y-icon-sorting-slider {
  &:before {
    content: $dlt-c8y-icon-sorting-slider;
  }
}
.dlt-c8y-icon-sliders {
  &:before {
    content: $dlt-c8y-icon-sliders;
  }
}
.dlt-c8y-icon-hierarchy {
  &:before {
    content: $dlt-c8y-icon-hierarchy;
  }
}
.dlt-c8y-icon-b2b {
  &:before {
    content: $dlt-c8y-icon-b2b;
  }
}
.dlt-c8y-icon-flow-chart {
  &:before {
    content: $dlt-c8y-icon-flow-chart;
  }
}
.dlt-c8y-icon-sitemap {
  &:before {
    content: $dlt-c8y-icon-sitemap;
  }
}
.dlt-c8y-icon-tree-structure {
  &:before {
    content: $dlt-c8y-icon-tree-structure;
  }
}
.dlt-c8y-icon-flow {
  &:before {
    content: $dlt-c8y-icon-flow;
  }
}
.dlt-c8y-icon-stacked-organizational-chart {
  &:before {
    content: $dlt-c8y-icon-stacked-organizational-chart;
  }
}
.dlt-c8y-icon-multicast {
  &:before {
    content: $dlt-c8y-icon-multicast;
  }
}
.dlt-c8y-icon-unicast {
  &:before {
    content: $dlt-c8y-icon-unicast;
  }
}
.dlt-c8y-icon-broadcasting {
  &:before {
    content: $dlt-c8y-icon-broadcasting;
  }
}
.dlt-c8y-icon-genealogy {
  &:before {
    content: $dlt-c8y-icon-genealogy;
  }
}
.dlt-c8y-icon-filter {
  &:before {
    content: $dlt-c8y-icon-filter;
  }
}
.dlt-c8y-icon-conversion {
  &:before {
    content: $dlt-c8y-icon-conversion;
  }
}
.dlt-c8y-icon-clear-filters {
  &:before {
    content: $dlt-c8y-icon-clear-filters;
  }
}
.dlt-c8y-icon-descending-sorting {
  &:before {
    content: $dlt-c8y-icon-descending-sorting;
  }
}
.dlt-c8y-icon-sort-amount-desc {
  &:before {
    content: $dlt-c8y-icon-sort-amount-desc;
  }
}
.dlt-c8y-icon-ascending-sorting {
  &:before {
    content: $dlt-c8y-icon-ascending-sorting;
  }
}
.dlt-c8y-icon-sort-amount-asc {
  &:before {
    content: $dlt-c8y-icon-sort-amount-asc;
  }
}
.dlt-c8y-icon-alphabetical-sorting {
  &:before {
    content: $dlt-c8y-icon-alphabetical-sorting;
  }
}
.dlt-c8y-icon-sort-alpha-asc {
  &:before {
    content: $dlt-c8y-icon-sort-alpha-asc;
  }
}
.dlt-c8y-icon-alphabetical-sorting-2 {
  &:before {
    content: $dlt-c8y-icon-alphabetical-sorting-2;
  }
}
.dlt-c8y-icon-sort-alpha-desc {
  &:before {
    content: $dlt-c8y-icon-sort-alpha-desc;
  }
}
.dlt-c8y-icon-data-exchange {
  &:before {
    content: $dlt-c8y-icon-data-exchange;
  }
}
.dlt-c8y-icon-filing-cabinet {
  &:before {
    content: $dlt-c8y-icon-filing-cabinet;
  }
}
.dlt-c8y-icon-database {
  &:before {
    content: $dlt-c8y-icon-database;
  }
}
.dlt-c8y-icon-database-administrator {
  &:before {
    content: $dlt-c8y-icon-database-administrator;
  }
}
.dlt-c8y-icon-data-export {
  &:before {
    content: $dlt-c8y-icon-data-export;
  }
}
.dlt-c8y-icon-export {
  &:before {
    content: $dlt-c8y-icon-export;
  }
}
.dlt-c8y-icon-data-import {
  &:before {
    content: $dlt-c8y-icon-data-import;
  }
}
.dlt-c8y-icon-import {
  &:before {
    content: $dlt-c8y-icon-import;
  }
}
.dlt-c8y-icon-grid-off {
  &:before {
    content: $dlt-c8y-icon-grid-off;
  }
}
.dlt-c8y-icon-gantt-chart {
  &:before {
    content: $dlt-c8y-icon-gantt-chart;
  }
}
.dlt-c8y-icon-data-grid {
  &:before {
    content: $dlt-c8y-icon-data-grid;
  }
}
.dlt-c8y-icon-grid-view {
  &:before {
    content: $dlt-c8y-icon-grid-view;
  }
}
.dlt-c8y-icon-grid-on {
  &:before {
    content: $dlt-c8y-icon-grid-on;
  }
}
.dlt-c8y-icon-row {
  &:before {
    content: $dlt-c8y-icon-row;
  }
}
.dlt-c8y-icon-rows {
  &:before {
    content: $dlt-c8y-icon-rows;
  }
}
.dlt-c8y-icon-column {
  &:before {
    content: $dlt-c8y-icon-column;
  }
}
.dlt-c8y-icon-grid {
  &:before {
    content: $dlt-c8y-icon-grid;
  }
}
.dlt-c8y-icon-table {
  &:before {
    content: $dlt-c8y-icon-table;
  }
}
.dlt-c8y-icon-split-table {
  &:before {
    content: $dlt-c8y-icon-split-table;
  }
}
.dlt-c8y-icon-data-sheet {
  &:before {
    content: $dlt-c8y-icon-data-sheet;
  }
}
.dlt-c8y-icon-blockchain-technology {
  &:before {
    content: $dlt-c8y-icon-blockchain-technology;
  }
}
.dlt-c8y-icon-workflow {
  &:before {
    content: $dlt-c8y-icon-workflow;
  }
}
.dlt-c8y-icon-parallel-workflow {
  &:before {
    content: $dlt-c8y-icon-parallel-workflow;
  }
}
.dlt-c8y-icon-mind-map {
  &:before {
    content: $dlt-c8y-icon-mind-map;
  }
}
.dlt-c8y-icon-query-inner-join-left {
  &:before {
    content: $dlt-c8y-icon-query-inner-join-left;
  }
}
.dlt-c8y-icon-sankey {
  &:before {
    content: $dlt-c8y-icon-sankey;
  }
}
.dlt-c8y-icon-creating {
  &:before {
    content: $dlt-c8y-icon-creating;
  }
}
.dlt-c8y-icon-creativity {
  &:before {
    content: $dlt-c8y-icon-creativity;
  }
}
.dlt-c8y-icon-financial-growth-analysis {
  &:before {
    content: $dlt-c8y-icon-financial-growth-analysis;
  }
}
.dlt-c8y-icon-input {
  &:before {
    content: $dlt-c8y-icon-input;
  }
}
.dlt-c8y-icon-omnichannel {
  &:before {
    content: $dlt-c8y-icon-omnichannel;
  }
}
.dlt-c8y-icon-string {
  &:before {
    content: $dlt-c8y-icon-string;
  }
}
.dlt-c8y-icon-variable {
  &:before {
    content: $dlt-c8y-icon-variable;
  }
}
.dlt-c8y-icon-variable-on {
  &:before {
    content: $dlt-c8y-icon-variable-on;
  }
}
.dlt-c8y-icon-thermometer-0 {
  &:before {
    content: $dlt-c8y-icon-thermometer-0;
  }
}
.dlt-c8y-icon-thermometer-empty {
  &:before {
    content: $dlt-c8y-icon-thermometer-empty;
  }
}
.dlt-c8y-icon-thermometer-3 {
  &:before {
    content: $dlt-c8y-icon-thermometer-3;
  }
}
.dlt-c8y-icon-thermometer-three-quarters {
  &:before {
    content: $dlt-c8y-icon-thermometer-three-quarters;
  }
}
.dlt-c8y-icon-thermometer-2 {
  &:before {
    content: $dlt-c8y-icon-thermometer-2;
  }
}
.dlt-c8y-icon-thermometer-half {
  &:before {
    content: $dlt-c8y-icon-thermometer-half;
  }
}
.dlt-c8y-icon-thermometer-1 {
  &:before {
    content: $dlt-c8y-icon-thermometer-1;
  }
}
.dlt-c8y-icon-thermometer-quarter {
  &:before {
    content: $dlt-c8y-icon-thermometer-quarter;
  }
}
.dlt-c8y-icon-thermometer {
  &:before {
    content: $dlt-c8y-icon-thermometer;
  }
}
.dlt-c8y-icon-thermometer-4 {
  &:before {
    content: $dlt-c8y-icon-thermometer-4;
  }
}
.dlt-c8y-icon-thermometer-full {
  &:before {
    content: $dlt-c8y-icon-thermometer-full;
  }
}
.dlt-c8y-icon-sensor {
  &:before {
    content: $dlt-c8y-icon-sensor;
  }
}
.dlt-c8y-icon-package {
  &:before {
    content: $dlt-c8y-icon-package;
  }
}
.dlt-c8y-icon-packages {
  &:before {
    content: $dlt-c8y-icon-packages;
  }
}
.dlt-c8y-icon-blockly {
  &:before {
    content: $dlt-c8y-icon-blockly;
  }
}
.dlt-c8y-icon-objects {
  &:before {
    content: $dlt-c8y-icon-objects;
  }
}
.dlt-c8y-icon-monitoring {
  &:before {
    content: $dlt-c8y-icon-monitoring;
  }
}
.dlt-c8y-icon-qr-code {
  &:before {
    content: $dlt-c8y-icon-qr-code;
  }
}
.dlt-c8y-icon-online {
  &:before {
    content: $dlt-c8y-icon-online;
  }
}
.dlt-c8y-icon-temperature {
  &:before {
    content: $dlt-c8y-icon-temperature;
  }
}
.dlt-c8y-icon-air-conditioner {
  &:before {
    content: $dlt-c8y-icon-air-conditioner;
  }
}
.dlt-c8y-icon-air-shaft {
  &:before {
    content: $dlt-c8y-icon-air-shaft;
  }
}
.dlt-c8y-icon-fan {
  &:before {
    content: $dlt-c8y-icon-fan;
  }
}
.dlt-c8y-icon-light {
  &:before {
    content: $dlt-c8y-icon-light;
  }
}
.dlt-c8y-icon-light-automation {
  &:before {
    content: $dlt-c8y-icon-light-automation;
  }
}
.dlt-c8y-icon-light-off {
  &:before {
    content: $dlt-c8y-icon-light-off;
  }
}
.dlt-c8y-icon-reflector-bulb {
  &:before {
    content: $dlt-c8y-icon-reflector-bulb;
  }
}
.dlt-c8y-icon-spiral-bulb {
  &:before {
    content: $dlt-c8y-icon-spiral-bulb;
  }
}
.dlt-c8y-icon-mirrored-reflector-bulb {
  &:before {
    content: $dlt-c8y-icon-mirrored-reflector-bulb;
  }
}
.dlt-c8y-icon-light-bulb {
  &:before {
    content: $dlt-c8y-icon-light-bulb;
  }
}
.dlt-c8y-icon-lightbulb-o {
  &:before {
    content: $dlt-c8y-icon-lightbulb-o;
  }
}
.dlt-c8y-icon-light-on {
  &:before {
    content: $dlt-c8y-icon-light-on;
  }
}
.dlt-c8y-icon-plumbing {
  &:before {
    content: $dlt-c8y-icon-plumbing;
  }
}
.dlt-c8y-icon-wi-fi-router {
  &:before {
    content: $dlt-c8y-icon-wi-fi-router;
  }
}
.dlt-c8y-icon-gas-bottle {
  &:before {
    content: $dlt-c8y-icon-gas-bottle;
  }
}
.dlt-c8y-icon-grater {
  &:before {
    content: $dlt-c8y-icon-grater;
  }
}
.dlt-c8y-icon-air-quality {
  &:before {
    content: $dlt-c8y-icon-air-quality;
  }
}
.dlt-c8y-icon-central-heating {
  &:before {
    content: $dlt-c8y-icon-central-heating;
  }
}
.dlt-c8y-icon-light-dimmer {
  &:before {
    content: $dlt-c8y-icon-light-dimmer;
  }
}
.dlt-c8y-icon-radio-station {
  &:before {
    content: $dlt-c8y-icon-radio-station;
  }
}
.dlt-c8y-icon-relay-home-automation {
  &:before {
    content: $dlt-c8y-icon-relay-home-automation;
  }
}
.dlt-c8y-icon-remote-control {
  &:before {
    content: $dlt-c8y-icon-remote-control;
  }
}
.dlt-c8y-icon-tv-off {
  &:before {
    content: $dlt-c8y-icon-tv-off;
  }
}
.dlt-c8y-icon-wall-socket {
  &:before {
    content: $dlt-c8y-icon-wall-socket;
  }
}
.dlt-c8y-icon-washing-machine {
  &:before {
    content: $dlt-c8y-icon-washing-machine;
  }
}
.dlt-c8y-icon-light-dimming-100-percent {
  &:before {
    content: $dlt-c8y-icon-light-dimming-100-percent;
  }
}
.dlt-c8y-icon-light-dimming-10-percent {
  &:before {
    content: $dlt-c8y-icon-light-dimming-10-percent;
  }
}
.dlt-c8y-icon-work-light {
  &:before {
    content: $dlt-c8y-icon-work-light;
  }
}
.dlt-c8y-icon-plug {
  &:before {
    content: $dlt-c8y-icon-plug;
  }
}
.dlt-c8y-icon-electrical {
  &:before {
    content: $dlt-c8y-icon-electrical;
  }
}
.dlt-c8y-icon-water-pipe {
  &:before {
    content: $dlt-c8y-icon-water-pipe;
  }
}
.dlt-c8y-icon-thermometer-automation {
  &:before {
    content: $dlt-c8y-icon-thermometer-automation;
  }
}
.dlt-c8y-icon-fire-extinguisher {
  &:before {
    content: $dlt-c8y-icon-fire-extinguisher;
  }
}
.dlt-c8y-icon-fire-extinguisher {
  &:before {
    content: $dlt-c8y-icon-fire-extinguisher;
  }
}
.dlt-c8y-icon-temperature-sensitive {
  &:before {
    content: $dlt-c8y-icon-temperature-sensitive;
  }
}
.dlt-c8y-icon-calculator {
  &:before {
    content: $dlt-c8y-icon-calculator;
  }
}
.dlt-c8y-icon-humidity {
  &:before {
    content: $dlt-c8y-icon-humidity;
  }
}
.dlt-c8y-icon-system-information {
  &:before {
    content: $dlt-c8y-icon-system-information;
  }
}
.dlt-c8y-icon-system-report {
  &:before {
    content: $dlt-c8y-icon-system-report;
  }
}
.dlt-c8y-icon-system-task {
  &:before {
    content: $dlt-c8y-icon-system-task;
  }
}
.dlt-c8y-icon-imac-clock {
  &:before {
    content: $dlt-c8y-icon-imac-clock;
  }
}
.dlt-c8y-icon-imac-exit {
  &:before {
    content: $dlt-c8y-icon-imac-exit;
  }
}
.dlt-c8y-icon-imac-settings {
  &:before {
    content: $dlt-c8y-icon-imac-settings;
  }
}
.dlt-c8y-icon-macbook-settings {
  &:before {
    content: $dlt-c8y-icon-macbook-settings;
  }
}
.dlt-c8y-icon-connected {
  &:before {
    content: $dlt-c8y-icon-connected;
  }
}
.dlt-c8y-icon-disconnected {
  &:before {
    content: $dlt-c8y-icon-disconnected;
  }
}
.dlt-c8y-icon-mobile {
  &:before {
    content: $dlt-c8y-icon-mobile;
  }
}
.dlt-c8y-icon-multiple-devices {
  &:before {
    content: $dlt-c8y-icon-multiple-devices;
  }
}
.dlt-c8y-icon-client-management {
  &:before {
    content: $dlt-c8y-icon-client-management;
  }
}
.dlt-c8y-icon-nfc {
  &:before {
    content: $dlt-c8y-icon-nfc;
  }
}
.dlt-c8y-icon-mobile-email {
  &:before {
    content: $dlt-c8y-icon-mobile-email;
  }
}
.dlt-c8y-icon-empty-battery {
  &:before {
    content: $dlt-c8y-icon-empty-battery;
  }
}
.dlt-c8y-icon-battery-empty {
  &:before {
    content: $dlt-c8y-icon-battery-empty;
  }
}
.dlt-c8y-icon-battery-0 {
  &:before {
    content: $dlt-c8y-icon-battery-0;
  }
}
.dlt-c8y-icon-low-battery {
  &:before {
    content: $dlt-c8y-icon-low-battery;
  }
}
.dlt-c8y-icon-battery-quarter {
  &:before {
    content: $dlt-c8y-icon-battery-quarter;
  }
}
.dlt-c8y-icon-battery-1 {
  &:before {
    content: $dlt-c8y-icon-battery-1;
  }
}
.dlt-c8y-icon-battery-level {
  &:before {
    content: $dlt-c8y-icon-battery-level;
  }
}
.dlt-c8y-icon-battery-2 {
  &:before {
    content: $dlt-c8y-icon-battery-2;
  }
}
.dlt-c8y-icon-battery-half {
  &:before {
    content: $dlt-c8y-icon-battery-half;
  }
}
.dlt-c8y-icon-charged-battery {
  &:before {
    content: $dlt-c8y-icon-charged-battery;
  }
}
.dlt-c8y-icon-battery-3 {
  &:before {
    content: $dlt-c8y-icon-battery-3;
  }
}
.dlt-c8y-icon-battery-three-quarters {
  &:before {
    content: $dlt-c8y-icon-battery-three-quarters;
  }
}
.dlt-c8y-icon-full-battery {
  &:before {
    content: $dlt-c8y-icon-full-battery;
  }
}
.dlt-c8y-icon-battery-full {
  &:before {
    content: $dlt-c8y-icon-battery-full;
  }
}
.dlt-c8y-icon-battery-4 {
  &:before {
    content: $dlt-c8y-icon-battery-4;
  }
}
.dlt-c8y-icon-battery {
  &:before {
    content: $dlt-c8y-icon-battery;
  }
}
.dlt-c8y-icon-no-battery {
  &:before {
    content: $dlt-c8y-icon-no-battery;
  }
}
.dlt-c8y-icon-charging-battery {
  &:before {
    content: $dlt-c8y-icon-charging-battery;
  }
}
.dlt-c8y-icon-no-connection {
  &:before {
    content: $dlt-c8y-icon-no-connection;
  }
}
.dlt-c8y-icon-low-connection {
  &:before {
    content: $dlt-c8y-icon-low-connection;
  }
}
.dlt-c8y-icon-signal {
  &:before {
    content: $dlt-c8y-icon-signal;
  }
}
.dlt-c8y-icon-factory {
  &:before {
    content: $dlt-c8y-icon-factory;
  }
}
.dlt-c8y-icon-industry {
  &:before {
    content: $dlt-c8y-icon-industry;
  }
}
.dlt-c8y-icon-robot {
  &:before {
    content: $dlt-c8y-icon-robot;
  }
}
.dlt-c8y-icon-bot {
  &:before {
    content: $dlt-c8y-icon-bot;
  }
}
.dlt-c8y-icon-fork-lift {
  &:before {
    content: $dlt-c8y-icon-fork-lift;
  }
}
.dlt-c8y-icon-gear-complex {
  &:before {
    content: $dlt-c8y-icon-gear-complex;
  }
}
.dlt-c8y-icon-cog-complex {
  &:before {
    content: $dlt-c8y-icon-cog-complex;
  }
}
.dlt-c8y-icon-gears {
  &:before {
    content: $dlt-c8y-icon-gears;
  }
}
.dlt-c8y-icon-cogs {
  &:before {
    content: $dlt-c8y-icon-cogs;
  }
}
.dlt-c8y-icon-automation {
  &:before {
    content: $dlt-c8y-icon-automation;
  }
}
.dlt-c8y-icon-automatic {
  &:before {
    content: $dlt-c8y-icon-automatic;
  }
}
.dlt-c8y-icon-electronics {
  &:before {
    content: $dlt-c8y-icon-electronics;
  }
}
.dlt-c8y-icon-gas {
  &:before {
    content: $dlt-c8y-icon-gas;
  }
}
.dlt-c8y-icon-water {
  &:before {
    content: $dlt-c8y-icon-water;
  }
}
.dlt-c8y-icon-tint {
  &:before {
    content: $dlt-c8y-icon-tint;
  }
}
.dlt-c8y-icon-greentech {
  &:before {
    content: $dlt-c8y-icon-greentech;
  }
}
.dlt-c8y-icon-radio-tower {
  &:before {
    content: $dlt-c8y-icon-radio-tower;
  }
}
.dlt-c8y-icon-industrial-scales {
  &:before {
    content: $dlt-c8y-icon-industrial-scales;
  }
}
.dlt-c8y-icon-balance-scale {
  &:before {
    content: $dlt-c8y-icon-balance-scale;
  }
}
.dlt-c8y-icon-iphone {
  &:before {
    content: $dlt-c8y-icon-iphone;
  }
}
.dlt-c8y-icon-mobile-phone {
  &:before {
    content: $dlt-c8y-icon-mobile-phone;
  }
}
.dlt-c8y-icon-smartphone {
  &:before {
    content: $dlt-c8y-icon-smartphone;
  }
}
.dlt-c8y-icon-smartphone-approve {
  &:before {
    content: $dlt-c8y-icon-smartphone-approve;
  }
}
.dlt-c8y-icon-smartphone-decline {
  &:before {
    content: $dlt-c8y-icon-smartphone-decline;
  }
}
.dlt-c8y-icon-phonelink-lock {
  &:before {
    content: $dlt-c8y-icon-phonelink-lock;
  }
}
.dlt-c8y-icon-tablet-mac {
  &:before {
    content: $dlt-c8y-icon-tablet-mac;
  }
}
.dlt-c8y-icon-tablet {
  &:before {
    content: $dlt-c8y-icon-tablet;
  }
}
.dlt-c8y-icon-devices {
  &:before {
    content: $dlt-c8y-icon-devices;
  }
}
.dlt-c8y-icon-imac {
  &:before {
    content: $dlt-c8y-icon-imac;
  }
}
.dlt-c8y-icon-desktop {
  &:before {
    content: $dlt-c8y-icon-desktop;
  }
}
.dlt-c8y-icon-desktop1 {
  &:before {
    content: $dlt-c8y-icon-desktop1;
  }
}
.dlt-c8y-icon-monitor {
  &:before {
    content: $dlt-c8y-icon-monitor;
  }
}
.dlt-c8y-icon-tv {
  &:before {
    content: $dlt-c8y-icon-tv;
  }
}
.dlt-c8y-icon-television {
  &:before {
    content: $dlt-c8y-icon-television;
  }
}
.dlt-c8y-icon-laptop {
  &:before {
    content: $dlt-c8y-icon-laptop;
  }
}
.dlt-c8y-icon-checked-laptop {
  &:before {
    content: $dlt-c8y-icon-checked-laptop;
  }
}
.dlt-c8y-icon-laptop-alert {
  &:before {
    content: $dlt-c8y-icon-laptop-alert;
  }
}
.dlt-c8y-icon-laptop-error {
  &:before {
    content: $dlt-c8y-icon-laptop-error;
  }
}
.dlt-c8y-icon-computer-support {
  &:before {
    content: $dlt-c8y-icon-computer-support;
  }
}
.dlt-c8y-icon-programming {
  &:before {
    content: $dlt-c8y-icon-programming;
  }
}
.dlt-c8y-icon-keyboard {
  &:before {
    content: $dlt-c8y-icon-keyboard;
  }
}
.dlt-c8y-icon-keyboard-o {
  &:before {
    content: $dlt-c8y-icon-keyboard-o;
  }
}
.dlt-c8y-icon-video-conference {
  &:before {
    content: $dlt-c8y-icon-video-conference;
  }
}
.dlt-c8y-icon-remote-desktop {
  &:before {
    content: $dlt-c8y-icon-remote-desktop;
  }
}
.dlt-c8y-icon-workspace {
  &:before {
    content: $dlt-c8y-icon-workspace;
  }
}
.dlt-c8y-icon-workstation {
  &:before {
    content: $dlt-c8y-icon-workstation;
  }
}
.dlt-c8y-icon-microchip {
  &:before {
    content: $dlt-c8y-icon-microchip;
  }
}
.dlt-c8y-icon-processor {
  &:before {
    content: $dlt-c8y-icon-processor;
  }
}
.dlt-c8y-icon-smartphone-ram {
  &:before {
    content: $dlt-c8y-icon-smartphone-ram;
  }
}
.dlt-c8y-icon-bios {
  &:before {
    content: $dlt-c8y-icon-bios;
  }
}
.dlt-c8y-icon-micro-sd {
  &:before {
    content: $dlt-c8y-icon-micro-sd;
  }
}
.dlt-c8y-icon-hdd {
  &:before {
    content: $dlt-c8y-icon-hdd;
  }
}
.dlt-c8y-icon-hdd-o {
  &:before {
    content: $dlt-c8y-icon-hdd-o;
  }
}
.dlt-c8y-icon-financial-dynamic-presentation {
  &:before {
    content: $dlt-c8y-icon-financial-dynamic-presentation;
  }
}
.dlt-c8y-icon-presentation {
  &:before {
    content: $dlt-c8y-icon-presentation;
  }
}
.dlt-c8y-icon-room-finder {
  &:before {
    content: $dlt-c8y-icon-room-finder;
  }
}
.dlt-c8y-icon-statistics {
  &:before {
    content: $dlt-c8y-icon-statistics;
  }
}
.dlt-c8y-icon-server {
  &:before {
    content: $dlt-c8y-icon-server;
  }
}
.dlt-c8y-icon-stack {
  &:before {
    content: $dlt-c8y-icon-stack;
  }
}
.dlt-c8y-icon-critical-thinking {
  &:before {
    content: $dlt-c8y-icon-critical-thinking;
  }
}
.dlt-c8y-icon-environment1 {
  &:before {
    content: $dlt-c8y-icon-environment1;
  }
}
.dlt-c8y-icon-network-card {
  &:before {
    content: $dlt-c8y-icon-network-card;
  }
}
.dlt-c8y-icon-network-cable {
  &:before {
    content: $dlt-c8y-icon-network-cable;
  }
}
.dlt-c8y-icon-usb-on {
  &:before {
    content: $dlt-c8y-icon-usb-on;
  }
}
.dlt-c8y-icon-ssd {
  &:before {
    content: $dlt-c8y-icon-ssd;
  }
}
.dlt-c8y-icon-asterisk-key {
  &:before {
    content: $dlt-c8y-icon-asterisk-key;
  }
}
.dlt-c8y-icon-asterisk {
  &:before {
    content: $dlt-c8y-icon-asterisk;
  }
}
.dlt-c8y-icon-end-button {
  &:before {
    content: $dlt-c8y-icon-end-button;
  }
}
.dlt-c8y-icon-hub {
  &:before {
    content: $dlt-c8y-icon-hub;
  }
}
.dlt-c8y-icon-individual-server {
  &:before {
    content: $dlt-c8y-icon-individual-server;
  }
}
.dlt-c8y-icon-data-center {
  &:before {
    content: $dlt-c8y-icon-data-center;
  }
}
.dlt-c8y-icon-root-server {
  &:before {
    content: $dlt-c8y-icon-root-server;
  }
}
.dlt-c8y-icon-switch {
  &:before {
    content: $dlt-c8y-icon-switch;
  }
}
.dlt-c8y-icon-nas {
  &:before {
    content: $dlt-c8y-icon-nas;
  }
}
.dlt-c8y-icon-shutdown {
  &:before {
    content: $dlt-c8y-icon-shutdown;
  }
}
.dlt-c8y-icon-power-off {
  &:before {
    content: $dlt-c8y-icon-power-off;
  }
}
.dlt-c8y-icon-hibernate {
  &:before {
    content: $dlt-c8y-icon-hibernate;
  }
}
.dlt-c8y-icon-phone-square {
  &:before {
    content: $dlt-c8y-icon-phone-square;
  }
}
.dlt-c8y-icon-phone {
  &:before {
    content: $dlt-c8y-icon-phone;
  }
}
.dlt-c8y-icon-no-idea {
  &:before {
    content: $dlt-c8y-icon-no-idea;
  }
}
.dlt-c8y-icon-network {
  &:before {
    content: $dlt-c8y-icon-network;
  }
}
.dlt-c8y-icon-mind-map {
  &:before {
    content: $dlt-c8y-icon-mind-map;
  }
}
.dlt-c8y-icon-centralized-network {
  &:before {
    content: $dlt-c8y-icon-centralized-network;
  }
}
.dlt-c8y-icon-customer-insights-manager {
  &:before {
    content: $dlt-c8y-icon-customer-insights-manager;
  }
}
.dlt-c8y-icon-gift {
  &:before {
    content: $dlt-c8y-icon-gift;
  }
}
.dlt-c8y-icon-small-business {
  &:before {
    content: $dlt-c8y-icon-small-business;
  }
}
.dlt-c8y-icon-pricing {
  &:before {
    content: $dlt-c8y-icon-pricing;
  }
}
.dlt-c8y-icon-free-trial {
  &:before {
    content: $dlt-c8y-icon-free-trial;
  }
}
.dlt-c8y-icon-buy1 {
  &:before {
    content: $dlt-c8y-icon-buy1;
  }
}
.dlt-c8y-icon-cart-plus {
  &:before {
    content: $dlt-c8y-icon-cart-plus;
  }
}
.dlt-c8y-icon-cart-arrow-down {
  &:before {
    content: $dlt-c8y-icon-cart-arrow-down;
  }
}
.dlt-c8y-icon-return-purchase {
  &:before {
    content: $dlt-c8y-icon-return-purchase;
  }
}
.dlt-c8y-icon-shopping-cart-with-money {
  &:before {
    content: $dlt-c8y-icon-shopping-cart-with-money;
  }
}
.dlt-c8y-icon-buying {
  &:before {
    content: $dlt-c8y-icon-buying;
  }
}
.dlt-c8y-icon-shopping-cart {
  &:before {
    content: $dlt-c8y-icon-shopping-cart;
  }
}
.dlt-c8y-icon-procurement {
  &:before {
    content: $dlt-c8y-icon-procurement;
  }
}
.dlt-c8y-icon-delivery-handcart {
  &:before {
    content: $dlt-c8y-icon-delivery-handcart;
  }
}
.dlt-c8y-icon-cheap-21 {
  &:before {
    content: $dlt-c8y-icon-cheap-21;
  }
}
.dlt-c8y-icon-average-2 {
  &:before {
    content: $dlt-c8y-icon-average-2;
  }
}
.dlt-c8y-icon-stripe1 {
  &:before {
    content: $dlt-c8y-icon-stripe1;
  }
}
.dlt-c8y-icon-loyalty-card {
  &:before {
    content: $dlt-c8y-icon-loyalty-card;
  }
}
.dlt-c8y-icon-shopping-check {
  &:before {
    content: $dlt-c8y-icon-shopping-check;
  }
}
.dlt-c8y-icon-shopping-bag {
  &:before {
    content: $dlt-c8y-icon-shopping-bag;
  }
}
.dlt-c8y-icon-shopping-bag {
  &:before {
    content: $dlt-c8y-icon-shopping-bag;
  }
}
.dlt-c8y-icon-atm {
  &:before {
    content: $dlt-c8y-icon-atm;
  }
}
.dlt-c8y-icon-bank-card-dollar {
  &:before {
    content: $dlt-c8y-icon-bank-card-dollar;
  }
}
.dlt-c8y-icon-bank-card-euro {
  &:before {
    content: $dlt-c8y-icon-bank-card-euro;
  }
}
.dlt-c8y-icon-contactless-payment {
  &:before {
    content: $dlt-c8y-icon-contactless-payment;
  }
}
.dlt-c8y-icon-qr-code {
  &:before {
    content: $dlt-c8y-icon-qr-code;
  }
}
.dlt-c8y-icon-qrcode {
  &:before {
    content: $dlt-c8y-icon-qrcode;
  }
}
.dlt-c8y-icon-data-matrix-code {
  &:before {
    content: $dlt-c8y-icon-data-matrix-code;
  }
}
.dlt-c8y-icon-barcode {
  &:before {
    content: $dlt-c8y-icon-barcode;
  }
}
.dlt-c8y-icon-no-barcode {
  &:before {
    content: $dlt-c8y-icon-no-barcode;
  }
}
.dlt-c8y-icon-refresh-barcode {
  &:before {
    content: $dlt-c8y-icon-refresh-barcode;
  }
}
.dlt-c8y-icon-touch-id {
  &:before {
    content: $dlt-c8y-icon-touch-id;
  }
}
.dlt-c8y-icon-redeem {
  &:before {
    content: $dlt-c8y-icon-redeem;
  }
}
.dlt-c8y-icon-card-exchange {
  &:before {
    content: $dlt-c8y-icon-card-exchange;
  }
}
.dlt-c8y-icon-card-security {
  &:before {
    content: $dlt-c8y-icon-card-security;
  }
}
.dlt-c8y-icon-cash-register {
  &:before {
    content: $dlt-c8y-icon-cash-register;
  }
}
.dlt-c8y-icon-cost {
  &:before {
    content: $dlt-c8y-icon-cost;
  }
}
.dlt-c8y-icon-create-order {
  &:before {
    content: $dlt-c8y-icon-create-order;
  }
}
.dlt-c8y-icon-online-payment1 {
  &:before {
    content: $dlt-c8y-icon-online-payment1;
  }
}
.dlt-c8y-icon-rent1 {
  &:before {
    content: $dlt-c8y-icon-rent1;
  }
}
.dlt-c8y-icon-package-settings {
  &:before {
    content: $dlt-c8y-icon-package-settings;
  }
}
.dlt-c8y-icon-product-loading {
  &:before {
    content: $dlt-c8y-icon-product-loading;
  }
}
.dlt-c8y-icon-purchase-order {
  &:before {
    content: $dlt-c8y-icon-purchase-order;
  }
}
.dlt-c8y-icon-receipt1 {
  &:before {
    content: $dlt-c8y-icon-receipt1;
  }
}
.dlt-c8y-icon-scan-stock {
  &:before {
    content: $dlt-c8y-icon-scan-stock;
  }
}
.dlt-c8y-icon-shipment-logistic {
  &:before {
    content: $dlt-c8y-icon-shipment-logistic;
  }
}
.dlt-c8y-icon-shipping-center {
  &:before {
    content: $dlt-c8y-icon-shipping-center;
  }
}
.dlt-c8y-icon-shipping-centre-loading-belt {
  &:before {
    content: $dlt-c8y-icon-shipping-centre-loading-belt;
  }
}
.dlt-c8y-icon-shopaholic {
  &:before {
    content: $dlt-c8y-icon-shopaholic;
  }
}
.dlt-c8y-icon-basket {
  &:before {
    content: $dlt-c8y-icon-basket;
  }
}
.dlt-c8y-icon-shopping-basket {
  &:before {
    content: $dlt-c8y-icon-shopping-basket;
  }
}
.dlt-c8y-icon-shopping-basket {
  &:before {
    content: $dlt-c8y-icon-shopping-basket;
  }
}
.dlt-c8y-icon-add-basket {
  &:before {
    content: $dlt-c8y-icon-add-basket;
  }
}
.dlt-c8y-icon-paid {
  &:before {
    content: $dlt-c8y-icon-paid;
  }
}
.dlt-c8y-icon-text-width {
  &:before {
    content: $dlt-c8y-icon-text-width;
  }
}
.dlt-c8y-icon-text-height {
  &:before {
    content: $dlt-c8y-icon-text-height;
  }
}
.dlt-c8y-icon-paragraph {
  &:before {
    content: $dlt-c8y-icon-paragraph;
  }
}
.dlt-c8y-icon-pilcrow {
  &:before {
    content: $dlt-c8y-icon-pilcrow;
  }
}
.dlt-c8y-icon-text-input {
  &:before {
    content: $dlt-c8y-icon-text-input;
  }
}
.dlt-c8y-icon-unavailable {
  &:before {
    content: $dlt-c8y-icon-unavailable;
  }
}
.dlt-c8y-icon-save {
  &:before {
    content: $dlt-c8y-icon-save;
  }
}
.dlt-c8y-icon-save-commit {
  &:before {
    content: $dlt-c8y-icon-save-commit;
  }
}
.dlt-c8y-icon-delete {
  &:before {
    content: $dlt-c8y-icon-delete;
  }
}
.dlt-c8y-icon-copy {
  &:before {
    content: $dlt-c8y-icon-copy;
  }
}
.dlt-c8y-icon-paste {
  &:before {
    content: $dlt-c8y-icon-paste;
  }
}
.dlt-c8y-icon-more-menu {
  &:before {
    content: $dlt-c8y-icon-more-menu;
  }
}
.dlt-c8y-icon-password-hide {
  &:before {
    content: $dlt-c8y-icon-password-hide;
  }
}
.dlt-c8y-icon-password-show {
  &:before {
    content: $dlt-c8y-icon-password-show;
  }
}
.dlt-c8y-icon-zoom-in {
  &:before {
    content: $dlt-c8y-icon-zoom-in;
  }
}
.dlt-c8y-icon-zoom-out {
  &:before {
    content: $dlt-c8y-icon-zoom-out;
  }
}
.dlt-c8y-icon-buy {
  &:before {
    content: $dlt-c8y-icon-buy;
  }
}
.dlt-c8y-icon-list {
  &:before {
    content: $dlt-c8y-icon-list;
  }
}
.dlt-c8y-icon-list-alt {
  &:before {
    content: $dlt-c8y-icon-list-alt;
  }
}
.dlt-c8y-icon-list-ul {
  &:before {
    content: $dlt-c8y-icon-list-ul;
  }
}
.dlt-c8y-icon-format-align-justify {
  &:before {
    content: $dlt-c8y-icon-format-align-justify;
  }
}
.dlt-c8y-icon-align-justify {
  &:before {
    content: $dlt-c8y-icon-align-justify;
  }
}
.dlt-c8y-icon-format-align-left {
  &:before {
    content: $dlt-c8y-icon-format-align-left;
  }
}
.dlt-c8y-icon-align-left {
  &:before {
    content: $dlt-c8y-icon-align-left;
  }
}
.dlt-c8y-icon-format-align-center {
  &:before {
    content: $dlt-c8y-icon-format-align-center;
  }
}
.dlt-c8y-icon-align-center {
  &:before {
    content: $dlt-c8y-icon-align-center;
  }
}
.dlt-c8y-icon-format-align-right {
  &:before {
    content: $dlt-c8y-icon-format-align-right;
  }
}
.dlt-c8y-icon-align-right {
  &:before {
    content: $dlt-c8y-icon-align-right;
  }
}
.dlt-c8y-icon-radio-button-on {
  &:before {
    content: $dlt-c8y-icon-radio-button-on;
  }
}
.dlt-c8y-icon-dot-circle-o {
  &:before {
    content: $dlt-c8y-icon-dot-circle-o;
  }
}
.dlt-c8y-icon-colorize {
  &:before {
    content: $dlt-c8y-icon-colorize;
  }
}
.dlt-c8y-icon-eyedropper {
  &:before {
    content: $dlt-c8y-icon-eyedropper;
  }
}
.dlt-c8y-icon-brush {
  &:before {
    content: $dlt-c8y-icon-brush;
  }
}
.dlt-c8y-icon-paint-brush {
  &:before {
    content: $dlt-c8y-icon-paint-brush;
  }
}
.dlt-c8y-icon-file-create {
  &:before {
    content: $dlt-c8y-icon-file-create;
  }
}
.dlt-c8y-icon-edit {
  &:before {
    content: $dlt-c8y-icon-edit;
  }
}
.dlt-c8y-icon-email-sign {
  &:before {
    content: $dlt-c8y-icon-email-sign;
  }
}
.dlt-c8y-icon-at {
  &:before {
    content: $dlt-c8y-icon-at;
  }
}
.dlt-c8y-icon-get-quote {
  &:before {
    content: $dlt-c8y-icon-get-quote;
  }
}
.dlt-c8y-icon-quote-right {
  &:before {
    content: $dlt-c8y-icon-quote-right;
  }
}
.dlt-c8y-icon-quote-left {
  &:before {
    content: $dlt-c8y-icon-quote-left;
  }
}
.dlt-c8y-icon-format-bold {
  &:before {
    content: $dlt-c8y-icon-format-bold;
  }
}
.dlt-c8y-icon-bold {
  &:before {
    content: $dlt-c8y-icon-bold;
  }
}
.dlt-c8y-icon-format-underlined {
  &:before {
    content: $dlt-c8y-icon-format-underlined;
  }
}
.dlt-c8y-icon-underline {
  &:before {
    content: $dlt-c8y-icon-underline;
  }
}
.dlt-c8y-icon-strikethrough-s {
  &:before {
    content: $dlt-c8y-icon-strikethrough-s;
  }
}
.dlt-c8y-icon-strikethrough {
  &:before {
    content: $dlt-c8y-icon-strikethrough;
  }
}
.dlt-c8y-icon-title {
  &:before {
    content: $dlt-c8y-icon-title;
  }
}
.dlt-c8y-icon-header {
  &:before {
    content: $dlt-c8y-icon-header;
  }
}
.dlt-c8y-icon-subscript {
  &:before {
    content: $dlt-c8y-icon-subscript;
  }
}
.dlt-c8y-icon-superscript {
  &:before {
    content: $dlt-c8y-icon-superscript;
  }
}
.dlt-c8y-icon-format-italic {
  &:before {
    content: $dlt-c8y-icon-format-italic;
  }
}
.dlt-c8y-icon-italic {
  &:before {
    content: $dlt-c8y-icon-italic;
  }
}
.dlt-c8y-icon-font-download {
  &:before {
    content: $dlt-c8y-icon-font-download;
  }
}
.dlt-c8y-icon-font {
  &:before {
    content: $dlt-c8y-icon-font;
  }
}
.dlt-c8y-icon-questionnaire {
  &:before {
    content: $dlt-c8y-icon-questionnaire;
  }
}
.dlt-c8y-icon-th-list {
  &:before {
    content: $dlt-c8y-icon-th-list;
  }
}
.dlt-c8y-icon-tasklist {
  &:before {
    content: $dlt-c8y-icon-tasklist;
  }
}
.dlt-c8y-icon-communication-sorting {
  &:before {
    content: $dlt-c8y-icon-communication-sorting;
  }
}
.dlt-c8y-icon-todo-list {
  &:before {
    content: $dlt-c8y-icon-todo-list;
  }
}
.dlt-c8y-icon-tasks {
  &:before {
    content: $dlt-c8y-icon-tasks;
  }
}
.dlt-c8y-icon-numbered-list {
  &:before {
    content: $dlt-c8y-icon-numbered-list;
  }
}
.dlt-c8y-icon-list-ol {
  &:before {
    content: $dlt-c8y-icon-list-ol;
  }
}
.dlt-c8y-icon-search-in-list {
  &:before {
    content: $dlt-c8y-icon-search-in-list;
  }
}
.dlt-c8y-icon-table-of-content {
  &:before {
    content: $dlt-c8y-icon-table-of-content;
  }
}
.dlt-c8y-icon-index {
  &:before {
    content: $dlt-c8y-icon-index;
  }
}
.dlt-c8y-icon-tiles {
  &:before {
    content: $dlt-c8y-icon-tiles;
  }
}
.dlt-c8y-icon-edit1 {
  &:before {
    content: $dlt-c8y-icon-edit1;
  }
}
.dlt-c8y-icon-pencil {
  &:before {
    content: $dlt-c8y-icon-pencil;
  }
}
.dlt-c8y-icon-pencil-square {
  &:before {
    content: $dlt-c8y-icon-pencil-square;
  }
}
.dlt-c8y-icon-pencil-square-o {
  &:before {
    content: $dlt-c8y-icon-pencil-square-o;
  }
}
.dlt-c8y-icon-edit11 {
  &:before {
    content: $dlt-c8y-icon-edit11;
  }
}
.dlt-c8y-icon-no-edit {
  &:before {
    content: $dlt-c8y-icon-no-edit;
  }
}
.dlt-c8y-icon-cut {
  &:before {
    content: $dlt-c8y-icon-cut;
  }
}
.dlt-c8y-icon-scissors {
  &:before {
    content: $dlt-c8y-icon-scissors;
  }
}
.dlt-c8y-icon-coupon {
  &:before {
    content: $dlt-c8y-icon-coupon;
  }
}
.dlt-c8y-icon-crop {
  &:before {
    content: $dlt-c8y-icon-crop;
  }
}
.dlt-c8y-icon-erase {
  &:before {
    content: $dlt-c8y-icon-erase;
  }
}
.dlt-c8y-icon-eraser {
  &:before {
    content: $dlt-c8y-icon-eraser;
  }
}
.dlt-c8y-icon-add-white-space {
  &:before {
    content: $dlt-c8y-icon-add-white-space;
  }
}
.dlt-c8y-icon-blur {
  &:before {
    content: $dlt-c8y-icon-blur;
  }
}
.dlt-c8y-icon-paint-palette {
  &:before {
    content: $dlt-c8y-icon-paint-palette;
  }
}
.dlt-c8y-icon-contrast {
  &:before {
    content: $dlt-c8y-icon-contrast;
  }
}
.dlt-c8y-icon-adjust {
  &:before {
    content: $dlt-c8y-icon-adjust;
  }
}
.dlt-c8y-icon-bring-forward {
  &:before {
    content: $dlt-c8y-icon-bring-forward;
  }
}
.dlt-c8y-icon-snap-background-to-white {
  &:before {
    content: $dlt-c8y-icon-snap-background-to-white;
  }
}
.dlt-c8y-icon-rename {
  &:before {
    content: $dlt-c8y-icon-rename;
  }
}
.dlt-c8y-icon-text-cursor {
  &:before {
    content: $dlt-c8y-icon-text-cursor;
  }
}
.dlt-c8y-icon-i-cursor {
  &:before {
    content: $dlt-c8y-icon-i-cursor;
  }
}
.dlt-c8y-icon-large-icons {
  &:before {
    content: $dlt-c8y-icon-large-icons;
  }
}
.dlt-c8y-icon-comma {
  &:before {
    content: $dlt-c8y-icon-comma;
  }
}
.dlt-c8y-icon-compare {
  &:before {
    content: $dlt-c8y-icon-compare;
  }
}
.dlt-c8y-icon-content {
  &:before {
    content: $dlt-c8y-icon-content;
  }
}
.dlt-c8y-icon-document-body {
  &:before {
    content: $dlt-c8y-icon-document-body;
  }
}
.dlt-c8y-icon-merge-docunemts {
  &:before {
    content: $dlt-c8y-icon-merge-docunemts;
  }
}
.dlt-c8y-icon-unit {
  &:before {
    content: $dlt-c8y-icon-unit;
  }
}
.dlt-c8y-icon-group {
  &:before {
    content: $dlt-c8y-icon-group;
  }
}
.dlt-c8y-icon-group-objects {
  &:before {
    content: $dlt-c8y-icon-group-objects;
  }
}
.dlt-c8y-icon-object-group {
  &:before {
    content: $dlt-c8y-icon-object-group;
  }
}
.dlt-c8y-icon-object-ungroup {
  &:before {
    content: $dlt-c8y-icon-object-ungroup;
  }
}
.dlt-c8y-icon-ungroup {
  &:before {
    content: $dlt-c8y-icon-ungroup;
  }
}
.dlt-c8y-icon-hexagonal-pattern {
  &:before {
    content: $dlt-c8y-icon-hexagonal-pattern;
  }
}
.dlt-c8y-icon-diagonal-lines {
  &:before {
    content: $dlt-c8y-icon-diagonal-lines;
  }
}
.dlt-c8y-icon-line-width {
  &:before {
    content: $dlt-c8y-icon-line-width;
  }
}
.dlt-c8y-icon-long-words {
  &:before {
    content: $dlt-c8y-icon-long-words;
  }
}
.dlt-c8y-icon-indent {
  &:before {
    content: $dlt-c8y-icon-indent;
  }
}
.dlt-c8y-icon-indent-left {
  &:before {
    content: $dlt-c8y-icon-indent-left;
  }
}
.dlt-c8y-icon-indent-decrease {
  &:before {
    content: $dlt-c8y-icon-indent-decrease;
  }
}
.dlt-c8y-icon-dedent {
  &:before {
    content: $dlt-c8y-icon-dedent;
  }
}
.dlt-c8y-icon-dedent-left {
  &:before {
    content: $dlt-c8y-icon-dedent-left;
  }
}
.dlt-c8y-icon-outdent {
  &:before {
    content: $dlt-c8y-icon-outdent;
  }
}
.dlt-c8y-icon-indent-right {
  &:before {
    content: $dlt-c8y-icon-indent-right;
  }
}
.dlt-c8y-icon-dedent-right {
  &:before {
    content: $dlt-c8y-icon-dedent-right;
  }
}
.dlt-c8y-icon-drag-reorder {
  &:before {
    content: $dlt-c8y-icon-drag-reorder;
  }
}
.dlt-c8y-icon-drag-list-up {
  &:before {
    content: $dlt-c8y-icon-drag-list-up;
  }
}
.dlt-c8y-icon-pull-down {
  &:before {
    content: $dlt-c8y-icon-pull-down;
  }
}
.dlt-c8y-icon-page {
  &:before {
    content: $dlt-c8y-icon-page;
  }
}
.dlt-c8y-icon-rearrange {
  &:before {
    content: $dlt-c8y-icon-rearrange;
  }
}
.dlt-c8y-icon-resize-file {
  &:before {
    content: $dlt-c8y-icon-resize-file;
  }
}
.dlt-c8y-icon-resize {
  &:before {
    content: $dlt-c8y-icon-resize;
  }
}
.dlt-c8y-icon-rich-text-converter {
  &:before {
    content: $dlt-c8y-icon-rich-text-converter;
  }
}
.dlt-c8y-icon-select-all {
  &:before {
    content: $dlt-c8y-icon-select-all;
  }
}
.dlt-c8y-icon-select-none {
  &:before {
    content: $dlt-c8y-icon-select-none;
  }
}
.dlt-c8y-icon-ruler {
  &:before {
    content: $dlt-c8y-icon-ruler;
  }
}
.dlt-c8y-icon-length {
  &:before {
    content: $dlt-c8y-icon-length;
  }
}
.dlt-c8y-icon-send-backward {
  &:before {
    content: $dlt-c8y-icon-send-backward;
  }
}
.dlt-c8y-icon-send-to-back {
  &:before {
    content: $dlt-c8y-icon-send-to-back;
  }
}
.dlt-c8y-icon-replace {
  &:before {
    content: $dlt-c8y-icon-replace;
  }
}
.dlt-c8y-icon-separated-lists {
  &:before {
    content: $dlt-c8y-icon-separated-lists;
  }
}
.dlt-c8y-icon-short-words {
  &:before {
    content: $dlt-c8y-icon-short-words;
  }
}
.dlt-c8y-icon-mix-words {
  &:before {
    content: $dlt-c8y-icon-mix-words;
  }
}
.dlt-c8y-icon-line-style {
  &:before {
    content: $dlt-c8y-icon-line-style;
  }
}
.dlt-c8y-icon-design {
  &:before {
    content: $dlt-c8y-icon-design;
  }
}
.dlt-c8y-icon-view-stream {
  &:before {
    content: $dlt-c8y-icon-view-stream;
  }
}
.dlt-c8y-icon-four-squares {
  &:before {
    content: $dlt-c8y-icon-four-squares;
  }
}
.dlt-c8y-icon-th-large {
  &:before {
    content: $dlt-c8y-icon-th-large;
  }
}
.dlt-c8y-icon-thumbnails {
  &:before {
    content: $dlt-c8y-icon-thumbnails;
  }
}
.dlt-c8y-icon-th {
  &:before {
    content: $dlt-c8y-icon-th;
  }
}
.dlt-c8y-icon-view-module {
  &:before {
    content: $dlt-c8y-icon-view-module;
  }
}
.dlt-c8y-icon-top-view {
  &:before {
    content: $dlt-c8y-icon-top-view;
  }
}
.dlt-c8y-icon-orthogonal-view {
  &:before {
    content: $dlt-c8y-icon-orthogonal-view;
  }
}
.dlt-c8y-icon-rectangular {
  &:before {
    content: $dlt-c8y-icon-rectangular;
  }
}
.dlt-c8y-icon-square {
  &:before {
    content: $dlt-c8y-icon-square;
  }
}
.dlt-c8y-icon-view-column {
  &:before {
    content: $dlt-c8y-icon-view-column;
  }
}
.dlt-c8y-icon-columns {
  &:before {
    content: $dlt-c8y-icon-columns;
  }
}
.dlt-c8y-icon-file-archive-o {
  &:before {
    content: $dlt-c8y-icon-file-archive-o;
  }
}
.dlt-c8y-icon-file-zip-o {
  &:before {
    content: $dlt-c8y-icon-file-zip-o;
  }
}
.dlt-c8y-icon-file-video-o {
  &:before {
    content: $dlt-c8y-icon-file-video-o;
  }
}
.dlt-c8y-icon-file-movie-o {
  &:before {
    content: $dlt-c8y-icon-file-movie-o;
  }
}
.dlt-c8y-icon-file-audio-o {
  &:before {
    content: $dlt-c8y-icon-file-audio-o;
  }
}
.dlt-c8y-icon-file-sound-o {
  &:before {
    content: $dlt-c8y-icon-file-sound-o;
  }
}
.dlt-c8y-icon-microsoft-access {
  &:before {
    content: $dlt-c8y-icon-microsoft-access;
  }
}
.dlt-c8y-icon-microsoft-onenote {
  &:before {
    content: $dlt-c8y-icon-microsoft-onenote;
  }
}
.dlt-c8y-icon-microsoft-outlook {
  &:before {
    content: $dlt-c8y-icon-microsoft-outlook;
  }
}
.dlt-c8y-icon-microsoft-project {
  &:before {
    content: $dlt-c8y-icon-microsoft-project;
  }
}
.dlt-c8y-icon-microsoft-publisher {
  &:before {
    content: $dlt-c8y-icon-microsoft-publisher;
  }
}
.dlt-c8y-icon-microsoft-sharepoint {
  &:before {
    content: $dlt-c8y-icon-microsoft-sharepoint;
  }
}
.dlt-c8y-icon-microsoft-visio {
  &:before {
    content: $dlt-c8y-icon-microsoft-visio;
  }
}
.dlt-c8y-icon-word {
  &:before {
    content: $dlt-c8y-icon-word;
  }
}
.dlt-c8y-icon-file-word-o {
  &:before {
    content: $dlt-c8y-icon-file-word-o;
  }
}
.dlt-c8y-icon-excel {
  &:before {
    content: $dlt-c8y-icon-excel;
  }
}
.dlt-c8y-icon-file-excel-o {
  &:before {
    content: $dlt-c8y-icon-file-excel-o;
  }
}
.dlt-c8y-icon-ppt {
  &:before {
    content: $dlt-c8y-icon-ppt;
  }
}
.dlt-c8y-icon-file-powerpoint-o {
  &:before {
    content: $dlt-c8y-icon-file-powerpoint-o;
  }
}
.dlt-c8y-icon-create-archive {
  &:before {
    content: $dlt-c8y-icon-create-archive;
  }
}
.dlt-c8y-icon-save-archive {
  &:before {
    content: $dlt-c8y-icon-save-archive;
  }
}
.dlt-c8y-icon-delete-archive {
  &:before {
    content: $dlt-c8y-icon-delete-archive;
  }
}
.dlt-c8y-icon-gif {
  &:before {
    content: $dlt-c8y-icon-gif;
  }
}
.dlt-c8y-icon-gis {
  &:before {
    content: $dlt-c8y-icon-gis;
  }
}
.dlt-c8y-icon-gpx {
  &:before {
    content: $dlt-c8y-icon-gpx;
  }
}
.dlt-c8y-icon-heic-filetype {
  &:before {
    content: $dlt-c8y-icon-heic-filetype;
  }
}
.dlt-c8y-icon-dmg {
  &:before {
    content: $dlt-c8y-icon-dmg;
  }
}
.dlt-c8y-icon-jpg {
  &:before {
    content: $dlt-c8y-icon-jpg;
  }
}
.dlt-c8y-icon-kmz {
  &:before {
    content: $dlt-c8y-icon-kmz;
  }
}
.dlt-c8y-icon-kml {
  &:before {
    content: $dlt-c8y-icon-kml;
  }
}
.dlt-c8y-icon-mp3 {
  &:before {
    content: $dlt-c8y-icon-mp3;
  }
}
.dlt-c8y-icon-dng {
  &:before {
    content: $dlt-c8y-icon-dng;
  }
}
.dlt-c8y-icon-nef {
  &:before {
    content: $dlt-c8y-icon-nef;
  }
}
.dlt-c8y-icon-nmea {
  &:before {
    content: $dlt-c8y-icon-nmea;
  }
}
.dlt-c8y-icon-tar {
  &:before {
    content: $dlt-c8y-icon-tar;
  }
}
.dlt-c8y-icon-ttf {
  &:before {
    content: $dlt-c8y-icon-ttf;
  }
}
.dlt-c8y-icon-tif {
  &:before {
    content: $dlt-c8y-icon-tif;
  }
}
.dlt-c8y-icon-obj {
  &:before {
    content: $dlt-c8y-icon-obj;
  }
}
.dlt-c8y-icon-webp {
  &:before {
    content: $dlt-c8y-icon-webp;
  }
}
.dlt-c8y-icon-ogg {
  &:before {
    content: $dlt-c8y-icon-ogg;
  }
}
.dlt-c8y-icon-woff {
  &:before {
    content: $dlt-c8y-icon-woff;
  }
}
.dlt-c8y-icon-xls {
  &:before {
    content: $dlt-c8y-icon-xls;
  }
}
.dlt-c8y-icon-xps {
  &:before {
    content: $dlt-c8y-icon-xps;
  }
}
.dlt-c8y-icon-zip {
  &:before {
    content: $dlt-c8y-icon-zip;
  }
}
.dlt-c8y-icon-wma {
  &:before {
    content: $dlt-c8y-icon-wma;
  }
}
.dlt-c8y-icon-osm {
  &:before {
    content: $dlt-c8y-icon-osm;
  }
}
.dlt-c8y-icon-otf {
  &:before {
    content: $dlt-c8y-icon-otf;
  }
}
.dlt-c8y-icon-dwg {
  &:before {
    content: $dlt-c8y-icon-dwg;
  }
}
.dlt-c8y-icon-png {
  &:before {
    content: $dlt-c8y-icon-png;
  }
}
.dlt-c8y-icon-eps {
  &:before {
    content: $dlt-c8y-icon-eps;
  }
}
.dlt-c8y-icon-ps {
  &:before {
    content: $dlt-c8y-icon-ps;
  }
}
.dlt-c8y-icon-rar {
  &:before {
    content: $dlt-c8y-icon-rar;
  }
}
.dlt-c8y-icon-raw {
  &:before {
    content: $dlt-c8y-icon-raw;
  }
}
.dlt-c8y-icon-psd {
  &:before {
    content: $dlt-c8y-icon-psd;
  }
}
.dlt-c8y-icon-exe {
  &:before {
    content: $dlt-c8y-icon-exe;
  }
}
.dlt-c8y-icon-fbx {
  &:before {
    content: $dlt-c8y-icon-fbx;
  }
}
.dlt-c8y-icon-pdf-2 {
  &:before {
    content: $dlt-c8y-icon-pdf-2;
  }
}
.dlt-c8y-icon-3fr {
  &:before {
    content: $dlt-c8y-icon-3fr;
  }
}
.dlt-c8y-icon-aac {
  &:before {
    content: $dlt-c8y-icon-aac;
  }
}
.dlt-c8y-icon-7zip {
  &:before {
    content: $dlt-c8y-icon-7zip;
  }
}
.dlt-c8y-icon-cr2 {
  &:before {
    content: $dlt-c8y-icon-cr2;
  }
}
.dlt-c8y-icon-ai {
  &:before {
    content: $dlt-c8y-icon-ai;
  }
}
.dlt-c8y-icon-apk {
  &:before {
    content: $dlt-c8y-icon-apk;
  }
}
.dlt-c8y-icon-wav {
  &:before {
    content: $dlt-c8y-icon-wav;
  }
}
.dlt-c8y-icon-jp2 {
  &:before {
    content: $dlt-c8y-icon-jp2;
  }
}
.dlt-c8y-icon-java-file {
  &:before {
    content: $dlt-c8y-icon-java-file;
  }
}
.dlt-c8y-icon-fff {
  &:before {
    content: $dlt-c8y-icon-fff;
  }
}
.dlt-c8y-icon-folder-aggregate {
  &:before {
    content: $dlt-c8y-icon-folder-aggregate;
  }
}
.dlt-c8y-icon-book {
  &:before {
    content: $dlt-c8y-icon-book;
  }
}
.dlt-c8y-icon-package-delivery-logistics {
  &:before {
    content: $dlt-c8y-icon-package-delivery-logistics;
  }
}
.dlt-c8y-icon-cube {
  &:before {
    content: $dlt-c8y-icon-cube;
  }
}
.dlt-c8y-icon-resume {
  &:before {
    content: $dlt-c8y-icon-resume;
  }
}
.dlt-c8y-icon-insert-drive-file {
  &:before {
    content: $dlt-c8y-icon-insert-drive-file;
  }
}
.dlt-c8y-icon-file {
  &:before {
    content: $dlt-c8y-icon-file;
  }
}
.dlt-c8y-icon-file-o {
  &:before {
    content: $dlt-c8y-icon-file-o;
  }
}
.dlt-c8y-icon-sheet-of-paper {
  &:before {
    content: $dlt-c8y-icon-sheet-of-paper;
  }
}
.dlt-c8y-icon-edit-file {
  &:before {
    content: $dlt-c8y-icon-edit-file;
  }
}
.dlt-c8y-icon-documents {
  &:before {
    content: $dlt-c8y-icon-documents;
  }
}
.dlt-c8y-icon-file-copy {
  &:before {
    content: $dlt-c8y-icon-file-copy;
  }
}
.dlt-c8y-icon-files {
  &:before {
    content: $dlt-c8y-icon-files;
  }
}
.dlt-c8y-icon-files-o {
  &:before {
    content: $dlt-c8y-icon-files-o;
  }
}
.dlt-c8y-icon-profile {
  &:before {
    content: $dlt-c8y-icon-profile;
  }
}
.dlt-c8y-icon-pdf {
  &:before {
    content: $dlt-c8y-icon-pdf;
  }
}
.dlt-c8y-icon-file-pdf-o {
  &:before {
    content: $dlt-c8y-icon-file-pdf-o;
  }
}
.dlt-c8y-icon-export-pdf {
  &:before {
    content: $dlt-c8y-icon-export-pdf;
  }
}
.dlt-c8y-icon-file-type-document {
  &:before {
    content: $dlt-c8y-icon-file-type-document;
  }
}
.dlt-c8y-icon-file-text-o {
  &:before {
    content: $dlt-c8y-icon-file-text-o;
  }
}
.dlt-c8y-icon-file-text {
  &:before {
    content: $dlt-c8y-icon-file-text;
  }
}
.dlt-c8y-icon-document {
  &:before {
    content: $dlt-c8y-icon-document;
  }
}
.dlt-c8y-icon-submit-document1 {
  &:before {
    content: $dlt-c8y-icon-submit-document1;
  }
}
.dlt-c8y-icon-privacy-policy {
  &:before {
    content: $dlt-c8y-icon-privacy-policy;
  }
}
.dlt-c8y-icon-selective-highlighting1 {
  &:before {
    content: $dlt-c8y-icon-selective-highlighting1;
  }
}
.dlt-c8y-icon-brief {
  &:before {
    content: $dlt-c8y-icon-brief;
  }
}
.dlt-c8y-icon-document-with-code {
  &:before {
    content: $dlt-c8y-icon-document-with-code;
  }
}
.dlt-c8y-icon-image-file {
  &:before {
    content: $dlt-c8y-icon-image-file;
  }
}
.dlt-c8y-icon-file-image-o {
  &:before {
    content: $dlt-c8y-icon-file-image-o;
  }
}
.dlt-c8y-icon-file-picture-o {
  &:before {
    content: $dlt-c8y-icon-file-picture-o;
  }
}
.dlt-c8y-icon-file-photo-o {
  &:before {
    content: $dlt-c8y-icon-file-photo-o;
  }
}
.dlt-c8y-icon-image-file-add {
  &:before {
    content: $dlt-c8y-icon-image-file-add;
  }
}
.dlt-c8y-icon-image-file-checked {
  &:before {
    content: $dlt-c8y-icon-image-file-checked;
  }
}
.dlt-c8y-icon-image-file-remove {
  &:before {
    content: $dlt-c8y-icon-image-file-remove;
  }
}
.dlt-c8y-icon-check-document {
  &:before {
    content: $dlt-c8y-icon-check-document;
  }
}
.dlt-c8y-icon-document-with-a-check-mark {
  &:before {
    content: $dlt-c8y-icon-document-with-a-check-mark;
  }
}
.dlt-c8y-icon-delete-file {
  &:before {
    content: $dlt-c8y-icon-delete-file;
  }
}
.dlt-c8y-icon-file-delete {
  &:before {
    content: $dlt-c8y-icon-file-delete;
  }
}
.dlt-c8y-icon-file-settings {
  &:before {
    content: $dlt-c8y-icon-file-settings;
  }
}
.dlt-c8y-icon-file-preview {
  &:before {
    content: $dlt-c8y-icon-file-preview;
  }
}
.dlt-c8y-icon-open-document {
  &:before {
    content: $dlt-c8y-icon-open-document;
  }
}
.dlt-c8y-icon-submit-document {
  &:before {
    content: $dlt-c8y-icon-submit-document;
  }
}
.dlt-c8y-icon-restore-page1 {
  &:before {
    content: $dlt-c8y-icon-restore-page1;
  }
}
.dlt-c8y-icon-document-exchange {
  &:before {
    content: $dlt-c8y-icon-document-exchange;
  }
}
.dlt-c8y-icon-import-file {
  &:before {
    content: $dlt-c8y-icon-import-file;
  }
}
.dlt-c8y-icon-share-document {
  &:before {
    content: $dlt-c8y-icon-share-document;
  }
}
.dlt-c8y-icon-idea {
  &:before {
    content: $dlt-c8y-icon-idea;
  }
}
.dlt-c8y-icon-mark-as-favorite {
  &:before {
    content: $dlt-c8y-icon-mark-as-favorite;
  }
}
.dlt-c8y-icon-email-document {
  &:before {
    content: $dlt-c8y-icon-email-document;
  }
}
.dlt-c8y-icon-file-view {
  &:before {
    content: $dlt-c8y-icon-file-view;
  }
}
.dlt-c8y-icon-policy-document {
  &:before {
    content: $dlt-c8y-icon-policy-document;
  }
}
.dlt-c8y-icon-business-report {
  &:before {
    content: $dlt-c8y-icon-business-report;
  }
}
.dlt-c8y-icon-graph-report {
  &:before {
    content: $dlt-c8y-icon-graph-report;
  }
}
.dlt-c8y-icon-edit-graph-report {
  &:before {
    content: $dlt-c8y-icon-edit-graph-report;
  }
}
.dlt-c8y-icon-download-graph-report {
  &:before {
    content: $dlt-c8y-icon-download-graph-report;
  }
}
.dlt-c8y-icon-agreement {
  &:before {
    content: $dlt-c8y-icon-agreement;
  }
}
.dlt-c8y-icon-estimates {
  &:before {
    content: $dlt-c8y-icon-estimates;
  }
}
.dlt-c8y-icon-winking-document {
  &:before {
    content: $dlt-c8y-icon-winking-document;
  }
}
.dlt-c8y-icon-insert-page {
  &:before {
    content: $dlt-c8y-icon-insert-page;
  }
}
.dlt-c8y-icon-note {
  &:before {
    content: $dlt-c8y-icon-note;
  }
}
.dlt-c8y-icon-brochure {
  &:before {
    content: $dlt-c8y-icon-brochure;
  }
}
.dlt-c8y-icon-read {
  &:before {
    content: $dlt-c8y-icon-read;
  }
}
.dlt-c8y-icon-cashbook {
  &:before {
    content: $dlt-c8y-icon-cashbook;
  }
}
.dlt-c8y-icon-price-tag {
  &:before {
    content: $dlt-c8y-icon-price-tag;
  }
}
.dlt-c8y-icon-tag {
  &:before {
    content: $dlt-c8y-icon-tag;
  }
}
.dlt-c8y-icon-add-tag {
  &:before {
    content: $dlt-c8y-icon-add-tag;
  }
}
.dlt-c8y-icon-tag1 {
  &:before {
    content: $dlt-c8y-icon-tag1;
  }
}
.dlt-c8y-icon-tags {
  &:before {
    content: $dlt-c8y-icon-tags;
  }
}
.dlt-c8y-icon-open-folder {
  &:before {
    content: $dlt-c8y-icon-open-folder;
  }
}
.dlt-c8y-icon-opened-folder {
  &:before {
    content: $dlt-c8y-icon-opened-folder;
  }
}
.dlt-c8y-icon-folder-open {
  &:before {
    content: $dlt-c8y-icon-folder-open;
  }
}
.dlt-c8y-icon-folder-open-o {
  &:before {
    content: $dlt-c8y-icon-folder-open-o;
  }
}
.dlt-c8y-icon-folder {
  &:before {
    content: $dlt-c8y-icon-folder;
  }
}
.dlt-c8y-icon-folder-o {
  &:before {
    content: $dlt-c8y-icon-folder-o;
  }
}
.dlt-c8y-icon-folder-open1 {
  &:before {
    content: $dlt-c8y-icon-folder-open1;
  }
}
.dlt-c8y-icon-add-folder {
  &:before {
    content: $dlt-c8y-icon-add-folder;
  }
}
.dlt-c8y-icon-delete-folder {
  &:before {
    content: $dlt-c8y-icon-delete-folder;
  }
}
.dlt-c8y-icon-search-folder {
  &:before {
    content: $dlt-c8y-icon-search-folder;
  }
}
.dlt-c8y-icon-folder-settings {
  &:before {
    content: $dlt-c8y-icon-folder-settings;
  }
}
.dlt-c8y-icon-extensions-folder {
  &:before {
    content: $dlt-c8y-icon-extensions-folder;
  }
}
.dlt-c8y-icon-private-folder {
  &:before {
    content: $dlt-c8y-icon-private-folder;
  }
}
.dlt-c8y-icon-edit-folder {
  &:before {
    content: $dlt-c8y-icon-edit-folder;
  }
}
.dlt-c8y-icon-e-mail-folder {
  &:before {
    content: $dlt-c8y-icon-e-mail-folder;
  }
}
.dlt-c8y-icon-documents-folder {
  &:before {
    content: $dlt-c8y-icon-documents-folder;
  }
}
.dlt-c8y-icon-downloads-folder {
  &:before {
    content: $dlt-c8y-icon-downloads-folder;
  }
}
.dlt-c8y-icon-favorite-folder {
  &:before {
    content: $dlt-c8y-icon-favorite-folder;
  }
}
.dlt-c8y-icon-user-folder {
  &:before {
    content: $dlt-c8y-icon-user-folder;
  }
}
.dlt-c8y-icon-download-from-ftp {
  &:before {
    content: $dlt-c8y-icon-download-from-ftp;
  }
}
.dlt-c8y-icon-upload-to-ftp {
  &:before {
    content: $dlt-c8y-icon-upload-to-ftp;
  }
}
.dlt-c8y-icon-ftp {
  &:before {
    content: $dlt-c8y-icon-ftp;
  }
}
.dlt-c8y-icon-big-parcel {
  &:before {
    content: $dlt-c8y-icon-big-parcel;
  }
}
.dlt-c8y-icon-open-parcel {
  &:before {
    content: $dlt-c8y-icon-open-parcel;
  }
}
.dlt-c8y-icon-packing {
  &:before {
    content: $dlt-c8y-icon-packing;
  }
}
.dlt-c8y-icon-unpacking {
  &:before {
    content: $dlt-c8y-icon-unpacking;
  }
}
.dlt-c8y-icon-cardboard-box {
  &:before {
    content: $dlt-c8y-icon-cardboard-box;
  }
}
.dlt-c8y-icon-archive {
  &:before {
    content: $dlt-c8y-icon-archive;
  }
}
.dlt-c8y-icon-archive-o {
  &:before {
    content: $dlt-c8y-icon-archive-o;
  }
}
.dlt-c8y-icon-file-archive-o1 {
  &:before {
    content: $dlt-c8y-icon-file-archive-o1;
  }
}
.dlt-c8y-icon-box {
  &:before {
    content: $dlt-c8y-icon-box;
  }
}
.dlt-c8y-icon-case {
  &:before {
    content: $dlt-c8y-icon-case;
  }
}
.dlt-c8y-icon-empty-box {
  &:before {
    content: $dlt-c8y-icon-empty-box;
  }
}
.dlt-c8y-icon-box-settings {
  &:before {
    content: $dlt-c8y-icon-box-settings;
  }
}
.dlt-c8y-icon-secured-delivery {
  &:before {
    content: $dlt-c8y-icon-secured-delivery;
  }
}
.dlt-c8y-icon-remove-delivery {
  &:before {
    content: $dlt-c8y-icon-remove-delivery;
  }
}
.dlt-c8y-icon-out-of-stock {
  &:before {
    content: $dlt-c8y-icon-out-of-stock;
  }
}
.dlt-c8y-icon-new-product {
  &:before {
    content: $dlt-c8y-icon-new-product;
  }
}
.dlt-c8y-icon-product {
  &:before {
    content: $dlt-c8y-icon-product;
  }
}
.dlt-c8y-icon-product-management {
  &:before {
    content: $dlt-c8y-icon-product-management;
  }
}
.dlt-c8y-icon-stacking {
  &:before {
    content: $dlt-c8y-icon-stacking;
  }
}
.dlt-c8y-icon-cv {
  &:before {
    content: $dlt-c8y-icon-cv;
  }
}
.dlt-c8y-icon-unarchive {
  &:before {
    content: $dlt-c8y-icon-unarchive;
  }
}
.dlt-c8y-icon-upload-archive {
  &:before {
    content: $dlt-c8y-icon-upload-archive;
  }
}
.dlt-c8y-icon-archive-file {
  &:before {
    content: $dlt-c8y-icon-archive-file;
  }
}
.dlt-c8y-icon-download-archive {
  &:before {
    content: $dlt-c8y-icon-download-archive;
  }
}
.dlt-c8y-icon-cc-stripe {
  &:before {
    content: $dlt-c8y-icon-cc-stripe;
  }
}
.dlt-c8y-icon-google-wallet {
  &:before {
    content: $dlt-c8y-icon-google-wallet;
  }
}
.dlt-c8y-icon-paypal {
  &:before {
    content: $dlt-c8y-icon-paypal;
  }
}
.dlt-c8y-icon-cc-paypal {
  &:before {
    content: $dlt-c8y-icon-cc-paypal;
  }
}
.dlt-c8y-icon-cc-amex {
  &:before {
    content: $dlt-c8y-icon-cc-amex;
  }
}
.dlt-c8y-icon-cc-discover {
  &:before {
    content: $dlt-c8y-icon-cc-discover;
  }
}
.dlt-c8y-icon-cc-visa {
  &:before {
    content: $dlt-c8y-icon-cc-visa;
  }
}
.dlt-c8y-icon-cc-mastercard {
  &:before {
    content: $dlt-c8y-icon-cc-mastercard;
  }
}
.dlt-c8y-icon-try {
  &:before {
    content: $dlt-c8y-icon-try;
  }
}
.dlt-c8y-icon-turkish-lira {
  &:before {
    content: $dlt-c8y-icon-turkish-lira;
  }
}
.dlt-c8y-icon-rouble {
  &:before {
    content: $dlt-c8y-icon-rouble;
  }
}
.dlt-c8y-icon-rub {
  &:before {
    content: $dlt-c8y-icon-rub;
  }
}
.dlt-c8y-icon-ruble {
  &:before {
    content: $dlt-c8y-icon-ruble;
  }
}
.dlt-c8y-icon-krw {
  &:before {
    content: $dlt-c8y-icon-krw;
  }
}
.dlt-c8y-icon-won {
  &:before {
    content: $dlt-c8y-icon-won;
  }
}
.dlt-c8y-icon-inr {
  &:before {
    content: $dlt-c8y-icon-inr;
  }
}
.dlt-c8y-icon-rupee {
  &:before {
    content: $dlt-c8y-icon-rupee;
  }
}
.dlt-c8y-icon-ils {
  &:before {
    content: $dlt-c8y-icon-ils;
  }
}
.dlt-c8y-icon-shekel {
  &:before {
    content: $dlt-c8y-icon-shekel;
  }
}
.dlt-c8y-icon-sheqel {
  &:before {
    content: $dlt-c8y-icon-sheqel;
  }
}
.dlt-c8y-icon-gbp {
  &:before {
    content: $dlt-c8y-icon-gbp;
  }
}
.dlt-c8y-icon-eur {
  &:before {
    content: $dlt-c8y-icon-eur;
  }
}
.dlt-c8y-icon-euro {
  &:before {
    content: $dlt-c8y-icon-euro;
  }
}
.dlt-c8y-icon-cny {
  &:before {
    content: $dlt-c8y-icon-cny;
  }
}
.dlt-c8y-icon-jpy {
  &:before {
    content: $dlt-c8y-icon-jpy;
  }
}
.dlt-c8y-icon-rmb {
  &:before {
    content: $dlt-c8y-icon-rmb;
  }
}
.dlt-c8y-icon-yen {
  &:before {
    content: $dlt-c8y-icon-yen;
  }
}
.dlt-c8y-icon-bitcoin {
  &:before {
    content: $dlt-c8y-icon-bitcoin;
  }
}
.dlt-c8y-icon-btc {
  &:before {
    content: $dlt-c8y-icon-btc;
  }
}
.dlt-c8y-icon-dollar {
  &:before {
    content: $dlt-c8y-icon-dollar;
  }
}
.dlt-c8y-icon-usd {
  &:before {
    content: $dlt-c8y-icon-usd;
  }
}
.dlt-c8y-icon-calculator1 {
  &:before {
    content: $dlt-c8y-icon-calculator1;
  }
}
.dlt-c8y-icon-accounting {
  &:before {
    content: $dlt-c8y-icon-accounting;
  }
}
.dlt-c8y-icon-contract {
  &:before {
    content: $dlt-c8y-icon-contract;
  }
}
.dlt-c8y-icon-sales-performance {
  &:before {
    content: $dlt-c8y-icon-sales-performance;
  }
}
.dlt-c8y-icon-bank-safe {
  &:before {
    content: $dlt-c8y-icon-bank-safe;
  }
}
.dlt-c8y-icon-mobile-payment {
  &:before {
    content: $dlt-c8y-icon-mobile-payment;
  }
}
.dlt-c8y-icon-money-yours {
  &:before {
    content: $dlt-c8y-icon-money-yours;
  }
}
.dlt-c8y-icon-online-payment {
  &:before {
    content: $dlt-c8y-icon-online-payment;
  }
}
.dlt-c8y-icon-us-dollar-circled {
  &:before {
    content: $dlt-c8y-icon-us-dollar-circled;
  }
}
.dlt-c8y-icon-credit-card {
  &:before {
    content: $dlt-c8y-icon-credit-card;
  }
}
.dlt-c8y-icon-credit-card-alt {
  &:before {
    content: $dlt-c8y-icon-credit-card-alt;
  }
}
.dlt-c8y-icon-credit-card {
  &:before {
    content: $dlt-c8y-icon-credit-card;
  }
}
.dlt-c8y-icon-only-cash {
  &:before {
    content: $dlt-c8y-icon-only-cash;
  }
}
.dlt-c8y-icon-credit-control {
  &:before {
    content: $dlt-c8y-icon-credit-control;
  }
}
.dlt-c8y-icon-bank-cards {
  &:before {
    content: $dlt-c8y-icon-bank-cards;
  }
}
.dlt-c8y-icon-check-for-payment {
  &:before {
    content: $dlt-c8y-icon-check-for-payment;
  }
}
.dlt-c8y-icon-paper-money {
  &:before {
    content: $dlt-c8y-icon-paper-money;
  }
}
.dlt-c8y-icon-money {
  &:before {
    content: $dlt-c8y-icon-money;
  }
}
.dlt-c8y-icon-cash-euro {
  &:before {
    content: $dlt-c8y-icon-cash-euro;
  }
}
.dlt-c8y-icon-stack-of-money {
  &:before {
    content: $dlt-c8y-icon-stack-of-money;
  }
}
.dlt-c8y-icon-wallet {
  &:before {
    content: $dlt-c8y-icon-wallet;
  }
}
.dlt-c8y-icon-certificate {
  &:before {
    content: $dlt-c8y-icon-certificate;
  }
}
.dlt-c8y-icon-card-security1 {
  &:before {
    content: $dlt-c8y-icon-card-security1;
  }
}
.dlt-c8y-icon-bill {
  &:before {
    content: $dlt-c8y-icon-bill;
  }
}
.dlt-c8y-icon-paid-bill {
  &:before {
    content: $dlt-c8y-icon-paid-bill;
  }
}
.dlt-c8y-icon-token-card-code1 {
  &:before {
    content: $dlt-c8y-icon-token-card-code1;
  }
}
.dlt-c8y-icon-receipt {
  &:before {
    content: $dlt-c8y-icon-receipt;
  }
}
.dlt-c8y-icon-add-receipt {
  &:before {
    content: $dlt-c8y-icon-add-receipt;
  }
}
.dlt-c8y-icon-sign-language {
  &:before {
    content: $dlt-c8y-icon-sign-language;
  }
}
.dlt-c8y-icon-signing {
  &:before {
    content: $dlt-c8y-icon-signing;
  }
}
.dlt-c8y-icon-hand-o-down {
  &:before {
    content: $dlt-c8y-icon-hand-o-down;
  }
}
.dlt-c8y-icon-hand-o-left {
  &:before {
    content: $dlt-c8y-icon-hand-o-left;
  }
}
.dlt-c8y-icon-hand-o-up {
  &:before {
    content: $dlt-c8y-icon-hand-o-up;
  }
}
.dlt-c8y-icon-hand-o-right {
  &:before {
    content: $dlt-c8y-icon-hand-o-right;
  }
}
.dlt-c8y-icon-hand-peace-o {
  &:before {
    content: $dlt-c8y-icon-hand-peace-o;
  }
}
.dlt-c8y-icon-hand-pointer-o {
  &:before {
    content: $dlt-c8y-icon-hand-pointer-o;
  }
}
.dlt-c8y-icon-hand-spock-o {
  &:before {
    content: $dlt-c8y-icon-hand-spock-o;
  }
}
.dlt-c8y-icon-hand-lizard-o {
  &:before {
    content: $dlt-c8y-icon-hand-lizard-o;
  }
}
.dlt-c8y-icon-hand-scissors-o {
  &:before {
    content: $dlt-c8y-icon-hand-scissors-o;
  }
}
.dlt-c8y-icon-hand-paper-o {
  &:before {
    content: $dlt-c8y-icon-hand-paper-o;
  }
}
.dlt-c8y-icon-hand-stop-o {
  &:before {
    content: $dlt-c8y-icon-hand-stop-o;
  }
}
.dlt-c8y-icon-hand-grab-o {
  &:before {
    content: $dlt-c8y-icon-hand-grab-o;
  }
}
.dlt-c8y-icon-hand-rock-o {
  &:before {
    content: $dlt-c8y-icon-hand-rock-o;
  }
}
.dlt-c8y-icon-thumbs-up {
  &:before {
    content: $dlt-c8y-icon-thumbs-up;
  }
}
.dlt-c8y-icon-thumbs-up {
  &:before {
    content: $dlt-c8y-icon-thumbs-up;
  }
}
.dlt-c8y-icon-thumbs-o-up {
  &:before {
    content: $dlt-c8y-icon-thumbs-o-up;
  }
}
.dlt-c8y-icon-thumbs-down {
  &:before {
    content: $dlt-c8y-icon-thumbs-down;
  }
}
.dlt-c8y-icon-thumbs-down {
  &:before {
    content: $dlt-c8y-icon-thumbs-down;
  }
}
.dlt-c8y-icon-thumbs-o-down {
  &:before {
    content: $dlt-c8y-icon-thumbs-o-down;
  }
}
.dlt-c8y-icon-do-not-touch {
  &:before {
    content: $dlt-c8y-icon-do-not-touch;
  }
}
.dlt-c8y-icon-hand {
  &:before {
    content: $dlt-c8y-icon-hand;
  }
}
.dlt-c8y-icon-disclaimer {
  &:before {
    content: $dlt-c8y-icon-disclaimer;
  }
}
.dlt-c8y-icon-touchpad {
  &:before {
    content: $dlt-c8y-icon-touchpad;
  }
}
.dlt-c8y-icon-holding-box {
  &:before {
    content: $dlt-c8y-icon-holding-box;
  }
}
.dlt-c8y-icon-recieve {
  &:before {
    content: $dlt-c8y-icon-recieve;
  }
}
.dlt-c8y-icon-sell {
  &:before {
    content: $dlt-c8y-icon-sell;
  }
}
.dlt-c8y-icon-get-revenue {
  &:before {
    content: $dlt-c8y-icon-get-revenue;
  }
}
.dlt-c8y-icon-handshake {
  &:before {
    content: $dlt-c8y-icon-handshake;
  }
}
.dlt-c8y-icon-handshake-o {
  &:before {
    content: $dlt-c8y-icon-handshake-o;
  }
}
.dlt-c8y-icon-partners {
  &:before {
    content: $dlt-c8y-icon-partners;
  }
}
.dlt-c8y-icon-wearable-technology {
  &:before {
    content: $dlt-c8y-icon-wearable-technology;
  }
}
.dlt-c8y-icon-america {
  &:before {
    content: $dlt-c8y-icon-america;
  }
}
.dlt-c8y-icon-globe {
  &:before {
    content: $dlt-c8y-icon-globe;
  }
}
.dlt-c8y-icon-location {
  &:before {
    content: $dlt-c8y-icon-location;
  }
}
.dlt-c8y-icon-globe1 {
  &:before {
    content: $dlt-c8y-icon-globe1;
  }
}
.dlt-c8y-icon-compass-north {
  &:before {
    content: $dlt-c8y-icon-compass-north;
  }
}
.dlt-c8y-icon-compass {
  &:before {
    content: $dlt-c8y-icon-compass;
  }
}
.dlt-c8y-icon-marker {
  &:before {
    content: $dlt-c8y-icon-marker;
  }
}
.dlt-c8y-icon-map-marker {
  &:before {
    content: $dlt-c8y-icon-map-marker;
  }
}
.dlt-c8y-icon-marker-off {
  &:before {
    content: $dlt-c8y-icon-marker-off;
  }
}
.dlt-c8y-icon-find-clinic {
  &:before {
    content: $dlt-c8y-icon-find-clinic;
  }
}
.dlt-c8y-icon-tesla-supercharger-pin {
  &:before {
    content: $dlt-c8y-icon-tesla-supercharger-pin;
  }
}
.dlt-c8y-icon-address {
  &:before {
    content: $dlt-c8y-icon-address;
  }
}
.dlt-c8y-icon-cab-stand {
  &:before {
    content: $dlt-c8y-icon-cab-stand;
  }
}
.dlt-c8y-icon-map {
  &:before {
    content: $dlt-c8y-icon-map;
  }
}
.dlt-c8y-icon-map-o {
  &:before {
    content: $dlt-c8y-icon-map-o;
  }
}
.dlt-c8y-icon-map-editing {
  &:before {
    content: $dlt-c8y-icon-map-editing;
  }
}
.dlt-c8y-icon-map-marker {
  &:before {
    content: $dlt-c8y-icon-map-marker;
  }
}
.dlt-c8y-icon-quest {
  &:before {
    content: $dlt-c8y-icon-quest;
  }
}
.dlt-c8y-icon-waypoint-map {
  &:before {
    content: $dlt-c8y-icon-waypoint-map;
  }
}
.dlt-c8y-icon-anchor-nodes {
  &:before {
    content: $dlt-c8y-icon-anchor-nodes;
  }
}
.dlt-c8y-icon-map-pin {
  &:before {
    content: $dlt-c8y-icon-map-pin;
  }
}
.dlt-c8y-icon-map-pin {
  &:before {
    content: $dlt-c8y-icon-map-pin;
  }
}
.dlt-c8y-icon-signpost-tourist {
  &:before {
    content: $dlt-c8y-icon-signpost-tourist;
  }
}
.dlt-c8y-icon-map-signs {
  &:before {
    content: $dlt-c8y-icon-map-signs;
  }
}
.dlt-c8y-icon-gps {
  &:before {
    content: $dlt-c8y-icon-gps;
  }
}
.dlt-c8y-icon-near-me {
  &:before {
    content: $dlt-c8y-icon-near-me;
  }
}
.dlt-c8y-icon-location-arrow {
  &:before {
    content: $dlt-c8y-icon-location-arrow;
  }
}
.dlt-c8y-icon-navigation {
  &:before {
    content: $dlt-c8y-icon-navigation;
  }
}
.dlt-c8y-icon-target1 {
  &:before {
    content: $dlt-c8y-icon-target1;
  }
}
.dlt-c8y-icon-hunt {
  &:before {
    content: $dlt-c8y-icon-hunt;
  }
}
.dlt-c8y-icon-location-off {
  &:before {
    content: $dlt-c8y-icon-location-off;
  }
}
.dlt-c8y-icon-location-update {
  &:before {
    content: $dlt-c8y-icon-location-update;
  }
}
.dlt-c8y-icon-layers {
  &:before {
    content: $dlt-c8y-icon-layers;
  }
}
.dlt-c8y-icon-journey {
  &:before {
    content: $dlt-c8y-icon-journey;
  }
}
.dlt-c8y-icon-here {
  &:before {
    content: $dlt-c8y-icon-here;
  }
}
.dlt-c8y-icon-street-view {
  &:before {
    content: $dlt-c8y-icon-street-view;
  }
}
.dlt-c8y-icon-parking {
  &:before {
    content: $dlt-c8y-icon-parking;
  }
}
.dlt-c8y-icon-reply {
  &:before {
    content: $dlt-c8y-icon-reply;
  }
}
.dlt-c8y-icon-mail-reply {
  &:before {
    content: $dlt-c8y-icon-mail-reply;
  }
}
.dlt-c8y-icon-forward1 {
  &:before {
    content: $dlt-c8y-icon-forward1;
  }
}
.dlt-c8y-icon-mail-forward {
  &:before {
    content: $dlt-c8y-icon-mail-forward;
  }
}
.dlt-c8y-icon-reply-all {
  &:before {
    content: $dlt-c8y-icon-reply-all;
  }
}
.dlt-c8y-icon-mail-reply-all {
  &:before {
    content: $dlt-c8y-icon-mail-reply-all;
  }
}
.dlt-c8y-icon-reply-all {
  &:before {
    content: $dlt-c8y-icon-reply-all;
  }
}
.dlt-c8y-icon-communication {
  &:before {
    content: $dlt-c8y-icon-communication;
  }
}
.dlt-c8y-icon-multichannel {
  &:before {
    content: $dlt-c8y-icon-multichannel;
  }
}
.dlt-c8y-icon-chat {
  &:before {
    content: $dlt-c8y-icon-chat;
  }
}
.dlt-c8y-icon-topic {
  &:before {
    content: $dlt-c8y-icon-topic;
  }
}
.dlt-c8y-icon-comment {
  &:before {
    content: $dlt-c8y-icon-comment;
  }
}
.dlt-c8y-icon-comment-o {
  &:before {
    content: $dlt-c8y-icon-comment-o;
  }
}
.dlt-c8y-icon-typing {
  &:before {
    content: $dlt-c8y-icon-typing;
  }
}
.dlt-c8y-icon-quote {
  &:before {
    content: $dlt-c8y-icon-quote;
  }
}
.dlt-c8y-icon-delete-message {
  &:before {
    content: $dlt-c8y-icon-delete-message;
  }
}
.dlt-c8y-icon-poll {
  &:before {
    content: $dlt-c8y-icon-poll;
  }
}
.dlt-c8y-icon-popular {
  &:before {
    content: $dlt-c8y-icon-popular;
  }
}
.dlt-c8y-icon-speaker-notes {
  &:before {
    content: $dlt-c8y-icon-speaker-notes;
  }
}
.dlt-c8y-icon-audio-description {
  &:before {
    content: $dlt-c8y-icon-audio-description;
  }
}
.dlt-c8y-icon-comment1 {
  &:before {
    content: $dlt-c8y-icon-comment1;
  }
}
.dlt-c8y-icon-comments {
  &:before {
    content: $dlt-c8y-icon-comments;
  }
}
.dlt-c8y-icon-comments-o {
  &:before {
    content: $dlt-c8y-icon-comments-o;
  }
}
.dlt-c8y-icon-speaker-notes-off {
  &:before {
    content: $dlt-c8y-icon-speaker-notes-off;
  }
}
.dlt-c8y-icon-new-topic {
  &:before {
    content: $dlt-c8y-icon-new-topic;
  }
}
.dlt-c8y-icon-ask-question {
  &:before {
    content: $dlt-c8y-icon-ask-question;
  }
}
.dlt-c8y-icon-chat-bubble {
  &:before {
    content: $dlt-c8y-icon-chat-bubble;
  }
}
.dlt-c8y-icon-commenting {
  &:before {
    content: $dlt-c8y-icon-commenting;
  }
}
.dlt-c8y-icon-commenting-o {
  &:before {
    content: $dlt-c8y-icon-commenting-o;
  }
}
.dlt-c8y-icon-mail {
  &:before {
    content: $dlt-c8y-icon-mail;
  }
}
.dlt-c8y-icon-important-mail {
  &:before {
    content: $dlt-c8y-icon-important-mail;
  }
}
.dlt-c8y-icon-envelope {
  &:before {
    content: $dlt-c8y-icon-envelope;
  }
}
.dlt-c8y-icon-envelope-o {
  &:before {
    content: $dlt-c8y-icon-envelope-o;
  }
}
.dlt-c8y-icon-envelope-open-o {
  &:before {
    content: $dlt-c8y-icon-envelope-open-o;
  }
}
.dlt-c8y-icon-envelope-open {
  &:before {
    content: $dlt-c8y-icon-envelope-open;
  }
}
.dlt-c8y-icon-edit-message {
  &:before {
    content: $dlt-c8y-icon-edit-message;
  }
}
.dlt-c8y-icon-received {
  &:before {
    content: $dlt-c8y-icon-received;
  }
}
.dlt-c8y-icon-deleted-message {
  &:before {
    content: $dlt-c8y-icon-deleted-message;
  }
}
.dlt-c8y-icon-group-message {
  &:before {
    content: $dlt-c8y-icon-group-message;
  }
}
.dlt-c8y-icon-subscription {
  &:before {
    content: $dlt-c8y-icon-subscription;
  }
}
.dlt-c8y-icon-unsubscribe {
  &:before {
    content: $dlt-c8y-icon-unsubscribe;
  }
}
.dlt-c8y-icon-send-email {
  &:before {
    content: $dlt-c8y-icon-send-email;
  }
}
.dlt-c8y-icon-read-message {
  &:before {
    content: $dlt-c8y-icon-read-message;
  }
}
.dlt-c8y-icon-inbox {
  &:before {
    content: $dlt-c8y-icon-inbox;
  }
}
.dlt-c8y-icon-add-to-inbox {
  &:before {
    content: $dlt-c8y-icon-add-to-inbox;
  }
}
.dlt-c8y-icon-check-inbox {
  &:before {
    content: $dlt-c8y-icon-check-inbox;
  }
}
.dlt-c8y-icon-remove-from-inbox {
  &:before {
    content: $dlt-c8y-icon-remove-from-inbox;
  }
}
.dlt-c8y-icon-outbox {
  &:before {
    content: $dlt-c8y-icon-outbox;
  }
}
.dlt-c8y-icon-post {
  &:before {
    content: $dlt-c8y-icon-post;
  }
}
.dlt-c8y-icon-envelope-square {
  &:before {
    content: $dlt-c8y-icon-envelope-square;
  }
}
.dlt-c8y-icon-sheet {
  &:before {
    content: $dlt-c8y-icon-sheet;
  }
}
.dlt-c8y-icon-sticky-note {
  &:before {
    content: $dlt-c8y-icon-sticky-note;
  }
}
.dlt-c8y-icon-sticky-note-o {
  &:before {
    content: $dlt-c8y-icon-sticky-note-o;
  }
}
.dlt-c8y-icon-note1 {
  &:before {
    content: $dlt-c8y-icon-note1;
  }
}
.dlt-c8y-icon-secure {
  &:before {
    content: $dlt-c8y-icon-secure;
  }
}
.dlt-c8y-icon-retweet {
  &:before {
    content: $dlt-c8y-icon-retweet;
  }
}
.dlt-c8y-icon-rss {
  &:before {
    content: $dlt-c8y-icon-rss;
  }
}
.dlt-c8y-icon-rss-square {
  &:before {
    content: $dlt-c8y-icon-rss-square;
  }
}
.dlt-c8y-icon-feed {
  &:before {
    content: $dlt-c8y-icon-feed;
  }
}
.dlt-c8y-icon-cancel-subscription {
  &:before {
    content: $dlt-c8y-icon-cancel-subscription;
  }
}
.dlt-c8y-icon-link {
  &:before {
    content: $dlt-c8y-icon-link;
  }
}
.dlt-c8y-icon-chain {
  &:before {
    content: $dlt-c8y-icon-chain;
  }
}
.dlt-c8y-icon-link-off {
  &:before {
    content: $dlt-c8y-icon-link-off;
  }
}
.dlt-c8y-icon-chain-broken {
  &:before {
    content: $dlt-c8y-icon-chain-broken;
  }
}
.dlt-c8y-icon-unlink {
  &:before {
    content: $dlt-c8y-icon-unlink;
  }
}
.dlt-c8y-icon-sent {
  &:before {
    content: $dlt-c8y-icon-sent;
  }
}
.dlt-c8y-icon-send {
  &:before {
    content: $dlt-c8y-icon-send;
  }
}
.dlt-c8y-icon-email-send {
  &:before {
    content: $dlt-c8y-icon-email-send;
  }
}
.dlt-c8y-icon-send-o {
  &:before {
    content: $dlt-c8y-icon-send-o;
  }
}
.dlt-c8y-icon-paper-plane {
  &:before {
    content: $dlt-c8y-icon-paper-plane;
  }
}
.dlt-c8y-icon-paper-plane-o {
  &:before {
    content: $dlt-c8y-icon-paper-plane-o;
  }
}
.dlt-c8y-icon-hashtag {
  &:before {
    content: $dlt-c8y-icon-hashtag;
  }
}
.dlt-c8y-icon-podcast {
  &:before {
    content: $dlt-c8y-icon-podcast;
  }
}
.dlt-c8y-icon-headphones {
  &:before {
    content: $dlt-c8y-icon-headphones;
  }
}
.dlt-c8y-icon-volume-control-phone {
  &:before {
    content: $dlt-c8y-icon-volume-control-phone;
  }
}
.dlt-c8y-icon-volume-off {
  &:before {
    content: $dlt-c8y-icon-volume-off;
  }
}
.dlt-c8y-icon-volume-mute {
  &:before {
    content: $dlt-c8y-icon-volume-mute;
  }
}
.dlt-c8y-icon-volume-zero {
  &:before {
    content: $dlt-c8y-icon-volume-zero;
  }
}
.dlt-c8y-icon-volume-low {
  &:before {
    content: $dlt-c8y-icon-volume-low;
  }
}
.dlt-c8y-icon-volume-down {
  &:before {
    content: $dlt-c8y-icon-volume-down;
  }
}
.dlt-c8y-icon-volume {
  &:before {
    content: $dlt-c8y-icon-volume;
  }
}
.dlt-c8y-icon-speaker {
  &:before {
    content: $dlt-c8y-icon-speaker;
  }
}
.dlt-c8y-icon-volume-up {
  &:before {
    content: $dlt-c8y-icon-volume-up;
  }
}
.dlt-c8y-icon-volume-high {
  &:before {
    content: $dlt-c8y-icon-volume-high;
  }
}
.dlt-c8y-icon-sound {
  &:before {
    content: $dlt-c8y-icon-sound;
  }
}
.dlt-c8y-icon-headset {
  &:before {
    content: $dlt-c8y-icon-headset;
  }
}
.dlt-c8y-icon-play-circle {
  &:before {
    content: $dlt-c8y-icon-play-circle;
  }
}
.dlt-c8y-icon-pause-circle {
  &:before {
    content: $dlt-c8y-icon-pause-circle;
  }
}
.dlt-c8y-icon-stop-circle {
  &:before {
    content: $dlt-c8y-icon-stop-circle;
  }
}
.dlt-c8y-icon-skip-previous {
  &:before {
    content: $dlt-c8y-icon-skip-previous;
  }
}
.dlt-c8y-icon-step-backward {
  &:before {
    content: $dlt-c8y-icon-step-backward;
  }
}
.dlt-c8y-icon-fast-rewind {
  &:before {
    content: $dlt-c8y-icon-fast-rewind;
  }
}
.dlt-c8y-icon-rewind {
  &:before {
    content: $dlt-c8y-icon-rewind;
  }
}
.dlt-c8y-icon-fast-rewind1 {
  &:before {
    content: $dlt-c8y-icon-fast-rewind1;
  }
}
.dlt-c8y-icon-fast-backward {
  &:before {
    content: $dlt-c8y-icon-fast-backward;
  }
}
.dlt-c8y-icon-backward {
  &:before {
    content: $dlt-c8y-icon-backward;
  }
}
.dlt-c8y-icon-play-arrow {
  &:before {
    content: $dlt-c8y-icon-play-arrow;
  }
}
.dlt-c8y-icon-play {
  &:before {
    content: $dlt-c8y-icon-play;
  }
}
.dlt-c8y-icon-play-circle1 {
  &:before {
    content: $dlt-c8y-icon-play-circle1;
  }
}
.dlt-c8y-icon-play-circle-o {
  &:before {
    content: $dlt-c8y-icon-play-circle-o;
  }
}
.dlt-c8y-icon-pause {
  &:before {
    content: $dlt-c8y-icon-pause;
  }
}
.dlt-c8y-icon-stop {
  &:before {
    content: $dlt-c8y-icon-stop;
  }
}
.dlt-c8y-icon-forward11 {
  &:before {
    content: $dlt-c8y-icon-forward11;
  }
}
.dlt-c8y-icon-fast-forward {
  &:before {
    content: $dlt-c8y-icon-fast-forward;
  }
}
.dlt-c8y-icon-forward111 {
  &:before {
    content: $dlt-c8y-icon-forward111;
  }
}
.dlt-c8y-icon-fast-forward1 {
  &:before {
    content: $dlt-c8y-icon-fast-forward1;
  }
}
.dlt-c8y-icon-skip-next {
  &:before {
    content: $dlt-c8y-icon-skip-next;
  }
}
.dlt-c8y-icon-step-forward {
  &:before {
    content: $dlt-c8y-icon-step-forward;
  }
}
.dlt-c8y-icon-no-image {
  &:before {
    content: $dlt-c8y-icon-no-image;
  }
}
.dlt-c8y-icon-local-movies {
  &:before {
    content: $dlt-c8y-icon-local-movies;
  }
}
.dlt-c8y-icon-theaters {
  &:before {
    content: $dlt-c8y-icon-theaters;
  }
}
.dlt-c8y-icon-film {
  &:before {
    content: $dlt-c8y-icon-film;
  }
}
.dlt-c8y-icon-mic {
  &:before {
    content: $dlt-c8y-icon-mic;
  }
}
.dlt-c8y-icon-microphone {
  &:before {
    content: $dlt-c8y-icon-microphone;
  }
}
.dlt-c8y-icon-block-microphone {
  &:before {
    content: $dlt-c8y-icon-block-microphone;
  }
}
.dlt-c8y-icon-microphone-slash {
  &:before {
    content: $dlt-c8y-icon-microphone-slash;
  }
}
.dlt-c8y-icon-music-note {
  &:before {
    content: $dlt-c8y-icon-music-note;
  }
}
.dlt-c8y-icon-music {
  &:before {
    content: $dlt-c8y-icon-music;
  }
}
.dlt-c8y-icon-photo-camera-front {
  &:before {
    content: $dlt-c8y-icon-photo-camera-front;
  }
}
.dlt-c8y-icon-video-camera {
  &:before {
    content: $dlt-c8y-icon-video-camera;
  }
}
.dlt-c8y-icon-camera {
  &:before {
    content: $dlt-c8y-icon-camera;
  }
}
.dlt-c8y-icon-camera-retro {
  &:before {
    content: $dlt-c8y-icon-camera-retro;
  }
}
.dlt-c8y-icon-cam {
  &:before {
    content: $dlt-c8y-icon-cam;
  }
}
.dlt-c8y-icon-image {
  &:before {
    content: $dlt-c8y-icon-image;
  }
}
.dlt-c8y-icon-picture {
  &:before {
    content: $dlt-c8y-icon-picture;
  }
}
.dlt-c8y-icon-picture-o {
  &:before {
    content: $dlt-c8y-icon-picture-o;
  }
}
.dlt-c8y-icon-photo {
  &:before {
    content: $dlt-c8y-icon-photo;
  }
}
.dlt-c8y-icon-photo-gallery {
  &:before {
    content: $dlt-c8y-icon-photo-gallery;
  }
}
.dlt-c8y-icon-albums {
  &:before {
    content: $dlt-c8y-icon-albums;
  }
}
.dlt-c8y-icon-cubes {
  &:before {
    content: $dlt-c8y-icon-cubes;
  }
}
.dlt-c8y-icon-slides {
  &:before {
    content: $dlt-c8y-icon-slides;
  }
}
.dlt-c8y-icon-bursts {
  &:before {
    content: $dlt-c8y-icon-bursts;
  }
}
.dlt-c8y-icon-exposure {
  &:before {
    content: $dlt-c8y-icon-exposure;
  }
}
.dlt-c8y-icon-gallery {
  &:before {
    content: $dlt-c8y-icon-gallery;
  }
}
.dlt-c8y-icon-insert-clip {
  &:before {
    content: $dlt-c8y-icon-insert-clip;
  }
}
.dlt-c8y-icon-memories {
  &:before {
    content: $dlt-c8y-icon-memories;
  }
}
.dlt-c8y-icon-overwrite-clip {
  &:before {
    content: $dlt-c8y-icon-overwrite-clip;
  }
}
.dlt-c8y-icon-selfies {
  &:before {
    content: $dlt-c8y-icon-selfies;
  }
}
.dlt-c8y-icon-facebook {
  &:before {
    content: $dlt-c8y-icon-facebook;
  }
}
.dlt-c8y-icon-instagram {
  &:before {
    content: $dlt-c8y-icon-instagram;
  }
}
.dlt-c8y-icon-linkedin {
  &:before {
    content: $dlt-c8y-icon-linkedin;
  }
}
.dlt-c8y-icon-pinterest {
  &:before {
    content: $dlt-c8y-icon-pinterest;
  }
}
.dlt-c8y-icon-twitter {
  &:before {
    content: $dlt-c8y-icon-twitter;
  }
}
.dlt-c8y-icon-youtube {
  &:before {
    content: $dlt-c8y-icon-youtube;
  }
}
.dlt-c8y-icon-youtube-play {
  &:before {
    content: $dlt-c8y-icon-youtube-play;
  }
}
.dlt-c8y-icon-google {
  &:before {
    content: $dlt-c8y-icon-google;
  }
}
.dlt-c8y-icon-github {
  &:before {
    content: $dlt-c8y-icon-github;
  }
}
.dlt-c8y-icon-cloud {
  &:before {
    content: $dlt-c8y-icon-cloud;
  }
}
.dlt-c8y-icon-cloud-search {
  &:before {
    content: $dlt-c8y-icon-cloud-search;
  }
}
.dlt-c8y-icon-search-in-cloud {
  &:before {
    content: $dlt-c8y-icon-search-in-cloud;
  }
}
.dlt-c8y-icon-cloud-settings {
  &:before {
    content: $dlt-c8y-icon-cloud-settings;
  }
}
.dlt-c8y-icon-cloud-checked {
  &:before {
    content: $dlt-c8y-icon-cloud-checked;
  }
}
.dlt-c8y-icon-cloud-restricted {
  &:before {
    content: $dlt-c8y-icon-cloud-restricted;
  }
}
.dlt-c8y-icon-cloud-plus {
  &:before {
    content: $dlt-c8y-icon-cloud-plus;
  }
}
.dlt-c8y-icon-cloud-minus {
  &:before {
    content: $dlt-c8y-icon-cloud-minus;
  }
}
.dlt-c8y-icon-cloud-error {
  &:before {
    content: $dlt-c8y-icon-cloud-error;
  }
}
.dlt-c8y-icon-cloud-unavailable {
  &:before {
    content: $dlt-c8y-icon-cloud-unavailable;
  }
}
.dlt-c8y-icon-cloud-upload {
  &:before {
    content: $dlt-c8y-icon-cloud-upload;
  }
}
.dlt-c8y-icon-backup {
  &:before {
    content: $dlt-c8y-icon-backup;
  }
}
.dlt-c8y-icon-cloud-download {
  &:before {
    content: $dlt-c8y-icon-cloud-download;
  }
}
.dlt-c8y-icon-cloud-backup-restore {
  &:before {
    content: $dlt-c8y-icon-cloud-backup-restore;
  }
}
.dlt-c8y-icon-cloud-remote-working {
  &:before {
    content: $dlt-c8y-icon-cloud-remote-working;
  }
}
.dlt-c8y-icon-cloud-bar-chart {
  &:before {
    content: $dlt-c8y-icon-cloud-bar-chart;
  }
}
.dlt-c8y-icon-cloud-binary-code {
  &:before {
    content: $dlt-c8y-icon-cloud-binary-code;
  }
}
.dlt-c8y-icon-cloud-broadcasting {
  &:before {
    content: $dlt-c8y-icon-cloud-broadcasting;
  }
}
.dlt-c8y-icon-secure-cloud {
  &:before {
    content: $dlt-c8y-icon-secure-cloud;
  }
}
.dlt-c8y-icon-error-cloud {
  &:before {
    content: $dlt-c8y-icon-error-cloud;
  }
}
.dlt-c8y-icon-cloud-cross {
  &:before {
    content: $dlt-c8y-icon-cloud-cross;
  }
}
.dlt-c8y-icon-dashed-cloud {
  &:before {
    content: $dlt-c8y-icon-dashed-cloud;
  }
}
.dlt-c8y-icon-cloud-connection {
  &:before {
    content: $dlt-c8y-icon-cloud-connection;
  }
}
.dlt-c8y-icon-cloud-firewall {
  &:before {
    content: $dlt-c8y-icon-cloud-firewall;
  }
}
.dlt-c8y-icon-cloud-link {
  &:before {
    content: $dlt-c8y-icon-cloud-link;
  }
}
.dlt-c8y-icon-cloud-waiting {
  &:before {
    content: $dlt-c8y-icon-cloud-waiting;
  }
}
.dlt-c8y-icon-public-cloud {
  &:before {
    content: $dlt-c8y-icon-public-cloud;
  }
}
.dlt-c8y-icon-delete-from-cloud {
  &:before {
    content: $dlt-c8y-icon-delete-from-cloud;
  }
}
.dlt-c8y-icon-cloud-refresh {
  &:before {
    content: $dlt-c8y-icon-cloud-refresh;
  }
}
.dlt-c8y-icon-cloud-right-u-arrow {
  &:before {
    content: $dlt-c8y-icon-cloud-right-u-arrow;
  }
}
.dlt-c8y-icon-cloud-share-symbol {
  &:before {
    content: $dlt-c8y-icon-cloud-share-symbol;
  }
}
.dlt-c8y-icon-cloud-development {
  &:before {
    content: $dlt-c8y-icon-cloud-development;
  }
}
.dlt-c8y-icon-cloud-sync {
  &:before {
    content: $dlt-c8y-icon-cloud-sync;
  }
}
.dlt-c8y-icon-cloud-user {
  &:before {
    content: $dlt-c8y-icon-cloud-user;
  }
}
.dlt-c8y-icon-cloud-computing {
  &:before {
    content: $dlt-c8y-icon-cloud-computing;
  }
}
.dlt-c8y-icon-cloud-storage {
  &:before {
    content: $dlt-c8y-icon-cloud-storage;
  }
}
.dlt-c8y-icon-cloud-network {
  &:before {
    content: $dlt-c8y-icon-cloud-network;
  }
}
.dlt-c8y-icon-computer {
  &:before {
    content: $dlt-c8y-icon-computer;
  }
}
.dlt-c8y-icon-connected-people {
  &:before {
    content: $dlt-c8y-icon-connected-people;
  }
}
.dlt-c8y-icon-connection-sync {
  &:before {
    content: $dlt-c8y-icon-connection-sync;
  }
}
.dlt-c8y-icon-network-2 {
  &:before {
    content: $dlt-c8y-icon-network-2;
  }
}
.dlt-c8y-icon-incoming-data {
  &:before {
    content: $dlt-c8y-icon-incoming-data;
  }
}
.dlt-c8y-icon-outgoing-data {
  &:before {
    content: $dlt-c8y-icon-outgoing-data;
  }
}
.dlt-c8y-icon-remote-desktop1 {
  &:before {
    content: $dlt-c8y-icon-remote-desktop1;
  }
}
.dlt-c8y-icon-security-wi-fi {
  &:before {
    content: $dlt-c8y-icon-security-wi-fi;
  }
}
.dlt-c8y-icon-server1 {
  &:before {
    content: $dlt-c8y-icon-server1;
  }
}
.dlt-c8y-icon-server-shutdown1 {
  &:before {
    content: $dlt-c8y-icon-server-shutdown1;
  }
}
.dlt-c8y-icon-ftp-server {
  &:before {
    content: $dlt-c8y-icon-ftp-server;
  }
}
.dlt-c8y-icon-thin-client {
  &:before {
    content: $dlt-c8y-icon-thin-client;
  }
}
.dlt-c8y-icon-wi-fi {
  &:before {
    content: $dlt-c8y-icon-wi-fi;
  }
}
.dlt-c8y-icon-wifi {
  &:before {
    content: $dlt-c8y-icon-wifi;
  }
}
.dlt-c8y-icon-wireless-network {
  &:before {
    content: $dlt-c8y-icon-wireless-network;
  }
}
.dlt-c8y-icon-wi-fi-off {
  &:before {
    content: $dlt-c8y-icon-wi-fi-off;
  }
}
.dlt-c8y-icon-wi-fi-connected {
  &:before {
    content: $dlt-c8y-icon-wi-fi-connected;
  }
}
.dlt-c8y-icon-wi-fi-disconnected {
  &:before {
    content: $dlt-c8y-icon-wi-fi-disconnected;
  }
}
.dlt-c8y-icon-wi-fi-lock {
  &:before {
    content: $dlt-c8y-icon-wi-fi-lock;
  }
}
.dlt-c8y-icon-scan-wi-fi {
  &:before {
    content: $dlt-c8y-icon-scan-wi-fi;
  }
}
.dlt-c8y-icon-bluetooth {
  &:before {
    content: $dlt-c8y-icon-bluetooth;
  }
}
.dlt-c8y-icon-bluetooth-b {
  &:before {
    content: $dlt-c8y-icon-bluetooth-b;
  }
}
.dlt-c8y-icon-bluetooth-symbol {
  &:before {
    content: $dlt-c8y-icon-bluetooth-symbol;
  }
}
.dlt-c8y-icon-decentralized-network {
  &:before {
    content: $dlt-c8y-icon-decentralized-network;
  }
}
.dlt-c8y-icon-centralized-network {
  &:before {
    content: $dlt-c8y-icon-centralized-network;
  }
}
.dlt-c8y-icon-light-bulb-outline {
  &:before {
    content: $dlt-c8y-icon-light-bulb-outline;
  }
}
.dlt-c8y-icon-medkit {
  &:before {
    content: $dlt-c8y-icon-medkit;
  }
}
.dlt-c8y-icon-fax {
  &:before {
    content: $dlt-c8y-icon-fax;
  }
}
.dlt-c8y-icon-beer {
  &:before {
    content: $dlt-c8y-icon-beer;
  }
}
.dlt-c8y-icon-department {
  &:before {
    content: $dlt-c8y-icon-department;
  }
}
.dlt-c8y-icon-stacked-organizational-chart {
  &:before {
    content: $dlt-c8y-icon-stacked-organizational-chart;
  }
}
.dlt-c8y-icon-tty {
  &:before {
    content: $dlt-c8y-icon-tty;
  }
}
.dlt-c8y-icon-target {
  &:before {
    content: $dlt-c8y-icon-target;
  }
}
.dlt-c8y-icon-crosshairs {
  &:before {
    content: $dlt-c8y-icon-crosshairs;
  }
}
.dlt-c8y-icon-accuracy {
  &:before {
    content: $dlt-c8y-icon-accuracy;
  }
}
.dlt-c8y-icon-goal {
  &:before {
    content: $dlt-c8y-icon-goal;
  }
}
.dlt-c8y-icon-bullseye {
  &:before {
    content: $dlt-c8y-icon-bullseye;
  }
}
.dlt-c8y-icon-address-book {
  &:before {
    content: $dlt-c8y-icon-address-book;
  }
}
.dlt-c8y-icon-address-book-o {
  &:before {
    content: $dlt-c8y-icon-address-book-o;
  }
}
.dlt-c8y-icon-contacts-book {
  &:before {
    content: $dlt-c8y-icon-contacts-book;
  }
}
.dlt-c8y-icon-attract-customers {
  &:before {
    content: $dlt-c8y-icon-attract-customers;
  }
}
.dlt-c8y-icon-magnet {
  &:before {
    content: $dlt-c8y-icon-magnet;
  }
}
.dlt-c8y-icon-guarantee {
  &:before {
    content: $dlt-c8y-icon-guarantee;
  }
}
.dlt-c8y-icon-commercial {
  &:before {
    content: $dlt-c8y-icon-commercial;
  }
}
.dlt-c8y-icon-bullhorn {
  &:before {
    content: $dlt-c8y-icon-bullhorn;
  }
}
.dlt-c8y-icon-announcement {
  &:before {
    content: $dlt-c8y-icon-announcement;
  }
}
.dlt-c8y-icon-podium {
  &:before {
    content: $dlt-c8y-icon-podium;
  }
}
.dlt-c8y-icon-time-card {
  &:before {
    content: $dlt-c8y-icon-time-card;
  }
}
.dlt-c8y-icon-change {
  &:before {
    content: $dlt-c8y-icon-change;
  }
}
.dlt-c8y-icon-applicant {
  &:before {
    content: $dlt-c8y-icon-applicant;
  }
}
.dlt-c8y-icon-download-resume {
  &:before {
    content: $dlt-c8y-icon-download-resume;
  }
}
.dlt-c8y-icon-submit-resume {
  &:before {
    content: $dlt-c8y-icon-submit-resume;
  }
}
.dlt-c8y-icon-briefcase {
  &:before {
    content: $dlt-c8y-icon-briefcase;
  }
}
.dlt-c8y-icon-suitcase {
  &:before {
    content: $dlt-c8y-icon-suitcase;
  }
}
.dlt-c8y-icon-new-job {
  &:before {
    content: $dlt-c8y-icon-new-job;
  }
}
.dlt-c8y-icon-job-seeker {
  &:before {
    content: $dlt-c8y-icon-job-seeker;
  }
}
.dlt-c8y-icon-business-building {
  &:before {
    content: $dlt-c8y-icon-business-building;
  }
}
.dlt-c8y-icon-dossier {
  &:before {
    content: $dlt-c8y-icon-dossier;
  }
}
.dlt-c8y-icon-alarms {
  &:before {
    content: $dlt-c8y-icon-alarms;
  }
}
.dlt-c8y-icon-project {
  &:before {
    content: $dlt-c8y-icon-project;
  }
}
.dlt-c8y-icon-important-book {
  &:before {
    content: $dlt-c8y-icon-important-book;
  }
}
.dlt-c8y-icon-important-note {
  &:before {
    content: $dlt-c8y-icon-important-note;
  }
}
.dlt-c8y-icon-name-tag {
  &:before {
    content: $dlt-c8y-icon-name-tag;
  }
}
.dlt-c8y-icon-id-badge {
  &:before {
    content: $dlt-c8y-icon-id-badge;
  }
}
.dlt-c8y-icon-assignment-return {
  &:before {
    content: $dlt-c8y-icon-assignment-return;
  }
}
.dlt-c8y-icon-note-taking {
  &:before {
    content: $dlt-c8y-icon-note-taking;
  }
}
.dlt-c8y-icon-audit {
  &:before {
    content: $dlt-c8y-icon-audit;
  }
}
.dlt-c8y-icon-badge {
  &:before {
    content: $dlt-c8y-icon-badge;
  }
}
.dlt-c8y-icon-vcard {
  &:before {
    content: $dlt-c8y-icon-vcard;
  }
}
.dlt-c8y-icon-vcard-o {
  &:before {
    content: $dlt-c8y-icon-vcard-o;
  }
}
.dlt-c8y-icon-id-card {
  &:before {
    content: $dlt-c8y-icon-id-card;
  }
}
.dlt-c8y-icon-id-card-o {
  &:before {
    content: $dlt-c8y-icon-id-card-o;
  }
}
.dlt-c8y-icon-address-card-o {
  &:before {
    content: $dlt-c8y-icon-address-card-o;
  }
}
.dlt-c8y-icon-address-card {
  &:before {
    content: $dlt-c8y-icon-address-card;
  }
}
.dlt-c8y-icon-drivers-license {
  &:before {
    content: $dlt-c8y-icon-drivers-license;
  }
}
.dlt-c8y-icon-drivers-license-o {
  &:before {
    content: $dlt-c8y-icon-drivers-license-o;
  }
}
.dlt-c8y-icon-new-contact {
  &:before {
    content: $dlt-c8y-icon-new-contact;
  }
}
.dlt-c8y-icon-contact-details {
  &:before {
    content: $dlt-c8y-icon-contact-details;
  }
}
.dlt-c8y-icon-phone-contact {
  &:before {
    content: $dlt-c8y-icon-phone-contact;
  }
}
.dlt-c8y-icon-inspection {
  &:before {
    content: $dlt-c8y-icon-inspection;
  }
}
.dlt-c8y-icon-clipboard-with-a-tick {
  &:before {
    content: $dlt-c8y-icon-clipboard-with-a-tick;
  }
}
.dlt-c8y-icon-survey {
  &:before {
    content: $dlt-c8y-icon-survey;
  }
}
.dlt-c8y-icon-no-data-available {
  &:before {
    content: $dlt-c8y-icon-no-data-available;
  }
}
.dlt-c8y-icon-task-planning {
  &:before {
    content: $dlt-c8y-icon-task-planning;
  }
}
.dlt-c8y-icon-invoice {
  &:before {
    content: $dlt-c8y-icon-invoice;
  }
}
.dlt-c8y-icon-summary-list {
  &:before {
    content: $dlt-c8y-icon-summary-list;
  }
}
.dlt-c8y-icon-study {
  &:before {
    content: $dlt-c8y-icon-study;
  }
}
.dlt-c8y-icon-card {
  &:before {
    content: $dlt-c8y-icon-card;
  }
}
.dlt-c8y-icon-job {
  &:before {
    content: $dlt-c8y-icon-job;
  }
}
.dlt-c8y-icon-print {
  &:before {
    content: $dlt-c8y-icon-print;
  }
}
.dlt-c8y-icon-communication-internet {
  &:before {
    content: $dlt-c8y-icon-communication-internet;
  }
}
.dlt-c8y-icon-language-skill {
  &:before {
    content: $dlt-c8y-icon-language-skill;
  }
}
.dlt-c8y-icon-language {
  &:before {
    content: $dlt-c8y-icon-language;
  }
}
.dlt-c8y-icon-close-program {
  &:before {
    content: $dlt-c8y-icon-close-program;
  }
}
.dlt-c8y-icon-window-close {
  &:before {
    content: $dlt-c8y-icon-window-close;
  }
}
.dlt-c8y-icon-window-close-o {
  &:before {
    content: $dlt-c8y-icon-window-close-o;
  }
}
.dlt-c8y-icon-new-slide {
  &:before {
    content: $dlt-c8y-icon-new-slide;
  }
}
.dlt-c8y-icon-web-analytics {
  &:before {
    content: $dlt-c8y-icon-web-analytics;
  }
}
.dlt-c8y-icon-planner {
  &:before {
    content: $dlt-c8y-icon-planner;
  }
}
.dlt-c8y-icon-rescheduling-a-task {
  &:before {
    content: $dlt-c8y-icon-rescheduling-a-task;
  }
}
.dlt-c8y-icon-resume-website {
  &:before {
    content: $dlt-c8y-icon-resume-website;
  }
}
.dlt-c8y-icon-product-documents {
  &:before {
    content: $dlt-c8y-icon-product-documents;
  }
}
.dlt-c8y-icon-resume-template {
  &:before {
    content: $dlt-c8y-icon-resume-template;
  }
}
.dlt-c8y-icon-rubber-stamp {
  &:before {
    content: $dlt-c8y-icon-rubber-stamp;
  }
}
.dlt-c8y-icon-stamp {
  &:before {
    content: $dlt-c8y-icon-stamp;
  }
}
.dlt-c8y-icon-video-projector {
  &:before {
    content: $dlt-c8y-icon-video-projector;
  }
}
.dlt-c8y-icon-management1 {
  &:before {
    content: $dlt-c8y-icon-management1;
  }
}
.dlt-c8y-icon-gavel {
  &:before {
    content: $dlt-c8y-icon-gavel;
  }
}
.dlt-c8y-icon-legal {
  &:before {
    content: $dlt-c8y-icon-legal;
  }
}
.dlt-c8y-icon-user-md {
  &:before {
    content: $dlt-c8y-icon-user-md;
  }
}
.dlt-c8y-icon-wheelchair-alt {
  &:before {
    content: $dlt-c8y-icon-wheelchair-alt;
  }
}
.dlt-c8y-icon-wheelchair {
  &:before {
    content: $dlt-c8y-icon-wheelchair;
  }
}
.dlt-c8y-icon-child {
  &:before {
    content: $dlt-c8y-icon-child;
  }
}
.dlt-c8y-icon-workspace1 {
  &:before {
    content: $dlt-c8y-icon-workspace1;
  }
}
.dlt-c8y-icon-male-user {
  &:before {
    content: $dlt-c8y-icon-male-user;
  }
}
.dlt-c8y-icon-user-circle-o {
  &:before {
    content: $dlt-c8y-icon-user-circle-o;
  }
}
.dlt-c8y-icon-user-circle {
  &:before {
    content: $dlt-c8y-icon-user-circle;
  }
}
.dlt-c8y-icon-user-plus {
  &:before {
    content: $dlt-c8y-icon-user-plus;
  }
}
.dlt-c8y-icon-add-user {
  &:before {
    content: $dlt-c8y-icon-add-user;
  }
}
.dlt-c8y-icon-remove-user {
  &:before {
    content: $dlt-c8y-icon-remove-user;
  }
}
.dlt-c8y-icon-user-minus {
  &:before {
    content: $dlt-c8y-icon-user-minus;
  }
}
.dlt-c8y-icon-user-times {
  &:before {
    content: $dlt-c8y-icon-user-times;
  }
}
.dlt-c8y-icon-user-account {
  &:before {
    content: $dlt-c8y-icon-user-account;
  }
}
.dlt-c8y-icon-user {
  &:before {
    content: $dlt-c8y-icon-user;
  }
}
.dlt-c8y-icon-user-o {
  &:before {
    content: $dlt-c8y-icon-user-o;
  }
}
.dlt-c8y-icon-manager {
  &:before {
    content: $dlt-c8y-icon-manager;
  }
}
.dlt-c8y-icon-businessman {
  &:before {
    content: $dlt-c8y-icon-businessman;
  }
}
.dlt-c8y-icon-male {
  &:before {
    content: $dlt-c8y-icon-male;
  }
}
.dlt-c8y-icon-document-writer {
  &:before {
    content: $dlt-c8y-icon-document-writer;
  }
}
.dlt-c8y-icon-supplier {
  &:before {
    content: $dlt-c8y-icon-supplier;
  }
}
.dlt-c8y-icon-search-client {
  &:before {
    content: $dlt-c8y-icon-search-client;
  }
}
.dlt-c8y-icon-account-enable {
  &:before {
    content: $dlt-c8y-icon-account-enable;
  }
}
.dlt-c8y-icon-account-disable {
  &:before {
    content: $dlt-c8y-icon-account-disable;
  }
}
.dlt-c8y-icon-insurance-agent {
  &:before {
    content: $dlt-c8y-icon-insurance-agent;
  }
}
.dlt-c8y-icon-payroll {
  &:before {
    content: $dlt-c8y-icon-payroll;
  }
}
.dlt-c8y-icon-lawyer {
  &:before {
    content: $dlt-c8y-icon-lawyer;
  }
}
.dlt-c8y-icon-complaint {
  &:before {
    content: $dlt-c8y-icon-complaint;
  }
}
.dlt-c8y-icon-appointment-scheduling {
  &:before {
    content: $dlt-c8y-icon-appointment-scheduling;
  }
}
.dlt-c8y-icon-caretaker {
  &:before {
    content: $dlt-c8y-icon-caretaker;
  }
}
.dlt-c8y-icon-guardian {
  &:before {
    content: $dlt-c8y-icon-guardian;
  }
}
.dlt-c8y-icon-batch-assign {
  &:before {
    content: $dlt-c8y-icon-batch-assign;
  }
}
.dlt-c8y-icon-people {
  &:before {
    content: $dlt-c8y-icon-people;
  }
}
.dlt-c8y-icon-users {
  &:before {
    content: $dlt-c8y-icon-users;
  }
}
.dlt-c8y-icon-group1 {
  &:before {
    content: $dlt-c8y-icon-group1;
  }
}
.dlt-c8y-icon-management {
  &:before {
    content: $dlt-c8y-icon-management;
  }
}
.dlt-c8y-icon-team {
  &:before {
    content: $dlt-c8y-icon-team;
  }
}
.dlt-c8y-icon-people-working-together {
  &:before {
    content: $dlt-c8y-icon-people-working-together;
  }
}
.dlt-c8y-icon-group-task {
  &:before {
    content: $dlt-c8y-icon-group-task;
  }
}
.dlt-c8y-icon-accessibility {
  &:before {
    content: $dlt-c8y-icon-accessibility;
  }
}
.dlt-c8y-icon-universal-access {
  &:before {
    content: $dlt-c8y-icon-universal-access;
  }
}
.dlt-c8y-icon-pregnant-woman {
  &:before {
    content: $dlt-c8y-icon-pregnant-woman;
  }
}
.dlt-c8y-icon-female {
  &:before {
    content: $dlt-c8y-icon-female;
  }
}
.dlt-c8y-icon-business-conference-female-speaker {
  &:before {
    content: $dlt-c8y-icon-business-conference-female-speaker;
  }
}
.dlt-c8y-icon-organization-chart-people {
  &:before {
    content: $dlt-c8y-icon-organization-chart-people;
  }
}
.dlt-c8y-icon-meeting-room {
  &:before {
    content: $dlt-c8y-icon-meeting-room;
  }
}
.dlt-c8y-icon-permanent-job {
  &:before {
    content: $dlt-c8y-icon-permanent-job;
  }
}
.dlt-c8y-icon-contact-us {
  &:before {
    content: $dlt-c8y-icon-contact-us;
  }
}
.dlt-c8y-icon-voice-recognition {
  &:before {
    content: $dlt-c8y-icon-voice-recognition;
  }
}
.dlt-c8y-icon-collaboration {
  &:before {
    content: $dlt-c8y-icon-collaboration;
  }
}
.dlt-c8y-icon-technical-support {
  &:before {
    content: $dlt-c8y-icon-technical-support;
  }
}
.dlt-c8y-icon-online-support {
  &:before {
    content: $dlt-c8y-icon-online-support;
  }
}
.dlt-c8y-icon-student-male {
  &:before {
    content: $dlt-c8y-icon-student-male;
  }
}
.dlt-c8y-icon-devops {
  &:before {
    content: $dlt-c8y-icon-devops;
  }
}
.dlt-c8y-icon-exhibitor {
  &:before {
    content: $dlt-c8y-icon-exhibitor;
  }
}
.dlt-c8y-icon-env-permission {
  &:before {
    content: $dlt-c8y-icon-env-permission;
  }
}
.dlt-c8y-icon-bathtub {
  &:before {
    content: $dlt-c8y-icon-bathtub;
  }
}
.dlt-c8y-icon-bath {
  &:before {
    content: $dlt-c8y-icon-bath;
  }
}
.dlt-c8y-icon-s15 {
  &:before {
    content: $dlt-c8y-icon-s15;
  }
}
.dlt-c8y-icon-hotel {
  &:before {
    content: $dlt-c8y-icon-hotel;
  }
}
.dlt-c8y-icon-local-hotel {
  &:before {
    content: $dlt-c8y-icon-local-hotel;
  }
}
.dlt-c8y-icon-bed {
  &:before {
    content: $dlt-c8y-icon-bed;
  }
}
.dlt-c8y-icon-intelligence {
  &:before {
    content: $dlt-c8y-icon-intelligence;
  }
}
.dlt-c8y-icon-source-code {
  &:before {
    content: $dlt-c8y-icon-source-code;
  }
}
.dlt-c8y-icon-code {
  &:before {
    content: $dlt-c8y-icon-code;
  }
}
.dlt-c8y-icon-html-code {
  &:before {
    content: $dlt-c8y-icon-html-code;
  }
}
.dlt-c8y-icon-code1 {
  &:before {
    content: $dlt-c8y-icon-code1;
  }
}
.dlt-c8y-icon-file-code-o {
  &:before {
    content: $dlt-c8y-icon-file-code-o;
  }
}
.dlt-c8y-icon-console {
  &:before {
    content: $dlt-c8y-icon-console;
  }
}
.dlt-c8y-icon-terminal {
  &:before {
    content: $dlt-c8y-icon-terminal;
  }
}
.dlt-c8y-icon-add-property {
  &:before {
    content: $dlt-c8y-icon-add-property;
  }
}
.dlt-c8y-icon-delete-document {
  &:before {
    content: $dlt-c8y-icon-delete-document;
  }
}
.dlt-c8y-icon-urgent-property {
  &:before {
    content: $dlt-c8y-icon-urgent-property;
  }
}
.dlt-c8y-icon-remove-property {
  &:before {
    content: $dlt-c8y-icon-remove-property;
  }
}
.dlt-c8y-icon-edit-property {
  &:before {
    content: $dlt-c8y-icon-edit-property;
  }
}
.dlt-c8y-icon-template {
  &:before {
    content: $dlt-c8y-icon-template;
  }
}
.dlt-c8y-icon-test {
  &:before {
    content: $dlt-c8y-icon-test;
  }
}
.dlt-c8y-icon-property-script {
  &:before {
    content: $dlt-c8y-icon-property-script;
  }
}
.dlt-c8y-icon-show-property {
  &:before {
    content: $dlt-c8y-icon-show-property;
  }
}
.dlt-c8y-icon-search-property {
  &:before {
    content: $dlt-c8y-icon-search-property;
  }
}
.dlt-c8y-icon-timesheet {
  &:before {
    content: $dlt-c8y-icon-timesheet;
  }
}
.dlt-c8y-icon-important-property {
  &:before {
    content: $dlt-c8y-icon-important-property;
  }
}
.dlt-c8y-icon-new-property {
  &:before {
    content: $dlt-c8y-icon-new-property;
  }
}
.dlt-c8y-icon-navigation-toolbar-top {
  &:before {
    content: $dlt-c8y-icon-navigation-toolbar-top;
  }
}
.dlt-c8y-icon-window-maximize {
  &:before {
    content: $dlt-c8y-icon-window-maximize;
  }
}
.dlt-c8y-icon-web-application-firewall {
  &:before {
    content: $dlt-c8y-icon-web-application-firewall;
  }
}
.dlt-c8y-icon-website-bug {
  &:before {
    content: $dlt-c8y-icon-website-bug;
  }
}
.dlt-c8y-icon-bug {
  &:before {
    content: $dlt-c8y-icon-bug;
  }
}
.dlt-c8y-icon-web-design {
  &:before {
    content: $dlt-c8y-icon-web-design;
  }
}
.dlt-c8y-icon-navigation-toolbar-bottom {
  &:before {
    content: $dlt-c8y-icon-navigation-toolbar-bottom;
  }
}
.dlt-c8y-icon-more-details {
  &:before {
    content: $dlt-c8y-icon-more-details;
  }
}
.dlt-c8y-icon-navigation-pane {
  &:before {
    content: $dlt-c8y-icon-navigation-pane;
  }
}
.dlt-c8y-icon-pin {
  &:before {
    content: $dlt-c8y-icon-pin;
  }
}
.dlt-c8y-icon-thumb-tack {
  &:before {
    content: $dlt-c8y-icon-thumb-tack;
  }
}
.dlt-c8y-icon-unpin {
  &:before {
    content: $dlt-c8y-icon-unpin;
  }
}
.dlt-c8y-icon-commit-git {
  &:before {
    content: $dlt-c8y-icon-commit-git;
  }
}
.dlt-c8y-icon-codefork {
  &:before {
    content: $dlt-c8y-icon-codefork;
  }
}
.dlt-c8y-icon-code-fork {
  &:before {
    content: $dlt-c8y-icon-code-fork;
  }
}
.dlt-c8y-icon-merge-git {
  &:before {
    content: $dlt-c8y-icon-merge-git;
  }
}
.dlt-c8y-icon-pull-request {
  &:before {
    content: $dlt-c8y-icon-pull-request;
  }
}
.dlt-c8y-icon-compare-git {
  &:before {
    content: $dlt-c8y-icon-compare-git;
  }
}
.dlt-c8y-icon-share {
  &:before {
    content: $dlt-c8y-icon-share;
  }
}
.dlt-c8y-icon-share-alt {
  &:before {
    content: $dlt-c8y-icon-share-alt;
  }
}
.dlt-c8y-icon-share-alt-square {
  &:before {
    content: $dlt-c8y-icon-share-alt-square;
  }
}
.dlt-c8y-icon-activity-history {
  &:before {
    content: $dlt-c8y-icon-activity-history;
  }
}
.dlt-c8y-icon-rules {
  &:before {
    content: $dlt-c8y-icon-rules;
  }
}
.dlt-c8y-icon-create-document {
  &:before {
    content: $dlt-c8y-icon-create-document;
  }
}
.dlt-c8y-icon-product-architecture {
  &:before {
    content: $dlt-c8y-icon-product-architecture;
  }
}
.dlt-c8y-icon-registry-editor {
  &:before {
    content: $dlt-c8y-icon-registry-editor;
  }
}
.dlt-c8y-icon-rest-api {
  &:before {
    content: $dlt-c8y-icon-rest-api;
  }
}
.dlt-c8y-icon-true-false {
  &:before {
    content: $dlt-c8y-icon-true-false;
  }
}
.dlt-c8y-icon-uninstall-programs {
  &:before {
    content: $dlt-c8y-icon-uninstall-programs;
  }
}
.dlt-c8y-icon-sheets {
  &:before {
    content: $dlt-c8y-icon-sheets;
  }
}
.dlt-c8y-icon-stages {
  &:before {
    content: $dlt-c8y-icon-stages;
  }
}
.dlt-c8y-icon-add-stage {
  &:before {
    content: $dlt-c8y-icon-add-stage;
  }
}
.dlt-c8y-icon-api {
  &:before {
    content: $dlt-c8y-icon-api;
  }
}
.dlt-c8y-icon-blockly-turquoise {
  &:before {
    content: $dlt-c8y-icon-blockly-turquoise;
  }
}
.dlt-c8y-icon-blockly-blue {
  &:before {
    content: $dlt-c8y-icon-blockly-blue;
  }
}
.dlt-c8y-icon-blockly-pink {
  &:before {
    content: $dlt-c8y-icon-blockly-pink;
  }
}
.dlt-c8y-icon-plugin {
  &:before {
    content: $dlt-c8y-icon-plugin;
  }
}
.dlt-c8y-icon-base-64 {
  &:before {
    content: $dlt-c8y-icon-base-64;
  }
}
.dlt-c8y-icon-xml-transformer {
  &:before {
    content: $dlt-c8y-icon-xml-transformer;
  }
}
.dlt-c8y-icon-shield {
  &:before {
    content: $dlt-c8y-icon-shield;
  }
}
.dlt-c8y-icon-protect {
  &:before {
    content: $dlt-c8y-icon-protect;
  }
}
.dlt-c8y-icon-warning-shield {
  &:before {
    content: $dlt-c8y-icon-warning-shield;
  }
}
.dlt-c8y-icon-free-forever {
  &:before {
    content: $dlt-c8y-icon-free-forever;
  }
}
.dlt-c8y-icon-identification-documents {
  &:before {
    content: $dlt-c8y-icon-identification-documents;
  }
}
.dlt-c8y-icon-id-verified {
  &:before {
    content: $dlt-c8y-icon-id-verified;
  }
}
.dlt-c8y-icon-fingerprint {
  &:before {
    content: $dlt-c8y-icon-fingerprint;
  }
}
.dlt-c8y-icon-pin-code {
  &:before {
    content: $dlt-c8y-icon-pin-code;
  }
}
.dlt-c8y-icon-password {
  &:before {
    content: $dlt-c8y-icon-password;
  }
}
.dlt-c8y-icon-lock {
  &:before {
    content: $dlt-c8y-icon-lock;
  }
}
.dlt-c8y-icon-unlock {
  &:before {
    content: $dlt-c8y-icon-unlock;
  }
}
.dlt-c8y-icon-privacy {
  &:before {
    content: $dlt-c8y-icon-privacy;
  }
}
.dlt-c8y-icon-lock-outline {
  &:before {
    content: $dlt-c8y-icon-lock-outline;
  }
}
.dlt-c8y-icon-lock1 {
  &:before {
    content: $dlt-c8y-icon-lock1;
  }
}
.dlt-c8y-icon-lock-open {
  &:before {
    content: $dlt-c8y-icon-lock-open;
  }
}
.dlt-c8y-icon-unlock1 {
  &:before {
    content: $dlt-c8y-icon-unlock1;
  }
}
.dlt-c8y-icon-unlock-alt {
  &:before {
    content: $dlt-c8y-icon-unlock-alt;
  }
}
.dlt-c8y-icon-key {
  &:before {
    content: $dlt-c8y-icon-key;
  }
}
.dlt-c8y-icon-access {
  &:before {
    content: $dlt-c8y-icon-access;
  }
}
.dlt-c8y-icon-lock11 {
  &:before {
    content: $dlt-c8y-icon-lock11;
  }
}
.dlt-c8y-icon-realtime {
  &:before {
    content: $dlt-c8y-icon-realtime;
  }
}
.dlt-c8y-icon-smart-home-connection {
  &:before {
    content: $dlt-c8y-icon-smart-home-connection;
  }
}
.dlt-c8y-icon-smart-home-checked {
  &:before {
    content: $dlt-c8y-icon-smart-home-checked;
  }
}
.dlt-c8y-icon-smart-home-error {
  &:before {
    content: $dlt-c8y-icon-smart-home-error;
  }
}
.dlt-c8y-icon-smart-home-remove {
  &:before {
    content: $dlt-c8y-icon-smart-home-remove;
  }
}
.dlt-c8y-icon-smart-home-shield {
  &:before {
    content: $dlt-c8y-icon-smart-home-shield;
  }
}
.dlt-c8y-icon-voice-id {
  &:before {
    content: $dlt-c8y-icon-voice-id;
  }
}
.dlt-c8y-icon-web-application-firewall1 {
  &:before {
    content: $dlt-c8y-icon-web-application-firewall1;
  }
}
.dlt-c8y-icon-copyright {
  &:before {
    content: $dlt-c8y-icon-copyright;
  }
}
.dlt-c8y-icon-spy {
  &:before {
    content: $dlt-c8y-icon-spy;
  }
}
.dlt-c8y-icon-user-secret {
  &:before {
    content: $dlt-c8y-icon-user-secret;
  }
}
.dlt-c8y-icon-bug1 {
  &:before {
    content: $dlt-c8y-icon-bug1;
  }
}
.dlt-c8y-icon-health-examine {
  &:before {
    content: $dlt-c8y-icon-health-examine;
  }
}
.dlt-c8y-icon-stethoscope {
  &:before {
    content: $dlt-c8y-icon-stethoscope;
  }
}
.dlt-c8y-icon-space-shuttle {
  &:before {
    content: $dlt-c8y-icon-space-shuttle;
  }
}
.dlt-c8y-icon-ambulance {
  &:before {
    content: $dlt-c8y-icon-ambulance;
  }
}
.dlt-c8y-icon-car {
  &:before {
    content: $dlt-c8y-icon-car;
  }
}
.dlt-c8y-icon-automobile {
  &:before {
    content: $dlt-c8y-icon-automobile;
  }
}
.dlt-c8y-icon-local-taxi {
  &:before {
    content: $dlt-c8y-icon-local-taxi;
  }
}
.dlt-c8y-icon-taxi {
  &:before {
    content: $dlt-c8y-icon-taxi;
  }
}
.dlt-c8y-icon-cab {
  &:before {
    content: $dlt-c8y-icon-cab;
  }
}
.dlt-c8y-icon-truck {
  &:before {
    content: $dlt-c8y-icon-truck;
  }
}
.dlt-c8y-icon-file-delivery {
  &:before {
    content: $dlt-c8y-icon-file-delivery;
  }
}
.dlt-c8y-icon-in-transit {
  &:before {
    content: $dlt-c8y-icon-in-transit;
  }
}
.dlt-c8y-icon-bus {
  &:before {
    content: $dlt-c8y-icon-bus;
  }
}
.dlt-c8y-icon-train {
  &:before {
    content: $dlt-c8y-icon-train;
  }
}
.dlt-c8y-icon-directions-subway {
  &:before {
    content: $dlt-c8y-icon-directions-subway;
  }
}
.dlt-c8y-icon-directions-transit {
  &:before {
    content: $dlt-c8y-icon-directions-transit;
  }
}
.dlt-c8y-icon-tram {
  &:before {
    content: $dlt-c8y-icon-tram;
  }
}
.dlt-c8y-icon-delivery-dining {
  &:before {
    content: $dlt-c8y-icon-delivery-dining;
  }
}
.dlt-c8y-icon-scooter {
  &:before {
    content: $dlt-c8y-icon-scooter;
  }
}
.dlt-c8y-icon-pedal-bike {
  &:before {
    content: $dlt-c8y-icon-pedal-bike;
  }
}
.dlt-c8y-icon-bicycle {
  &:before {
    content: $dlt-c8y-icon-bicycle;
  }
}
.dlt-c8y-icon-motorcycle {
  &:before {
    content: $dlt-c8y-icon-motorcycle;
  }
}
.dlt-c8y-icon-airport {
  &:before {
    content: $dlt-c8y-icon-airport;
  }
}
.dlt-c8y-icon-plane {
  &:before {
    content: $dlt-c8y-icon-plane;
  }
}
.dlt-c8y-icon-fighter-jet {
  &:before {
    content: $dlt-c8y-icon-fighter-jet;
  }
}
.dlt-c8y-icon-directions-ferry {
  &:before {
    content: $dlt-c8y-icon-directions-ferry;
  }
}
.dlt-c8y-icon-ship {
  &:before {
    content: $dlt-c8y-icon-ship;
  }
}
.dlt-c8y-icon-deploy {
  &:before {
    content: $dlt-c8y-icon-deploy;
  }
}
.dlt-c8y-icon-rocket {
  &:before {
    content: $dlt-c8y-icon-rocket;
  }
}
.dlt-c8y-icon-wrench1 {
  &:before {
    content: $dlt-c8y-icon-wrench1;
  }
}
.dlt-c8y-icon-road {
  &:before {
    content: $dlt-c8y-icon-road;
  }
}
.dlt-c8y-icon-flag-outline {
  &:before {
    content: $dlt-c8y-icon-flag-outline;
  }
}
.dlt-c8y-icon-software {
  &:before {
    content: $dlt-c8y-icon-software;
  }
}
.dlt-c8y-icon-future {
  &:before {
    content: $dlt-c8y-icon-future;
  }
}
.dlt-c8y-icon-time-machine {
  &:before {
    content: $dlt-c8y-icon-time-machine;
  }
}
.dlt-c8y-icon-history {
  &:before {
    content: $dlt-c8y-icon-history;
  }
}
.dlt-c8y-icon-delivery-time {
  &:before {
    content: $dlt-c8y-icon-delivery-time;
  }
}
.dlt-c8y-icon-schedule {
  &:before {
    content: $dlt-c8y-icon-schedule;
  }
}
.dlt-c8y-icon-clock {
  &:before {
    content: $dlt-c8y-icon-clock;
  }
}
.dlt-c8y-icon-clock-o {
  &:before {
    content: $dlt-c8y-icon-clock-o;
  }
}
.dlt-c8y-icon-watches-front-view {
  &:before {
    content: $dlt-c8y-icon-watches-front-view;
  }
}
.dlt-c8y-icon-clock1 {
  &:before {
    content: $dlt-c8y-icon-clock1;
  }
}
.dlt-c8y-icon-clock11 {
  &:before {
    content: $dlt-c8y-icon-clock11;
  }
}
.dlt-c8y-icon-alarm1 {
  &:before {
    content: $dlt-c8y-icon-alarm1;
  }
}
.dlt-c8y-icon-timer {
  &:before {
    content: $dlt-c8y-icon-timer;
  }
}
.dlt-c8y-icon-wall-clock {
  &:before {
    content: $dlt-c8y-icon-wall-clock;
  }
}
.dlt-c8y-icon-alarm-add {
  &:before {
    content: $dlt-c8y-icon-alarm-add;
  }
}
.dlt-c8y-icon-alarm-on {
  &:before {
    content: $dlt-c8y-icon-alarm-on;
  }
}
.dlt-c8y-icon-alarm-off {
  &:before {
    content: $dlt-c8y-icon-alarm-off;
  }
}
.dlt-c8y-icon-calendar {
  &:before {
    content: $dlt-c8y-icon-calendar;
  }
}
.dlt-c8y-icon-calendar-o {
  &:before {
    content: $dlt-c8y-icon-calendar-o;
  }
}
.dlt-c8y-icon-today {
  &:before {
    content: $dlt-c8y-icon-today;
  }
}
.dlt-c8y-icon-calendar-check-o {
  &:before {
    content: $dlt-c8y-icon-calendar-check-o;
  }
}
.dlt-c8y-icon-calendar-1 {
  &:before {
    content: $dlt-c8y-icon-calendar-1;
  }
}
.dlt-c8y-icon-calendar-31 {
  &:before {
    content: $dlt-c8y-icon-calendar-31;
  }
}
.dlt-c8y-icon-monday {
  &:before {
    content: $dlt-c8y-icon-monday;
  }
}
.dlt-c8y-icon-sunday {
  &:before {
    content: $dlt-c8y-icon-sunday;
  }
}
.dlt-c8y-icon-january {
  &:before {
    content: $dlt-c8y-icon-january;
  }
}
.dlt-c8y-icon-december {
  &:before {
    content: $dlt-c8y-icon-december;
  }
}
.dlt-c8y-icon-edit-calendar {
  &:before {
    content: $dlt-c8y-icon-edit-calendar;
  }
}
.dlt-c8y-icon-view-schedule {
  &:before {
    content: $dlt-c8y-icon-view-schedule;
  }
}
.dlt-c8y-icon-calendar-add {
  &:before {
    content: $dlt-c8y-icon-calendar-add;
  }
}
.dlt-c8y-icon-calendar-plus {
  &:before {
    content: $dlt-c8y-icon-calendar-plus;
  }
}
.dlt-c8y-icon-calendar-plus-o {
  &:before {
    content: $dlt-c8y-icon-calendar-plus-o;
  }
}
.dlt-c8y-icon-calendar-remove {
  &:before {
    content: $dlt-c8y-icon-calendar-remove;
  }
}
.dlt-c8y-icon-calendar-minus {
  &:before {
    content: $dlt-c8y-icon-calendar-minus;
  }
}
.dlt-c8y-icon-calendar-times {
  &:before {
    content: $dlt-c8y-icon-calendar-times;
  }
}
.dlt-c8y-icon-calendar-minus-o {
  &:before {
    content: $dlt-c8y-icon-calendar-minus-o;
  }
}
.dlt-c8y-icon-calendar-times-o {
  &:before {
    content: $dlt-c8y-icon-calendar-times-o;
  }
}
.dlt-c8y-icon-important-month {
  &:before {
    content: $dlt-c8y-icon-important-month;
  }
}
.dlt-c8y-icon-schedule1 {
  &:before {
    content: $dlt-c8y-icon-schedule1;
  }
}
.dlt-c8y-icon-sand-watch {
  &:before {
    content: $dlt-c8y-icon-sand-watch;
  }
}
.dlt-c8y-icon-hourglass-start {
  &:before {
    content: $dlt-c8y-icon-hourglass-start;
  }
}
.dlt-c8y-icon-hourglass-o {
  &:before {
    content: $dlt-c8y-icon-hourglass-o;
  }
}
.dlt-c8y-icon-hourglass-half {
  &:before {
    content: $dlt-c8y-icon-hourglass-half;
  }
}
.dlt-c8y-icon-hourglass-end {
  &:before {
    content: $dlt-c8y-icon-hourglass-end;
  }
}
.dlt-c8y-icon-hourglass {
  &:before {
    content: $dlt-c8y-icon-hourglass;
  }
}
.dlt-c8y-icon-hourglass-1 {
  &:before {
    content: $dlt-c8y-icon-hourglass-1;
  }
}
.dlt-c8y-icon-hourglass-2 {
  &:before {
    content: $dlt-c8y-icon-hourglass-2;
  }
}
.dlt-c8y-icon-hourglass-3 {
  &:before {
    content: $dlt-c8y-icon-hourglass-3;
  }
}
.dlt-c8y-icon-timezone-globe {
  &:before {
    content: $dlt-c8y-icon-timezone-globe;
  }
}
.dlt-c8y-icon-timezone {
  &:before {
    content: $dlt-c8y-icon-timezone;
  }
}
.dlt-c8y-icon-timetable {
  &:before {
    content: $dlt-c8y-icon-timetable;
  }
}
.dlt-c8y-icon-day-view {
  &:before {
    content: $dlt-c8y-icon-day-view;
  }
}
.dlt-c8y-icon-month-view {
  &:before {
    content: $dlt-c8y-icon-month-view;
  }
}
.dlt-c8y-icon-week-view {
  &:before {
    content: $dlt-c8y-icon-week-view;
  }
}
.dlt-c8y-icon-year-view {
  &:before {
    content: $dlt-c8y-icon-year-view;
  }
}
.dlt-c8y-icon-newspaper-o {
  &:before {
    content: $dlt-c8y-icon-newspaper-o;
  }
}
.dlt-c8y-icon-creative-commons {
  &:before {
    content: $dlt-c8y-icon-creative-commons;
  }
}
.dlt-c8y-icon-spinner {
  &:before {
    content: $dlt-c8y-icon-spinner;
  }
}
.dlt-c8y-icon-loading {
  &:before {
    content: $dlt-c8y-icon-loading;
  }
}
.dlt-c8y-icon-busy {
  &:before {
    content: $dlt-c8y-icon-busy;
  }
}
.dlt-c8y-icon-wheel {
  &:before {
    content: $dlt-c8y-icon-wheel;
  }
}
.dlt-c8y-icon-trademark {
  &:before {
    content: $dlt-c8y-icon-trademark;
  }
}
.dlt-c8y-icon-toggle-on {
  &:before {
    content: $dlt-c8y-icon-toggle-on;
  }
}
.dlt-c8y-icon-toggle-off {
  &:before {
    content: $dlt-c8y-icon-toggle-off;
  }
}
.dlt-c8y-icon-ticket {
  &:before {
    content: $dlt-c8y-icon-ticket;
  }
}
.dlt-c8y-icon-spoon {
  &:before {
    content: $dlt-c8y-icon-spoon;
  }
}
.dlt-c8y-icon-sort-numeric-desc {
  &:before {
    content: $dlt-c8y-icon-sort-numeric-desc;
  }
}
.dlt-c8y-icon-sort-numeric-asc {
  &:before {
    content: $dlt-c8y-icon-sort-numeric-asc;
  }
}
.dlt-c8y-icon-snowflake-o {
  &:before {
    content: $dlt-c8y-icon-snowflake-o;
  }
}
.dlt-c8y-icon-shower {
  &:before {
    content: $dlt-c8y-icon-shower;
  }
}
.dlt-c8y-icon-percent {
  &:before {
    content: $dlt-c8y-icon-percent;
  }
}
.dlt-c8y-icon-paw {
  &:before {
    content: $dlt-c8y-icon-paw;
  }
}
.dlt-c8y-icon-mouse-pointer {
  &:before {
    content: $dlt-c8y-icon-mouse-pointer;
  }
}
.dlt-c8y-icon-lemon-o {
  &:before {
    content: $dlt-c8y-icon-lemon-o;
  }
}
.dlt-c8y-icon-leaf {
  &:before {
    content: $dlt-c8y-icon-leaf;
  }
}
.dlt-c8y-icon-diamond {
  &:before {
    content: $dlt-c8y-icon-diamond;
  }
}
.dlt-c8y-icon-braille {
  &:before {
    content: $dlt-c8y-icon-braille;
  }
}
.dlt-c8y-icon-bomb {
  &:before {
    content: $dlt-c8y-icon-bomb;
  }
}
.dlt-c8y-icon-binoculars {
  &:before {
    content: $dlt-c8y-icon-binoculars;
  }
}
.dlt-c8y-icon-eject {
  &:before {
    content: $dlt-c8y-icon-eject;
  }
}
.dlt-c8y-icon-touch-app {
  &:before {
    content: $dlt-c8y-icon-touch-app;
  }
}
.dlt-c8y-icon-soccer {
  &:before {
    content: $dlt-c8y-icon-soccer;
  }
}
.dlt-c8y-icon-football {
  &:before {
    content: $dlt-c8y-icon-football;
  }
}
.dlt-c8y-icon-futbol-o {
  &:before {
    content: $dlt-c8y-icon-futbol-o;
  }
}
.dlt-c8y-icon-soccer-ball-o {
  &:before {
    content: $dlt-c8y-icon-soccer-ball-o;
  }
}
.dlt-c8y-icon-mood {
  &:before {
    content: $dlt-c8y-icon-mood;
  }
}
.dlt-c8y-icon-meh-o {
  &:before {
    content: $dlt-c8y-icon-meh-o;
  }
}
.dlt-c8y-icon-frown-o {
  &:before {
    content: $dlt-c8y-icon-frown-o;
  }
}
.dlt-c8y-icon-smile-o {
  &:before {
    content: $dlt-c8y-icon-smile-o;
  }
}
.dlt-c8y-icon-deaf {
  &:before {
    content: $dlt-c8y-icon-deaf;
  }
}
.dlt-c8y-icon-deafness {
  &:before {
    content: $dlt-c8y-icon-deafness;
  }
}
.dlt-c8y-icon-hard-of-hearing {
  &:before {
    content: $dlt-c8y-icon-hard-of-hearing;
  }
}
.dlt-c8y-icon-assistive-listening-systems {
  &:before {
    content: $dlt-c8y-icon-assistive-listening-systems;
  }
}
.dlt-c8y-icon-american-sign-language-interpreting {
  &:before {
    content: $dlt-c8y-icon-american-sign-language-interpreting;
  }
}
.dlt-c8y-icon-asl-interpreting {
  &:before {
    content: $dlt-c8y-icon-asl-interpreting;
  }
}
.dlt-c8y-icon-cc {
  &:before {
    content: $dlt-c8y-icon-cc;
  }
}
.dlt-c8y-icon-closed-caption {
  &:before {
    content: $dlt-c8y-icon-closed-caption;
  }
}
.dlt-c8y-icon-anchor {
  &:before {
    content: $dlt-c8y-icon-anchor;
  }
}
.dlt-c8y-icon-accessibility-settings {
  &:before {
    content: $dlt-c8y-icon-accessibility-settings;
  }
}
.dlt-c8y-icon-info {
  &:before {
    content: $dlt-c8y-icon-info;
  }
}
.dlt-c8y-icon-info-circle {
  &:before {
    content: $dlt-c8y-icon-info-circle;
  }
}
.dlt-c8y-icon-warning {
  &:before {
    content: $dlt-c8y-icon-warning;
  }
}
.dlt-c8y-icon-report-problem {
  &:before {
    content: $dlt-c8y-icon-report-problem;
  }
}
.dlt-c8y-icon-exclamation-triangle {
  &:before {
    content: $dlt-c8y-icon-exclamation-triangle;
  }
}
.dlt-c8y-icon-exclamation-circle {
  &:before {
    content: $dlt-c8y-icon-exclamation-circle;
  }
}
.dlt-c8y-icon-error {
  &:before {
    content: $dlt-c8y-icon-error;
  }
}
.dlt-c8y-icon-high-priority {
  &:before {
    content: $dlt-c8y-icon-high-priority;
  }
}
.dlt-c8y-icon-spam {
  &:before {
    content: $dlt-c8y-icon-spam;
  }
}
.dlt-c8y-icon-help {
  &:before {
    content: $dlt-c8y-icon-help;
  }
}
.dlt-c8y-icon-question-circle {
  &:before {
    content: $dlt-c8y-icon-question-circle;
  }
}
.dlt-c8y-icon-question {
  &:before {
    content: $dlt-c8y-icon-question;
  }
}
.dlt-c8y-icon-help-outline {
  &:before {
    content: $dlt-c8y-icon-help-outline;
  }
}
.dlt-c8y-icon-question-circle-o {
  &:before {
    content: $dlt-c8y-icon-question-circle-o;
  }
}
.dlt-c8y-icon-approval {
  &:before {
    content: $dlt-c8y-icon-approval;
  }
}
.dlt-c8y-icon-trophy {
  &:before {
    content: $dlt-c8y-icon-trophy;
  }
}
.dlt-c8y-icon-unverified-account {
  &:before {
    content: $dlt-c8y-icon-unverified-account;
  }
}
.dlt-c8y-icon-automatic-gearbox-warning {
  &:before {
    content: $dlt-c8y-icon-automatic-gearbox-warning;
  }
}
.dlt-c8y-icon-exclamation-circle1 {
  &:before {
    content: $dlt-c8y-icon-exclamation-circle1;
  }
}
.dlt-c8y-icon-user-manual {
  &:before {
    content: $dlt-c8y-icon-user-manual;
  }
}
.dlt-c8y-icon-stages1 {
  &:before {
    content: $dlt-c8y-icon-stages1;
  }
}
.dlt-c8y-icon-menu {
  &:before {
    content: $dlt-c8y-icon-menu;
  }
}
.dlt-c8y-icon-reorder {
  &:before {
    content: $dlt-c8y-icon-reorder;
  }
}
.dlt-c8y-icon-navicon {
  &:before {
    content: $dlt-c8y-icon-navicon;
  }
}
.dlt-c8y-icon-bars {
  &:before {
    content: $dlt-c8y-icon-bars;
  }
}
.dlt-c8y-icon-hamburger {
  &:before {
    content: $dlt-c8y-icon-hamburger;
  }
}
.dlt-c8y-icon-menu-vertical {
  &:before {
    content: $dlt-c8y-icon-menu-vertical;
  }
}
.dlt-c8y-icon-ellipsis-v {
  &:before {
    content: $dlt-c8y-icon-ellipsis-v;
  }
}
.dlt-c8y-icon-app-switcher {
  &:before {
    content: $dlt-c8y-icon-app-switcher;
  }
}
.dlt-c8y-icon-menu-horizontal {
  &:before {
    content: $dlt-c8y-icon-menu-horizontal;
  }
}
.dlt-c8y-icon-ellipsis-h {
  &:before {
    content: $dlt-c8y-icon-ellipsis-h;
  }
}
.dlt-c8y-icon-plus-math {
  &:before {
    content: $dlt-c8y-icon-plus-math;
  }
}
.dlt-c8y-icon-plus {
  &:before {
    content: $dlt-c8y-icon-plus;
  }
}
.dlt-c8y-icon-subtract {
  &:before {
    content: $dlt-c8y-icon-subtract;
  }
}
.dlt-c8y-icon-minus {
  &:before {
    content: $dlt-c8y-icon-minus;
  }
}
.dlt-c8y-icon-clear {
  &:before {
    content: $dlt-c8y-icon-clear;
  }
}
.dlt-c8y-icon-close {
  &:before {
    content: $dlt-c8y-icon-close;
  }
}
.dlt-c8y-icon-remove {
  &:before {
    content: $dlt-c8y-icon-remove;
  }
}
.dlt-c8y-icon-times {
  &:before {
    content: $dlt-c8y-icon-times;
  }
}
.dlt-c8y-icon-equals {
  &:before {
    content: $dlt-c8y-icon-equals;
  }
}
.dlt-c8y-icon-exclamation {
  &:before {
    content: $dlt-c8y-icon-exclamation;
  }
}
.dlt-c8y-icon-check {
  &:before {
    content: $dlt-c8y-icon-check;
  }
}
.dlt-c8y-icon-upvote-downvote {
  &:before {
    content: $dlt-c8y-icon-upvote-downvote;
  }
}
.dlt-c8y-icon-math {
  &:before {
    content: $dlt-c8y-icon-math;
  }
}
.dlt-c8y-icon-less-than1 {
  &:before {
    content: $dlt-c8y-icon-less-than1;
  }
}
.dlt-c8y-icon-more-than {
  &:before {
    content: $dlt-c8y-icon-more-than;
  }
}
.dlt-c8y-icon-circled-notch {
  &:before {
    content: $dlt-c8y-icon-circled-notch;
  }
}
.dlt-c8y-icon-circle-o-notch {
  &:before {
    content: $dlt-c8y-icon-circle-o-notch;
  }
}
.dlt-c8y-icon-inactive-state {
  &:before {
    content: $dlt-c8y-icon-inactive-state;
  }
}
.dlt-c8y-icon-storage1 {
  &:before {
    content: $dlt-c8y-icon-storage1;
  }
}
.dlt-c8y-icon-lifebuoy {
  &:before {
    content: $dlt-c8y-icon-lifebuoy;
  }
}
.dlt-c8y-icon-life-buoy {
  &:before {
    content: $dlt-c8y-icon-life-buoy;
  }
}
.dlt-c8y-icon-life-ring {
  &:before {
    content: $dlt-c8y-icon-life-ring;
  }
}
.dlt-c8y-icon-life-saver {
  &:before {
    content: $dlt-c8y-icon-life-saver;
  }
}
.dlt-c8y-icon-wait {
  &:before {
    content: $dlt-c8y-icon-wait;
  }
}
.dlt-c8y-icon-block {
  &:before {
    content: $dlt-c8y-icon-block;
  }
}
.dlt-c8y-icon-ban {
  &:before {
    content: $dlt-c8y-icon-ban;
  }
}
.dlt-c8y-icon-copy1 {
  &:before {
    content: $dlt-c8y-icon-copy1;
  }
}
.dlt-c8y-icon-clone {
  &:before {
    content: $dlt-c8y-icon-clone;
  }
}
.dlt-c8y-icon-paste1 {
  &:before {
    content: $dlt-c8y-icon-paste1;
  }
}
.dlt-c8y-icon-clipboard {
  &:before {
    content: $dlt-c8y-icon-clipboard;
  }
}
.dlt-c8y-icon-search-more {
  &:before {
    content: $dlt-c8y-icon-search-more;
  }
}
.dlt-c8y-icon-search {
  &:before {
    content: $dlt-c8y-icon-search;
  }
}
.dlt-c8y-icon-mobile-zoom-in {
  &:before {
    content: $dlt-c8y-icon-mobile-zoom-in;
  }
}
.dlt-c8y-icon-search-plus {
  &:before {
    content: $dlt-c8y-icon-search-plus;
  }
}
.dlt-c8y-icon-mobile-zoom-out {
  &:before {
    content: $dlt-c8y-icon-mobile-zoom-out;
  }
}
.dlt-c8y-icon-search-minus {
  &:before {
    content: $dlt-c8y-icon-search-minus;
  }
}
.dlt-c8y-icon-product1 {
  &:before {
    content: $dlt-c8y-icon-product1;
  }
}
.dlt-c8y-icon-trash-can {
  &:before {
    content: $dlt-c8y-icon-trash-can;
  }
}
.dlt-c8y-icon-trash {
  &:before {
    content: $dlt-c8y-icon-trash;
  }
}
.dlt-c8y-icon-editing-trash {
  &:before {
    content: $dlt-c8y-icon-editing-trash;
  }
}
.dlt-c8y-icon-trash-o {
  &:before {
    content: $dlt-c8y-icon-trash-o;
  }
}
.dlt-c8y-icon-delete-bin {
  &:before {
    content: $dlt-c8y-icon-delete-bin;
  }
}
.dlt-c8y-icon-eye {
  &:before {
    content: $dlt-c8y-icon-eye;
  }
}
.dlt-c8y-icon-visibility-off {
  &:before {
    content: $dlt-c8y-icon-visibility-off;
  }
}
.dlt-c8y-icon-low-vision {
  &:before {
    content: $dlt-c8y-icon-low-vision;
  }
}
.dlt-c8y-icon-eye-slash {
  &:before {
    content: $dlt-c8y-icon-eye-slash;
  }
}
.dlt-c8y-icon-blind {
  &:before {
    content: $dlt-c8y-icon-blind;
  }
}
.dlt-c8y-icon-double-tap {
  &:before {
    content: $dlt-c8y-icon-double-tap;
  }
}
.dlt-c8y-icon-heart {
  &:before {
    content: $dlt-c8y-icon-heart;
  }
}
.dlt-c8y-icon-heart-o {
  &:before {
    content: $dlt-c8y-icon-heart-o;
  }
}
.dlt-c8y-icon-unheart {
  &:before {
    content: $dlt-c8y-icon-unheart;
  }
}
.dlt-c8y-icon-science {
  &:before {
    content: $dlt-c8y-icon-science;
  }
}
.dlt-c8y-icon-flask {
  &:before {
    content: $dlt-c8y-icon-flask;
  }
}
.dlt-c8y-icon-school {
  &:before {
    content: $dlt-c8y-icon-school;
  }
}
.dlt-c8y-icon-graduation-cap {
  &:before {
    content: $dlt-c8y-icon-graduation-cap;
  }
}
.dlt-c8y-icon-mortar-board {
  &:before {
    content: $dlt-c8y-icon-mortar-board;
  }
}
.dlt-c8y-icon-badge1 {
  &:before {
    content: $dlt-c8y-icon-badge1;
  }
}
.dlt-c8y-icon-data-quality {
  &:before {
    content: $dlt-c8y-icon-data-quality;
  }
}
.dlt-c8y-icon-brightness-low {
  &:before {
    content: $dlt-c8y-icon-brightness-low;
  }
}
.dlt-c8y-icon-brightness-5 {
  &:before {
    content: $dlt-c8y-icon-brightness-5;
  }
}
.dlt-c8y-icon-sun-o {
  &:before {
    content: $dlt-c8y-icon-sun-o;
  }
}
.dlt-c8y-icon-bedtime {
  &:before {
    content: $dlt-c8y-icon-bedtime;
  }
}
.dlt-c8y-icon-moon-o {
  &:before {
    content: $dlt-c8y-icon-moon-o;
  }
}
.dlt-c8y-icon-moon {
  &:before {
    content: $dlt-c8y-icon-moon;
  }
}
.dlt-c8y-icon-add-identity-provider {
  &:before {
    content: $dlt-c8y-icon-add-identity-provider;
  }
}
.dlt-c8y-icon-bookmark-outline {
  &:before {
    content: $dlt-c8y-icon-bookmark-outline;
  }
}
.dlt-c8y-icon-turned-in-not {
  &:before {
    content: $dlt-c8y-icon-turned-in-not;
  }
}
.dlt-c8y-icon-bookmark-o {
  &:before {
    content: $dlt-c8y-icon-bookmark-o;
  }
}
.dlt-c8y-icon-unbookmark {
  &:before {
    content: $dlt-c8y-icon-unbookmark;
  }
}
.dlt-c8y-icon-bookmark {
  &:before {
    content: $dlt-c8y-icon-bookmark;
  }
}
.dlt-c8y-icon-turned-in {
  &:before {
    content: $dlt-c8y-icon-turned-in;
  }
}
.dlt-c8y-icon-check-box {
  &:before {
    content: $dlt-c8y-icon-check-box;
  }
}
.dlt-c8y-icon-check-square {
  &:before {
    content: $dlt-c8y-icon-check-square;
  }
}
.dlt-c8y-icon-check-square-o {
  &:before {
    content: $dlt-c8y-icon-check-square-o;
  }
}
.dlt-c8y-icon-checkbox-selected {
  &:before {
    content: $dlt-c8y-icon-checkbox-selected;
  }
}
.dlt-c8y-icon-add-box {
  &:before {
    content: $dlt-c8y-icon-add-box;
  }
}
.dlt-c8y-icon-plus-square {
  &:before {
    content: $dlt-c8y-icon-plus-square;
  }
}
.dlt-c8y-icon-indeterminate-check-box {
  &:before {
    content: $dlt-c8y-icon-indeterminate-check-box;
  }
}
.dlt-c8y-icon-minus-square {
  &:before {
    content: $dlt-c8y-icon-minus-square;
  }
}
.dlt-c8y-icon-duplicate {
  &:before {
    content: $dlt-c8y-icon-duplicate;
  }
}
.dlt-c8y-icon-check-box-outline-blank {
  &:before {
    content: $dlt-c8y-icon-check-box-outline-blank;
  }
}
.dlt-c8y-icon-square-o {
  &:before {
    content: $dlt-c8y-icon-square-o;
  }
}
.dlt-c8y-icon-checkbox-unselected {
  &:before {
    content: $dlt-c8y-icon-checkbox-unselected;
  }
}
.dlt-c8y-icon-add-new {
  &:before {
    content: $dlt-c8y-icon-add-new;
  }
}
.dlt-c8y-icon-plus-square-o {
  &:before {
    content: $dlt-c8y-icon-plus-square-o;
  }
}
.dlt-c8y-icon-negative {
  &:before {
    content: $dlt-c8y-icon-negative;
  }
}
.dlt-c8y-icon-minus-square-o {
  &:before {
    content: $dlt-c8y-icon-minus-square-o;
  }
}
.dlt-c8y-icon-multiplication {
  &:before {
    content: $dlt-c8y-icon-multiplication;
  }
}
.dlt-c8y-icon-times-rectangle-o {
  &:before {
    content: $dlt-c8y-icon-times-rectangle-o;
  }
}
.dlt-c8y-icon-times-rectangle {
  &:before {
    content: $dlt-c8y-icon-times-rectangle;
  }
}
.dlt-c8y-icon-ratio {
  &:before {
    content: $dlt-c8y-icon-ratio;
  }
}
.dlt-c8y-icon-circle {
  &:before {
    content: $dlt-c8y-icon-circle;
  }
}
.dlt-c8y-icon-contrast1 {
  &:before {
    content: $dlt-c8y-icon-contrast1;
  }
}
.dlt-c8y-icon-connection-status-on {
  &:before {
    content: $dlt-c8y-icon-connection-status-on;
  }
}
.dlt-c8y-icon-ok {
  &:before {
    content: $dlt-c8y-icon-ok;
  }
}
.dlt-c8y-icon-check-circle {
  &:before {
    content: $dlt-c8y-icon-check-circle;
  }
}
.dlt-c8y-icon-check-circle-o {
  &:before {
    content: $dlt-c8y-icon-check-circle-o;
  }
}
.dlt-c8y-icon-success {
  &:before {
    content: $dlt-c8y-icon-success;
  }
}
.dlt-c8y-icon-cross-circle {
  &:before {
    content: $dlt-c8y-icon-cross-circle;
  }
}
.dlt-c8y-icon-times-circle {
  &:before {
    content: $dlt-c8y-icon-times-circle;
  }
}
.dlt-c8y-icon-times-circle-o {
  &:before {
    content: $dlt-c8y-icon-times-circle-o;
  }
}
.dlt-c8y-icon-add {
  &:before {
    content: $dlt-c8y-icon-add;
  }
}
.dlt-c8y-icon-plus-circle {
  &:before {
    content: $dlt-c8y-icon-plus-circle;
  }
}
.dlt-c8y-icon-minus-circle {
  &:before {
    content: $dlt-c8y-icon-minus-circle;
  }
}
.dlt-c8y-icon-minus-sign {
  &:before {
    content: $dlt-c8y-icon-minus-sign;
  }
}
.dlt-c8y-icon-radio-button-unchecked {
  &:before {
    content: $dlt-c8y-icon-radio-button-unchecked;
  }
}
.dlt-c8y-icon-circle-o {
  &:before {
    content: $dlt-c8y-icon-circle-o;
  }
}
.dlt-c8y-icon-circle-thin {
  &:before {
    content: $dlt-c8y-icon-circle-thin;
  }
}
.dlt-c8y-icon-add-circle-outline {
  &:before {
    content: $dlt-c8y-icon-add-circle-outline;
  }
}
.dlt-c8y-icon-plus-circle-o {
  &:before {
    content: $dlt-c8y-icon-plus-circle-o;
  }
}
.dlt-c8y-icon-local-fire-department {
  &:before {
    content: $dlt-c8y-icon-local-fire-department;
  }
}
.dlt-c8y-icon-fire {
  &:before {
    content: $dlt-c8y-icon-fire;
  }
}
.dlt-c8y-icon-outlined-flag {
  &:before {
    content: $dlt-c8y-icon-outlined-flag;
  }
}
.dlt-c8y-icon-flag-o {
  &:before {
    content: $dlt-c8y-icon-flag-o;
  }
}
.dlt-c8y-icon-flag {
  &:before {
    content: $dlt-c8y-icon-flag;
  }
}
.dlt-c8y-icon-assistant-photo {
  &:before {
    content: $dlt-c8y-icon-assistant-photo;
  }
}
.dlt-c8y-icon-flag-checkered {
  &:before {
    content: $dlt-c8y-icon-flag-checkered;
  }
}
.dlt-c8y-icon-flash-on {
  &:before {
    content: $dlt-c8y-icon-flash-on;
  }
}
.dlt-c8y-icon-bolt {
  &:before {
    content: $dlt-c8y-icon-bolt;
  }
}
.dlt-c8y-icon-quick-mode-on {
  &:before {
    content: $dlt-c8y-icon-quick-mode-on;
  }
}
.dlt-c8y-icon-flash {
  &:before {
    content: $dlt-c8y-icon-flash;
  }
}
.dlt-c8y-icon-quick-mode-off {
  &:before {
    content: $dlt-c8y-icon-quick-mode-off;
  }
}
.dlt-c8y-icon-videogame-asset {
  &:before {
    content: $dlt-c8y-icon-videogame-asset;
  }
}
.dlt-c8y-icon-gamepad {
  &:before {
    content: $dlt-c8y-icon-gamepad;
  }
}
.dlt-c8y-icon-portraits {
  &:before {
    content: $dlt-c8y-icon-portraits;
  }
}
.dlt-c8y-icon-extension {
  &:before {
    content: $dlt-c8y-icon-extension;
  }
}
.dlt-c8y-icon-puzzle {
  &:before {
    content: $dlt-c8y-icon-puzzle;
  }
}
.dlt-c8y-icon-puzzle-piece {
  &:before {
    content: $dlt-c8y-icon-puzzle-piece;
  }
}
.dlt-c8y-icon-star-outline {
  &:before {
    content: $dlt-c8y-icon-star-outline;
  }
}
.dlt-c8y-icon-star-o {
  &:before {
    content: $dlt-c8y-icon-star-o;
  }
}
.dlt-c8y-icon-unstar {
  &:before {
    content: $dlt-c8y-icon-unstar;
  }
}
.dlt-c8y-icon-star-half {
  &:before {
    content: $dlt-c8y-icon-star-half;
  }
}
.dlt-c8y-icon-star-half-empty {
  &:before {
    content: $dlt-c8y-icon-star-half-empty;
  }
}
.dlt-c8y-icon-star-half-full {
  &:before {
    content: $dlt-c8y-icon-star-half-full;
  }
}
.dlt-c8y-icon-star-half-o {
  &:before {
    content: $dlt-c8y-icon-star-half-o;
  }
}
.dlt-c8y-icon-star {
  &:before {
    content: $dlt-c8y-icon-star;
  }
}
.dlt-c8y-icon-grade {
  &:before {
    content: $dlt-c8y-icon-grade;
  }
}
.dlt-c8y-icon-home-page {
  &:before {
    content: $dlt-c8y-icon-home-page;
  }
}
.dlt-c8y-icon-home {
  &:before {
    content: $dlt-c8y-icon-home;
  }
}
.dlt-c8y-icon-attach {
  &:before {
    content: $dlt-c8y-icon-attach;
  }
}
.dlt-c8y-icon-paper-clip {
  &:before {
    content: $dlt-c8y-icon-paper-clip;
  }
}
.dlt-c8y-icon-paperclip {
  &:before {
    content: $dlt-c8y-icon-paperclip;
  }
}
.dlt-c8y-icon-link1 {
  &:before {
    content: $dlt-c8y-icon-link1;
  }
}
.dlt-c8y-icon-unlink1 {
  &:before {
    content: $dlt-c8y-icon-unlink1;
  }
}
.dlt-c8y-icon-online1 {
  &:before {
    content: $dlt-c8y-icon-online1;
  }
}
.dlt-c8y-icon-offline {
  &:before {
    content: $dlt-c8y-icon-offline;
  }
}
.dlt-c8y-icon-notification {
  &:before {
    content: $dlt-c8y-icon-notification;
  }
}
.dlt-c8y-icon-notifications {
  &:before {
    content: $dlt-c8y-icon-notifications;
  }
}
.dlt-c8y-icon-bell {
  &:before {
    content: $dlt-c8y-icon-bell;
  }
}
.dlt-c8y-icon-bell-o {
  &:before {
    content: $dlt-c8y-icon-bell-o;
  }
}
.dlt-c8y-icon-alarm {
  &:before {
    content: $dlt-c8y-icon-alarm;
  }
}
.dlt-c8y-icon-no-reminders {
  &:before {
    content: $dlt-c8y-icon-no-reminders;
  }
}
.dlt-c8y-icon-bell-slash {
  &:before {
    content: $dlt-c8y-icon-bell-slash;
  }
}
.dlt-c8y-icon-bell-slash-o {
  &:before {
    content: $dlt-c8y-icon-bell-slash-o;
  }
}
.dlt-c8y-icon-medium-priority {
  &:before {
    content: $dlt-c8y-icon-medium-priority;
  }
}
.dlt-c8y-icon-sliders1 {
  &:before {
    content: $dlt-c8y-icon-sliders1;
  }
}
.dlt-c8y-icon-sliders2 {
  &:before {
    content: $dlt-c8y-icon-sliders2;
  }
}
.dlt-c8y-icon-adjust1 {
  &:before {
    content: $dlt-c8y-icon-adjust1;
  }
}
.dlt-c8y-icon-tune {
  &:before {
    content: $dlt-c8y-icon-tune;
  }
}
.dlt-c8y-icon-customize {
  &:before {
    content: $dlt-c8y-icon-customize;
  }
}
.dlt-c8y-icon-button {
  &:before {
    content: $dlt-c8y-icon-button;
  }
}
.dlt-c8y-icon-breadcrumb {
  &:before {
    content: $dlt-c8y-icon-breadcrumb;
  }
}
.dlt-c8y-icon-window-minimize {
  &:before {
    content: $dlt-c8y-icon-window-minimize;
  }
}
.dlt-c8y-icon-versions {
  &:before {
    content: $dlt-c8y-icon-versions;
  }
}
.dlt-c8y-icon-pages {
  &:before {
    content: $dlt-c8y-icon-pages;
  }
}
.dlt-c8y-icon-change-theme {
  &:before {
    content: $dlt-c8y-icon-change-theme;
  }
}
.dlt-c8y-icon-window-restore {
  &:before {
    content: $dlt-c8y-icon-window-restore;
  }
}
.dlt-c8y-icon-landing-page {
  &:before {
    content: $dlt-c8y-icon-landing-page;
  }
}
.dlt-c8y-icon-continuous-mode {
  &:before {
    content: $dlt-c8y-icon-continuous-mode;
  }
}
.dlt-c8y-icon-ruler-pencil {
  &:before {
    content: $dlt-c8y-icon-ruler-pencil;
  }
}
.dlt-c8y-icon-header1 {
  &:before {
    content: $dlt-c8y-icon-header1;
  }
}
.dlt-c8y-icon-body {
  &:before {
    content: $dlt-c8y-icon-body;
  }
}
.dlt-c8y-icon-footer {
  &:before {
    content: $dlt-c8y-icon-footer;
  }
}
.dlt-c8y-icon-logs {
  &:before {
    content: $dlt-c8y-icon-logs;
  }
}
.dlt-c8y-icon-overview {
  &:before {
    content: $dlt-c8y-icon-overview;
  }
}
.dlt-c8y-icon-tab {
  &:before {
    content: $dlt-c8y-icon-tab;
  }
}
.dlt-c8y-icon-cursor-in-window {
  &:before {
    content: $dlt-c8y-icon-cursor-in-window;
  }
}
.dlt-c8y-icon-advertisement-page {
  &:before {
    content: $dlt-c8y-icon-advertisement-page;
  }
}
.dlt-c8y-icon-share-square-o {
  &:before {
    content: $dlt-c8y-icon-share-square-o;
  }
}
.dlt-c8y-icon-share-square {
  &:before {
    content: $dlt-c8y-icon-share-square;
  }
}
.dlt-c8y-icon-share1 {
  &:before {
    content: $dlt-c8y-icon-share1;
  }
}
.dlt-c8y-icon-external-link {
  &:before {
    content: $dlt-c8y-icon-external-link;
  }
}
.dlt-c8y-icon-level-up {
  &:before {
    content: $dlt-c8y-icon-level-up;
  }
}
.dlt-c8y-icon-trigger {
  &:before {
    content: $dlt-c8y-icon-trigger;
  }
}
.dlt-c8y-icon-rules1 {
  &:before {
    content: $dlt-c8y-icon-rules1;
  }
}
.dlt-c8y-icon-email {
  &:before {
    content: $dlt-c8y-icon-email;
  }
}
.dlt-c8y-icon-form {
  &:before {
    content: $dlt-c8y-icon-form;
  }
}
.dlt-c8y-icon-language1 {
  &:before {
    content: $dlt-c8y-icon-language1;
  }
}
.dlt-c8y-icon-loading-bar {
  &:before {
    content: $dlt-c8y-icon-loading-bar;
  }
}
.dlt-c8y-icon-output {
  &:before {
    content: $dlt-c8y-icon-output;
  }
}
.dlt-c8y-icon-save1 {
  &:before {
    content: $dlt-c8y-icon-save1;
  }
}
.dlt-c8y-icon-floppy-o {
  &:before {
    content: $dlt-c8y-icon-floppy-o;
  }
}
.dlt-c8y-icon-single-choice {
  &:before {
    content: $dlt-c8y-icon-single-choice;
  }
}
.dlt-c8y-icon-single-page-mode {
  &:before {
    content: $dlt-c8y-icon-single-page-mode;
  }
}
.dlt-c8y-icon-skip {
  &:before {
    content: $dlt-c8y-icon-skip;
  }
}
.dlt-c8y-icon-support {
  &:before {
    content: $dlt-c8y-icon-support;
  }
}
.dlt-c8y-icon-wrench {
  &:before {
    content: $dlt-c8y-icon-wrench;
  }
}
.dlt-c8y-icon-maintenance {
  &:before {
    content: $dlt-c8y-icon-maintenance;
  }
}
.dlt-c8y-icon-utility {
  &:before {
    content: $dlt-c8y-icon-utility;
  }
}
.dlt-c8y-icon-themes {
  &:before {
    content: $dlt-c8y-icon-themes;
  }
}
.dlt-c8y-icon-book1 {
  &:before {
    content: $dlt-c8y-icon-book1;
  }
}
.dlt-c8y-icon-top-menu {
  &:before {
    content: $dlt-c8y-icon-top-menu;
  }
}
.dlt-c8y-icon-user-menu-male {
  &:before {
    content: $dlt-c8y-icon-user-menu-male;
  }
}
.dlt-c8y-icon-hint {
  &:before {
    content: $dlt-c8y-icon-hint;
  }
}
.dlt-c8y-icon-xlarge-icons1 {
  &:before {
    content: $dlt-c8y-icon-xlarge-icons1;
  }
}
.dlt-c8y-icon-circled-menu {
  &:before {
    content: $dlt-c8y-icon-circled-menu;
  }
}
.dlt-c8y-icon-keypad {
  &:before {
    content: $dlt-c8y-icon-keypad;
  }
}
.dlt-c8y-icon-cake {
  &:before {
    content: $dlt-c8y-icon-cake;
  }
}
.dlt-c8y-icon-birthday-cake {
  &:before {
    content: $dlt-c8y-icon-birthday-cake;
  }
}
.dlt-c8y-icon-intelligence1 {
  &:before {
    content: $dlt-c8y-icon-intelligence1;
  }
}
.dlt-c8y-icon-beginner {
  &:before {
    content: $dlt-c8y-icon-beginner;
  }
}
.dlt-c8y-icon-restaurant {
  &:before {
    content: $dlt-c8y-icon-restaurant;
  }
}
.dlt-c8y-icon-cutlery {
  &:before {
    content: $dlt-c8y-icon-cutlery;
  }
}
.dlt-c8y-icon-local-cafe {
  &:before {
    content: $dlt-c8y-icon-local-cafe;
  }
}
.dlt-c8y-icon-free-breakfast {
  &:before {
    content: $dlt-c8y-icon-free-breakfast;
  }
}
.dlt-c8y-icon-coffee {
  &:before {
    content: $dlt-c8y-icon-coffee;
  }
}
.dlt-c8y-icon-wine-bar {
  &:before {
    content: $dlt-c8y-icon-wine-bar;
  }
}
.dlt-c8y-icon-glass {
  &:before {
    content: $dlt-c8y-icon-glass;
  }
}
.dlt-c8y-icon-espresso-cup {
  &:before {
    content: $dlt-c8y-icon-espresso-cup;
  }
}
.dlt-c8y-icon-broom {
  &:before {
    content: $dlt-c8y-icon-broom;
  }
}
.dlt-c8y-icon-buy-upgrade {
  &:before {
    content: $dlt-c8y-icon-buy-upgrade;
  }
}
.dlt-c8y-icon-speedometer {
  &:before {
    content: $dlt-c8y-icon-speedometer;
  }
}
.dlt-c8y-icon-dashboard {
  &:before {
    content: $dlt-c8y-icon-dashboard;
  }
}
.dlt-c8y-icon-tachometer {
  &:before {
    content: $dlt-c8y-icon-tachometer;
  }
}
.dlt-c8y-icon-palette {
  &:before {
    content: $dlt-c8y-icon-palette;
  }
}
.dlt-c8y-icon-car-battery {
  &:before {
    content: $dlt-c8y-icon-car-battery;
  }
}
.dlt-c8y-icon-magnetic {
  &:before {
    content: $dlt-c8y-icon-magnetic;
  }
}
.dlt-c8y-icon-magnet1 {
  &:before {
    content: $dlt-c8y-icon-magnet1;
  }
}
.dlt-c8y-icon-right-navigation-toolbar {
  &:before {
    content: $dlt-c8y-icon-right-navigation-toolbar;
  }
}
.dlt-c8y-icon-tilt {
  &:before {
    content: $dlt-c8y-icon-tilt;
  }
}
.dlt-c8y-icon-clear-symbol {
  &:before {
    content: $dlt-c8y-icon-clear-symbol;
  }
}
.dlt-c8y-icon-settings {
  &:before {
    content: $dlt-c8y-icon-settings;
  }
}
.dlt-c8y-icon-cog {
  &:before {
    content: $dlt-c8y-icon-cog;
  }
}
.dlt-c8y-icon-gear {
  &:before {
    content: $dlt-c8y-icon-gear;
  }
}
.dlt-c8y-icon-advanced-search {
  &:before {
    content: $dlt-c8y-icon-advanced-search;
  }
}
.dlt-c8y-icon-heartbeat {
  &:before {
    content: $dlt-c8y-icon-heartbeat;
  }
}
.dlt-c8y-icon-installing-updates {
  &:before {
    content: $dlt-c8y-icon-installing-updates;
  }
}
.dlt-c8y-icon-heating-automation {
  &:before {
    content: $dlt-c8y-icon-heating-automation;
  }
}
.dlt-c8y-icon-impressum {
  &:before {
    content: $dlt-c8y-icon-impressum;
  }
}
.dlt-c8y-icon-sun {
  &:before {
    content: $dlt-c8y-icon-sun;
  }
}
.dlt-c8y-icon-sunlight {
  &:before {
    content: $dlt-c8y-icon-sunlight;
  }
}
.dlt-c8y-icon-cloud1 {
  &:before {
    content: $dlt-c8y-icon-cloud1;
  }
}
.dlt-c8y-icon-rain {
  &:before {
    content: $dlt-c8y-icon-rain;
  }
}
.dlt-c8y-icon-winter {
  &:before {
    content: $dlt-c8y-icon-winter;
  }
}
.dlt-c8y-icon-storm {
  &:before {
    content: $dlt-c8y-icon-storm;
  }
}
.dlt-c8y-icon-keep-dry {
  &:before {
    content: $dlt-c8y-icon-keep-dry;
  }
}
.dlt-c8y-icon-umbrella {
  &:before {
    content: $dlt-c8y-icon-umbrella;
  }
}
