
$main-navbar-bg:                    var(--c8y-palette-gray-10);
$main-navbar-color:                 var(--c8y-palette-gray-100);

$text-muted:                        var(--c8y-text-muted);
$body-bg-color:                     var(--c8y-body-background-color);
$body-text-color:                   var(--c8y-text-color);

$brand-primary: var(--c8y-brand-primary);
$brand-light: var(--c8y-brand-light);
$brand-dark: var(--c8y-brand-dark);


$grid-border-color:                var(--c8y-palette-gray-70);

/*
* Layout
***********/
$global-padding: 1.5rem;
$section-padding-h: 8rem;
$global-max-width: 1350px;

/*
* Typography
***********/

$font-family:                     var(--c8y-font-family-sans-serif);
$font-family-mono:                var( --c8y-font-family-mono-spaced);

$headings-font-family:            'Euclid Square','Libre Franklin', "Helvetica", Arial, sans-serif;
$headings-font-weight: bold;

$h1-font-size: clamp(2.027rem, 3.8vw, 3rem);
// $h1-font-size: 3.188rem;
$h2-font-size: clamp(1.802rem, 2.8vw, 2.25rem);
$h3-font-size: clamp(1.602rem, 2.5vw, 2.1875rem);
$h4-font-size: clamp(1.424rem, 2.25vw, 1.8125rem);
$h5-font-size: clamp(1.266rem, 2.125vw, 1.5rem);
$h6-font-size: 1rem;
$h1-line-height: 1;
$h2-line-height: 1.1;
$h3-line-height: 1.1;
$h4-line-height: 1.1;
$h5-line-height: 1.1;
$h6-line-height: 1.1;


$font-size-lead: clamp(1.15rem, 2.8vw, 1.3125rem);
$font-size-default: 1rem;
$font-size-small: .8125rem;
$font-size-xs: .75rem;

$font-weight-default: 300;

:root{

  --btn-background-default: transparent;
  --btn-border-color-default: var(--c8y-palette-yellow-60);
  --btn-color-default: var(--c8y-palette-gray-10);

  --btn-background-focus: transparent;
  --btn-border-color-focus: var(--c8y-palette-yellow-60);
  --btn-color-focus: var(--c8y-palette-yellow-60);

  --btn-background-hover: var(--c8y-palette-yellow-70);
  --btn-border-color-hover: var(--c8y-palette-yellow-60);
  --btn-color-hover: var(--c8y-palette-gray-10);

  --btn-background-active: var(--c8y-palette-yellow-60);
  --btn-border-color-active:var(--c8y-palette-yellow-60);
  --btn-color-active:  var(--c8y-palette-gray-10);

  --btn-primary-background-default: var(--c8y-palette-yellow-60);
  --btn-primary-border-color-default: var(--c8y-palette-yellow-60);
  --btn-primary-color-default: var(--c8y-palette-gray-10);

  --btn-primary-background-focus: transparent;
  --btn-primary-border-color-focus: var(--c8y-palette-yellow-60);
  --btn-primary-color-focus: var(--c8y-palette-yellow-60);

  --btn-primary-background-hover: var(--c8y-palette-yellow-70);
  --btn-primary-border-color-hover: var(--c8y-palette-yellow-60);
  --btn-primary-color-hover: var(--c8y-palette-gray-10);

  --btn-primary-background-active: var(--c8y-palette-yellow-60);
  --btn-primary-border-color-active:var(--c8y-palette-yellow-60);
  --btn-primary-color-active:var(--c8y-palette-gray-10);

  --c8y-brand-light: var(--c8y-palette-yellow-65);
  --c8y-brand-primary: var(--c8y-palette-yellow-60);
  --c8y-brand-dark: var(--c8y-palette-gray-20);

    --shadow-color: 0deg 0% 0%;
    --shadow-elevation-low:
      0px 1px 1.5px hsl(var(--shadow-color) / 0),
      0px 3.3px 4.9px hsl(var(--shadow-color) / 0.1);
    --shadow-elevation-medium:
      0px 1px 1.5px hsl(var(--shadow-color) / 0),
      0.1px 16.7px 25.1px hsl(var(--shadow-color) / 0.14);
    --shadow-elevation-high:
      0px 1px 1.5px hsl(var(--shadow-color) / 0),
      0.1px 21.6px 32.4px hsl(var(--shadow-color) / 0.12),
      0.2px 67px 100.5px hsl(var(--shadow-color) / 0.24);
  
}
