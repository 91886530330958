
.success-stories{

  &__list{
    .grid-col{
      padding: 0;
    }
    .c-s{
      width: 100%;
      float: left;
    }
    @include media-breakpoint-up('lg'){
    .c-s{
      width: 33.3333%;
      float: left
    }
  }

    .panel__container{
      padding-top: 0;
    }

    .card.success-story{
      background-color: var(--c8y-palette-high);
      // margin: 1px;
      position: relative;
      z-index: 3;
      display: flex;
      flex-direction: column;
      &, .card__content, 
      .success-story__header__title{
        transition: all 0.3s ease;
      }
    }
    .card__link{
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      transition: all 0.3s ease;
    }
  }
}




.success-story{
  &__header{

    &__title {
      padding: 1rem 2rem;
      border-bottom: 1px solid var(--c8y-palette-gray-80);
      > *{
        font-size: 1.5rem;
        font-weight: bold;
      }
    }

    &__logo{
      width: 80px;
      aspect-ratio: 1;
      margin: auto;
      flex-shrink: 0;
      background-color: white;
      border-radius: 50%;
      border:.5rem  solid white;
      outline: 2px solid var(--c8y-palette-gray-60);
      outline-offset: 0;
      position: relative;
      margin-top: -70px;
      overflow: hidden;
      >img{
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
  &__image{
    aspect-ratio: 16/9;
    >img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

}





