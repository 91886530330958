label, legend.hs-field-desc {
  display: inline-block;
  margin-bottom: calc(var(--c8y-unit-base) * .5);
  max-width: 100%;
  color: var(--c8y-form-label-color);
  text-transform: var(--c8y-form-label-text-transform);
  font-weight: var(--c8y-form-label-font-weight);
  font-size: var(--c8y-form-label-font-size);
  font-family: var(--c8y-form-control-font-family)
}


.form-control {
  display: block;
  padding: var(--c8y-form-control-padding-base-vertical) var(--c8y-form-control-padding-base-horizontal);
  width: 100%;
  height: var(--c8y-form-control-height-base);
  border: 0;
  border-radius: var(--c8y-form-control-border-radius);
  background-color: var(--c8y-form-control-background-default);
  background-image: none;
  box-shadow: inset 0 0 0 var(--c8y-form-control-border-width, 1px) var(--c8y-form-control-border-color-default);
  color: var(--c8y-form-control-color-default);
  font-weight: var(--c8y-form-control-font-weight);
  font-size: var(--c8y-font-size-base);
  font-family: var(--c8y-form-control-font-family);
  line-height: var(--c8y-form-control-line-height);
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.form-control:focus {
  border-color: var(--c8y-form-control-border-color-focus);
  outline: 0;
  box-shadow: inset 0 0 0 2px var(--c8y-form-control-border-color-focus);
  background-color: var(--c8y-form-control-background-focus);
  color: var(--c8y-form-control-color-focus)
}

.form-control::-moz-placeholder {
  color: var(--c8y-form-control-placeholder-color);
  opacity: 1;
  font-style: italic
}

.form-control:-ms-input-placeholder {
  color: var(--c8y-form-control-placeholder-color);
  font-style: italic
}

.form-control::-webkit-input-placeholder {
  color: var(--c8y-form-control-placeholder-color);
  font-style: italic
}

.form-control::-moz-placeholder {
  color: var(--c8y-form-control-placeholder-color);
  opacity: 1;
  font-style: var(--c8y-form-control-placeholder-font-style)
}

.form-control:-ms-input-placeholder {
  color: var(--c8y-form-control-placeholder-color);
  font-style: var(--c8y-form-control-placeholder-font-style)
}

.form-control::-webkit-input-placeholder {
  color: var(--c8y-form-control-placeholder-color);
  font-style: var(--c8y-form-control-placeholder-font-style)
}

.form-control[disabled],
fieldset[disabled] .form-control {
  opacity: var(--c8y-form-control-disabled-opacity)
}

.form-control[readonly],
.form-control[readonly]:focus {
  background-color: var(--c8y-form-control-background-default);
  color: var(--c8y-component-color-text-muted, var(--c8y-root-component-color-text-muted));
  opacity: 1
}

.form-control[disabled],
fieldset[disabled] .form-control {
  cursor: not-allowed
}

textarea .form-control {
  height: auto
}

textarea.form-control {
  min-height: var(--c8y-form-control-height-base);
  height: auto;
  resize: vertical
}

textarea.form-control::-webkit-scrollbar {
  width: 4px;
  height: 4px
}

textarea.form-control::-webkit-scrollbar-track {
  background: transparent
}

textarea.form-control::-webkit-scrollbar-track:not(textarea) {
  background: var(--c8y-component-scrollbar-track, var(--c8y-root-component-scrollbar-track))
}

textarea.form-control::-webkit-scrollbar-thumb {
  width: 4px;
  border-radius: 1px;
  background: var(--c8y-component-scrollbar-thumb-default, var(--c8y-root-component-scrollbar-thumb-default));
  -webkit-transition: all .25s ease;
  transition: all .25s ease
}

textarea.form-control:hover::-webkit-scrollbar-thumb {
  background: var(--c8y-component-scrollbar-thumb-hover, var(--c8y-root-component-scrollbar-thumb-hover))
}

textarea.form-control.no-resize {
  resize: none
}

.hs-input{
  width: 100% !important;
  @extend .form-control;
  

  &[type="checkbox"],
  &[type="radio"] {
    width: auto !important;
  }
}

.form-group,
.hs-form-field {
  display: block;
  &:not(.hs-fieldtype-booleancheckbox){
    margin-bottom: var(--c8y-form-validation-bottom-margin)
  }
}

label.hs-form-radio-display{
  position: relative;
  display: flex;
  align-items: center;
  margin: 0;
  color: var(--c8y-form-control-color-default);
  text-transform: none;
  font-weight: normal;
  font-size: inherit;
  cursor: pointer;
  min-height: 2rem;
  input[type='radio'] {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    margin: 0;
    opacity: 0;
  }
  input + span {
    position: relative;
    z-index: 2;
    display: inline-block;
  }
  input[type='radio'] + span {
    position: relative;
    padding-left: 1.5rem;
    &::before{
      content: '';
      position: absolute;
      display: inline-block;
      flex-shrink: 0;
      width: 1rem;
      height: 1rem;
      background-color:var(--c8y-form-control-background-default);
      box-shadow: inset 0 0 0 1px var(--c8y-form-control-border-color-default);
      transition:
        border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
      left: 0;
    }
    + span {
      line-height: var(--c8y-form-control-height-base)t;
    }
  }

  input[type='radio'] + span::before {
    border-radius: 50%;
  }
  &:hover,
  input:focus {
    + span::before {
      background-color: var(--c8y-form-control-background-focus);
      box-shadow: inset 0 0 0 2px var(--c8y-form-control-border-color-focus);
      color: var(--c8y-form-control-color-focus);
    }
  }


  input[type='radio']:checked + span::after {
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: var(--c8y-form-control-border-color-focus);
    content: '';
    font-size: 10px;
    line-height: inherit;
    transform: translate(4px,4px);
    pointer-events: none;
  }

}


.form-control-feedback-message:before,
.input-group+.help-block:before,
select~.help-block:before,
c8y-field-input~.help-block:before,
textarea~.help-block:before,
input~.help-block:before,
file-picker~.help-block:before,
.form-control~.help-block:before {
  position: absolute;
  top: 2px;
  left: 1px;
  display: inline-block;
  font: 14px/1 dlt-c8y-icons;
  font-size: 16px;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}

.form-control-feedback-message:not(.ng-inactive),
.input-group+.help-block,
select~.help-block,
textarea~.help-block,
file-picker~.help-block,
.form-control~.help-block,
c8y-field-input~.help-block,
input~.help-block {
  position: relative;
  padding-top: calc(var(--c8y-unit-base) * .25);
  padding-left: 20px;
  margin-top: 0
}

.form-group .help-block,
.form-control-feedback-message .form-control-feedback-message label,
.hs-error-msgs label {
  position: relative;
  display: block;
  margin-top: 0;
  min-height: var(--c8y-form-validation-bottom-margin);
  font-size: var(--c8y-font-size-small);
  line-height: 1.5;
  font-weight: 400;
}

.c8y-messages,
.hs-error-msgs {
  display: block;
  min-height: var(--c8y-form-validation-bottom-margin);
  margin: 0 0 calc(var(--c8y-form-validation-bottom-margin) * -1);
  padding: 0;
  list-style: none;

  li {
    display: contents;
  }
}


.form-control-feedback-message:before {
  color: var(--c8y-form-validation-color-error);
}

.form-control-feedback-message:before {
  content: var(--c8y-alert-danger-icon, "\e97b");
}


.form-control-feedback-message.has-error {
  margin-bottom: 8px;
  line-height: 1.2;
}


.c8y-select-wrapper {
  position: relative;

  select {
    padding-right: 24px !important;
    background-image: none;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    &::-ms-expand {
      display: none;
    }
  }

  &:after {
    @extend .dlt-c8y-icon;
    position: absolute;
    top: 50%;
    right: 5px;
    z-index: 2;
    color: var(--c8y-form-control-border-color-focus);
    content: $dlt-c8y-icon-caret-down;
    font-size: 18px;
    transform: translate(0, -50%);
    pointer-events: none;
  }
}

// overrides for HS

body form {
  fieldset {
    max-width: unset !important;

    &[class^='form-columns-'],
    &[class*=' form-columns-'] {
      display: flex;
      flex-direction: column;
      justify-content: stretch;

      >.hs-form-field {
        flex-grow: 1;
      }

      .hs-form-field {
        width: 100% !important;
      }

      @include media-breakpoint-up('lg') {
        flex-direction: row;
        gap: 1.5rem;
      }

      .input {
        margin: 0 !important;
      }
    }
  }
}

.card--form {
  background: var(--c8y-form-control-background-default);
  box-shadow: var(--c8y-elevation-sm);
  border-radius: .25rem;
  padding: 1.5rem;
  @include media-breakpoint-up('lg') {
    padding: 2rem;
  }
}

.hs-form-private {
  display: flex;
  flex-direction: column;
}

.hs-error-msgs {
  @extend .form-control-feedback-message;
}

.hs-form-field {
  position: relative;

  .hs-error-msgs {
    position: absolute;
    width: 100%;

    +.hs-error-msgs {
      position: relative;
      top: 16px;

      &::before {
        content: '';
      }
    }
  }

  &:has(.hs-error-msgs) {
    .hs-input {
      box-shadow: inset 1px 0 0 0 var(--c8y-form-control-border-color-default), inset -1px 0 0 0 var(--c8y-form-control-border-color-default), inset 0 1px 0 0 var(--c8y-form-control-border-color-default), inset 0 -4px 0 var(--c8y-form-validation-color-error) !important;
    }
  }
}

.legal-consent-container .field.hs-form-field {
  margin: 0 !important;
}

.inputs-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.hs-form-booleancheckbox-display, 
.hs-accept_partner_terms .inputs-list + div > label {
  color: var(--c8y-component-color-default);
  font-weight: var(--c8y-form-control-font-weight);
  font-size: var(--c8y-font-size-base);
  line-height: var(--c8y-form-control-line-height);
  margin: 0;
  display: inline-flex;
  cursor: pointer;
  position: relative;

  input {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    margin: 0;
    opacity: 0;

  }

  >span {
    display: inline-block !important;
    margin-left: 8px !important;
    line-height: var(--c8y-form-control-line-height);
  }

  &::before {
    position: relative;
    z-index: 2;
    content: '';
    display: inline-block;
    flex-shrink: 0;
    width: calc(var(--c8y-unit-base) * 2);
    height: calc(var(--c8y-unit-base) * 2);
    border-radius: var(--c8y-form-control-border-radius);
    background-color: var(--c8y-form-control-background-default);
    box-shadow: inset 0 0 0 1px var(--c8y-form-control-border-color-default);
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    margin: 2px 0;
  }

  &:has(input[type="checkbox"]:focus) {
    &::before {
      box-shadow: inset 0 0 0 2px var(--c8y-form-control-border-color-focus);
    }
  }


  &:has(input[type="checkbox"]:checked) {
    &::after {
      position: absolute;
      top: 6.4px;
      left: 3.7px;
      display: block;
      width: 9px;
      height: 5px;
      border-bottom: 2px solid;
      border-left: 2px solid;
      color: var(--c8y-form-control-border-color-focus);
      content: "";
      transform: rotate(-45deg);
      z-index: 3;
    }
  }
}

.hs-richtext {
  font-size: var(--c8y-font-size-base);

  h1,
  h2,
  h3 {
    margin-bottom: 1rem;
  }

  p {
    font-size: var(--c8y-font-size-small);
    margin-bottom: 1em;
  }
  &.hs-main-font-element{
    p{
      font-size: var(--c8y-font-size-base);
    }
  }

  // p:last-child:not(:first-child) {
  //   font-size: var(--c8y-font-size-small);
  // }
}

.hs-button {
  @extend .btn;
  @extend .btn-lg;
}

.hs-button.primary {
  @extend .btn-primary;
}

.hs-recaptcha {
  margin: 0;
}

.grecaptcha-badge {
  visibility: hidden !important;
  height: 0 !important;

}

.hs-form-required {
  display: inline-block;
  margin-left: .25rem;
  color: var(--c8y-palette-status-info);
}

.hs_error_rollup {
  display: none;

  .hs-error-msgs {

    &,
    label {
      font-size: var(--c8y-font-size-base);
    }
  }
}

.hs_submit {
  margin-top: 2rem;
}

.signupform{
  .hs-form-private fieldset:has(small), .hs-form-private fieldset:has(.small) {
    order: 100;
    color: var(--c8y-palette-gray-30);
  }
}


body .hs-fieldtype-intl-phone.hs-input,
body .hs-fieldtype-select .input {
  select {
    float: none;
    width: 100% !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image:
      linear-gradient(45deg, transparent 50%, var(--c8y-brand-primary) 50%),
      linear-gradient(135deg, var(--c8y-brand-primary) 50%, transparent 50%);
    background-position:
      calc(100% - 20px) calc(1em),
      calc(100% - 15px) calc(1em),
      calc(100% - 2.5em) 0.5em;
    background-size:
      5px 5px,
      5px 5px,
      1px 1.5em;
    background-repeat: no-repeat;
    padding-right: 2rem;
  }
}

body .hs-fieldtype-intl-phone.hs-input {
  display: flex;

  select {
    width: 40% !important;

    +.hs-input {
      flex-grow: 1;
      margin-left: -1px;
    }
  }
}


.legal-consent-container {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
}

.signupform .legal-consent-container {

  >div:first-child {
    order: 100;
  }
}

.hs-dependent-field:has(.hs-error-msgs) {
  .hs-form-booleancheckbox-display:before {
    box-shadow: inset 0 0 0 2px var(--c8y-palette-status-danger);
  }
}

.hs-accept_partner_terms:has(.hs-error-msgs) {
  .inputs-list .hs-form-booleancheckbox-display:before {
    box-shadow: inset 0 0 0 2px var(--c8y-palette-status-danger);
  }
  .inputs-list + div > label:before {
    box-shadow: inset 0 0 0 2px var(--c8y-palette-status-danger);
  }
}

.hs-submit input[type="submit"] {
  min-width: 144px;
}

.hs-accept_partner_terms{
  margin-bottom: 1.1rem;
  .hs-form-booleancheckbox-display{
    // display: none;
  }

}
div#clickthrough-host {
  display: none;
}


.hs-form-booleancheckbox{
  // margin-bottom: 1rem;
}

.legal-consent-container .hs-error-msgs label {
  color: inherit !important;
}
