.main-footer{
  text-align: center;
  position: relative;
  z-index: 900;
  color: var(--c8y-palette-gray-70);
  background-color: var(--c8y-palette-gray-10);
  .btn-link{
    color: inherit;
    text-decoration: none;
    &:hover{
      text-decoration: underline!important;
    }
  }
  &__inner{
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr;
    max-width: $global-max-width;
    padding: 2rem $global-padding 1rem;
    margin: 0 auto;
  }
  .brand{
    max-width: 320px;
    margin: 0 auto;
    color: var(--c8y-palette-yellow-60)
  }
  p{
    color: var(--c8y-palette-gray-70);
  }
  a{
    text-decoration: none!important;
  }
  &__footer{
    grid-column: 1 / -1;
    font-size: $font-size-xs;
    display: flex;
    align-items: center;
    flex-direction: column;
    .social-menu{
      order: 1;
    }
    .copyright{
      order: 2;
      color: var(--c8y-palette-gray-40);
      p{
        margin: 0;
      }
    }
  }
}

.footer-nav{
  display: contents;
  list-style: none;
  padding: 0;
}

.sub-footer{
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: $font-size-xs;
  li{
    padding: .25rem 0;
  }
}

.social-menu{
  padding:0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  a{ 
    color: inherit; 
    text-decoration: none!important;
    &:hover{
      color: var(--c8y-palette-yellow-60);
    }
  }
  li {
    padding: .5rem;
    svg{
      height: 24px;
      fill: currentColor;
    }
  }
}

@include media-breakpoint-up('md'){
  .main-footer{
    text-align: left;
    &__inner{
      grid-template-columns: minmax(-webkit-min-content, 2fr) 4fr;
      grid-template-columns: minmax(min-content, 2fr) 4fr;
    }
    &__footer{
      align-items: center;
      flex-direction: row;
      .social-menu{
        order: 2;
      }
      .copyright{
        order: 1;
        flex-grow: 1;
      }
    }
    .brand{
      padding-right: 2rem;
    }
  }
  .footer-nav{
    margin: 0;
    padding-top: .7rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
    
  }
}


@include media-breakpoint-up('lg'){
  .main-footer{
    text-align: left;
    &__inner{
      grid-template-columns: minmax(-webkit-min-content, 320px) 5fr;
      grid-template-columns: minmax(min-content, 320px) 5fr;
    }
  }
  .footer-nav{
    display: grid;
    grid-template-columns: repeat(6, minmax(0, auto));
    a{
      text-decoration: none!important;
    }
  }
  
}
