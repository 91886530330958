
/* Icon Font: c8yicon */

@font-face {
  font-family: "c8yicon-dark";
  src: url("./fonts/c8yicon-dark.eot");
  src: url("./fonts/c8yicon-dark.eot?#iefix") format("embedded-opentype"),
       url("./fonts/c8yicon-dark.woff") format("woff"),
       url("./fonts/c8yicon-dark.ttf") format("truetype"),
       url("./fonts/c8yicon-dark.svg#c8yicon-dark") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "c8yicon-dark";
    src: url("./fonts/c8yicon-dark.svg#c8yicon-dark") format("svg");
  }
}

@font-face {
  font-family: "c8yicon-light";
  src: url("./fonts/c8yicon-light.eot");
  src: url("./fonts/c8yicon-light.eot?#iefix") format("embedded-opentype"),
       url("./fonts/c8yicon-light.woff") format("woff"),
       url("./fonts/c8yicon-light.ttf") format("truetype"),
       url("./fonts/c8yicon-light.svg#c8yicon-light") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "c8yicon-light";
    src: url("./fonts/c8yicon-light.svg#c8yicon-light") format("svg");
  }
}

.c8y-icon {
  position: relative;
  display: inline-block;
  line-height: 1;
  padding: 0;
  &:not([class^="fa-"]):not([class*=" fa-"]):not([class^="dlt-c8y-icon-"]):not([class*=" dlt-c8y-icon-"])::before{
    display: inline-block;
    font-family: "c8yicon-dark";
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    line-height: 1;
    text-decoration: inherit;
    text-rendering: optimizeLegibility;
    text-transform: none;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
  }
  &:not([class^="fa-"]):not([class*=" fa-"]):not([class^="dlt-c8y-icon-"]):not([class*=" dlt-c8y-icon-"])::after{
    display: inline-block;
    font-family: "c8yicon-light";
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    line-height: 1;
    text-decoration: inherit;
    text-rendering: optimizeLegibility;
    text-transform: none;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    position: absolute;
    left: 0;
    right: 0;
    top:0;
  }
}

.c8y-icon-white{
  &:before{
    color: var(--icon-white-color-dark);
  }
  &::after{
    color: var(--icon-white-color-light);
  }
}

.c8y-icon-duocolor{
  &:before{
    color: var(--c8y-brand-dark);
  }
  &.text-danger{
    &:before{
      color: var(--status-danger);
    }
  }
  &.text-warning{
    &:before{
      color: var(--status-warning);
    }
  }
  &.text-info{
    &:before{
      color:var(--status-info);
    }
  }
  &.text-success{
    &:before{
      color: var(--status-success);
    }
  }
  &.text-muted{
    &:before{
      color: var(--text-muted);
    }
  }
  &:after{
    color: var(--c8y-brand-light);
  }
}

.c8y-icon-aab-icon-model:before,
.c8y-icon-aab-icon-model:after { content: "\f100"; }
.c8y-icon-aab-icon-template-model:before,
.c8y-icon-aab-icon-template-model:after { content: "\f101"; }
.c8y-icon-accounts:before,
.c8y-icon-accounts:after { content: "\f102"; }
.c8y-icon-add-user:before,
.c8y-icon-add-user:after { content: "\f103"; }
.c8y-icon-administration:before,
.c8y-icon-administration:after { content: "\f104"; }
.c8y-icon-alarm:before,
.c8y-icon-alarm:after { content: "\f105"; }
.c8y-icon-alert-idle:before,
.c8y-icon-alert-idle:after { content: "\f106"; }
.c8y-icon-alfabet:before,
.c8y-icon-alfabet:after { content: "\f107"; }
.c8y-icon-analytic-model:before,
.c8y-icon-analytic-model:after { content: "\f108"; }
.c8y-icon-analytics-builder:before,
.c8y-icon-analytics-builder:after { content: "\f109"; }
.c8y-icon-apama-epl:before,
.c8y-icon-apama-epl:after { content: "\f10a"; }
.c8y-icon-apama-machine-learning:before,
.c8y-icon-apama-machine-learning:after { content: "\f10b"; }
.c8y-icon-apama-machine-learning-workbench:before,
.c8y-icon-apama-machine-learning-workbench:after { content: "\f10c"; }
.c8y-icon-archive:before,
.c8y-icon-archive:after { content: "\f10d"; }
.c8y-icon-aris:before,
.c8y-icon-aris:after { content: "\f10e"; }
.c8y-icon-atom:before,
.c8y-icon-atom:after { content: "\f10f"; }
.c8y-icon-book:before,
.c8y-icon-book:after { content: "\f110"; }
.c8y-icon-bookmark:before,
.c8y-icon-bookmark:after { content: "\f111"; }
.c8y-icon-bulb:before,
.c8y-icon-bulb:after { content: "\f112"; }
.c8y-icon-business-rules:before,
.c8y-icon-business-rules:after { content: "\f113"; }
.c8y-icon-c8y:before,
.c8y-icon-c8y:after { content: "\f114"; }
.c8y-icon-c8y-data:before,
.c8y-icon-c8y-data:after { content: "\f115"; }
.c8y-icon-c8y-support:before,
.c8y-icon-c8y-support:after { content: "\f116"; }
.c8y-icon-calendar:before,
.c8y-icon-calendar:after { content: "\f117"; }
.c8y-icon-chart:before,
.c8y-icon-chart:after { content: "\f118"; }
.c8y-icon-circle-star:before,
.c8y-icon-circle-star:after { content: "\f119"; }
.c8y-icon-cloud-container:before,
.c8y-icon-cloud-container:after { content: "\f11a"; }
.c8y-icon-cockpit:before,
.c8y-icon-cockpit:after { content: "\f11b"; }
.c8y-icon-component:before,
.c8y-icon-component:after { content: "\f11c"; }
.c8y-icon-connector-in:before,
.c8y-icon-connector-in:after { content: "\f11d"; }
.c8y-icon-connector-out:before,
.c8y-icon-connector-out:after { content: "\f11e"; }
.c8y-icon-css:before,
.c8y-icon-css:after { content: "\f11f"; }
.c8y-icon-cumulocity-iot:before,
.c8y-icon-cumulocity-iot:after { content: "\f120"; }
.c8y-icon-data-broker:before,
.c8y-icon-data-broker:after { content: "\f121"; }
.c8y-icon-data-explorer:before,
.c8y-icon-data-explorer:after { content: "\f122"; }
.c8y-icon-data-hub:before,
.c8y-icon-data-hub:after { content: "\f123"; }
.c8y-icon-data-points:before,
.c8y-icon-data-points:after { content: "\f124"; }
.c8y-icon-design:before,
.c8y-icon-design:after { content: "\f125"; }
.c8y-icon-device:before,
.c8y-icon-device:after { content: "\f126"; }
.c8y-icon-device-connect:before,
.c8y-icon-device-connect:after { content: "\f127"; }
.c8y-icon-device-control:before,
.c8y-icon-device-control:after { content: "\f128"; }
.c8y-icon-device-management:before,
.c8y-icon-device-management:after { content: "\f129"; }
.c8y-icon-device-profile:before,
.c8y-icon-device-profile:after { content: "\f12a"; }
.c8y-icon-device-protocols:before,
.c8y-icon-device-protocols:after { content: "\f12b"; }
.c8y-icon-e2e-monitoring:before,
.c8y-icon-e2e-monitoring:after { content: "\f167"; }
.c8y-icon-energy:before,
.c8y-icon-energy:after { content: "\f12c"; }
.c8y-icon-enterprise:before,
.c8y-icon-enterprise:after { content: "\f12d"; }
.c8y-icon-event-processing:before,
.c8y-icon-event-processing:after { content: "\f12e"; }
.c8y-icon-events:before,
.c8y-icon-events:after { content: "\f12f"; }
.c8y-icon-fieldbus:before,
.c8y-icon-fieldbus:after { content: "\f130"; }
.c8y-icon-find-map:before,
.c8y-icon-find-map:after { content: "\f131"; }
.c8y-icon-firmware:before,
.c8y-icon-firmware:after { content: "\f132"; }
.c8y-icon-grid-off:before,
.c8y-icon-grid-off:after { content: "\f133"; }
.c8y-icon-grid-on:before,
.c8y-icon-grid-on:after { content: "\f134"; }
.c8y-icon-group:before,
.c8y-icon-group:after { content: "\f135"; }
.c8y-icon-group-add:before,
.c8y-icon-group-add:after { content: "\f136"; }
.c8y-icon-group-open:before,
.c8y-icon-group-open:after { content: "\f137"; }
.c8y-icon-group-remote:before,
.c8y-icon-group-remote:after { content: "\f138"; }
.c8y-icon-group-remote-inactive:before,
.c8y-icon-group-remote-inactive:after { content: "\f139"; }
.c8y-icon-group-remote-open:before,
.c8y-icon-group-remote-open:after { content: "\f13a"; }
.c8y-icon-group-smart:before,
.c8y-icon-group-smart:after { content: "\f13b"; }
.c8y-icon-group-smart-open:before,
.c8y-icon-group-smart-open:after { content: "\f13c"; }
.c8y-icon-java:before,
.c8y-icon-java:after { content: "\f13d"; }
.c8y-icon-layers:before,
.c8y-icon-layers:after { content: "\f13e"; }
.c8y-icon-layout:before,
.c8y-icon-layout:after { content: "\f13f"; }
.c8y-icon-location:before,
.c8y-icon-location:after { content: "\f140"; }
.c8y-icon-machine-portal:before,
.c8y-icon-machine-portal:after { content: "\f166"; }
.c8y-icon-management:before,
.c8y-icon-management:after { content: "\f141"; }
.c8y-icon-metering:before,
.c8y-icon-metering:after { content: "\f163"; }
.c8y-icon-mft:before,
.c8y-icon-mft:after { content: "\f168"; }
.c8y-icon-mobile-add:before,
.c8y-icon-mobile-add:after { content: "\f142"; }
.c8y-icon-mobile-config:before,
.c8y-icon-mobile-config:after { content: "\f143"; }
.c8y-icon-modules:before,
.c8y-icon-modules:after { content: "\f144"; }
.c8y-icon-mycloud:before,
.c8y-icon-mycloud:after { content: "\f145"; }
.c8y-icon-notification:before,
.c8y-icon-notification:after { content: "\f146"; }
.c8y-icon-oee:before,
.c8y-icon-oee:after { content: "\f164"; }
.c8y-icon-onnx:before,
.c8y-icon-onnx:after { content: "\f147"; }
.c8y-icon-overviews:before,
.c8y-icon-overviews:after { content: "\f148"; }
.c8y-icon-parameters:before,
.c8y-icon-parameters:after { content: "\f149"; }
.c8y-icon-parameters-on:before,
.c8y-icon-parameters-on:after { content: "\f14a"; }
.c8y-icon-parking:before,
.c8y-icon-parking:after { content: "\f14b"; }
.c8y-icon-report:before,
.c8y-icon-report:after { content: "\f14c"; }
.c8y-icon-reports:before,
.c8y-icon-reports:after { content: "\f14d"; }
.c8y-icon-rocket:before,
.c8y-icon-rocket:after { content: "\f14e"; }
.c8y-icon-saas:before,
.c8y-icon-saas:after { content: "\f14f"; }
.c8y-icon-security:before,
.c8y-icon-security:after { content: "\f150"; }
.c8y-icon-shield:before,
.c8y-icon-shield:after { content: "\f151"; }
.c8y-icon-simulator:before,
.c8y-icon-simulator:after { content: "\f152"; }
.c8y-icon-smart-rest:before,
.c8y-icon-smart-rest:after { content: "\f153"; }
.c8y-icon-smart-rules:before,
.c8y-icon-smart-rules:after { content: "\f154"; }
.c8y-icon-solution-accelerator:before,
.c8y-icon-solution-accelerator:after { content: "\f155"; }
.c8y-icon-streaming-analytics:before,
.c8y-icon-streaming-analytics:after { content: "\f156"; }
.c8y-icon-sub-tenants:before,
.c8y-icon-sub-tenants:after { content: "\f157"; }
.c8y-icon-tenant-policies:before,
.c8y-icon-tenant-policies:after { content: "\f158"; }
.c8y-icon-tools:before,
.c8y-icon-tools:after { content: "\f159"; }
.c8y-icon-tracking:before,
.c8y-icon-tracking:after { content: "\f15a"; }
.c8y-icon-usage-statistics:before,
.c8y-icon-usage-statistics:after { content: "\f15b"; }
.c8y-icon-user:before,
.c8y-icon-user:after { content: "\f15c"; }
.c8y-icon-users:before,
.c8y-icon-users:after { content: "\f15d"; }
.c8y-icon-waste-bin:before,
.c8y-icon-waste-bin:after { content: "\f15e"; }
.c8y-icon-wm-api:before,
.c8y-icon-wm-api:after { content: "\f15f"; }
.c8y-icon-wm-b2b:before,
.c8y-icon-wm-b2b:after { content: "\f160"; }
.c8y-icon-wm-dynamicapps:before,
.c8y-icon-wm-dynamicapps:after { content: "\f161"; }
.c8y-icon-wm-integration:before,
.c8y-icon-wm-integration:after { content: "\f162"; }


