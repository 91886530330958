.card{
  &__link{
    display: flex;
    flex-direction: column;
    &, &:hover{
      text-decoration: none;
      color: inherit;
    }
  }
  &__title{
    padding: 1rem;
    @include media-breakpoint-up('lg'){
        padding: 1rem 2rem 2rem;
    }
    .bits-line{
      aspect-ratio: 6 / 1;
      height: 1rem;
      &::before{
        height: 100%;
        width: 100%;
        mask-size: contain;
        mask-repeat: no-repeat;
      }
    }
  }
  &__content{
    padding: 1rem;
    @include media-breakpoint-up('lg'){
      padding: 1rem 2rem 2rem;
    }
  }
  &__meta{
    display: flex;
    flex-wrap: wrap;
    padding: 1rem;
    border-bottom: 1px solid var(--c8y-palette-gray-80);
    gap: .5rem;
    @include media-breakpoint-up('lg'){
      padding: 1rem 2rem;
    }
  }
  &--clipping{
    .bits-line{
      aspect-ratio: 6 / 1;
      height: 1rem;
      &::before{
        height: 100%;
        width: 100%;
        mask-size: contain;
        mask-repeat: no-repeat;
      }
    }
    &__image{
      object-fit: cover;
      aspect-ratio: 370/132;
      margin-bottom: 1rem;
      width: 100%;
    }
  }
}
