.tag{
  font-size: var(--c8y-btn-font-size-xs);
  line-height: var(--c8y-btn-line-height-small);
  padding: 3px 6px;
  display: flex;
  align-items: center;
  border-radius: .25rem;
  color: var(--c8y-palette-status-info-dark);
  background: var(--c8y-palette-status-info-light);
  border: none;
  cursor: pointer;
  border: 2px solid var(--c8y-palette-status-info-light);
  &--small{
    padding: 0.1em 0.6em;
    border-width: 1px;
  }
  &--danger{
    color: var(--c8y-palette-status-danger-dark);
    background: var(--c8y-palette-status-danger-light);
    border-color: var(--c8y-palette-status-danger);
  }
  &--product{
    color: var(--c8y-palette-gray-30);
    background: var(--c8y-palette-high);
    border-color: transparent;
    text-transform: uppercase;
    letter-spacing: .2em;
    font-size: var(--c8y-btn-font-size-small);
  
  }
  > span{
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-weight: 500;
    flex-shrink: 0;
    text-decoration: none;
    pointer-events: none;
  }
  &:has(.dlt-c8y-icon-times){
    padding-left: .5rem;
    .dlt-c8y-icon-times{
      margin-right: .25rem;
    }
  }
}
button{
  transition: all .15s linear;
  &:hover{
    cursor: pointer;
  }
  &.tag{
    background: transparent;
    &:hover{
      background: var(--c8y-palette-blue-70);
    }
    &.active:not(.tag--product){
      outline: 2px solid var(--c8y-palette-blue-50);
    }
  }
  &.tag--danger{
    &:hover{
      background: var(--c8y-palette-red-70);
    }
  }
  &.tag--product{
    &:hover{
      background: transparent;
      color: var(--c8y-palette-gray-10);
      border-color: var(--c8y-palette-yellow-60);
    }
  }
}
