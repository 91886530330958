
.section-title-separator{
  display: block;
  aspect-ratio: 1 / 0.126261932;
  position: absolute !important;
  left: 0;
  top: 2.3rem;
  z-index: 100;
  transform: translateX(calc(-100% - 24px));
  color:var(--section-separator-color, $brand-primary);
}

h1 + .section-title-separator{
  height: calc( #{$h1-font-size} - 12px);
}

.title-bits + .section-title-separator{
    top: 0.4rem
}

h2 + .section-title-separator{
  height: calc( #{$h2-font-size} - 12px);
  top: 2.5rem;
}

.section-separator{
  position: absolute;
  transform: translateY(50%);
  bottom: 0;
  width: 65%;
  &--right{
    right: 0;
  }
}
.section-separator--top{
  position: absolute;
  transform: translateY(-50%);
  top: 0;
  width: 65%;
  &--right{
    right: 0;
  }
}

.page-header{
  h2{
    font-size: $h3-font-size;
  }
  &__image{
    min-height: 70vh;
    position: relative;
    > img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      object-position: center;
      filter: grayscale(100%);
      mix-blend-mode: multiply;
    } 
  }
}
