$icon-font-family: "dlt-c8y-icons";

$dlt-c8y-icon-collapse-arrow: "\e917";
$dlt-c8y-icon-angle-up: "\e917";
$dlt-c8y-icon-chevron-up: "\e917";
$dlt-c8y-icon-forward: "\e92b";
$dlt-c8y-icon-angle-right: "\e92b";
$dlt-c8y-icon-chevron-right: "\e92b";
$dlt-c8y-icon-chevron-breadcrumb: "\e92b";
$dlt-c8y-icon-expand-arrow: "\e92a";
$dlt-c8y-icon-angle-down: "\e92a";
$dlt-c8y-icon-chevron-down: "\e92a";
$dlt-c8y-icon-caret-back: "\e907";
$dlt-c8y-icon-angle-left: "\e907";
$dlt-c8y-icon-chevron-left: "\e907";
$dlt-c8y-icon-chevron-double-left: "\e97a";
$dlt-c8y-icon-chevron-double-right: "\e97c";
$dlt-c8y-icon-chevron-double-up: "\e97d";
$dlt-c8y-icon-chevron-double-down: "\e98d";
$dlt-c8y-icon-chevron-circle-up: "\e96a";
$dlt-c8y-icon-chevron-circle-right: "\e992";
$dlt-c8y-icon-chevron-circle-down: "\e9a5";
$dlt-c8y-icon-chevron-circle-left: "\e9aa";
$dlt-c8y-icon-arrow-up: "\e95e";
$dlt-c8y-icon-arrow-right: "\e95d";
$dlt-c8y-icon-arrow-down: "\e95f";
$dlt-c8y-icon-arrow-left: "\e95c";
$dlt-c8y-icon-panel-control-up: "\e9b3";
$dlt-c8y-icon-angle-double-up: "\e9b3";
$dlt-c8y-icon-panel-control-right: "\e9b4";
$dlt-c8y-icon-angle-double-right: "\e9b4";
$dlt-c8y-icon-panel-control-down: "\e9b5";
$dlt-c8y-icon-angle-double-down: "\e9b5";
$dlt-c8y-icon-panel-control-left: "\e9b6";
$dlt-c8y-icon-angle-double-left: "\e9b6";
$dlt-c8y-icon-download: "\e937";
$dlt-c8y-icon-upload: "\e940";
$dlt-c8y-icon-internal: "\f6b0";
$dlt-c8y-icon-external: "\f68c";
$dlt-c8y-icon-undo: "\e948";
$dlt-c8y-icon-redo: "\e958";
$dlt-c8y-icon-signout: "\e9b9";
$dlt-c8y-icon-signin: "\e9ba";
$dlt-c8y-icon-login: "\e923";
$dlt-c8y-icon-sign-in: "\e923";
$dlt-c8y-icon-logout: "\e928";
$dlt-c8y-icon-sign-out: "\e928";
$dlt-c8y-icon-arrow-circle-up: "\e962";
$dlt-c8y-icon-arrow-circle-o-up: "\e962";
$dlt-c8y-icon-arrow-circle-right: "\e912";
$dlt-c8y-icon-arrow-circle-o-right: "\e912";
$dlt-c8y-icon-arrow-circle-down: "\e906";
$dlt-c8y-icon-arrow-circle-o-down: "\e906";
$dlt-c8y-icon-arrow-circle-left: "\e92e";
$dlt-c8y-icon-arrow-circle-o-left: "\e92e";
$dlt-c8y-icon-arrow-circle-up-left: "\e913";
$dlt-c8y-icon-arrow-circle-down-left: "\e90e";
$dlt-c8y-icon-arrow-circle-up-right: "\e914";
$dlt-c8y-icon-arrow-circle-bottom-right: "\e910";
$dlt-c8y-icon-arrow-circle-diameter: "\f382";
$dlt-c8y-icon-arrow-circle-divide-horizontal: "\e91b";
$dlt-c8y-icon-arrow-circle-minimize-horizontal: "\e901";
$dlt-c8y-icon-caret-square-o-up: "\ea31";
$dlt-c8y-icon-toggle-up: "\ea31";
$dlt-c8y-icon-caret-square-o-down: "\ea35";
$dlt-c8y-icon-toggle-down: "\ea35";
$dlt-c8y-icon-caret-square-o-left: "\ea39";
$dlt-c8y-icon-toggle-left: "\ea39";
$dlt-c8y-icon-caret-square-o-right: "\ea3a";
$dlt-c8y-icon-toggle-right: "\ea3a";
$dlt-c8y-icon-enter-bottom: "\e9df";
$dlt-c8y-icon-enter-right: "\e9e0";
$dlt-c8y-icon-enter-top: "\e9e2";
$dlt-c8y-icon-enter-left: "\e9ed";
$dlt-c8y-icon-exit-top: "\e9f7";
$dlt-c8y-icon-exit-right: "\e9f8";
$dlt-c8y-icon-exit-bottom: "\e9f9";
$dlt-c8y-icon-exit-left: "\e9fa";
$dlt-c8y-icon-input-output: "\ea0b";
$dlt-c8y-icon-rotate-right: "\ea4c";
$dlt-c8y-icon-rotate: "\ea4c";
$dlt-c8y-icon-repeat: "\ea4c";
$dlt-c8y-icon-reset: "\ea53";
$dlt-c8y-icon-rotate-left: "\ea53";
$dlt-c8y-icon-restore: "\e946";
$dlt-c8y-icon-refresh: "\e947";
$dlt-c8y-icon-process: "\ea36";
$dlt-c8y-icon-recycle: "\ea36";
$dlt-c8y-icon-refresh-exception: "\ea43";
$dlt-c8y-icon-lock-orientation: "\ecc7";
$dlt-c8y-icon-arrow-dotted-up: "\e900";
$dlt-c8y-icon-arrow-dotted-right: "\e904";
$dlt-c8y-icon-arrow-advance: "\e904";
$dlt-c8y-icon-arrow-dotted-down: "\e90b";
$dlt-c8y-icon-arrow-dotted-left: "\e916";
$dlt-c8y-icon-arrows-dotted-left-right: "\e91a";
$dlt-c8y-icon-arrows-dotted-up-down: "\e921";
$dlt-c8y-icon-up-down-arrows: "\e921";
$dlt-c8y-icon-collect: "\e990";
$dlt-c8y-icon-merge-horizontal: "\e935";
$dlt-c8y-icon-split-horizontal: "\e951";
$dlt-c8y-icon-merge-vertical: "\e936";
$dlt-c8y-icon-divider: "\e91c";
$dlt-c8y-icon-split-vertical: "\e91c";
$dlt-c8y-icon-drag: "\e927";
$dlt-c8y-icon-arrows: "\e927";
$dlt-c8y-icon-move-step: "\e927";
$dlt-c8y-icon-resize-expand: "\ecff";
$dlt-c8y-icon-arrows-alt: "\ecff";
$dlt-c8y-icon-expand: "\ecff";
$dlt-c8y-icon-compress: "\e918";
$dlt-c8y-icon-resize-collapse: "\ecfb";
$dlt-c8y-icon-collapse: "\ecfb";
$dlt-c8y-icon-fullscreen: "\e941";
$dlt-c8y-icon-fullscreen-exit: "\e942";
$dlt-c8y-icon-long-arrow-up: "\e934";
$dlt-c8y-icon-long-arrow-right: "\e943";
$dlt-c8y-icon-long-arrow-down: "\e902";
$dlt-c8y-icon-long-arrow-left: "\e933";
$dlt-c8y-icon-arrows-v: "\e93f";
$dlt-c8y-icon-arrows-h: "\e93e";
$dlt-c8y-icon-enlarge: "\e929";
$dlt-c8y-icon-data-transfer: "\eb16";
$dlt-c8y-icon-exchange: "\eb16";
$dlt-c8y-icon-sort-arrow: "\eb17";
$dlt-c8y-icon-arrows-left-right-diagonal: "\e931";
$dlt-c8y-icon-level-up: "\e945";
$dlt-c8y-icon-level-down: "\e956";
$dlt-c8y-icon-u-turn: "\e957";
$dlt-c8y-icon-u-turn-right: "\e959";
$dlt-c8y-icon-u-turn-left: "\e922";
$dlt-c8y-icon-undo1: "\e95a";
$dlt-c8y-icon-redo1: "\e93b";
$dlt-c8y-icon-sort: "\e94c";
$dlt-c8y-icon-unsorted: "\e94c";
$dlt-c8y-icon-sort-down: "\e94d";
$dlt-c8y-icon-sort-desc: "\e94d";
$dlt-c8y-icon-sort-up: "\e950";
$dlt-c8y-icon-sort-asc: "\e950";
$dlt-c8y-icon-caret-up: "\eba8";
$dlt-c8y-icon-caret-right: "\e94f";
$dlt-c8y-icon-caret-down: "\eba9";
$dlt-c8y-icon-caret-left: "\e94e";
$dlt-c8y-icon-squiggly-arrow: "\e952";
$dlt-c8y-icon-curly-arrow: "\e919";
$dlt-c8y-icon-swap: "\e953";
$dlt-c8y-icon-environment: "\eed9";
$dlt-c8y-icon-shuffle: "\f083";
$dlt-c8y-icon-random: "\f083";
$dlt-c8y-icon-swipe-up: "\f1c1";
$dlt-c8y-icon-swipe-right: "\f1bf";
$dlt-c8y-icon-swipe-down: "\f1bd";
$dlt-c8y-icon-swipe-left: "\f1be";
$dlt-c8y-icon-two-finger-swipe-up: "\f1d0";
$dlt-c8y-icon-two-finger-swipe-right: "\f1cf";
$dlt-c8y-icon-two-finger-swipe-down: "\f1cd";
$dlt-c8y-icon-two-finger-swipe-left: "\f1ce";
$dlt-c8y-icon-pinch: "\f1a4";
$dlt-c8y-icon-hospital-o: "\e911";
$dlt-c8y-icon-h-square: "\eb2b";
$dlt-c8y-icon-university: "\f400";
$dlt-c8y-icon-institution: "\f400";
$dlt-c8y-icon-bank: "\f400";
$dlt-c8y-icon-ios-themes: "\f170";
$dlt-c8y-icon-building: "\ee8f";
$dlt-c8y-icon-building-o: "\ee8f";
$dlt-c8y-icon-apartment: "\ee6f";
$dlt-c8y-icon-department1: "\e9b7";
$dlt-c8y-icon-organization: "\ea1d";
$dlt-c8y-icon-office: "\ea15";
$dlt-c8y-icon-building-with-rooftop-terrace: "\ee90";
$dlt-c8y-icon-company: "\e999";
$dlt-c8y-icon-warehouse: "\efc0";
$dlt-c8y-icon-shop: "\ea5c";
$dlt-c8y-icon-work-from-home: "\ea8f";
$dlt-c8y-icon-house: "\ef0a";
$dlt-c8y-icon-dog-house: "\eec7";
$dlt-c8y-icon-home-automation: "\ef07";
$dlt-c8y-icon-garage-door: "\eef6";
$dlt-c8y-icon-depot: "\eebf";
$dlt-c8y-icon-garage: "\eef5";
$dlt-c8y-icon-open-garage-door: "\ef56";
$dlt-c8y-icon-close-garage-door: "\eea3";
$dlt-c8y-icon-barn: "\ee77";
$dlt-c8y-icon-bungalow: "\ee91";
$dlt-c8y-icon-exterior: "\eedd";
$dlt-c8y-icon-equal-housing-opportunity: "\eeda";
$dlt-c8y-icon-heating-room: "\ef06";
$dlt-c8y-icon-mortgage: "\ef47";
$dlt-c8y-icon-home-safety: "\ef09";
$dlt-c8y-icon-enterprise-resource-planning: "\e9c7";
$dlt-c8y-icon-travel-agency: "\ea80";
$dlt-c8y-icon-department-shop: "\e9b8";
$dlt-c8y-icon-book-shelf: "\ee89";
$dlt-c8y-icon-emergency-exit: "\eed5";
$dlt-c8y-icon-stairs: "\ef94";
$dlt-c8y-icon-stairs-down: "\ef95";
$dlt-c8y-icon-stairs-up: "\ef96";
$dlt-c8y-icon-temperature-inside: "\efa1";
$dlt-c8y-icon-treehouse: "\efb1";
$dlt-c8y-icon-tree: "\efb1";
$dlt-c8y-icon-water-heater: "\efc9";
$dlt-c8y-icon-clean: "\eea1";
$dlt-c8y-icon-magic: "\eea1";
$dlt-c8y-icon-storage: "\e9fb";
$dlt-c8y-icon-60-degrees: "\eae8";
$dlt-c8y-icon-120-degrees: "\eadc";
$dlt-c8y-icon-225-degrees: "\eae0";
$dlt-c8y-icon-300-degrees: "\eae2";
$dlt-c8y-icon-360-degrees: "\eae6";
$dlt-c8y-icon-pie-chart: "\eb51";
$dlt-c8y-icon-pie-chart: "\eb51";
$dlt-c8y-icon-bar-chart: "\eaf8";
$dlt-c8y-icon-bar-chart-o: "\eaf8";
$dlt-c8y-icon-data-account: "\eb07";
$dlt-c8y-icon-data-decline: "\eb08";
$dlt-c8y-icon-increase: "\eb36";
$dlt-c8y-icon-neutral-trading: "\eb48";
$dlt-c8y-icon-decrease: "\eb1f";
$dlt-c8y-icon-timeline: "\e924";
$dlt-c8y-icon-increase-profits: "\eb37";
$dlt-c8y-icon-combo-chart: "\eb04";
$dlt-c8y-icon-line-chart: "\eb04";
$dlt-c8y-icon-graph: "\eb04";
$dlt-c8y-icon-area-chart: "\eaf6";
$dlt-c8y-icon-area-chart: "\eaf6";
$dlt-c8y-icon-sorting-slider: "\eb6b";
$dlt-c8y-icon-sliders: "\eb6b";
$dlt-c8y-icon-hierarchy: "\e9e5";
$dlt-c8y-icon-b2b: "\e973";
$dlt-c8y-icon-flow-chart: "\e9d9";
$dlt-c8y-icon-sitemap: "\e9d9";
$dlt-c8y-icon-tree-structure: "\ea81";
$dlt-c8y-icon-flow: "\e9d8";
$dlt-c8y-icon-stacked-organizational-chart: "\ea61";
$dlt-c8y-icon-multicast: "\eb47";
$dlt-c8y-icon-unicast: "\eb73";
$dlt-c8y-icon-broadcasting: "\eafa";
$dlt-c8y-icon-genealogy: "\eb2d";
$dlt-c8y-icon-filter: "\e95b";
$dlt-c8y-icon-conversion: "\eb06";
$dlt-c8y-icon-clear-filters: "\eaff";
$dlt-c8y-icon-descending-sorting: "\eb23";
$dlt-c8y-icon-sort-amount-desc: "\eb23";
$dlt-c8y-icon-ascending-sorting: "\eaf7";
$dlt-c8y-icon-sort-amount-asc: "\eaf7";
$dlt-c8y-icon-alphabetical-sorting: "\eaf4";
$dlt-c8y-icon-sort-alpha-asc: "\eaf4";
$dlt-c8y-icon-alphabetical-sorting-2: "\eaf5";
$dlt-c8y-icon-sort-alpha-desc: "\eaf5";
$dlt-c8y-icon-data-exchange: "\eb09";
$dlt-c8y-icon-filing-cabinet: "\e9fc";
$dlt-c8y-icon-database: "\e9fc";
$dlt-c8y-icon-database-administrator: "\e9fd";
$dlt-c8y-icon-data-export: "\eb0a";
$dlt-c8y-icon-export: "\eb0a";
$dlt-c8y-icon-data-import: "\eb0b";
$dlt-c8y-icon-import: "\eb0b";
$dlt-c8y-icon-grid-off: "\ea06";
$dlt-c8y-icon-gantt-chart: "\eb2c";
$dlt-c8y-icon-data-grid: "\eb10";
$dlt-c8y-icon-grid-view: "\eb30";
$dlt-c8y-icon-grid-on: "\ea07";
$dlt-c8y-icon-row: "\e9fe";
$dlt-c8y-icon-rows: "\e9fe";
$dlt-c8y-icon-column: "\ea01";
$dlt-c8y-icon-grid: "\eb2e";
$dlt-c8y-icon-table: "\eb71";
$dlt-c8y-icon-split-table: "\eb6d";
$dlt-c8y-icon-data-sheet: "\eb15";
$dlt-c8y-icon-blockchain-technology: "\eaf9";
$dlt-c8y-icon-workflow: "\eb78";
$dlt-c8y-icon-parallel-workflow: "\eb4f";
$dlt-c8y-icon-mind-map: "\eb46";
$dlt-c8y-icon-query-inner-join-left: "\eb54";
$dlt-c8y-icon-sankey: "\eb5c";
$dlt-c8y-icon-creating: "\e9a3";
$dlt-c8y-icon-creativity: "\e9a4";
$dlt-c8y-icon-financial-growth-analysis: "\e9d6";
$dlt-c8y-icon-input: "\e925";
$dlt-c8y-icon-omnichannel: "\ea17";
$dlt-c8y-icon-string: "\ea04";
$dlt-c8y-icon-variable: "\ea0e";
$dlt-c8y-icon-variable-on: "\ea11";
$dlt-c8y-icon-thermometer-0: "\eb2f";
$dlt-c8y-icon-thermometer-empty: "\eb2f";
$dlt-c8y-icon-thermometer-3: "\eb31";
$dlt-c8y-icon-thermometer-three-quarters: "\eb31";
$dlt-c8y-icon-thermometer-2: "\eb32";
$dlt-c8y-icon-thermometer-half: "\eb32";
$dlt-c8y-icon-thermometer-1: "\eb33";
$dlt-c8y-icon-thermometer-quarter: "\eb33";
$dlt-c8y-icon-thermometer: "\eb34";
$dlt-c8y-icon-thermometer-4: "\eb34";
$dlt-c8y-icon-thermometer-full: "\eb34";
$dlt-c8y-icon-sensor: "\ea14";
$dlt-c8y-icon-package: "\ea16";
$dlt-c8y-icon-packages: "\ea18";
$dlt-c8y-icon-blockly: "\ea22";
$dlt-c8y-icon-objects: "\ea1a";
$dlt-c8y-icon-monitoring: "\ea1b";
$dlt-c8y-icon-qr-code: "\ea1f";
$dlt-c8y-icon-online: "\ea24";
$dlt-c8y-icon-temperature: "\ea25";
$dlt-c8y-icon-air-conditioner: "\ee6a";
$dlt-c8y-icon-air-shaft: "\ee6c";
$dlt-c8y-icon-fan: "\eedf";
$dlt-c8y-icon-light: "\ef2a";
$dlt-c8y-icon-light-automation: "\ef2b";
$dlt-c8y-icon-light-off: "\ef39";
$dlt-c8y-icon-reflector-bulb: "\ef6d";
$dlt-c8y-icon-spiral-bulb: "\ef92";
$dlt-c8y-icon-mirrored-reflector-bulb: "\ef44";
$dlt-c8y-icon-light-bulb: "\ef2c";
$dlt-c8y-icon-lightbulb-o: "\ef2c";
$dlt-c8y-icon-light-on: "\ef3a";
$dlt-c8y-icon-plumbing: "\ef62";
$dlt-c8y-icon-wi-fi-router: "\f25a";
$dlt-c8y-icon-gas-bottle: "\eef9";
$dlt-c8y-icon-grater: "\eeff";
$dlt-c8y-icon-air-quality: "\ee6b";
$dlt-c8y-icon-central-heating: "\ee9c";
$dlt-c8y-icon-light-dimmer: "\ef2d";
$dlt-c8y-icon-radio-station: "\ef69";
$dlt-c8y-icon-relay-home-automation: "\ef6f";
$dlt-c8y-icon-remote-control: "\ef70";
$dlt-c8y-icon-tv-off: "\efb6";
$dlt-c8y-icon-wall-socket: "\efbd";
$dlt-c8y-icon-washing-machine: "\efc3";
$dlt-c8y-icon-light-dimming-100-percent: "\ef2e";
$dlt-c8y-icon-light-dimming-10-percent: "\ef2f";
$dlt-c8y-icon-work-light: "\efd5";
$dlt-c8y-icon-plug: "\ef61";
$dlt-c8y-icon-electrical: "\eed4";
$dlt-c8y-icon-water-pipe: "\efca";
$dlt-c8y-icon-thermometer-automation: "\efa2";
$dlt-c8y-icon-fire-extinguisher: "\eee4";
$dlt-c8y-icon-fire-extinguisher: "\eee4";
$dlt-c8y-icon-temperature-sensitive: "\ec31";
$dlt-c8y-icon-calculator: "\f373";
$dlt-c8y-icon-humidity: "\f3a4";
$dlt-c8y-icon-system-information: "\f34a";
$dlt-c8y-icon-system-report: "\f34b";
$dlt-c8y-icon-system-task: "\f34c";
$dlt-c8y-icon-imac-clock: "\f30f";
$dlt-c8y-icon-imac-exit: "\f310";
$dlt-c8y-icon-imac-settings: "\f315";
$dlt-c8y-icon-macbook-settings: "\f320";
$dlt-c8y-icon-connected: "\f209";
$dlt-c8y-icon-disconnected: "\f213";
$dlt-c8y-icon-mobile: "\f17f";
$dlt-c8y-icon-multiple-devices: "\f183";
$dlt-c8y-icon-client-management: "\e98a";
$dlt-c8y-icon-nfc: "\f184";
$dlt-c8y-icon-mobile-email: "\f182";
$dlt-c8y-icon-empty-battery: "\f15e";
$dlt-c8y-icon-battery-empty: "\f15e";
$dlt-c8y-icon-battery-0: "\f15e";
$dlt-c8y-icon-low-battery: "\f179";
$dlt-c8y-icon-battery-quarter: "\f179";
$dlt-c8y-icon-battery-1: "\f179";
$dlt-c8y-icon-battery-level: "\f144";
$dlt-c8y-icon-battery-2: "\f144";
$dlt-c8y-icon-battery-half: "\f144";
$dlt-c8y-icon-charged-battery: "\f152";
$dlt-c8y-icon-battery-3: "\f152";
$dlt-c8y-icon-battery-three-quarters: "\f152";
$dlt-c8y-icon-full-battery: "\f163";
$dlt-c8y-icon-battery-full: "\f163";
$dlt-c8y-icon-battery-4: "\f163";
$dlt-c8y-icon-battery: "\f163";
$dlt-c8y-icon-no-battery: "\f18c";
$dlt-c8y-icon-charging-battery: "\f153";
$dlt-c8y-icon-no-connection: "\f18d";
$dlt-c8y-icon-low-connection: "\f17a";
$dlt-c8y-icon-signal: "\f1af";
$dlt-c8y-icon-factory: "\efe9";
$dlt-c8y-icon-industry: "\efe9";
$dlt-c8y-icon-robot: "\f009";
$dlt-c8y-icon-bot: "\efdc";
$dlt-c8y-icon-fork-lift: "\efed";
$dlt-c8y-icon-gear-complex: "\eff2";
$dlt-c8y-icon-cog-complex: "\eff2";
$dlt-c8y-icon-gears: "\eff3";
$dlt-c8y-icon-cogs: "\eff3";
$dlt-c8y-icon-automation: "\efda";
$dlt-c8y-icon-automatic: "\efd9";
$dlt-c8y-icon-electronics: "\efe6";
$dlt-c8y-icon-gas: "\eff0";
$dlt-c8y-icon-water: "\f011";
$dlt-c8y-icon-tint: "\f011";
$dlt-c8y-icon-greentech: "\eff5";
$dlt-c8y-icon-radio-tower: "\f007";
$dlt-c8y-icon-industrial-scales: "\effa";
$dlt-c8y-icon-balance-scale: "\effa";
$dlt-c8y-icon-iphone: "\f173";
$dlt-c8y-icon-mobile-phone: "\f173";
$dlt-c8y-icon-smartphone: "\f173";
$dlt-c8y-icon-smartphone-approve: "\f1b4";
$dlt-c8y-icon-smartphone-decline: "\f1b5";
$dlt-c8y-icon-phonelink-lock: "\f1a1";
$dlt-c8y-icon-tablet-mac: "\e909";
$dlt-c8y-icon-tablet: "\e909";
$dlt-c8y-icon-devices: "\ea29";
$dlt-c8y-icon-imac: "\eaad";
$dlt-c8y-icon-desktop: "\eaad";
$dlt-c8y-icon-desktop1: "\ea28";
$dlt-c8y-icon-monitor: "\eaba";
$dlt-c8y-icon-tv: "\eaba";
$dlt-c8y-icon-television: "\eaba";
$dlt-c8y-icon-laptop: "\eab0";
$dlt-c8y-icon-checked-laptop: "\ea95";
$dlt-c8y-icon-laptop-alert: "\eab1";
$dlt-c8y-icon-laptop-error: "\eab2";
$dlt-c8y-icon-computer-support: "\f673";
$dlt-c8y-icon-programming: "\eac2";
$dlt-c8y-icon-keyboard: "\eaaf";
$dlt-c8y-icon-keyboard-o: "\eaaf";
$dlt-c8y-icon-video-conference: "\ea84";
$dlt-c8y-icon-remote-desktop: "\eac4";
$dlt-c8y-icon-workspace: "\eada";
$dlt-c8y-icon-workstation: "\eadb";
$dlt-c8y-icon-microchip: "\f17b";
$dlt-c8y-icon-processor: "\eac1";
$dlt-c8y-icon-smartphone-ram: "\f1b6";
$dlt-c8y-icon-bios: "\ea93";
$dlt-c8y-icon-micro-sd: "\eab8";
$dlt-c8y-icon-hdd: "\eaa9";
$dlt-c8y-icon-hdd-o: "\eaa9";
$dlt-c8y-icon-financial-dynamic-presentation: "\e9d5";
$dlt-c8y-icon-presentation: "\ea32";
$dlt-c8y-icon-room-finder: "\ea50";
$dlt-c8y-icon-statistics: "\ea65";
$dlt-c8y-icon-server: "\eac9";
$dlt-c8y-icon-stack: "\eacf";
$dlt-c8y-icon-critical-thinking: "\e9a6";
$dlt-c8y-icon-environment1: "\ea2a";
$dlt-c8y-icon-network-card: "\eabd";
$dlt-c8y-icon-network-cable: "\eabc";
$dlt-c8y-icon-usb-on: "\ead5";
$dlt-c8y-icon-ssd: "\eace";
$dlt-c8y-icon-asterisk-key: "\ea91";
$dlt-c8y-icon-asterisk: "\ea91";
$dlt-c8y-icon-end-button: "\ea99";
$dlt-c8y-icon-hub: "\eaac";
$dlt-c8y-icon-individual-server: "\eaae";
$dlt-c8y-icon-data-center: "\ea98";
$dlt-c8y-icon-root-server: "\eac7";
$dlt-c8y-icon-switch: "\ead0";
$dlt-c8y-icon-nas: "\eabb";
$dlt-c8y-icon-shutdown: "\eacc";
$dlt-c8y-icon-power-off: "\eacc";
$dlt-c8y-icon-hibernate: "\eaaa";
$dlt-c8y-icon-phone-square: "\f19b";
$dlt-c8y-icon-phone: "\f19b";
$dlt-c8y-icon-no-idea: "\ea12";
$dlt-c8y-icon-network: "\ea2c";
$dlt-c8y-icon-mind-map: "\ea2d";
$dlt-c8y-icon-centralized-network: "\ea2e";
$dlt-c8y-icon-customer-insights-manager: "\e9a9";
$dlt-c8y-icon-gift: "\f440";
$dlt-c8y-icon-small-business: "\ea30";
$dlt-c8y-icon-pricing: "\ea3b";
$dlt-c8y-icon-free-trial: "\ea3c";
$dlt-c8y-icon-buy1: "\eb95";
$dlt-c8y-icon-cart-plus: "\eb95";
$dlt-c8y-icon-cart-arrow-down: "\eb95";
$dlt-c8y-icon-return-purchase: "\ec15";
$dlt-c8y-icon-shopping-cart-with-money: "\ec29";
$dlt-c8y-icon-buying: "\eb97";
$dlt-c8y-icon-shopping-cart: "\eb97";
$dlt-c8y-icon-procurement: "\ec09";
$dlt-c8y-icon-delivery-handcart: "\ebb3";
$dlt-c8y-icon-cheap-21: "\f434";
$dlt-c8y-icon-average-2: "\eb84";
$dlt-c8y-icon-stripe1: "\ec2c";
$dlt-c8y-icon-loyalty-card: "\f442";
$dlt-c8y-icon-shopping-check: "\f459";
$dlt-c8y-icon-shopping-bag: "\f45a";
$dlt-c8y-icon-shopping-bag: "\f45a";
$dlt-c8y-icon-atm: "\eb81";
$dlt-c8y-icon-bank-card-dollar: "\eb85";
$dlt-c8y-icon-bank-card-euro: "\eb86";
$dlt-c8y-icon-contactless-payment: "\ede2";
$dlt-c8y-icon-qr-code: "\ec0e";
$dlt-c8y-icon-qrcode: "\ec0e";
$dlt-c8y-icon-data-matrix-code: "\ebaf";
$dlt-c8y-icon-barcode: "\eb88";
$dlt-c8y-icon-no-barcode: "\ebee";
$dlt-c8y-icon-refresh-barcode: "\ec12";
$dlt-c8y-icon-touch-id: "\ec35";
$dlt-c8y-icon-redeem: "\ec11";
$dlt-c8y-icon-card-exchange: "\eb98";
$dlt-c8y-icon-card-security: "\eb99";
$dlt-c8y-icon-cash-register: "\eb9f";
$dlt-c8y-icon-cost: "\ebaa";
$dlt-c8y-icon-create-order: "\ebac";
$dlt-c8y-icon-online-payment1: "\ebf2";
$dlt-c8y-icon-rent1: "\ec14";
$dlt-c8y-icon-package-settings: "\ec02";
$dlt-c8y-icon-product-loading: "\ec0b";
$dlt-c8y-icon-purchase-order: "\ec0d";
$dlt-c8y-icon-receipt1: "\ec0f";
$dlt-c8y-icon-scan-stock: "\ec18";
$dlt-c8y-icon-shipment-logistic: "\ec1c";
$dlt-c8y-icon-shipping-center: "\ec1e";
$dlt-c8y-icon-shipping-centre-loading-belt: "\ec1f";
$dlt-c8y-icon-shopaholic: "\ec23";
$dlt-c8y-icon-basket: "\eb8b";
$dlt-c8y-icon-shopping-basket: "\ec26";
$dlt-c8y-icon-shopping-basket: "\ec26";
$dlt-c8y-icon-add-basket: "\eb79";
$dlt-c8y-icon-paid: "\ec04";
$dlt-c8y-icon-text-width: "\eb5f";
$dlt-c8y-icon-text-height: "\eb60";
$dlt-c8y-icon-paragraph: "\eb61";
$dlt-c8y-icon-pilcrow: "\eb61";
$dlt-c8y-icon-text-input: "\eb2a";
$dlt-c8y-icon-unavailable: "\ea3d";
$dlt-c8y-icon-save: "\e92f";
$dlt-c8y-icon-save-commit: "\e930";
$dlt-c8y-icon-delete: "\e932";
$dlt-c8y-icon-copy: "\e960";
$dlt-c8y-icon-paste: "\e966";
$dlt-c8y-icon-more-menu: "\ea3f";
$dlt-c8y-icon-password-hide: "\ea41";
$dlt-c8y-icon-password-show: "\ea42";
$dlt-c8y-icon-zoom-in: "\ea44";
$dlt-c8y-icon-zoom-out: "\ea45";
$dlt-c8y-icon-buy: "\e926";
$dlt-c8y-icon-list: "\ea46";
$dlt-c8y-icon-list-alt: "\ea46";
$dlt-c8y-icon-list-ul: "\ea46";
$dlt-c8y-icon-format-align-justify: "\e9bc";
$dlt-c8y-icon-align-justify: "\e9bc";
$dlt-c8y-icon-format-align-left: "\e9bd";
$dlt-c8y-icon-align-left: "\e9bd";
$dlt-c8y-icon-format-align-center: "\e9be";
$dlt-c8y-icon-align-center: "\e9be";
$dlt-c8y-icon-format-align-right: "\e9bf";
$dlt-c8y-icon-align-right: "\e9bf";
$dlt-c8y-icon-radio-button-on: "\e9c2";
$dlt-c8y-icon-dot-circle-o: "\e9c2";
$dlt-c8y-icon-colorize: "\e92d";
$dlt-c8y-icon-eyedropper: "\e92d";
$dlt-c8y-icon-brush: "\e938";
$dlt-c8y-icon-paint-brush: "\e938";
$dlt-c8y-icon-file-create: "\ed6f";
$dlt-c8y-icon-edit: "\ed6f";
$dlt-c8y-icon-email-sign: "\f15d";
$dlt-c8y-icon-at: "\f15d";
$dlt-c8y-icon-get-quote: "\f0b9";
$dlt-c8y-icon-quote-right: "\f0b9";
$dlt-c8y-icon-quote-left: "\f0ba";
$dlt-c8y-icon-format-bold: "\e9c5";
$dlt-c8y-icon-bold: "\e9c5";
$dlt-c8y-icon-format-underlined: "\e9c6";
$dlt-c8y-icon-underline: "\e9c6";
$dlt-c8y-icon-strikethrough-s: "\e9c8";
$dlt-c8y-icon-strikethrough: "\e9c8";
$dlt-c8y-icon-title: "\e9c9";
$dlt-c8y-icon-header: "\e9c9";
$dlt-c8y-icon-subscript: "\e9cb";
$dlt-c8y-icon-superscript: "\e9cc";
$dlt-c8y-icon-format-italic: "\e9cd";
$dlt-c8y-icon-italic: "\e9cd";
$dlt-c8y-icon-font-download: "\e9ce";
$dlt-c8y-icon-font: "\e9ce";
$dlt-c8y-icon-questionnaire: "\ea40";
$dlt-c8y-icon-th-list: "\ea40";
$dlt-c8y-icon-tasklist: "\ea72";
$dlt-c8y-icon-communication-sorting: "\e997";
$dlt-c8y-icon-todo-list: "\ea7b";
$dlt-c8y-icon-tasks: "\ea7b";
$dlt-c8y-icon-numbered-list: "\ecd5";
$dlt-c8y-icon-list-ol: "\ecd5";
$dlt-c8y-icon-search-in-list: "\ed14";
$dlt-c8y-icon-table-of-content: "\ed2d";
$dlt-c8y-icon-index: "\ecb7";
$dlt-c8y-icon-tiles: "\ed37";
$dlt-c8y-icon-edit1: "\ece9";
$dlt-c8y-icon-pencil: "\ece9";
$dlt-c8y-icon-pencil-square: "\ece9";
$dlt-c8y-icon-pencil-square-o: "\ece9";
$dlt-c8y-icon-edit11: "\ec89";
$dlt-c8y-icon-no-edit: "\ecd1";
$dlt-c8y-icon-cut: "\ec7a";
$dlt-c8y-icon-scissors: "\ec7a";
$dlt-c8y-icon-coupon: "\ebab";
$dlt-c8y-icon-crop: "\ec78";
$dlt-c8y-icon-erase: "\ea47";
$dlt-c8y-icon-eraser: "\ea47";
$dlt-c8y-icon-add-white-space: "\ec49";
$dlt-c8y-icon-blur: "\ec56";
$dlt-c8y-icon-paint-palette: "\ece1";
$dlt-c8y-icon-contrast: "\ec73";
$dlt-c8y-icon-adjust: "\ec73";
$dlt-c8y-icon-bring-forward: "\ec5f";
$dlt-c8y-icon-snap-background-to-white: "\ed21";
$dlt-c8y-icon-rename: "\ecf8";
$dlt-c8y-icon-text-cursor: "\ed32";
$dlt-c8y-icon-i-cursor: "\ed32";
$dlt-c8y-icon-large-icons: "\ecbf";
$dlt-c8y-icon-comma: "\ec6f";
$dlt-c8y-icon-compare: "\ec70";
$dlt-c8y-icon-content: "\ec72";
$dlt-c8y-icon-document-body: "\ec83";
$dlt-c8y-icon-merge-docunemts: "\eccc";
$dlt-c8y-icon-unit: "\ea83";
$dlt-c8y-icon-group: "\ea48";
$dlt-c8y-icon-group-objects: "\ea48";
$dlt-c8y-icon-object-group: "\ea48";
$dlt-c8y-icon-object-ungroup: "\ea48";
$dlt-c8y-icon-ungroup: "\ea49";
$dlt-c8y-icon-hexagonal-pattern: "\ecb1";
$dlt-c8y-icon-diagonal-lines: "\ec82";
$dlt-c8y-icon-line-width: "\ecc5";
$dlt-c8y-icon-long-words: "\ecc8";
$dlt-c8y-icon-indent: "\e9cf";
$dlt-c8y-icon-indent-left: "\e9cf";
$dlt-c8y-icon-indent-decrease: "\e9d0";
$dlt-c8y-icon-dedent: "\e9d0";
$dlt-c8y-icon-dedent-left: "\e9d0";
$dlt-c8y-icon-outdent: "\ecdc";
$dlt-c8y-icon-indent-right: "\ecdc";
$dlt-c8y-icon-dedent-right: "\eb35";
$dlt-c8y-icon-drag-reorder: "\ec87";
$dlt-c8y-icon-drag-list-up: "\ec86";
$dlt-c8y-icon-pull-down: "\ecf2";
$dlt-c8y-icon-page: "\ece0";
$dlt-c8y-icon-rearrange: "\ecf4";
$dlt-c8y-icon-resize-file: "\ed00";
$dlt-c8y-icon-resize: "\ecfa";
$dlt-c8y-icon-rich-text-converter: "\ed06";
$dlt-c8y-icon-select-all: "\ed15";
$dlt-c8y-icon-select-none: "\ed16";
$dlt-c8y-icon-ruler: "\ed11";
$dlt-c8y-icon-length: "\f3ae";
$dlt-c8y-icon-send-backward: "\ed18";
$dlt-c8y-icon-send-to-back: "\ed19";
$dlt-c8y-icon-replace: "\ea4b";
$dlt-c8y-icon-separated-lists: "\ed1b";
$dlt-c8y-icon-short-words: "\ed1c";
$dlt-c8y-icon-mix-words: "\eccf";
$dlt-c8y-icon-line-style: "\ecc4";
$dlt-c8y-icon-design: "\ec80";
$dlt-c8y-icon-view-stream: "\ed4a";
$dlt-c8y-icon-four-squares: "\eca0";
$dlt-c8y-icon-th-large: "\eca0";
$dlt-c8y-icon-thumbnails: "\ed36";
$dlt-c8y-icon-th: "\ed36";
$dlt-c8y-icon-view-module: "\ed48";
$dlt-c8y-icon-top-view: "\ed38";
$dlt-c8y-icon-orthogonal-view: "\ecdb";
$dlt-c8y-icon-rectangular: "\ecf6";
$dlt-c8y-icon-square: "\ed24";
$dlt-c8y-icon-view-column: "\e9d1";
$dlt-c8y-icon-columns: "\e9d1";
$dlt-c8y-icon-file-archive-o: "\eb38";
$dlt-c8y-icon-file-zip-o: "\eb38";
$dlt-c8y-icon-file-video-o: "\eb39";
$dlt-c8y-icon-file-movie-o: "\eb39";
$dlt-c8y-icon-file-audio-o: "\eb3a";
$dlt-c8y-icon-file-sound-o: "\eb3a";
$dlt-c8y-icon-microsoft-access: "\e939";
$dlt-c8y-icon-microsoft-onenote: "\e93a";
$dlt-c8y-icon-microsoft-outlook: "\e93c";
$dlt-c8y-icon-microsoft-project: "\e93d";
$dlt-c8y-icon-microsoft-publisher: "\e944";
$dlt-c8y-icon-microsoft-sharepoint: "\e949";
$dlt-c8y-icon-microsoft-visio: "\e94a";
$dlt-c8y-icon-word: "\e94b";
$dlt-c8y-icon-file-word-o: "\e94b";
$dlt-c8y-icon-excel: "\e954";
$dlt-c8y-icon-file-excel-o: "\e954";
$dlt-c8y-icon-ppt: "\e955";
$dlt-c8y-icon-file-powerpoint-o: "\e955";
$dlt-c8y-icon-create-archive: "\ed5f";
$dlt-c8y-icon-save-archive: "\ed9f";
$dlt-c8y-icon-delete-archive: "\ed61";
$dlt-c8y-icon-gif: "\ed75";
$dlt-c8y-icon-gis: "\ed76";
$dlt-c8y-icon-gpx: "\ed77";
$dlt-c8y-icon-heic-filetype: "\ed78";
$dlt-c8y-icon-dmg: "\ed63";
$dlt-c8y-icon-jpg: "\ed82";
$dlt-c8y-icon-kmz: "\ed87";
$dlt-c8y-icon-kml: "\ed86";
$dlt-c8y-icon-mp3: "\ed8a";
$dlt-c8y-icon-dng: "\ed64";
$dlt-c8y-icon-nef: "\ed8c";
$dlt-c8y-icon-nmea: "\ed8d";
$dlt-c8y-icon-tar: "\eda4";
$dlt-c8y-icon-ttf: "\eda7";
$dlt-c8y-icon-tif: "\eda5";
$dlt-c8y-icon-obj: "\ed8e";
$dlt-c8y-icon-webp: "\edaa";
$dlt-c8y-icon-ogg: "\ed8f";
$dlt-c8y-icon-woff: "\edad";
$dlt-c8y-icon-xls: "\edaf";
$dlt-c8y-icon-xps: "\edb2";
$dlt-c8y-icon-zip: "\edb3";
$dlt-c8y-icon-wma: "\edac";
$dlt-c8y-icon-osm: "\ed90";
$dlt-c8y-icon-otf: "\ed91";
$dlt-c8y-icon-dwg: "\ed66";
$dlt-c8y-icon-png: "\ed94";
$dlt-c8y-icon-eps: "\ed68";
$dlt-c8y-icon-ps: "\ed96";
$dlt-c8y-icon-rar: "\ed98";
$dlt-c8y-icon-raw: "\ed99";
$dlt-c8y-icon-psd: "\ed97";
$dlt-c8y-icon-exe: "\ed6a";
$dlt-c8y-icon-fbx: "\ed6d";
$dlt-c8y-icon-pdf-2: "\e961";
$dlt-c8y-icon-3fr: "\ed50";
$dlt-c8y-icon-aac: "\ed52";
$dlt-c8y-icon-7zip: "\ed51";
$dlt-c8y-icon-cr2: "\ed5e";
$dlt-c8y-icon-ai: "\ed55";
$dlt-c8y-icon-apk: "\ed56";
$dlt-c8y-icon-wav: "\eda9";
$dlt-c8y-icon-jp2: "\ed81";
$dlt-c8y-icon-java-file: "\ed80";
$dlt-c8y-icon-fff: "\ed6e";
$dlt-c8y-icon-folder-aggregate: "\ea54";
$dlt-c8y-icon-book: "\f36a";
$dlt-c8y-icon-package-delivery-logistics: "\ec01";
$dlt-c8y-icon-cube: "\ec01";
$dlt-c8y-icon-resume: "\ea4d";
$dlt-c8y-icon-insert-drive-file: "\e9d3";
$dlt-c8y-icon-file: "\e9d3";
$dlt-c8y-icon-file-o: "\e9d3";
$dlt-c8y-icon-sheet-of-paper: "\e9d3";
$dlt-c8y-icon-edit-file: "\ea57";
$dlt-c8y-icon-documents: "\ea58";
$dlt-c8y-icon-file-copy: "\e9d2";
$dlt-c8y-icon-files: "\e9d2";
$dlt-c8y-icon-files-o: "\e9d2";
$dlt-c8y-icon-profile: "\ea38";
$dlt-c8y-icon-pdf: "\ed92";
$dlt-c8y-icon-file-pdf-o: "\ed92";
$dlt-c8y-icon-export-pdf: "\ed6b";
$dlt-c8y-icon-file-type-document: "\ed70";
$dlt-c8y-icon-file-text-o: "\ed70";
$dlt-c8y-icon-file-text: "\ed70";
$dlt-c8y-icon-document: "\ed70";
$dlt-c8y-icon-submit-document1: "\eda3";
$dlt-c8y-icon-privacy-policy: "\ea34";
$dlt-c8y-icon-selective-highlighting1: "\eda0";
$dlt-c8y-icon-brief: "\e97e";
$dlt-c8y-icon-document-with-code: "\ea59";
$dlt-c8y-icon-image-file: "\ed7a";
$dlt-c8y-icon-file-image-o: "\ed7a";
$dlt-c8y-icon-file-picture-o: "\ed7a";
$dlt-c8y-icon-file-photo-o: "\ed7a";
$dlt-c8y-icon-image-file-add: "\ed7b";
$dlt-c8y-icon-image-file-checked: "\ed7c";
$dlt-c8y-icon-image-file-remove: "\ed7d";
$dlt-c8y-icon-check-document: "\ed5a";
$dlt-c8y-icon-document-with-a-check-mark: "\ed5a";
$dlt-c8y-icon-delete-file: "\ed62";
$dlt-c8y-icon-file-delete: "\ed72";
$dlt-c8y-icon-file-settings: "\ed74";
$dlt-c8y-icon-file-preview: "\ed73";
$dlt-c8y-icon-open-document: "\ecd9";
$dlt-c8y-icon-submit-document: "\ea5a";
$dlt-c8y-icon-restore-page1: "\ed9d";
$dlt-c8y-icon-document-exchange: "\ed65";
$dlt-c8y-icon-import-file: "\ed7e";
$dlt-c8y-icon-share-document: "\eda1";
$dlt-c8y-icon-idea: "\e9e7";
$dlt-c8y-icon-mark-as-favorite: "\ed88";
$dlt-c8y-icon-email-document: "\ec90";
$dlt-c8y-icon-file-view: "\ed71";
$dlt-c8y-icon-policy-document: "\ed95";
$dlt-c8y-icon-business-report: "\e985";
$dlt-c8y-icon-graph-report: "\e9de";
$dlt-c8y-icon-edit-graph-report: "\e9c4";
$dlt-c8y-icon-download-graph-report: "\e9c1";
$dlt-c8y-icon-agreement: "\e968";
$dlt-c8y-icon-estimates: "\e9ca";
$dlt-c8y-icon-winking-document: "\edab";
$dlt-c8y-icon-insert-page: "\ecb9";
$dlt-c8y-icon-note: "\ea5b";
$dlt-c8y-icon-brochure: "\e980";
$dlt-c8y-icon-read: "\ed9a";
$dlt-c8y-icon-cashbook: "\e988";
$dlt-c8y-icon-price-tag: "\f44f";
$dlt-c8y-icon-tag: "\f44f";
$dlt-c8y-icon-add-tag: "\ea5d";
$dlt-c8y-icon-tag1: "\f45d";
$dlt-c8y-icon-tags: "\f45d";
$dlt-c8y-icon-open-folder: "\ee54";
$dlt-c8y-icon-opened-folder: "\ee54";
$dlt-c8y-icon-folder-open: "\ee54";
$dlt-c8y-icon-folder-open-o: "\ee54";
$dlt-c8y-icon-folder: "\ee4e";
$dlt-c8y-icon-folder-o: "\ee4e";
$dlt-c8y-icon-folder-open1: "\ea5e";
$dlt-c8y-icon-add-folder: "\ee44";
$dlt-c8y-icon-delete-folder: "\ee45";
$dlt-c8y-icon-search-folder: "\ee56";
$dlt-c8y-icon-folder-settings: "\ee4f";
$dlt-c8y-icon-extensions-folder: "\ee4b";
$dlt-c8y-icon-private-folder: "\ee55";
$dlt-c8y-icon-edit-folder: "\ee4a";
$dlt-c8y-icon-e-mail-folder: "\ee49";
$dlt-c8y-icon-documents-folder: "\ee47";
$dlt-c8y-icon-downloads-folder: "\ee48";
$dlt-c8y-icon-favorite-folder: "\ee4c";
$dlt-c8y-icon-user-folder: "\ee58";
$dlt-c8y-icon-download-from-ftp: "\f215";
$dlt-c8y-icon-upload-to-ftp: "\f249";
$dlt-c8y-icon-ftp: "\f218";
$dlt-c8y-icon-big-parcel: "\eb8e";
$dlt-c8y-icon-open-parcel: "\ebfe";
$dlt-c8y-icon-packing: "\ec03";
$dlt-c8y-icon-unpacking: "\ec3c";
$dlt-c8y-icon-cardboard-box: "\eb9c";
$dlt-c8y-icon-archive: "\eb9c";
$dlt-c8y-icon-archive-o: "\eb9c";
$dlt-c8y-icon-file-archive-o1: "\eb9c";
$dlt-c8y-icon-box: "\eb91";
$dlt-c8y-icon-case: "\eb91";
$dlt-c8y-icon-empty-box: "\ebbe";
$dlt-c8y-icon-box-settings: "\eb92";
$dlt-c8y-icon-secured-delivery: "\ec19";
$dlt-c8y-icon-remove-delivery: "\ec13";
$dlt-c8y-icon-out-of-stock: "\ec00";
$dlt-c8y-icon-new-product: "\f44a";
$dlt-c8y-icon-product: "\ec0a";
$dlt-c8y-icon-product-management: "\ec0c";
$dlt-c8y-icon-stacking: "\ec2b";
$dlt-c8y-icon-cv: "\ec7b";
$dlt-c8y-icon-unarchive: "\e9d4";
$dlt-c8y-icon-upload-archive: "\e9d4";
$dlt-c8y-icon-archive-file: "\e9d7";
$dlt-c8y-icon-download-archive: "\e9d7";
$dlt-c8y-icon-cc-stripe: "\eb89";
$dlt-c8y-icon-google-wallet: "\eb8a";
$dlt-c8y-icon-paypal: "\eb8d";
$dlt-c8y-icon-cc-paypal: "\eb8f";
$dlt-c8y-icon-cc-amex: "\eb90";
$dlt-c8y-icon-cc-discover: "\eb93";
$dlt-c8y-icon-cc-visa: "\eb94";
$dlt-c8y-icon-cc-mastercard: "\eb9a";
$dlt-c8y-icon-try: "\eb9b";
$dlt-c8y-icon-turkish-lira: "\eb9b";
$dlt-c8y-icon-rouble: "\eb9d";
$dlt-c8y-icon-rub: "\eb9d";
$dlt-c8y-icon-ruble: "\eb9d";
$dlt-c8y-icon-krw: "\eb9e";
$dlt-c8y-icon-won: "\eb9e";
$dlt-c8y-icon-inr: "\eba0";
$dlt-c8y-icon-rupee: "\eba0";
$dlt-c8y-icon-ils: "\eba1";
$dlt-c8y-icon-shekel: "\eba1";
$dlt-c8y-icon-sheqel: "\eba1";
$dlt-c8y-icon-gbp: "\eba2";
$dlt-c8y-icon-eur: "\eba3";
$dlt-c8y-icon-euro: "\eba3";
$dlt-c8y-icon-cny: "\eba4";
$dlt-c8y-icon-jpy: "\eba4";
$dlt-c8y-icon-rmb: "\eba4";
$dlt-c8y-icon-yen: "\eba4";
$dlt-c8y-icon-bitcoin: "\eba5";
$dlt-c8y-icon-btc: "\eba5";
$dlt-c8y-icon-dollar: "\eba6";
$dlt-c8y-icon-usd: "\eba6";
$dlt-c8y-icon-calculator1: "\ea5f";
$dlt-c8y-icon-accounting: "\ea60";
$dlt-c8y-icon-contract: "\ea62";
$dlt-c8y-icon-sales-performance: "\ea63";
$dlt-c8y-icon-bank-safe: "\e976";
$dlt-c8y-icon-mobile-payment: "\ea05";
$dlt-c8y-icon-money-yours: "\ea08";
$dlt-c8y-icon-online-payment: "\ea19";
$dlt-c8y-icon-us-dollar-circled: "\ee3d";
$dlt-c8y-icon-credit-card: "\ede4";
$dlt-c8y-icon-credit-card-alt: "\ede4";
$dlt-c8y-icon-credit-card: "\ede4";
$dlt-c8y-icon-only-cash: "\ee14";
$dlt-c8y-icon-credit-control: "\ede6";
$dlt-c8y-icon-bank-cards: "\edbd";
$dlt-c8y-icon-check-for-payment: "\eddb";
$dlt-c8y-icon-paper-money: "\ee16";
$dlt-c8y-icon-money: "\ee16";
$dlt-c8y-icon-cash-euro: "\edd6";
$dlt-c8y-icon-stack-of-money: "\ee30";
$dlt-c8y-icon-wallet: "\ee3f";
$dlt-c8y-icon-certificate: "\edd9";
$dlt-c8y-icon-card-security1: "\edd0";
$dlt-c8y-icon-bill: "\edbf";
$dlt-c8y-icon-paid-bill: "\ee15";
$dlt-c8y-icon-token-card-code1: "\ee35";
$dlt-c8y-icon-receipt: "\ee21";
$dlt-c8y-icon-add-receipt: "\edb5";
$dlt-c8y-icon-sign-language: "\eb3b";
$dlt-c8y-icon-signing: "\eb3b";
$dlt-c8y-icon-hand-o-down: "\eb3c";
$dlt-c8y-icon-hand-o-left: "\eb3d";
$dlt-c8y-icon-hand-o-up: "\eb3e";
$dlt-c8y-icon-hand-o-right: "\eb3f";
$dlt-c8y-icon-hand-peace-o: "\eb40";
$dlt-c8y-icon-hand-pointer-o: "\eb41";
$dlt-c8y-icon-hand-spock-o: "\eb42";
$dlt-c8y-icon-hand-lizard-o: "\eb43";
$dlt-c8y-icon-hand-scissors-o: "\eb44";
$dlt-c8y-icon-hand-paper-o: "\eb45";
$dlt-c8y-icon-hand-stop-o: "\eb45";
$dlt-c8y-icon-hand-grab-o: "\eb49";
$dlt-c8y-icon-hand-rock-o: "\eb49";
$dlt-c8y-icon-thumbs-up: "\ee66";
$dlt-c8y-icon-thumbs-up: "\ee66";
$dlt-c8y-icon-thumbs-o-up: "\ee66";
$dlt-c8y-icon-thumbs-down: "\ee65";
$dlt-c8y-icon-thumbs-down: "\ee65";
$dlt-c8y-icon-thumbs-o-down: "\ee65";
$dlt-c8y-icon-do-not-touch: "\ee5a";
$dlt-c8y-icon-hand: "\ee5c";
$dlt-c8y-icon-disclaimer: "\e9bb";
$dlt-c8y-icon-touchpad: "\ead1";
$dlt-c8y-icon-holding-box: "\ebd3";
$dlt-c8y-icon-recieve: "\ec10";
$dlt-c8y-icon-sell: "\ec1a";
$dlt-c8y-icon-get-revenue: "\ebca";
$dlt-c8y-icon-handshake: "\ebd2";
$dlt-c8y-icon-handshake-o: "\ebd2";
$dlt-c8y-icon-partners: "\ebd2";
$dlt-c8y-icon-wearable-technology: "\f013";
$dlt-c8y-icon-america: "\f017";
$dlt-c8y-icon-globe: "\f017";
$dlt-c8y-icon-location: "\f038";
$dlt-c8y-icon-globe1: "\f029";
$dlt-c8y-icon-compass-north: "\ea66";
$dlt-c8y-icon-compass: "\ea66";
$dlt-c8y-icon-marker: "\f042";
$dlt-c8y-icon-map-marker: "\f042";
$dlt-c8y-icon-marker-off: "\f045";
$dlt-c8y-icon-find-clinic: "\f026";
$dlt-c8y-icon-tesla-supercharger-pin: "\f069";
$dlt-c8y-icon-address: "\f016";
$dlt-c8y-icon-cab-stand: "\f019";
$dlt-c8y-icon-map: "\f03d";
$dlt-c8y-icon-map-o: "\f03d";
$dlt-c8y-icon-map-editing: "\f03e";
$dlt-c8y-icon-map-marker: "\f03f";
$dlt-c8y-icon-quest: "\f057";
$dlt-c8y-icon-waypoint-map: "\f06b";
$dlt-c8y-icon-anchor-nodes: "\eb7e";
$dlt-c8y-icon-map-pin: "\f040";
$dlt-c8y-icon-map-pin: "\f040";
$dlt-c8y-icon-signpost-tourist: "\f05e";
$dlt-c8y-icon-map-signs: "\f05e";
$dlt-c8y-icon-gps: "\f02b";
$dlt-c8y-icon-near-me: "\f04a";
$dlt-c8y-icon-location-arrow: "\f04a";
$dlt-c8y-icon-navigation: "\f049";
$dlt-c8y-icon-target1: "\ea67";
$dlt-c8y-icon-hunt: "\f032";
$dlt-c8y-icon-location-off: "\f03a";
$dlt-c8y-icon-location-update: "\f03b";
$dlt-c8y-icon-layers: "\f035";
$dlt-c8y-icon-journey: "\f033";
$dlt-c8y-icon-here: "\f031";
$dlt-c8y-icon-street-view: "\f031";
$dlt-c8y-icon-parking: "\ea68";
$dlt-c8y-icon-reply: "\e964";
$dlt-c8y-icon-mail-reply: "\e964";
$dlt-c8y-icon-forward1: "\eb4a";
$dlt-c8y-icon-mail-forward: "\eb4a";
$dlt-c8y-icon-reply-all: "\e965";
$dlt-c8y-icon-mail-reply-all: "\e965";
$dlt-c8y-icon-reply-all: "\e965";
$dlt-c8y-icon-communication: "\e995";
$dlt-c8y-icon-multichannel: "\ea09";
$dlt-c8y-icon-chat: "\ea6f";
$dlt-c8y-icon-topic: "\f12d";
$dlt-c8y-icon-comment: "\f12d";
$dlt-c8y-icon-comment-o: "\f12d";
$dlt-c8y-icon-typing: "\f130";
$dlt-c8y-icon-quote: "\f0fd";
$dlt-c8y-icon-delete-message: "\f0a7";
$dlt-c8y-icon-poll: "\f0f0";
$dlt-c8y-icon-popular: "\f0f1";
$dlt-c8y-icon-speaker-notes: "\f123";
$dlt-c8y-icon-audio-description: "\f123";
$dlt-c8y-icon-comment1: "\ea70";
$dlt-c8y-icon-comments: "\ea70";
$dlt-c8y-icon-comments-o: "\ea70";
$dlt-c8y-icon-speaker-notes-off: "\f124";
$dlt-c8y-icon-new-topic: "\f0e4";
$dlt-c8y-icon-ask-question: "\f090";
$dlt-c8y-icon-chat-bubble: "\f097";
$dlt-c8y-icon-commenting: "\f097";
$dlt-c8y-icon-commenting-o: "\f097";
$dlt-c8y-icon-mail: "\f0c1";
$dlt-c8y-icon-important-mail: "\f0c1";
$dlt-c8y-icon-envelope: "\f0c1";
$dlt-c8y-icon-envelope-o: "\f0c1";
$dlt-c8y-icon-envelope-open-o: "\f0c1";
$dlt-c8y-icon-envelope-open: "\f0c1";
$dlt-c8y-icon-edit-message: "\f0b1";
$dlt-c8y-icon-received: "\f100";
$dlt-c8y-icon-deleted-message: "\f0a9";
$dlt-c8y-icon-group-message: "\f0bb";
$dlt-c8y-icon-subscription: "\f12b";
$dlt-c8y-icon-unsubscribe: "\f134";
$dlt-c8y-icon-send-email: "\f116";
$dlt-c8y-icon-read-message: "\f1a6";
$dlt-c8y-icon-inbox: "\f0c2";
$dlt-c8y-icon-add-to-inbox: "\f08f";
$dlt-c8y-icon-check-inbox: "\f09b";
$dlt-c8y-icon-remove-from-inbox: "\f104";
$dlt-c8y-icon-outbox: "\f0ed";
$dlt-c8y-icon-post: "\f0f2";
$dlt-c8y-icon-envelope-square: "\f0f2";
$dlt-c8y-icon-sheet: "\f11f";
$dlt-c8y-icon-sticky-note: "\f11f";
$dlt-c8y-icon-sticky-note-o: "\f11f";
$dlt-c8y-icon-note1: "\f0e7";
$dlt-c8y-icon-secure: "\f111";
$dlt-c8y-icon-retweet: "\f10b";
$dlt-c8y-icon-rss: "\f10c";
$dlt-c8y-icon-rss-square: "\f10c";
$dlt-c8y-icon-feed: "\f10c";
$dlt-c8y-icon-cancel-subscription: "\f095";
$dlt-c8y-icon-link: "\f0ca";
$dlt-c8y-icon-chain: "\f0ca";
$dlt-c8y-icon-link-off: "\e9da";
$dlt-c8y-icon-chain-broken: "\e9da";
$dlt-c8y-icon-unlink: "\e9da";
$dlt-c8y-icon-sent: "\f11b";
$dlt-c8y-icon-send: "\f0b3";
$dlt-c8y-icon-email-send: "\f0b3";
$dlt-c8y-icon-send-o: "\f0b3";
$dlt-c8y-icon-paper-plane: "\f0b3";
$dlt-c8y-icon-paper-plane-o: "\f0b3";
$dlt-c8y-icon-hashtag: "\ea73";
$dlt-c8y-icon-podcast: "\eb4b";
$dlt-c8y-icon-headphones: "\eb4c";
$dlt-c8y-icon-volume-control-phone: "\eb4d";
$dlt-c8y-icon-volume-off: "\e915";
$dlt-c8y-icon-volume-mute: "\e915";
$dlt-c8y-icon-volume-zero: "\e915";
$dlt-c8y-icon-volume-low: "\ea74";
$dlt-c8y-icon-volume-down: "\ea74";
$dlt-c8y-icon-volume: "\ea75";
$dlt-c8y-icon-speaker: "\f1ba";
$dlt-c8y-icon-volume-up: "\f1ba";
$dlt-c8y-icon-volume-high: "\f1ba";
$dlt-c8y-icon-sound: "\ef8e";
$dlt-c8y-icon-headset: "\f165";
$dlt-c8y-icon-play-circle: "\ea77";
$dlt-c8y-icon-pause-circle: "\ea79";
$dlt-c8y-icon-stop-circle: "\ea7a";
$dlt-c8y-icon-skip-previous: "\e9ec";
$dlt-c8y-icon-step-backward: "\e9ec";
$dlt-c8y-icon-fast-rewind: "\ea7c";
$dlt-c8y-icon-rewind: "\ea7d";
$dlt-c8y-icon-fast-rewind1: "\ea7d";
$dlt-c8y-icon-fast-backward: "\ea7d";
$dlt-c8y-icon-backward: "\ea7d";
$dlt-c8y-icon-play-arrow: "\e9e8";
$dlt-c8y-icon-play: "\e9e8";
$dlt-c8y-icon-play-circle1: "\e9e8";
$dlt-c8y-icon-play-circle-o: "\e9e8";
$dlt-c8y-icon-pause: "\e9e4";
$dlt-c8y-icon-stop: "\ea7e";
$dlt-c8y-icon-forward11: "\ea7f";
$dlt-c8y-icon-fast-forward: "\ea7f";
$dlt-c8y-icon-forward111: "\ea7f";
$dlt-c8y-icon-fast-forward1: "\ea82";
$dlt-c8y-icon-skip-next: "\e9eb";
$dlt-c8y-icon-step-forward: "\e9eb";
$dlt-c8y-icon-no-image: "\ecd3";
$dlt-c8y-icon-local-movies: "\e9ad";
$dlt-c8y-icon-theaters: "\e9ad";
$dlt-c8y-icon-film: "\e9ad";
$dlt-c8y-icon-mic: "\e9ae";
$dlt-c8y-icon-microphone: "\e9ae";
$dlt-c8y-icon-block-microphone: "\f147";
$dlt-c8y-icon-microphone-slash: "\f147";
$dlt-c8y-icon-music-note: "\e9af";
$dlt-c8y-icon-music: "\e9af";
$dlt-c8y-icon-photo-camera-front: "\e91d";
$dlt-c8y-icon-video-camera: "\e91d";
$dlt-c8y-icon-camera: "\f297";
$dlt-c8y-icon-camera-retro: "\f297";
$dlt-c8y-icon-cam: "\f297";
$dlt-c8y-icon-image: "\f2bd";
$dlt-c8y-icon-picture: "\f2bd";
$dlt-c8y-icon-picture-o: "\f2bd";
$dlt-c8y-icon-photo: "\f2bd";
$dlt-c8y-icon-photo-gallery: "\f2cc";
$dlt-c8y-icon-albums: "\f291";
$dlt-c8y-icon-cubes: "\f291";
$dlt-c8y-icon-slides: "\ea86";
$dlt-c8y-icon-bursts: "\f295";
$dlt-c8y-icon-exposure: "\f2ac";
$dlt-c8y-icon-gallery: "\f2b7";
$dlt-c8y-icon-insert-clip: "\f2bf";
$dlt-c8y-icon-memories: "\f2c2";
$dlt-c8y-icon-overwrite-clip: "\f2ca";
$dlt-c8y-icon-selfies: "\f2d6";
$dlt-c8y-icon-facebook: "\ea87";
$dlt-c8y-icon-instagram: "\ea89";
$dlt-c8y-icon-linkedin: "\ea8a";
$dlt-c8y-icon-pinterest: "\ea8c";
$dlt-c8y-icon-twitter: "\ea8d";
$dlt-c8y-icon-youtube: "\ea8e";
$dlt-c8y-icon-youtube-play: "\ea8e";
$dlt-c8y-icon-google: "\ea90";
$dlt-c8y-icon-github: "\ea92";
$dlt-c8y-icon-cloud: "\ea94";
$dlt-c8y-icon-cloud-search: "\ea96";
$dlt-c8y-icon-search-in-cloud: "\ea96";
$dlt-c8y-icon-cloud-settings: "\ea97";
$dlt-c8y-icon-cloud-checked: "\ea9a";
$dlt-c8y-icon-cloud-restricted: "\ea9b";
$dlt-c8y-icon-cloud-plus: "\ea9c";
$dlt-c8y-icon-cloud-minus: "\ea9d";
$dlt-c8y-icon-cloud-error: "\ea9e";
$dlt-c8y-icon-cloud-unavailable: "\ea9f";
$dlt-c8y-icon-cloud-upload: "\eaa0";
$dlt-c8y-icon-backup: "\eaa0";
$dlt-c8y-icon-cloud-download: "\eaa1";
$dlt-c8y-icon-cloud-backup-restore: "\f1ea";
$dlt-c8y-icon-cloud-remote-working: "\eaa2";
$dlt-c8y-icon-cloud-bar-chart: "\f1eb";
$dlt-c8y-icon-cloud-binary-code: "\f1ec";
$dlt-c8y-icon-cloud-broadcasting: "\f1ed";
$dlt-c8y-icon-secure-cloud: "\f237";
$dlt-c8y-icon-error-cloud: "\f216";
$dlt-c8y-icon-cloud-cross: "\f1f3";
$dlt-c8y-icon-dashed-cloud: "\f20f";
$dlt-c8y-icon-cloud-connection: "\f1f2";
$dlt-c8y-icon-cloud-firewall: "\f1f6";
$dlt-c8y-icon-cloud-link: "\f1f9";
$dlt-c8y-icon-cloud-waiting: "\f205";
$dlt-c8y-icon-public-cloud: "\f22e";
$dlt-c8y-icon-delete-from-cloud: "\f212";
$dlt-c8y-icon-cloud-refresh: "\f1fd";
$dlt-c8y-icon-cloud-right-u-arrow: "\f1fe";
$dlt-c8y-icon-cloud-share-symbol: "\f1ff";
$dlt-c8y-icon-cloud-development: "\f1f5";
$dlt-c8y-icon-cloud-sync: "\f201";
$dlt-c8y-icon-cloud-user: "\f202";
$dlt-c8y-icon-cloud-computing: "\f1f1";
$dlt-c8y-icon-cloud-storage: "\eb02";
$dlt-c8y-icon-cloud-network: "\f1fa";
$dlt-c8y-icon-computer: "\f207";
$dlt-c8y-icon-connected-people: "\f20b";
$dlt-c8y-icon-connection-sync: "\f20c";
$dlt-c8y-icon-network-2: "\f226";
$dlt-c8y-icon-incoming-data: "\f21c";
$dlt-c8y-icon-outgoing-data: "\e903";
$dlt-c8y-icon-remote-desktop1: "\f230";
$dlt-c8y-icon-security-wi-fi: "\f239";
$dlt-c8y-icon-server1: "\f23b";
$dlt-c8y-icon-server-shutdown1: "\f23d";
$dlt-c8y-icon-ftp-server: "\f219";
$dlt-c8y-icon-thin-client: "\f246";
$dlt-c8y-icon-wi-fi: "\f251";
$dlt-c8y-icon-wifi: "\f251";
$dlt-c8y-icon-wireless-network: "\f251";
$dlt-c8y-icon-wi-fi-off: "\f258";
$dlt-c8y-icon-wi-fi-connected: "\f252";
$dlt-c8y-icon-wi-fi-disconnected: "\f254";
$dlt-c8y-icon-wi-fi-lock: "\f255";
$dlt-c8y-icon-scan-wi-fi: "\f235";
$dlt-c8y-icon-bluetooth: "\f1d7";
$dlt-c8y-icon-bluetooth-b: "\f1d7";
$dlt-c8y-icon-bluetooth-symbol: "\f1d7";
$dlt-c8y-icon-decentralized-network: "\f211";
$dlt-c8y-icon-centralized-network: "\f1db";
$dlt-c8y-icon-light-bulb-outline: "\eaa4";
$dlt-c8y-icon-medkit: "\eb4e";
$dlt-c8y-icon-fax: "\eb50";
$dlt-c8y-icon-beer: "\eb52";
$dlt-c8y-icon-department: "\eaa5";
$dlt-c8y-icon-stacked-organizational-chart: "\eaa6";
$dlt-c8y-icon-tty: "\e90c";
$dlt-c8y-icon-target: "\ea6e";
$dlt-c8y-icon-crosshairs: "\ea6e";
$dlt-c8y-icon-accuracy: "\e963";
$dlt-c8y-icon-goal: "\e9dd";
$dlt-c8y-icon-bullseye: "\e9dd";
$dlt-c8y-icon-address-book: "\e967";
$dlt-c8y-icon-address-book-o: "\e967";
$dlt-c8y-icon-contacts-book: "\e967";
$dlt-c8y-icon-attract-customers: "\e971";
$dlt-c8y-icon-magnet: "\e971";
$dlt-c8y-icon-guarantee: "\e9e3";
$dlt-c8y-icon-commercial: "\e993";
$dlt-c8y-icon-bullhorn: "\e993";
$dlt-c8y-icon-announcement: "\e993";
$dlt-c8y-icon-podium: "\ea2f";
$dlt-c8y-icon-time-card: "\ea78";
$dlt-c8y-icon-change: "\e989";
$dlt-c8y-icon-applicant: "\e96c";
$dlt-c8y-icon-download-resume: "\e9c3";
$dlt-c8y-icon-submit-resume: "\ea6a";
$dlt-c8y-icon-briefcase: "\e97f";
$dlt-c8y-icon-suitcase: "\e97f";
$dlt-c8y-icon-new-job: "\ea0d";
$dlt-c8y-icon-job-seeker: "\e9f0";
$dlt-c8y-icon-business-building: "\e982";
$dlt-c8y-icon-dossier: "\e9c0";
$dlt-c8y-icon-alarms: "\e969";
$dlt-c8y-icon-project: "\ea3e";
$dlt-c8y-icon-important-book: "\e9e9";
$dlt-c8y-icon-important-note: "\e9ea";
$dlt-c8y-icon-name-tag: "\ea0a";
$dlt-c8y-icon-id-badge: "\ea0a";
$dlt-c8y-icon-assignment-return: "\e970";
$dlt-c8y-icon-note-taking: "\ea13";
$dlt-c8y-icon-audit: "\e972";
$dlt-c8y-icon-badge: "\e975";
$dlt-c8y-icon-vcard: "\e975";
$dlt-c8y-icon-vcard-o: "\e975";
$dlt-c8y-icon-id-card: "\e975";
$dlt-c8y-icon-id-card-o: "\e975";
$dlt-c8y-icon-address-card-o: "\e975";
$dlt-c8y-icon-address-card: "\e975";
$dlt-c8y-icon-drivers-license: "\e975";
$dlt-c8y-icon-drivers-license-o: "\e975";
$dlt-c8y-icon-new-contact: "\ea0c";
$dlt-c8y-icon-contact-details: "\e99f";
$dlt-c8y-icon-phone-contact: "\ea27";
$dlt-c8y-icon-inspection: "\e9ee";
$dlt-c8y-icon-clipboard-with-a-tick: "\e9ee";
$dlt-c8y-icon-survey: "\ea6d";
$dlt-c8y-icon-no-data-available: "\ea10";
$dlt-c8y-icon-task-planning: "\ea71";
$dlt-c8y-icon-invoice: "\eaa7";
$dlt-c8y-icon-summary-list: "\ea6b";
$dlt-c8y-icon-study: "\ea69";
$dlt-c8y-icon-card: "\eaa8";
$dlt-c8y-icon-job: "\e9ef";
$dlt-c8y-icon-print: "\eaab";
$dlt-c8y-icon-communication-internet: "\e996";
$dlt-c8y-icon-language-skill: "\e9f4";
$dlt-c8y-icon-language: "\e9f4";
$dlt-c8y-icon-close-program: "\e98b";
$dlt-c8y-icon-window-close: "\e98b";
$dlt-c8y-icon-window-close-o: "\e98b";
$dlt-c8y-icon-new-slide: "\ea0f";
$dlt-c8y-icon-web-analytics: "\ea8b";
$dlt-c8y-icon-planner: "\ea2b";
$dlt-c8y-icon-rescheduling-a-task: "\ea4a";
$dlt-c8y-icon-resume-website: "\ea4f";
$dlt-c8y-icon-product-documents: "\ea37";
$dlt-c8y-icon-resume-template: "\ea4e";
$dlt-c8y-icon-rubber-stamp: "\ea51";
$dlt-c8y-icon-stamp: "\ea64";
$dlt-c8y-icon-video-projector: "\ea85";
$dlt-c8y-icon-management1: "\e9ff";
$dlt-c8y-icon-gavel: "\e92c";
$dlt-c8y-icon-legal: "\e92c";
$dlt-c8y-icon-user-md: "\eb53";
$dlt-c8y-icon-wheelchair-alt: "\eb55";
$dlt-c8y-icon-wheelchair: "\eb56";
$dlt-c8y-icon-child: "\eb57";
$dlt-c8y-icon-workspace1: "\eab3";
$dlt-c8y-icon-male-user: "\f276";
$dlt-c8y-icon-user-circle-o: "\f276";
$dlt-c8y-icon-user-circle: "\f276";
$dlt-c8y-icon-user-plus: "\eb58";
$dlt-c8y-icon-add-user: "\eb58";
$dlt-c8y-icon-remove-user: "\eb59";
$dlt-c8y-icon-user-minus: "\eb59";
$dlt-c8y-icon-user-times: "\eb59";
$dlt-c8y-icon-user-account: "\f137";
$dlt-c8y-icon-user: "\f137";
$dlt-c8y-icon-user-o: "\f137";
$dlt-c8y-icon-manager: "\ea00";
$dlt-c8y-icon-businessman: "\ea00";
$dlt-c8y-icon-male: "\ea00";
$dlt-c8y-icon-document-writer: "\f26b";
$dlt-c8y-icon-supplier: "\ea6c";
$dlt-c8y-icon-search-client: "\ea56";
$dlt-c8y-icon-account-enable: "\eab4";
$dlt-c8y-icon-account-disable: "\eab5";
$dlt-c8y-icon-insurance-agent: "\f274";
$dlt-c8y-icon-payroll: "\ea20";
$dlt-c8y-icon-lawyer: "\f275";
$dlt-c8y-icon-complaint: "\e99a";
$dlt-c8y-icon-appointment-scheduling: "\e96d";
$dlt-c8y-icon-caretaker: "\ee97";
$dlt-c8y-icon-guardian: "\f3a0";
$dlt-c8y-icon-batch-assign: "\f367";
$dlt-c8y-icon-people: "\ea21";
$dlt-c8y-icon-users: "\ea21";
$dlt-c8y-icon-group1: "\ea21";
$dlt-c8y-icon-management: "\ea21";
$dlt-c8y-icon-team: "\ea21";
$dlt-c8y-icon-people-working-together: "\eab6";
$dlt-c8y-icon-group-task: "\e9e1";
$dlt-c8y-icon-accessibility: "\e90d";
$dlt-c8y-icon-universal-access: "\e90d";
$dlt-c8y-icon-pregnant-woman: "\e96b";
$dlt-c8y-icon-female: "\e96b";
$dlt-c8y-icon-business-conference-female-speaker: "\e983";
$dlt-c8y-icon-organization-chart-people: "\ea1e";
$dlt-c8y-icon-meeting-room: "\ea03";
$dlt-c8y-icon-permanent-job: "\ea23";
$dlt-c8y-icon-contact-us: "\e9a0";
$dlt-c8y-icon-voice-recognition: "\ea88";
$dlt-c8y-icon-collaboration: "\e98e";
$dlt-c8y-icon-technical-support: "\ea76";
$dlt-c8y-icon-online-support: "\ea1c";
$dlt-c8y-icon-student-male: "\eab7";
$dlt-c8y-icon-devops: "\f26a";
$dlt-c8y-icon-exhibitor: "\f26c";
$dlt-c8y-icon-env-permission: "\eab9";
$dlt-c8y-icon-bathtub: "\e96f";
$dlt-c8y-icon-bath: "\e96f";
$dlt-c8y-icon-s15: "\e96f";
$dlt-c8y-icon-hotel: "\e96e";
$dlt-c8y-icon-local-hotel: "\e96e";
$dlt-c8y-icon-bed: "\e96e";
$dlt-c8y-icon-intelligence: "\eabe";
$dlt-c8y-icon-source-code: "\f342";
$dlt-c8y-icon-code: "\f342";
$dlt-c8y-icon-html-code: "\f342";
$dlt-c8y-icon-code1: "\eabf";
$dlt-c8y-icon-file-code-o: "\eabf";
$dlt-c8y-icon-console: "\eac0";
$dlt-c8y-icon-terminal: "\eac0";
$dlt-c8y-icon-add-property: "\f2f8";
$dlt-c8y-icon-delete-document: "\f309";
$dlt-c8y-icon-urgent-property: "\f356";
$dlt-c8y-icon-remove-property: "\f338";
$dlt-c8y-icon-edit-property: "\f30c";
$dlt-c8y-icon-template: "\f34d";
$dlt-c8y-icon-test: "\f34e";
$dlt-c8y-icon-property-script: "\f335";
$dlt-c8y-icon-show-property: "\f33f";
$dlt-c8y-icon-search-property: "\f33e";
$dlt-c8y-icon-timesheet: "\f351";
$dlt-c8y-icon-important-property: "\f317";
$dlt-c8y-icon-new-property: "\f328";
$dlt-c8y-icon-navigation-toolbar-top: "\f327";
$dlt-c8y-icon-window-maximize: "\f327";
$dlt-c8y-icon-web-application-firewall: "\f358";
$dlt-c8y-icon-website-bug: "\f35a";
$dlt-c8y-icon-bug: "\f35a";
$dlt-c8y-icon-web-design: "\f359";
$dlt-c8y-icon-navigation-toolbar-bottom: "\f325";
$dlt-c8y-icon-more-details: "\f323";
$dlt-c8y-icon-navigation-pane: "\f324";
$dlt-c8y-icon-pin: "\f32c";
$dlt-c8y-icon-thumb-tack: "\f32c";
$dlt-c8y-icon-unpin: "\f355";
$dlt-c8y-icon-commit-git: "\f306";
$dlt-c8y-icon-codefork: "\f304";
$dlt-c8y-icon-code-fork: "\f304";
$dlt-c8y-icon-merge-git: "\f322";
$dlt-c8y-icon-pull-request: "\f336";
$dlt-c8y-icon-compare-git: "\f307";
$dlt-c8y-icon-share: "\eac3";
$dlt-c8y-icon-share-alt: "\eac3";
$dlt-c8y-icon-share-alt-square: "\eac3";
$dlt-c8y-icon-activity-history: "\eaeb";
$dlt-c8y-icon-rules: "\ea52";
$dlt-c8y-icon-create-document: "\e9a2";
$dlt-c8y-icon-product-architecture: "\f331";
$dlt-c8y-icon-registry-editor: "\f337";
$dlt-c8y-icon-rest-api: "\f33b";
$dlt-c8y-icon-true-false: "\f353";
$dlt-c8y-icon-uninstall-programs: "\f354";
$dlt-c8y-icon-sheets: "\ef7f";
$dlt-c8y-icon-stages: "\ef7f";
$dlt-c8y-icon-add-stage: "\eac5";
$dlt-c8y-icon-api: "\f2f9";
$dlt-c8y-icon-blockly-turquoise: "\f2ff";
$dlt-c8y-icon-blockly-blue: "\f2fa";
$dlt-c8y-icon-blockly-pink: "\f2fe";
$dlt-c8y-icon-plugin: "\f32f";
$dlt-c8y-icon-base-64: "\ed57";
$dlt-c8y-icon-xml-transformer: "\edb1";
$dlt-c8y-icon-shield: "\eac6";
$dlt-c8y-icon-protect: "\f419";
$dlt-c8y-icon-warning-shield: "\f425";
$dlt-c8y-icon-free-forever: "\eac8";
$dlt-c8y-icon-identification-documents: "\eaca";
$dlt-c8y-icon-id-verified: "\f412";
$dlt-c8y-icon-fingerprint: "\f410";
$dlt-c8y-icon-pin-code: "\f418";
$dlt-c8y-icon-password: "\f416";
$dlt-c8y-icon-lock: "\eacb";
$dlt-c8y-icon-unlock: "\eacd";
$dlt-c8y-icon-privacy: "\ea33";
$dlt-c8y-icon-lock-outline: "\e91e";
$dlt-c8y-icon-lock1: "\e91e";
$dlt-c8y-icon-lock-open: "\e91f";
$dlt-c8y-icon-unlock1: "\e91f";
$dlt-c8y-icon-unlock-alt: "\e91f";
$dlt-c8y-icon-key: "\f413";
$dlt-c8y-icon-access: "\f409";
$dlt-c8y-icon-lock11: "\f414";
$dlt-c8y-icon-realtime: "\f41a";
$dlt-c8y-icon-smart-home-connection: "\f41d";
$dlt-c8y-icon-smart-home-checked: "\f41c";
$dlt-c8y-icon-smart-home-error: "\f41e";
$dlt-c8y-icon-smart-home-remove: "\f41f";
$dlt-c8y-icon-smart-home-shield: "\f420";
$dlt-c8y-icon-voice-id: "\f423";
$dlt-c8y-icon-web-application-firewall1: "\f426";
$dlt-c8y-icon-copyright: "\ead2";
$dlt-c8y-icon-spy: "\ead3";
$dlt-c8y-icon-user-secret: "\ead3";
$dlt-c8y-icon-bug1: "\ead4";
$dlt-c8y-icon-health-examine: "\ead6";
$dlt-c8y-icon-stethoscope: "\ead6";
$dlt-c8y-icon-space-shuttle: "\eb5a";
$dlt-c8y-icon-ambulance: "\eb5b";
$dlt-c8y-icon-car: "\f53f";
$dlt-c8y-icon-automobile: "\f53f";
$dlt-c8y-icon-local-taxi: "\e920";
$dlt-c8y-icon-taxi: "\e920";
$dlt-c8y-icon-cab: "\e920";
$dlt-c8y-icon-truck: "\f636";
$dlt-c8y-icon-file-delivery: "\ebc5";
$dlt-c8y-icon-in-transit: "\ebd5";
$dlt-c8y-icon-bus: "\f53b";
$dlt-c8y-icon-train: "\e9f1";
$dlt-c8y-icon-directions-subway: "\e9f2";
$dlt-c8y-icon-directions-transit: "\e9f2";
$dlt-c8y-icon-tram: "\e9f2";
$dlt-c8y-icon-delivery-dining: "\e9f5";
$dlt-c8y-icon-scooter: "\e9f5";
$dlt-c8y-icon-pedal-bike: "\e9b1";
$dlt-c8y-icon-bicycle: "\e9b1";
$dlt-c8y-icon-motorcycle: "\e9b2";
$dlt-c8y-icon-airport: "\f521";
$dlt-c8y-icon-plane: "\f521";
$dlt-c8y-icon-fighter-jet: "\f521";
$dlt-c8y-icon-directions-ferry: "\e9f3";
$dlt-c8y-icon-ship: "\e9f3";
$dlt-c8y-icon-deploy: "\eade";
$dlt-c8y-icon-rocket: "\eade";
$dlt-c8y-icon-wrench1: "\eadf";
$dlt-c8y-icon-road: "\f5e5";
$dlt-c8y-icon-flag-outline: "\eae1";
$dlt-c8y-icon-software: "\f340";
$dlt-c8y-icon-future: "\f4b9";
$dlt-c8y-icon-time-machine: "\f4e2";
$dlt-c8y-icon-history: "\f4e2";
$dlt-c8y-icon-delivery-time: "\f4e2";
$dlt-c8y-icon-schedule: "\e9b0";
$dlt-c8y-icon-clock: "\e9b0";
$dlt-c8y-icon-clock-o: "\e9b0";
$dlt-c8y-icon-watches-front-view: "\f508";
$dlt-c8y-icon-clock1: "\ead7";
$dlt-c8y-icon-clock11: "\ead7";
$dlt-c8y-icon-alarm1: "\ead8";
$dlt-c8y-icon-timer: "\ead8";
$dlt-c8y-icon-wall-clock: "\efbc";
$dlt-c8y-icon-alarm-add: "\f47a";
$dlt-c8y-icon-alarm-on: "\f47d";
$dlt-c8y-icon-alarm-off: "\f47c";
$dlt-c8y-icon-calendar: "\f480";
$dlt-c8y-icon-calendar-o: "\f480";
$dlt-c8y-icon-today: "\f504";
$dlt-c8y-icon-calendar-check-o: "\f504";
$dlt-c8y-icon-calendar-1: "\f481";
$dlt-c8y-icon-calendar-31: "\f499";
$dlt-c8y-icon-monday: "\f4c9";
$dlt-c8y-icon-sunday: "\f4df";
$dlt-c8y-icon-january: "\f4bf";
$dlt-c8y-icon-december: "\f4b1";
$dlt-c8y-icon-edit-calendar: "\f4b2";
$dlt-c8y-icon-view-schedule: "\f506";
$dlt-c8y-icon-calendar-add: "\ead9";
$dlt-c8y-icon-calendar-plus: "\ead9";
$dlt-c8y-icon-calendar-plus-o: "\ead9";
$dlt-c8y-icon-calendar-remove: "\eadd";
$dlt-c8y-icon-calendar-minus: "\eadd";
$dlt-c8y-icon-calendar-times: "\eadd";
$dlt-c8y-icon-calendar-minus-o: "\eadd";
$dlt-c8y-icon-calendar-times-o: "\eadd";
$dlt-c8y-icon-important-month: "\f4bd";
$dlt-c8y-icon-schedule1: "\ea55";
$dlt-c8y-icon-sand-watch: "\f4d7";
$dlt-c8y-icon-hourglass-start: "\f4d7";
$dlt-c8y-icon-hourglass-o: "\f4d7";
$dlt-c8y-icon-hourglass-half: "\f4d7";
$dlt-c8y-icon-hourglass-end: "\f4d7";
$dlt-c8y-icon-hourglass: "\f4d7";
$dlt-c8y-icon-hourglass-1: "\f4d7";
$dlt-c8y-icon-hourglass-2: "\f4d7";
$dlt-c8y-icon-hourglass-3: "\f4d7";
$dlt-c8y-icon-timezone-globe: "\f502";
$dlt-c8y-icon-timezone: "\f4e9";
$dlt-c8y-icon-timetable: "\f4e8";
$dlt-c8y-icon-day-view: "\f4b0";
$dlt-c8y-icon-month-view: "\f4ca";
$dlt-c8y-icon-week-view: "\f50a";
$dlt-c8y-icon-year-view: "\f50b";
$dlt-c8y-icon-newspaper-o: "\eba7";
$dlt-c8y-icon-creative-commons: "\eb5d";
$dlt-c8y-icon-spinner: "\eb5e";
$dlt-c8y-icon-loading: "\eb5e";
$dlt-c8y-icon-busy: "\eb5e";
$dlt-c8y-icon-wheel: "\eb5e";
$dlt-c8y-icon-trademark: "\eb62";
$dlt-c8y-icon-toggle-on: "\eb63";
$dlt-c8y-icon-toggle-off: "\eb64";
$dlt-c8y-icon-ticket: "\eb65";
$dlt-c8y-icon-spoon: "\eb66";
$dlt-c8y-icon-sort-numeric-desc: "\eb67";
$dlt-c8y-icon-sort-numeric-asc: "\eb68";
$dlt-c8y-icon-snowflake-o: "\eb69";
$dlt-c8y-icon-shower: "\eb6a";
$dlt-c8y-icon-percent: "\eb6c";
$dlt-c8y-icon-paw: "\eb6e";
$dlt-c8y-icon-mouse-pointer: "\eb6f";
$dlt-c8y-icon-lemon-o: "\eb70";
$dlt-c8y-icon-leaf: "\eb72";
$dlt-c8y-icon-diamond: "\eb74";
$dlt-c8y-icon-braille: "\eb75";
$dlt-c8y-icon-bomb: "\eb76";
$dlt-c8y-icon-binoculars: "\eb77";
$dlt-c8y-icon-eject: "\eb7a";
$dlt-c8y-icon-touch-app: "\eb7b";
$dlt-c8y-icon-soccer: "\eb7c";
$dlt-c8y-icon-football: "\eb7c";
$dlt-c8y-icon-futbol-o: "\eb7c";
$dlt-c8y-icon-soccer-ball-o: "\eb7c";
$dlt-c8y-icon-mood: "\eb7d";
$dlt-c8y-icon-meh-o: "\eb7d";
$dlt-c8y-icon-frown-o: "\eb7d";
$dlt-c8y-icon-smile-o: "\eb7d";
$dlt-c8y-icon-deaf: "\eb7f";
$dlt-c8y-icon-deafness: "\eb7f";
$dlt-c8y-icon-hard-of-hearing: "\eb7f";
$dlt-c8y-icon-assistive-listening-systems: "\eb80";
$dlt-c8y-icon-american-sign-language-interpreting: "\eb82";
$dlt-c8y-icon-asl-interpreting: "\eb82";
$dlt-c8y-icon-cc: "\eb83";
$dlt-c8y-icon-closed-caption: "\eb83";
$dlt-c8y-icon-anchor: "\eb87";
$dlt-c8y-icon-accessibility-settings: "\eae3";
$dlt-c8y-icon-info: "\eae4";
$dlt-c8y-icon-info-circle: "\eae4";
$dlt-c8y-icon-warning: "\eae5";
$dlt-c8y-icon-report-problem: "\eae5";
$dlt-c8y-icon-exclamation-triangle: "\eae5";
$dlt-c8y-icon-exclamation-circle: "\e97b";
$dlt-c8y-icon-error: "\e97b";
$dlt-c8y-icon-high-priority: "\e9e6";
$dlt-c8y-icon-spam: "\f122";
$dlt-c8y-icon-help: "\f69e";
$dlt-c8y-icon-question-circle: "\f69e";
$dlt-c8y-icon-question: "\f69e";
$dlt-c8y-icon-help-outline: "\eae7";
$dlt-c8y-icon-question-circle-o: "\eae7";
$dlt-c8y-icon-approval: "\f65d";
$dlt-c8y-icon-trophy: "\f65d";
$dlt-c8y-icon-unverified-account: "\f72e";
$dlt-c8y-icon-automatic-gearbox-warning: "\f52a";
$dlt-c8y-icon-exclamation-circle1: "\f52a";
$dlt-c8y-icon-user-manual: "\f732";
$dlt-c8y-icon-stages1: "\eae9";
$dlt-c8y-icon-menu: "\f6ca";
$dlt-c8y-icon-reorder: "\f6ca";
$dlt-c8y-icon-navicon: "\f6ca";
$dlt-c8y-icon-bars: "\f6ca";
$dlt-c8y-icon-hamburger: "\f6ca";
$dlt-c8y-icon-menu-vertical: "\f6cc";
$dlt-c8y-icon-ellipsis-v: "\f6cc";
$dlt-c8y-icon-app-switcher: "\eaea";
$dlt-c8y-icon-menu-horizontal: "\e974";
$dlt-c8y-icon-ellipsis-h: "\e974";
$dlt-c8y-icon-plus-math: "\f3d6";
$dlt-c8y-icon-plus: "\f3d6";
$dlt-c8y-icon-subtract: "\f3f3";
$dlt-c8y-icon-minus: "\f3f3";
$dlt-c8y-icon-clear: "\e977";
$dlt-c8y-icon-close: "\e977";
$dlt-c8y-icon-remove: "\e977";
$dlt-c8y-icon-times: "\e977";
$dlt-c8y-icon-equals: "\eaec";
$dlt-c8y-icon-exclamation: "\eaed";
$dlt-c8y-icon-check: "\eaee";
$dlt-c8y-icon-upvote-downvote: "\f731";
$dlt-c8y-icon-math: "\f3b8";
$dlt-c8y-icon-less-than1: "\f3b4";
$dlt-c8y-icon-more-than: "\f3be";
$dlt-c8y-icon-circled-notch: "\ec67";
$dlt-c8y-icon-circle-o-notch: "\ec67";
$dlt-c8y-icon-inactive-state: "\f6a5";
$dlt-c8y-icon-storage1: "\f712";
$dlt-c8y-icon-lifebuoy: "\f6b7";
$dlt-c8y-icon-life-buoy: "\f6b7";
$dlt-c8y-icon-life-ring: "\f6b7";
$dlt-c8y-icon-life-saver: "\f6b7";
$dlt-c8y-icon-wait: "\f73a";
$dlt-c8y-icon-block: "\e978";
$dlt-c8y-icon-ban: "\e978";
$dlt-c8y-icon-copy1: "\ed5d";
$dlt-c8y-icon-clone: "\ed5d";
$dlt-c8y-icon-paste1: "\ece5";
$dlt-c8y-icon-clipboard: "\ece5";
$dlt-c8y-icon-search-more: "\f701";
$dlt-c8y-icon-search: "\eaef";
$dlt-c8y-icon-mobile-zoom-in: "\f180";
$dlt-c8y-icon-search-plus: "\f180";
$dlt-c8y-icon-mobile-zoom-out: "\f181";
$dlt-c8y-icon-search-minus: "\f181";
$dlt-c8y-icon-product1: "\eaf0";
$dlt-c8y-icon-trash-can: "\ed39";
$dlt-c8y-icon-trash: "\ed39";
$dlt-c8y-icon-editing-trash: "\ec8e";
$dlt-c8y-icon-trash-o: "\ec8e";
$dlt-c8y-icon-delete-bin: "\ec7e";
$dlt-c8y-icon-eye: "\f0b5";
$dlt-c8y-icon-visibility-off: "\e981";
$dlt-c8y-icon-low-vision: "\e981";
$dlt-c8y-icon-eye-slash: "\e981";
$dlt-c8y-icon-blind: "\e981";
$dlt-c8y-icon-double-tap: "\f15a";
$dlt-c8y-icon-heart: "\f0be";
$dlt-c8y-icon-heart-o: "\f0be";
$dlt-c8y-icon-unheart: "\eaf1";
$dlt-c8y-icon-science: "\e984";
$dlt-c8y-icon-flask: "\e984";
$dlt-c8y-icon-school: "\e987";
$dlt-c8y-icon-graduation-cap: "\e987";
$dlt-c8y-icon-mortar-board: "\e987";
$dlt-c8y-icon-badge1: "\eaf2";
$dlt-c8y-icon-data-quality: "\eb13";
$dlt-c8y-icon-brightness-low: "\e908";
$dlt-c8y-icon-brightness-5: "\e908";
$dlt-c8y-icon-sun-o: "\e908";
$dlt-c8y-icon-bedtime: "\e98c";
$dlt-c8y-icon-moon-o: "\e98c";
$dlt-c8y-icon-moon: "\e98c";
$dlt-c8y-icon-add-identity-provider: "\eaf3";
$dlt-c8y-icon-bookmark-outline: "\eafb";
$dlt-c8y-icon-turned-in-not: "\eafb";
$dlt-c8y-icon-bookmark-o: "\eafb";
$dlt-c8y-icon-unbookmark: "\eafb";
$dlt-c8y-icon-bookmark: "\e98f";
$dlt-c8y-icon-turned-in: "\e98f";
$dlt-c8y-icon-check-box: "\e991";
$dlt-c8y-icon-check-square: "\e991";
$dlt-c8y-icon-check-square-o: "\e991";
$dlt-c8y-icon-checkbox-selected: "\e991";
$dlt-c8y-icon-add-box: "\e905";
$dlt-c8y-icon-plus-square: "\e905";
$dlt-c8y-icon-indeterminate-check-box: "\e994";
$dlt-c8y-icon-minus-square: "\e994";
$dlt-c8y-icon-duplicate: "\eafc";
$dlt-c8y-icon-check-box-outline-blank: "\e9db";
$dlt-c8y-icon-square-o: "\e9db";
$dlt-c8y-icon-checkbox-unselected: "\eafd";
$dlt-c8y-icon-add-new: "\f35e";
$dlt-c8y-icon-plus-square-o: "\f35e";
$dlt-c8y-icon-negative: "\f3c4";
$dlt-c8y-icon-minus-square-o: "\f3c4";
$dlt-c8y-icon-multiplication: "\f3c1";
$dlt-c8y-icon-times-rectangle-o: "\f3c1";
$dlt-c8y-icon-times-rectangle: "\f3c1";
$dlt-c8y-icon-ratio: "\f3db";
$dlt-c8y-icon-circle: "\e998";
$dlt-c8y-icon-contrast1: "\eafe";
$dlt-c8y-icon-connection-status-on: "\f20e";
$dlt-c8y-icon-ok: "\eb00";
$dlt-c8y-icon-check-circle: "\eb00";
$dlt-c8y-icon-check-circle-o: "\eb00";
$dlt-c8y-icon-success: "\eb00";
$dlt-c8y-icon-cross-circle: "\eb01";
$dlt-c8y-icon-times-circle: "\eb01";
$dlt-c8y-icon-times-circle-o: "\eb01";
$dlt-c8y-icon-add: "\eb03";
$dlt-c8y-icon-plus-circle: "\eb03";
$dlt-c8y-icon-minus-circle: "\eb05";
$dlt-c8y-icon-minus-sign: "\eb05";
$dlt-c8y-icon-radio-button-unchecked: "\e9dc";
$dlt-c8y-icon-circle-o: "\e9dc";
$dlt-c8y-icon-circle-thin: "\e9dc";
$dlt-c8y-icon-add-circle-outline: "\e9f6";
$dlt-c8y-icon-plus-circle-o: "\e9f6";
$dlt-c8y-icon-local-fire-department: "\e99b";
$dlt-c8y-icon-fire: "\e99b";
$dlt-c8y-icon-outlined-flag: "\e99c";
$dlt-c8y-icon-flag-o: "\e99c";
$dlt-c8y-icon-flag: "\e99d";
$dlt-c8y-icon-assistant-photo: "\e99d";
$dlt-c8y-icon-flag-checkered: "\e99d";
$dlt-c8y-icon-flash-on: "\e99e";
$dlt-c8y-icon-bolt: "\e99e";
$dlt-c8y-icon-quick-mode-on: "\f6f2";
$dlt-c8y-icon-flash: "\f6f2";
$dlt-c8y-icon-quick-mode-off: "\f6f1";
$dlt-c8y-icon-videogame-asset: "\e9a1";
$dlt-c8y-icon-gamepad: "\e9a1";
$dlt-c8y-icon-portraits: "\f2d0";
$dlt-c8y-icon-extension: "\e90a";
$dlt-c8y-icon-puzzle: "\e90a";
$dlt-c8y-icon-puzzle-piece: "\e90a";
$dlt-c8y-icon-star-outline: "\e90f";
$dlt-c8y-icon-star-o: "\e90f";
$dlt-c8y-icon-unstar: "\e90f";
$dlt-c8y-icon-star-half: "\e979";
$dlt-c8y-icon-star-half-empty: "\e979";
$dlt-c8y-icon-star-half-full: "\e979";
$dlt-c8y-icon-star-half-o: "\e979";
$dlt-c8y-icon-star: "\eb0c";
$dlt-c8y-icon-grade: "\eb0c";
$dlt-c8y-icon-home-page: "\eb0d";
$dlt-c8y-icon-home: "\eb0d";
$dlt-c8y-icon-attach: "\f091";
$dlt-c8y-icon-paper-clip: "\f091";
$dlt-c8y-icon-paperclip: "\f091";
$dlt-c8y-icon-link1: "\eb0e";
$dlt-c8y-icon-unlink1: "\eb0f";
$dlt-c8y-icon-online1: "\f0ea";
$dlt-c8y-icon-offline: "\f0e8";
$dlt-c8y-icon-notification: "\e9a7";
$dlt-c8y-icon-notifications: "\e9a7";
$dlt-c8y-icon-bell: "\e9a7";
$dlt-c8y-icon-bell-o: "\e9a7";
$dlt-c8y-icon-alarm: "\f479";
$dlt-c8y-icon-no-reminders: "\f0e6";
$dlt-c8y-icon-bell-slash: "\f0e6";
$dlt-c8y-icon-bell-slash-o: "\f0e6";
$dlt-c8y-icon-medium-priority: "\ea02";
$dlt-c8y-icon-sliders1: "\f656";
$dlt-c8y-icon-sliders2: "\f656";
$dlt-c8y-icon-adjust1: "\f656";
$dlt-c8y-icon-tune: "\f728";
$dlt-c8y-icon-customize: "\f728";
$dlt-c8y-icon-button: "\eb11";
$dlt-c8y-icon-breadcrumb: "\eb12";
$dlt-c8y-icon-window-minimize: "\f665";
$dlt-c8y-icon-versions: "\eb14";
$dlt-c8y-icon-pages: "\eb18";
$dlt-c8y-icon-change-theme: "\f668";
$dlt-c8y-icon-window-restore: "\f668";
$dlt-c8y-icon-landing-page: "\eb19";
$dlt-c8y-icon-continuous-mode: "\f675";
$dlt-c8y-icon-ruler-pencil: "\eb1a";
$dlt-c8y-icon-header1: "\eb1b";
$dlt-c8y-icon-body: "\eb1c";
$dlt-c8y-icon-footer: "\eb1d";
$dlt-c8y-icon-logs: "\eb1e";
$dlt-c8y-icon-overview: "\eb20";
$dlt-c8y-icon-tab: "\eb21";
$dlt-c8y-icon-cursor-in-window: "\ebae";
$dlt-c8y-icon-advertisement-page: "\f659";
$dlt-c8y-icon-share-square-o: "\f659";
$dlt-c8y-icon-share-square: "\f659";
$dlt-c8y-icon-share1: "\f659";
$dlt-c8y-icon-external-link: "\eb22";
$dlt-c8y-icon-level-up: "\f6b6";
$dlt-c8y-icon-trigger: "\eb24";
$dlt-c8y-icon-rules1: "\eb25";
$dlt-c8y-icon-email: "\f686";
$dlt-c8y-icon-form: "\f696";
$dlt-c8y-icon-language1: "\f6b3";
$dlt-c8y-icon-loading-bar: "\f6bc";
$dlt-c8y-icon-output: "\f6e4";
$dlt-c8y-icon-save1: "\f6f5";
$dlt-c8y-icon-floppy-o: "\f6f5";
$dlt-c8y-icon-single-choice: "\f708";
$dlt-c8y-icon-single-page-mode: "\f709";
$dlt-c8y-icon-skip: "\f70a";
$dlt-c8y-icon-support: "\f717";
$dlt-c8y-icon-wrench: "\f717";
$dlt-c8y-icon-maintenance: "\f5b1";
$dlt-c8y-icon-utility: "\f5b1";
$dlt-c8y-icon-themes: "\f71f";
$dlt-c8y-icon-book1: "\eb26";
$dlt-c8y-icon-top-menu: "\f723";
$dlt-c8y-icon-user-menu-male: "\f734";
$dlt-c8y-icon-hint: "\f6a2";
$dlt-c8y-icon-xlarge-icons1: "\f742";
$dlt-c8y-icon-circled-menu: "\f66e";
$dlt-c8y-icon-keypad: "\f6b2";
$dlt-c8y-icon-cake: "\e9a8";
$dlt-c8y-icon-birthday-cake: "\e9a8";
$dlt-c8y-icon-intelligence1: "\ebd6";
$dlt-c8y-icon-beginner: "\eb8c";
$dlt-c8y-icon-restaurant: "\e9ac";
$dlt-c8y-icon-cutlery: "\e9ac";
$dlt-c8y-icon-local-cafe: "\eb27";
$dlt-c8y-icon-free-breakfast: "\eb27";
$dlt-c8y-icon-coffee: "\eb27";
$dlt-c8y-icon-wine-bar: "\e9ab";
$dlt-c8y-icon-glass: "\e9ab";
$dlt-c8y-icon-espresso-cup: "\eedb";
$dlt-c8y-icon-broom: "\ee8c";
$dlt-c8y-icon-buy-upgrade: "\eb96";
$dlt-c8y-icon-speedometer: "\f608";
$dlt-c8y-icon-dashboard: "\f55d";
$dlt-c8y-icon-tachometer: "\f55d";
$dlt-c8y-icon-palette: "\eb28";
$dlt-c8y-icon-car-battery: "\f541";
$dlt-c8y-icon-magnetic: "\ebdd";
$dlt-c8y-icon-magnet1: "\ebdd";
$dlt-c8y-icon-right-navigation-toolbar: "\f33c";
$dlt-c8y-icon-tilt: "\f1c6";
$dlt-c8y-icon-clear-symbol: "\f670";
$dlt-c8y-icon-settings: "\e986";
$dlt-c8y-icon-cog: "\e986";
$dlt-c8y-icon-gear: "\e986";
$dlt-c8y-icon-advanced-search: "\f658";
$dlt-c8y-icon-heartbeat: "\f658";
$dlt-c8y-icon-installing-updates: "\f16a";
$dlt-c8y-icon-heating-automation: "\ef05";
$dlt-c8y-icon-impressum: "\eb29";
$dlt-c8y-icon-sun: "\f75a";
$dlt-c8y-icon-sunlight: "\ec2d";
$dlt-c8y-icon-cloud1: "\f745";
$dlt-c8y-icon-rain: "\f754";
$dlt-c8y-icon-winter: "\f761";
$dlt-c8y-icon-storm: "\f758";
$dlt-c8y-icon-keep-dry: "\ebd9";
$dlt-c8y-icon-umbrella: "\ebd9";

