.d-grid{
  display: grid;
}

@include media-breakpoint-up(lg) {
  [class^='grid--'],
  [class*=' grid--']{
    display: grid!important;
  }
}


.full-colspan{
  grid-column: 1 / -1!important;
}


.grid-row{
  position: relative;
  // z-index: 1;
  &::before, &::after{
    content: '';
    display: block;
    height: 1px;
    background: var(--grid-border-color, var(--c8y-palette-gray-60));
    mix-blend-mode: var(--grid-blend-mode);
    position: absolute;
    left: -1rem;
    right: -1rem;
  }
  &::before{
    top: 0;
    display: none;
  }
  &::after{
    bottom: 0;
  }
  &:first-child, &.first-row{
    &::before{
      display: block;
    }
  }
  &--bottom{
    &::before{
      display: none;
    }
    &::after{
      display: block;
    }
  }
  &--top{
    &::before{
      display: block;
    }
    &::after{
      display: none;
    }
  }
}

.grid-col{
  position: relative;
  padding: 1rem;
  &:has(.card--form){
    padding: 0;
  }
  &:first-child{
   .h-line{
      display: none;
   }
  }
  @include media-breakpoint-up('lg'){
    &, &:has(.card--form){
      padding: 2rem;
    }
  }
  > *{
   position: relative;
   z-index: 10;
  }
  &::before, &::after{
    content: '';
    display: block;
    width: 1px;
    background: var(--grid-border-color, var(--c8y-palette-gray-60));
    mix-blend-mode: var(--grid-blend-mode);
    position: absolute;
    top: -1rem;
    bottom: -1rem;
    z-index: 0;
  }
  &::before{
    left: 0;
  }
  &::after{
    right: 0;
  }
  .v-line{
    display: none;
  }

  @include media-breakpoint-up('lg'){
    .v-line{
      display: block;
    }
    &:not(:first-child){
      &::before{
        display: none;
      }
    }
  }
  &.grid-col--md{
    @include media-breakpoint-up('md'){
      &:not(:first-child){
        &::before{
          display: none;
        }
      }
    }
  }
}

.bullet-col{
  display: contents;
  ul{
    margin: 0;
    padding: 0;
    list-style: none;
    display: contents;
    li{
      @extend .grid-col;
      display: flex;
      gap: 1rem;

      strong{
        display: block;
        margin-top: 1rem;
        margin-bottom: .5rem;
        //font-size: $h6-font-size;
      }
      p:has(img){
        display: contents;
      }
      img{
        width: 150px;
        margin-top: -1rem;
      }
    }
  }
  &--4{
    li{
      @include media-breakpoint-up('lg'){
        width: 33.333333%;
        // &:first-child{
        //   &::before{
        //     display: none;
        //   }
        // }
      }
    }
  }
}

.cell-image{
  // padding: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  >img{
    width: 100%;
    max-width: 110px;
    border: 1rem solid var(--c8y-palette-high);
    aspect-ratio: 1;
    margin: auto;
    object-fit: contain;
    background-color: var(--c8y-palette-high);
    border-radius: 50%;
  }
}


.v-line{
  display: block;
  width: 1px;
  background: var(--grid-border-color, var(--c8y-palette-gray-60));
  mix-blend-mode: var(--grid-blend-mode);
  position: absolute;
  top: -1rem;
  bottom: -1rem;
  z-index: 0;
  
  .col-lg-8 &, .col-lg-4 &{
    left: calc(50% - 1px);
  }

  .col-lg-12 &, .col-lg-6 &, .col-md-6 &{
    left: calc(66.66666% - 1px);
    &:first-of-type{
      left: calc(33.3333% - 1px);
    }
  }
  
  .col-lg-6.lines-1 &{
    left: calc(50% - 1px);
  }
  .col-lg-9.lines-2 &,
  .col-lg-12.lines-2 &{
    left: calc(66.66666% - 1px);
    &:first-of-type{
      left: 33.3333%;
    }
  }

  .col-lg-12.lines-3 &,
  .col-lg-8.lines-3 &{
    &:nth-child(1){
      left: calc(25% - 1px);
    }
    &:nth-child(2){
      left: calc(50% - 1px);
    }
    &:nth-child(3){
      left: calc(75% - 1px);
    }
  }
}


.h-line{
  display: block;
  height: 1px;
  background: var(--grid-border-color, var(--c8y-palette-gray-60));
  mix-blend-mode: var(--grid-blend-mode);
  position: absolute;
  left: -1rem;
  right: -1rem;
  top: 0;
  z-index: 0;

  &--md{
    @include media-breakpoint-up('md'){
      display: none;
    }
  }
  &--lg{
    @include media-breakpoint-up('lg'){
      display: none;
    }
  }
}



.numbers-story{
  ul{
    list-style: none;
    margin: 0;
    padding: 0;
    @extend .d-col-lg;
    // @extend .grid-row;
    &::before{
      content: '';
        display: block;
        width: 1px;
        background: var(--grid-border-color, var(--c8y-palette-gray-60));
        mix-blend-mode: var(--grid-blend-mode);
        position: absolute;
        top: -1rem;
        bottom: -1rem;
        z-index: 0;
        left: 0;
    }
    @include media-breakpoint-up('lg'){
      &::before{
        display: none!important;
      }
    }
    >li{
      padding: 2rem;
      position: relative;
      &::before{
        content: '';
        display: block;
        width: 1px;
        background: var(--grid-border-color, var(--c8y-palette-gray-60));
        mix-blend-mode: var(--grid-blend-mode);
        position: absolute;
        top: -1rem;
        bottom: -1rem;
        right: 0;
        z-index: 0;
      }
      &::after{
        content: '';
        display: block;
        height: 1px;
        background: var(--grid-border-color, var(--c8y-palette-gray-60));
        mix-blend-mode: var(--grid-blend-mode);
        position: absolute;
        left: -1rem;
        right: -1rem;
        bottom:0;
      }
      &:last-child{
        &::after{
          display: none;
        }
      }
      
      h3{
        text-align: left;
        font-size: $h1-font-size!important;
        color: var(--section-title-color, var(--c8y-palette-gray-90));
        @include media-breakpoint-up('md'){
          margin-bottom: 2rem;
          transform: scale(1.3);
          transform-origin: 0 0;
        }
      }
    }
  }
}


.isotope{
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  .grid-col:has(.card){
    padding: 0;
    display: flex;
    align-items: stretch;
  }
  .card{
    position: relative;
    &::before, &::after{
      content: '';
      display: block;
      width: 1px;
      background: var(--grid-border-color, var(--c8y-palette-gray-60));
      mix-blend-mode: var(--grid-blend-mode);
      position: absolute;
      top: -1rem;
      bottom: -1rem;
      z-index: 0;
    }
    &::before{
      left: 0;
    }
    &::after{
      right: -1px;
    }

    > *{
      &::before, &::after{
        content: '';
        display: block;
        height: 1px;
        background: var(--grid-border-color, var(--c8y-palette-gray-60));
        mix-blend-mode: var(--grid-blend-mode);
        position: absolute;
        left: -1rem;
        right: -1rem;
      }
      &::before{
        top: 0;
        display: none;
      }
      &::after{
        bottom: 0;
      }
    }
  }
}
