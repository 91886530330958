

.btn {
  --c8y-btn-padding-base-vertical: 0.5rem;
  --c8y-btn-padding-base-horizontal: 1rem;
  --c8y-btn-border-radius-base: 0.25rem;
  --c8y-btn-padding-large-vertical: 0.7371007475rem;
  --c8y-btn-padding-large-horizontal: 1.5rem;
  --c8y-btn-border-radius-base: 0.25rem;
  --c8y-btn-line-height-large: 1.2015;
  --c8y-btn-font-weight: 600;
  background-color: transparent;
  background-image: none;
  border: 2px solid transparent;
  border-radius: var(--c8y-btn-border-radius-base);
  cursor: pointer;
  display: inline-block;
  font-size: var(--c8y-btn-font-size-base);
  font-weight: var(--c8y-btn-font-weight);
  line-height: var(--c8y-btn-line-height-base);
  margin-bottom: 0;
  padding: var(--c8y-btn-padding-base-vertical) var(--c8y-btn-padding-base-horizontal);
  text-align: center;
  text-decoration: none;
  touch-action: manipulation;
  transition: all .3s linear;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
      user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  &--accent{
    font-family: $headings-font-family;
    text-transform: uppercase;
    --btn-primary-background-default: var(--c8y-palette-gray-10);
    --btn-primary-border-color-default: var(--c8y-palette-gray-10);
    --btn-primary-color-default: var(--c8y-palette-yellow-60);

    --btn-primary-background-focus: var(--c8y-palette-gray-10);
    --btn-primary-border-color-focus: var(--c8y-palette-gray-10);
    --btn-primary-color-focus: var(--c8y-palette-yellow-60);

    --btn-primary-background-hover: var(--c8y-palette-yellow-60);
    --btn-primary-border-color-hover: var(--c8y-palette-gray-10);
    --btn-primary-color-hover: var(--c8y-palette-gray-10);

    --btn-primary-background-active: var(--c8y-palette-yellow-60);
    --btn-primary-border-color-active:var(--c8y-palette-gray-10);
    --btn-primary-color-active:var(--c8y-palette-gray-10);

    --btn-background-default: var(--c8y-palette-high);
    --btn-border-color-default: var(--c8y-palette-gray-10);
    --btn-color-default: var(--c8y-palette-gray-10);
    --btn-background-focus: var(--c8y-palette-high);
    --btn-border-color-focus: var(--c8y-palette-gray-10);
    --btn-color-focus: var(--c8y-palette-gray-10);
    --btn-background-hover: var(--c8y-palette-yellow-70);
    --btn-border-color-hover: var(--c8y-palette-gray-10);
    --btn-color-hover: var(--c8y-palette-gray-10);
    --btn-background-active: var(--c8y-palette-yellow-60);
    --btn-border-color-active:var(--c8y-palette-gray-10);
    --btn-color-active:var(--c8y-palette-gray-10);
  }
}

.btn-sm{
  font-size: var(--c8y-btn-font-size-small);
  line-height: var(--c8y-btn-line-height-small);
  padding: var(--c8y-btn-padding-small-vertical) var(--c8y-btn-padding-small-horizontal);
}
.btn-lg{
  font-size: var(--c8y-btn-font-size-large);
  line-height: var(--c8y-btn-line-height-large);
  padding: var(--c8y-btn-padding-large-vertical) var(--c8y-btn-padding-large-horizontal);
}

.btn>i {
  vertical-align: text-bottom;
}

.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn:active:focus,
.btn:focus {
  outline: none
}


.btn.focus,.btn:focus,.btn:hover {
  text-decoration: none
}

.btn.disabled,.btn.disabled:focus,.btn.disabled:hover,.btn[disabled],.btn[disabled]:focus,.btn[disabled]:hover,fieldset[disabled] .btn,fieldset[disabled] .btn:focus,fieldset[disabled] .btn:hover {
  box-shadow: none;
  cursor: not-allowed!important;
  filter: alpha(opacity=65);
  opacity: .65
}

a .btn.disabled,fieldset[disabled] a .btn {
  pointer-events: none
}


.btn-default {
  background-color: var(--btn-background-default, var(--c8y-btn-default-background-default));
  border-color: var(--btn-border-color-default, var(--c8y-btn-default-border-color-default));
  color: var(--btn-color-default, var(--c8y-btn-default-color-default));
  &--dark{
    border-color: var(--c8y-palette-gray-50);
  }
}

.btn-default.focus,.btn-default:focus {
  background-color: var(--btn-background-focus, var(--c8y-btn-default-background-focus));
  border-color: var(--btn-border-color-focus, var(--c8y-btn-default-border-color-focus));
  box-shadow: inset 0 0 0 1px var(--btn-border-color-focus, var(--c8y-btn-default-border-color-focus));
  color: var(--btn-color-focus, var(--c8y-btn-default-color-focus));
}

.btn-default:hover {
  background-color: var(--btn-background-hover, var(--c8y-btn-default-background-hover));
  border-color: var(--btn-border-color-hover, var(--c8y-btn-default-border-color-hover));
  box-shadow: inset 0 0 0 1px var(--btn-border-color-hover, var(--c8y-btn-default-border-color-hover));
  color: var(--btn-color-hover, var(--c8y-btn-default-color-hover));
}

.btn-default.active,.btn-default:active,.open>.dropdown-toggle.btn-default {
  background-color: var(--btn-background-active, var(--c8y-btn-default-background-active));
  background-image: none;
  border-color: var(--btn-border-color-active, var(--c8y-btn-default-border-color-active));
  box-shadow: inset 0 0 0 1px var(--btn-border-color-active, var(--c8y-btn-default-border-color-active));
  color: var(--btn-color-active, var(--c8y-btn-default-color-active));
}

.btn-default.disabled,.btn-default.disabled.active,.btn-default.disabled.focus,.btn-default.disabled:active,.btn-default.disabled:focus,.btn-default.disabled:hover,.btn-default[disabled],.btn-default[disabled].active,.btn-default[disabled].focus,.btn-default[disabled]:active,.btn-default[disabled]:focus,.btn-default[disabled]:hover,fieldset[disabled] .btn-default,fieldset[disabled] .btn-default.active,fieldset[disabled] .btn-default.focus,fieldset[disabled] .btn-default:active,fieldset[disabled] .btn-default:focus,fieldset[disabled] .btn-default:hover {
  background-color: var(--bnt-default-background-default, var(--c8y-btn-default-background-default));
  border-color: var(--btn-border-color-default, var(--c8y-btn-default-border-color-default));
  color: var(--btn-color-default, var(--c8y-btn-default-color-default))
}

.btn-primary {
  .bg-yellow--60:not(:has(.card--form)) &{
    @extend .btn-default;
  }

  background-color: var(--btn-primary-background-default, var(--c8y-btn-primary-background-default));
  border-color: var(--btn-primary-border-color-default, var(--c8y-btn-primary-border-color-default));
  color: var(--btn-primary-color-default, var(--c8y-btn-primary-color-default));
}

.btn-primary.focus,.btn-primary:focus {
  background-color: var(--btn-primary-background-focus, var(--c8y-btn-primary-background-focus));
  border-color: var(--btn-primary-border-color-focus, var(--c8y-btn-primary-border-color-focus));
  box-shadow: inset 0 0 0 1px var(--btn-primary-border-color-focus, var(--c8y-btn-primary-border-color-focus));
  color: var(--btn-primary-color-focus, var(--c8y-btn-primary-color-focus));
}

.btn-primary:hover {
  background-color: var(--btn-primary-background-hover, var(--c8y-btn-primary-background-hover));
  border-color:  var(--btn-primary-border-color-hover, var(--c8y-btn-primary-border-color-hover));
  box-shadow: inset 0 0 0 1px  var(--btn-primary-border-color-hover, var(--c8y-btn-primary-border-color-hover));
  color: var(--btn-primary-color-hover, var(--c8y-btn-primary-color-hover));
}

.btn-primary.active,.btn-primary:active,.open>.dropdown-toggle.btn-primary {
  background-color: var(--btn-primary-background-active, var(--c8y-btn-primary-background-active));
  background-image: none;
  border-color: var(--btn-primary-border-color-active, var(--c8y-btn-primary-border-color-active));
  box-shadow: inset 0 0 0 1px var(--btn-primary-border-color-active, var(--c8y-btn-primary-border-color-active));
  color: var(--btn-primary-color-active, var(--c8y-btn-primary-color-active));
}

.btn-primary.disabled,.btn-primary.disabled.active,.btn-primary.disabled.focus,.btn-primary.disabled:active,.btn-primary.disabled:focus,.btn-primary.disabled:hover,.btn-primary[disabled],.btn-primary[disabled].active,.btn-primary[disabled].focus,.btn-primary[disabled]:active,.btn-primary[disabled]:focus,.btn-primary[disabled]:hover,fieldset[disabled] .btn-primary,fieldset[disabled] .btn-primary.active,fieldset[disabled] .btn-primary.focus,fieldset[disabled] .btn-primary:active,fieldset[disabled] .btn-primary:focus,fieldset[disabled] .btn-primary:hover {
  background-color: var(--btn-primary-background-default, var(--c8y-btn-primary-background-default));
  border-color: var(--btn-primary-border-color-default, var(--c8y-btn-primary-border-color-default));
  color: var(--btn-primary-color-default, var(--c8y-btn-primary-color-default));
}





.btn-clean, .btn-link{
  display: inline-block;
  margin: 0;
  border: 0;
  background-image: none;
  background-color: transparent;
  text-align: center;
  text-decoration: none!important;
  white-space: normal;
  font-weight: inherit;
  cursor: pointer;
  padding:0;
  font-size: inherit;
  line-height: inherit;
  touch-action: manipulation;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}


.btn-link{
  color: var(--c8y-brand-accent);
  &:hover{
    color: var(--c8y-brand-accent-dark);
  }
}
