.panel{
  &__container{
    max-width: $global-max-width;
    margin: 0 auto;
    width: 100%;
    padding: $section-padding-h $global-padding;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &.panel--first{
      padding-top: 6rem;
    }
    > .section-separator{
      z-index: 0;
    }
    > .p-absolute{
      z-index: 10;
      pointer-events: none;
    }
    &:has(.isotope){
      // min-height: 100vh;
    }
  }

  &.bg-white{
  --btn-background-default: transparent
  --btn-border-color-default: var(--c8y-palette-yellow-60);
  --btn-color-default: var(--c8y-palette-gray-10);

  --btn-background-hover: var(--c8y-palette-yellow-68);
  --btn-border-color-hover: var(--c8y-palette-yellow-60);
  --btn-color-hover: var(--c8y-palette-gray-10);
  
  --btn-background-active: var(--c8y-palette-yellow-65);
  --btn-border-color-active: var(--c8y-palette-yellow-60);
  --btn-color-active: var(--c8y-palette-gray-10);
  
  --btn-border-color-focus: var(--c8y-palette-yellow-60);
  --btn-color-focus: var(--c8y-palette-gray-10);
  --btn-background-focus: var(--c8y-palette-yellow-50);
  }
}
