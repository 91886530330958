
ul {
  padding-left: 1em;

  .large-list &,
  &.large-list {
    padding-left: 1em;
    list-style: none;

    li {
      &::before {
        display: inline-block;
        content: '•';
        vertical-align: middle;
        color: var(--c8y-brand-primary);
        width: 1em;
        margin-left: -1em;
      }
    }

    li+li {
      margin-top: 1rem;
    }
  }
}

ol{
  padding-left: 1em;
}


.bullet-check {
  padding: 0;
  margin: 0;
  list-style: none;
  ul {
    padding: 0;
    list-style: none;
  }

  li {
    margin-bottom: 1rem;
    display: flex;
    align-items: center;

    &::before {
      @extend .dlt-c8y-icon;
      content: $dlt-c8y-icon-check;
      display: flex;
      // font-size: 1em;
      margin-right: .5rem;
      line-height: 1;
      color: currentColor;
      background: transparent;
      border: 1px solid currentColor;
      height: 1.5rem;
      width: 1.5rem;
      border-radius: 50%;
      align-items: center;
      justify-content: center;
      align-self: start;
      flex-shrink: 0;
    }
  }
  &--column{
    li{
      display: block;
      padding-left: 2rem;
      position: relative;
      margin-bottom: 2.5rem;
      p{
        margin-top: 0!important;
      }
      &::before {
        position: absolute;
        left: 0;
      }
    }
  }
}


.list-container{
  ul{
    margin: 0;
    list-style: none;
    padding: 0;
    border-top: 1px solid var(--grid-border-color, var(--c8y-palette-gray-60));
    mix-blend-mode: var(--grid-blend-mode);
    li{
      a:not([class]){
        text-decoration: none;
        display: flex;
        align-items: center;
        gap: .5rem;
        width: 100%;
        padding: 1rem;
        border-bottom: 1px solid var(--grid-border-color, var(--c8y-palette-gray-60));
        mix-blend-mode: var(--grid-blend-mode);
        > i:last-child{
          margin-left: auto;
        }
        &::after{
          display: none;
        }
        &:hover{
          background-color: var(--c8y-palette-yellow-80);
        }
      }
    }
  }
}




.list-circles{
  ul, &{
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    @include media-breakpoint-up('xl'){
      justify-content: flex-start;
      flex-wrap: nowrap;
    }
  }
  &--dashed{
    ul{
      gap: 4rem;
      align-items: flex-start;
    }
    li{
      flex-basis: 33.333%;
      display: flex;
      flex-direction: column;
      aspect-ratio: 1;
      border-radius: 50%;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-size: 87.5%;
      min-width: 23rem;
      z-index: 3; 
      background-color: #ffbe00c4;
      // dashed border
      /**
      --n: 30;   // control the number of dashes 
      --d: 8deg; // control the distance between dashes
      --t: 3px;  // control the thickness of border
      --c: white;  // control the coloration (can be a gradient) 
      ** */
      --n: 141;
      --d: 2deg;
      --t: 20px;
      --c: hsl(0deg 0% 100% / 70%);
      position: relative;
      padding: 2.4rem;

      &::after {
        content: "";
        position: absolute;
        inset: 0;
        border-radius: 50%;
        padding: var(--t);
        background: var(--c);
        mask:
            linear-gradient(#0000 0 0) content-box,
            repeating-conic-gradient(
               from calc(var(--d)/2),
               #000  0 calc(360deg/var(--n) - var(--d)),
               #0000 0 calc(360deg/var(--n))
             );
        mask-composite: intersect;
      }

      @include media-breakpoint-up('xl'){
        
        &:nth-child(2){
          margin-top: 2rem;
        }
        &:nth-child(3){
          margin-top: 4rem;
        }
      }
      h4{
        font-size: 1.5rem;
        margin-bottom: .5rem;
      }
      p{
        margin-top: 0;
      }
    }
  }
}
