html,
body {
  font-family: $font-family;

}

html.lenis {
  height: auto;
}

.lenis.lenis-smooth {
  scroll-behavior: auto;
}

.lenis.lenis-smooth [data-lenis-prevent] {
  -ms-scroll-chaining: none;
  overscroll-behavior: contain;
}

.lenis.lenis-stopped {
  overflow: hidden;
}

.lenis.lenis-scrolling iframe {
  pointer-events: none;
}



body {
  background-color: $body-bg-color;
  color: $body-text-color;
  max-width: 100vw;
  overflow-x: hidden;
}

.main-content {
  transition: opacity .5s linear;
  opacity: 1;
  max-height: unset;
  max-width: 100vw;
  min-height: calc(100vh - 344px);

  &:not(:has(.nav-filters)) {
    position: relative;
    z-index: 100;
    overflow: hidden;
  }

  &__container {
    max-width: $global-max-width;
    margin: 0 auto;
    width: 100%;
    padding: 2rem $global-padding $section-padding-h $global-padding;

    @include media-breakpoint-up('lg') {
      padding: $section-padding-h $global-padding;
      padding-top: 6rem;
    }

    position: relative;
  }
}

.single-panel{
  min-height: calc(100vh - 117px);
}

body.loading {

  .main-content {
    opacity: 0;
    max-height: 100vh;
    overflow: hidden;
    width: 100vw;
  }
}

body.nav-open {
  overflow: hidden;
}


body:has(.nav-filters){
  .page-header .main-content__container{
    padding-bottom: 0;

    .grid-row:last-child{
      &:after{
        display: none;
      }
    }
  }
}


.empty-state{
  padding: 2rem;
  border: 2px dashed var(--c8y-palette-gray-60);
  margin: 1rem auto;
  border-radius: 2rem;
}
