/* ==============================
// BITS LINE
=============================== */


.bits-line{
  position: relative;
  display: inline-block;
  overflow: visible;
  vertical-align: top;
  transition: all .25s ease 1s;
  &::before{
    content: '';
    position: absolute;
    height: var(--bits-height);
    display: block;
    left: 0;
    width: 100vw;
    background: currentColor;
    z-index: 1;
    --mask: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzA0IiBoZWlnaHQ9IjQ4IiB2aWV3Qm94PSIwIDAgMzA0IDQ4IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNMjY4Ljk5MyA3SDI1Ni43NjZWNDIuNDYyOUgyNjguOTkzVjdaIiBmaWxsPSIjQjBEMEYyIi8+CjxwYXRoIGQ9Ik0yMjguMDk5IDQyLjY4OTZDMjM4LjIzIDQyLjY4OTYgMjQ2LjQ0NCAzNC40NzYzIDI0Ni40NDQgMjQuMzQ0OEMyNDYuNDQ0IDE0LjIxMzMgMjM4LjIzIDYgMjI4LjA5OSA2QzIxNy45NjcgNiAyMDkuNzU0IDE0LjIxMzMgMjA5Ljc1NCAyNC4zNDQ4QzIwOS43NTQgMzQuNDc2MyAyMTcuOTY3IDQyLjY4OTYgMjI4LjA5OSA0Mi42ODk2WiIgZmlsbD0iI0IwRDBGMiIvPgo8cGF0aCBkPSJNMTgwLjM2OCA0Mi42ODk2QzE5MC41IDQyLjY4OTYgMTk4LjcxMyAzNC40NzYzIDE5OC43MTMgMjQuMzQ0OEMxOTguNzEzIDE0LjIxMzMgMTkwLjUgNiAxODAuMzY4IDZDMTcwLjIzNyA2IDE2Mi4wMjMgMTQuMjEzMyAxNjIuMDIzIDI0LjM0NDhDMTYyLjAyMyAzNC40NzYzIDE3MC4yMzcgNDIuNjg5NiAxODAuMzY4IDQyLjY4OTZaIiBmaWxsPSIjQjBEMEYyIi8+CjxwYXRoIGQ9Ik0xMzQuMDcxIDQyLjY4OTZDMTQ0LjIwMyA0Mi42ODk2IDE1Mi40MTYgMzQuNDc2MyAxNTIuNDE2IDI0LjM0NDhDMTUyLjQxNiAxNC4yMTMzIDE0NC4yMDMgNiAxMzQuMDcxIDZDMTIzLjk0IDYgMTE1LjcyNyAxNC4yMTMzIDExNS43MjcgMjQuMzQ0OEMxMTUuNzI3IDM0LjQ3NjMgMTIzLjk0IDQyLjY4OTYgMTM0LjA3MSA0Mi42ODk2WiIgZmlsbD0iI0IwRDBGMiIvPgo8cGF0aCBkPSJNODcuMDU1OSA0Mi42ODk2Qzk3LjE4NzUgNDIuNjg5NiAxMDUuNDAxIDM0LjQ3NjMgMTA1LjQwMSAyNC4zNDQ4QzEwNS40MDEgMTQuMjEzMyA5Ny4xODc1IDYgODcuMDU1OSA2Qzc2LjkyNDQgNiA2OC43MTA5IDE0LjIxMzMgNjguNzEwOSAyNC4zNDQ4QzY4LjcxMDkgMzQuNDc2MyA3Ni45MjQ0IDQyLjY4OTYgODcuMDU1OSA0Mi42ODk2WiIgZmlsbD0iI0IwRDBGMiIvPgo8cGF0aCBkPSJNMjkyLjEzNyA3SDI3OS45MVY0Mi40NjI5SDI5Mi4xMzdWN1oiIGZpbGw9IiNCMEQwRjIiLz4KPHBhdGggZD0iTTU5LjI0MjkgN0g0Ny4wMTU2VjQyLjQ2MjlINTkuMjQyOVY3WiIgZmlsbD0iI0IwRDBGMiIvPgo8cGF0aCBkPSJNMTguMzQ0NiA0Mi42ODk2QzI4LjQ3NjEgNDIuNjg5NiAzNi42ODk2IDM0LjQ3NjMgMzYuNjg5NiAyNC4zNDQ4QzM2LjY4OTYgMTQuMjEzMyAyOC40NzYxIDYgMTguMzQ0NiA2QzguMjEzMDYgNiAwIDE0LjIxMzMgMCAyNC4zNDQ4QzAgMzQuNDc2MyA4LjIxMzA2IDQyLjY4OTYgMTguMzQ0NiA0Mi42ODk2WiIgZmlsbD0iI0IwRDBGMiIvPgo8L3N2Zz4K");
    -webkit-mask: var(--mask);
    mask: var(--mask);
    mask-size: contain;
    mask-repeat: repeat-x;
    transform-origin: left;
    top: var(--bits-top);
    transform: scale(var(--bits-scale));
  }
  &--text{
    height: 1em;
    aspect-ratio: 6 / 1;
    &::before{
      height: 100%;
      width: 100%;
      mask-size: contain;
      mask-repeat: no-repeat;
    }
  }
  &--fit{
    display: block;
    height: 100%;
    .sub-nav__intro &{
      margin-right: -2rem;
      overflow: hidden;
      color: rgba(0,0,0,0.1);
      mix-blend-mode: multiply;
    }
    &::before{
      height: 100%;
      width: 100%;
      mask-size: cover;
      mask-repeat: no-repeat;
    }
  }
  .p-r-0 &{
    margin-right: 1px;
  }
}


// COLOR UTILITIES

.colors-3{
  .bg-color{
    border: 1px solid transparent;
    mix-blend-mode: multiply;
  }
  >*:nth-child(1) .bg-color{
    background-color: var(--c8y-palette-blue-70);
  }
  >*:nth-child(2) .bg-color{
    background-color: var(--c8y-palette-green-70);
  }
  >*:nth-child(3) .bg-color{
    background-color: var(--c8y-palette-yellow-60);
  }
}

.color-inherit{
  color: inherit;
}

.multiply{
  mix-blend-mode: multiply;
}


/* ==============================
// BORDER
=============================== */

.b-r-16 {
  border-radius: 1rem;
}

.border-contour {
  border: .5rem solid var(--c8y-palette-gray-80);
  background-color: white;
}


// IMAGE

.image-circle{
  border-radius: 50%;
  object-fit: cover;
  aspect-ratio: 1;
}

.avatar{
  width: 60px;
  display: flex;
  
  img{
    border-radius: 50%;
    object-fit: cover;
    aspect-ratio: 1;
  }
  &--sm{
    width: 40px;
  }
}

/* ==============================
// ITYPED CURSOR
=============================== */

// .ityped-cursor {
//   font-size: 1em;
//   opacity: 1;
//   margin-left: .5rem;
//   height:.8em;
//   display: inline-block;
//   border-left: 2px solid currentColor;
//   -webkit-animation: blink 0.3s infinite;
//   -moz-animation: blink 0.3s infinite;
//   animation: blink 0.3s infinite;
//   animation-direction: alternate;
//  }
 
//  @keyframes blink {
//   100% {
//    opacity: 0;
//   }
//  }
 
//  @-webkit-keyframes blink {
//   100% {
//    opacity: 0;
//   }
//  }
 
//  @-moz-keyframes blink {
//   100% {
//    opacity: 0;
//   }
//  }
 

/* ==============================
// ELEVATION
=============================== */

.elevation-sm {
  box-shadow: var(--c8y-elevation-sm);
}

.elevation-md-top {
  box-shadow: var(--c8y-elevation-md-top);
}

.elevation-md-bottom {
  box-shadow: var(--c8y-elevation-md-bottom);
}

.elevation-md-left {
  box-shadow: var(--c8y-elevation-md-left);
}

.elevation-md-right {
  box-shadow: var(--c8y-elevation-md-right);
}

.elevation-lg {
  box-shadow: var(--c8y-elevation-lg);
}



.elevation-xl--top {
 
  box-shadow:  0px -0.7px 0.9px rgba(var(--c8y-elevation-color), 0.04),
  0px -4.1px 5.3px -0.3px rgba(var(--c8y-elevation-color), 0.05),
  0px -7.6px 9.8px -0.6px rgba(var(--c8y-elevation-color), 0.06),
  0.1px -13.1px 16.9px -0.8px rgba(var(--c8y-elevation-color), 0.07),
  0.1px -22.1px 28.5px -1.1px rgba(var(--c8y-elevation-color), 0.08),
  0.2px -36.6px 47.2px -1.4px rgba(var(--c8y-elevation-color), 0.09);
}



/* ==============================
// POSITIONING
=============================== */

.p-relative {
  position: relative;
}

.p-absolute {
  position: absolute;
}

.p-static {
  position: static;
}

.p-fixed {
  position: fixed;
}

.p-sticky {
  position: sticky;
}

@include media-breakpoint-up('lg'){
  .p-relative--lg {
    position: relative;
  }
  
  .p-absolute--lg {
    position: absolute;
  }
  
  .p-static--lg {
    position: static;
  }
  
  .p-fixed--lg {
    position: fixed;
  }
  
  .p-sticky--lg {
    position: sticky;
  }
}


.t-0 {
  top: 0;
}
.t--panel__container{
  top: 9rem;
  &--w-title{
    top: calc(clamp(4rem, 10vw, 32rem) + 7rem);
  }
}

.l-0 {
  left: 0;
}

.r-0 {
  right: 0;
}

.b-0 {
  bottom: 0;
}


/* ==============================
// Size utilities
=============================== */

.fit-w {
  width: 100%;
}

.fit-h {
  height: 100%;
}

.fit-h-650{
  min-height: 650px;
}

.max-h-300{
  max-height: 300px;
}

.max-70vh {
  max-height: 70vh;
}

.vertical-center {
  top: 50%;
  transform: translateY(-50%);
}

.horizontal-center {
  left: 50%;
  transform: translateX(-50%);
}


.z-10{
  z-index: 10;
}




/* ==============================
// Flex utilities
=============================== */

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-grow {
  flex-grow: 1;
}
.flex-no-grow {
  flex-grow: 0;
}
.flex-shrink {
  flex-shrink: 1;
}
.flex-no-shrink {
  flex-shrink: 0;
}
/* ==============================
// IMAGE AND SVG UTILITIES 
=============================== */

.img-circle {
  overflow: hidden;
  border-radius: 50%;
  aspect-ratio: 1;
  -o-object-fit: cover;
  object-fit: cover;
}

img {
  max-width: 100%;
  height: auto;
}

.fixed-image{
  position: fixed;
  top:0;
  min-height: 100vh;
  &--right{
    right: 0;
    max-width: 50%;
  }
}

.fixed-center{
  height: 0;
  overflow: visible;
  left: 50%;
  transform: translateX(-50%);
  position: fixed;
  top: 280px;
  z-index: 10;
  img{
    height: clamp(730px, 1000px,100vh);
  }
}

.fixed-right{
  height: 0;
  overflow: visible;
  right: calc((100vw - 1200px) / 2);
  transform: translateX(-50%);
  position: fixed;
  top: 280px;
  z-index: 10;
  img{
    height: clamp(730px, 1000px,100vh);
  }
}

.absolute-image{
  position: absolute;
  top:0;
  min-height: 100vh;
  &--right{
    right: 0;
    max-width: 50%;
  }
}

.c8y-illustration {
  >svg {
    max-height: 200px;
    margin: auto;
  }
}

.img-wrapper-h--200 {
  height: 200px;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center
  }
}

.wide-img {
  max-width: calc(100vw - #{$global-padding * 2}) !important;
  width: $global-max-width;
  margin-left: 50%;
  transform: translateX(-50%);
}



/* ==============================
// ISOMETRIC ICONS SIZES
=============================== */

.iso-icon-h--170 {
  height: 170px;

  img {
    height: 100%;
    width: auto;
    margin: auto
  }
}



/* ==============================
// VISIBILITY UTILITIES
=============================== */

.visible-xs,
.visible-sm,
.visible-md,
.visible-lg {
  @include responsive-invisibility();
}

.visible-xs {
  @media (max-width: 767px) {
    @include responsive-visibility();
  }
}

.visible-sm {
  @media (min-width: 768px) and (max-width: 991px) {
    @include responsive-visibility();
  }
}

.visible-md {
  @media (min-width: 992px) and (max-width: 1199px) {
    @include responsive-visibility();
  }
}

.visible-lg {
  @media (min-width: 1200px) {
    @include responsive-visibility();
  }
}

.hidden-xs {
  @media (max-width: 767px) {
    @include responsive-invisibility();
  }
}

.hidden-sm {
  @media (min-width: 768px) and (max-width: 991px) {
    @include responsive-invisibility();
  }
}

.hidden-md {
  @media (min-width: 992px) and (max-width: 1199px) {
    @include responsive-invisibility();
  }
}

.hidden-lg {
  @media (min-width: 1200px) {
    @include responsive-invisibility();
  }
}

.hidden {
  @include responsive-invisibility();
}



/* ==============================
// ANIMATIONS
=============================== */

.floating {
  animation: float 6s ease-in-out infinite;
}

@keyframes float {
  0% {
      transform: translateY(0);
  }

  50% {
      transform: translateY(-20px);
  }

  100% {
      transform: translateY(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@media (prefers-reduced-motion) {
  @keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
    }

    to {
      opacity: 0;
    }
  }
}


.fadeIn {
  animation-name: fadeIn;
  animation-duration: 1.5s;
  animation-fill-mode: both;
  animation-iteration-count: 1 !important;

  @media (prefers-reduced-motion) {
    animation-duration: 2s;
    animation-delay: 0;
  }
}



.fadeOut {
  animation-name: fadeOut;
  animation-fill-mode: both;
  animation-duration: 1.5s;
  animation-delay: .15s;
  animation-iteration-count: 1 !important;

  @media (prefers-reduced-motion) {
    animation-duration: 2s;
    animation-delay: 0;
  }
}



@keyframes slideIn {
  from {
    opacity: 0;
    transform: translate(0, -100%);
  }

  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}

.slideIn {
  animation-name: slideIn;
  animation-duration: .5s;
  animation-delay: .25s;
  animation-fill-mode: both;
  animation-iteration-count: 1 !important;
}

@keyframes slideOut {
  from {
    opacity: 1;
    transform: translate(0, 0);
  }

  to {
    opacity: 0;
    transform: translate(0, -100%);
  }
}

.slideOut {
  animation-name: slideOut;
  animation-fill-mode: both;
  animation-duration: .25s;
  animation-iteration-count: 1 !important;
}


@keyframes flip {
  from {
    transform: perspective(800px) scale3d(1, 1, 1) translate3d(0, 0, 0) rotate3d(0, 1, 0, -360deg);
    animation-timing-function: ease-out;
  }

  40% {
    transform: perspective(800px) scale3d(1, 1, 1) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
    animation-timing-function: ease-out;
  }

  50% {
    transform: perspective(800px) scale3d(1, 1, 1) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
    animation-timing-function: ease-in;
  }

  80% {
    transform: perspective(800px) scale3d(0.95, 0.95, 0.95) translate3d(0, 0, 0) rotate3d(0, 1, 0, 0deg);
    animation-timing-function: ease-in;
  }

  to {
    transform: perspective(800px) scale3d(1, 1, 1) translate3d(0, 0, 0) rotate3d(0, 1, 0, 0deg);
    animation-timing-function: ease-in;
  }
}

.animated.flip {
  backface-visibility: visible;
  animation-name: flip;
}


@keyframes flipInY {
  from {
    transform: perspective(800px) rotate3d(0, 1, 0, 90deg);
    animation-timing-function: ease-in;
    opacity: 0;
  }

  40% {
    transform: perspective(800px) rotate3d(0, 1, 0, -20deg);
    animation-timing-function: ease-in;
  }

  60% {
    transform: perspective(800px) rotate3d(0, 1, 0, 10deg);
    opacity: 1;
  }

  80% {
    transform: perspective(800px) rotate3d(0, 1, 0, -5deg);
  }

  to {
    transform: perspective(800px);
  }
}

.flipInY {
  // backface-visibility: visible !important;
  animation-name: flipInY;
  animation-fill-mode: both;
  animation-duration: .25s;
  animation-iteration-count: 1 !important;
}

@keyframes flipOutY {
  from {
    transform: perspective(800px);
  }

  30% {
    transform: perspective(800px) rotate3d(0, 1, 0, -15deg);
    opacity: 1;
  }

  to {
    transform: perspective(800px) rotate3d(0, 1, 0, 90deg);
    opacity: 0;
  }
}

.flipOutY {
  // backface-visibility: visible !important;
  animation-name: flipOutY;
  animation-fill-mode: both;
  animation-duration: .25s;
  animation-iteration-count: 1 !important;
}

@keyframes flipInX {
  from {
    transform: perspective(800px) rotate3d(1, 0, 0, -90deg);
  }

  to {
    transform: perspective(800px);
  }
}

.flipInX {
  animation-name: flipInX;
  animation-fill-mode: both;
  animation-timing-function: ease-in;
  animation-duration: .25s;
  animation-iteration-count: 1 !important;
}


@keyframes flipOutX {
  from {
    transform: perspective(800px);
  }

  to {
    transform: perspective(800px) rotate3d(1, 0, 0, 90deg);
  }
}

.flipOutX {
  animation-name: flipOutX;
  animation-timing-function: ease-in;
  animation-fill-mode: both;
  animation-duration: .25s;
  animation-iteration-count: 1 !important;
}


.cookieconsent-optout-marketing{
 padding: 2rem;
 border: 2px dashed var(--c8y-palette-gray-60);
 mix-blend-mode: var(--grid-blend-mode);
 margin: 1rem auto;
 border-radius: 2rem;

 img{
  max-height: 150px;
 }
 &[style="display: block;" ] + div:has(iframe){
  display: none;
 }
}
