@charset "UTF-8";
/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */



// To use CSS filters as the hover effect instead of opacity,
// set $hamburger-hover-use-filter as true and
// change the value of $hamburger-hover-filter accordingly.
$hamburger-hover-use-filter: false !default;
$hamburger-hover-filter : opacity(50%) !default;

// Types (Remove or comment out what you don’t need)
// ==================================================
$hamburger-types: (spring, squeeze) !default;

// Base Hamburger (We need this)
// ==================================================
@import "base";

// Hamburger types
// ==================================================

@import "squeeze";
@import "spring";