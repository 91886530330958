.resource-library{
  &__list{
    
    .r-l{
      width: 100%;
      float: left;
    }
    @include media-breakpoint-up('lg'){
    .r-l{
      width: 33.33333%;
      float: left
    }
  }
  

    .panel__container{
      padding-top: 0;
    }

    .resource-library-item{
      background-color: var(--section-bg);
      margin: 1px;
      position: relative;
      z-index: 3;
      display: flex;
      flex-direction: column;
      transition: all 0.3s ease;
      .bits-line{
        aspect-ratio: 6 / 1;
        height: 1rem;
        color: var(--c8y-palette-yellow-60);
        &::before{
          height: 100%;
          width: 100%;
          mask-size: contain;
          mask-repeat: no-repeat;
        }
      }
      
      &.whitepaper{
        --section-title-color: var(--c8y-palette-gray-10);
        // --section-bg: var(--c8y-palette-red-80);

      }
      &.guide{
        --section-title-color: var(--c8y-palette-gray-10);
        // --section-bg: var(--c8y-palette-red-70);
      }
      &.article{
        --section-title-color: var(--c8y-palette-gray-10);
        // --section-bg: var(--c8y-palette-red-60);
      }
      .card__title{
        // padding: 1.5rem 2rem 0;
        color: var(--section-title-color);
        padding-bottom: 0;
        h3{
          font-size: $h4-font-size;
        }
        p{
          font-size: $font-size-xs;
          letter-spacing: .2em;
        }
      }
      .card__content{
        padding: 1rem 1rem 2rem;
        @include media-breakpoint-up('lg'){
          padding: 1rem 2rem 2rem;
        }
      }
    }
    .card__link{
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      transition: all 0.3s ease;
    }
  }
  &__filters{
    position: sticky;
    top: 105px;
    z-index: 100;
    background-color: var(--c8y-palette-high);
    >.grid-row::before{
      display: none;
    }
  }
}
