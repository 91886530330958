.professional-services{
  &__list{
    
    .p-s{
      width: 100%;
      float: left;
    }
    @include media-breakpoint-up('lg'){
    .p-s{
      width: 33.33333%;
      float: left;
    }
  }
  

    .professional-services-item{
      background-color: var(--section-bg);
      margin: 1px;
      position: relative;
      z-index: 3;
      display: flex;
      flex-direction: column;
      transition: all 0.3s ease;
      // @extend %btn-over-colors;
      .bits-line{
        aspect-ratio: 6 / 1;
        height: 1rem;
        // color: var(--c8y-palette-yellow-60);
        &::before{
          height: 100%;
          width: 100%;
          mask-size: contain;
          mask-repeat: no-repeat;
        }
      }

      @include media-breakpoint-up('lg'){
        .p-s__body{
          min-height: 216px;
        }
      }
      
 
      .card__title{
        padding: 1.5rem 1rem 0;
        @include media-breakpoint-up('lg'){
          padding: 1.5rem 2rem 0;
        }
        background: var(--section-bg);
        color: var(--section-title-color);
        h3{
          font-size: $h4-font-size;
          @include media-breakpoint-up('lg'){
            min-height: 4ch;
          }
        }
        p{
          font-size: $font-size-xs;
          letter-spacing: .2em;
        }
      }
    }
    .card__link{
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      transition: all 0.3s ease;
    }
  }
  
}


.single-ps{
  .main-content__container > .grid-row:first-child{
   &:after{
    display: none;
   }
  }
}

.main-content:has(.single-ps){

  .grid-col > ul {
    padding: 0;
    list-style: none;
  

    >li {
      margin-bottom: 1rem;
      position: relative;
      padding-left: 2rem;


      &::before {
        @extend .dlt-c8y-icon;
        content: $dlt-c8y-icon-check;
        display: flex;
        line-height: 1;
        color: var(--c8y-palette-gray-30);
        background: transparent;
        border: 1px solid var(--c8y-palette-gray-30);
        height: 1.5rem;
        width: 1.5rem;
        border-radius: 50%;
        align-items: center;
        justify-content: center;
        align-self: start;
        flex-shrink: 0;
        position: absolute;
        left:0;
      }
    }
  }
  .grid-col > ol {
    padding: 0;
    list-style: none;
  
    counter-reset: item;

    >li {
      margin-bottom: 1rem;
      position: relative;
      padding-left: 2rem;
      counter-increment: item;
      
      &::before {
        content: counters(item, ".");
        display: flex;
        line-height: 1;
        color: var(--c8y-palette-gray-30);
        background: transparent;
        border: 1px solid var(--c8y-palette-gray-30);
        height: 1.5rem;
        width: 1.5rem;
        border-radius: 50%;
        align-items: center;
        justify-content: center;
        align-self: start;
        flex-shrink: 0;
        position: absolute;
        left:0;
      }
    }
  }
}

