.nav-filters{
  padding: 1rem 2rem;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  position: sticky;
  z-index: 100;
  background-color: var(--c8y-palette-high);
  border-width: 0 1px 0 1px;
  border-style: solid;
  border-color: var(--grid-border-color, var(--c8y-palette-gray-60));
  top: 60px;
  @include media-breakpoint-up('lg') {
    top: 105px;
  }
  &::before, &::after{
    content: '';
    display: block;
    height: 1px;
    background: var(--grid-border-color, var(--c8y-palette-gray-60));
    mix-blend-mode: var(--grid-blend-mode);
    position: absolute;
    left: -1rem;
    right: -1rem;
  }
  &::before{
    top: 0;
  }
  &::after{
    bottom: 0;
  }

  &:has(.d-flex-lg.grid-row){
    border-width: 0;
    &::before, &::after{
      display: none;
    }
    .d-flex-lg.grid-row::before{
      display: none;
    }
  }
}


.btn-filter-toggle{
  display: inline-block;
  margin: 0;
  border: 0;
  background-image: none;
  background-color: transparent;
  text-align: left;
  text-decoration: none !important;
  white-space: normal;
  font-weight: inherit;
  cursor: pointer;
  padding: 0;
  font-size: inherit;
  line-height: inherit;
  touch-action: manipulation;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: all .25s linear;
  box-shadow: inset 0 0 0 var(--c8y-palette-gray-20);
  align-self: start;
  height: 100%;
  
  &:hover, &:focus-visible{ 
    text-decoration: none;
    background: var(--c8y-palette-gray-80);
    border-color: var(--c8y-palette-gray-60);
    outline-color: var(--c8y-palette-gray-50);
  }
  &.active{
    text-decoration: none;
    border-color: var(--c8y-brand-light);
    background: var(--c8y-palette-yellow-60);
  }
}
