.testimonial-slot{
  color: var(--c8y-text-color);
  position: relative;
  &:before{
    position: absolute;
    display: inline-block;
    content: '“';
    font-size: 300%;
    opacity: .3;
    font-family: Georgia, 'Times New Roman', Times, serif;
    transform: translate(-1.5rem, -1rem);
  }
  
  span:has(img){
    width: 60px;
  }
  img{
    border-radius: 50%;
    height: 100%;
    margin: 0;
    aspect-ratio: 1;
  }
  .avatar{
    object-fit: cover;
    border: 2px solid var(--c8y-root-component-border-color);
  }
  .customer__logo{
    object-fit: cover;
    padding: 8px;
    border: 1px solid var(--c8y-root-component-border-color);
    background-color: white;
  }
}


.testimonial-pic{
  display: flex;
  align-items: center;
  gap: 1rem;
  img{
    max-width: 26.5%;
  }
}
