.main-navbar{
  --subOpenBg: var(--c8y-palette-gray-20);
  --c8y-brand-dark: var(--c8y-palette-gray-100);

  --btn-primary-background-default: var(--c8y-palette-yellow-60);
  --btn-primary-border-color-default: var(--c8y-palette-yellow-60);
  --btn-primary-color-default: var(--c8y-palette-gray-10);

  --btn-primary-background-hover: var(--c8y-palette-yellow-50);
  --btn-primary-border-color-hover: var(--c8y-palette-yellow-50);
  --btn-primary-color-hover: var(--c8y-palette-gray-10);
  
  --btn-primary-background-active: var(--c8y-palette-yellow-50);
  --btn-primary-border-color-active: var(--c8y-palette-yellow-50);
  --btn-primary-color-active: var(--c8y-palette-gray-10);
  
  --btn-primary-border-color-focus: var(--c8y-palette-yellow-60);
  --btn-primary-color-focus: var(--c8y-palette-gray-10);

  

  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: $main-navbar-bg;
  height: 4rem;
  color: $main-navbar-color;
  z-index: 1000;
  transition: transform .25s ease;
  // outline: 1px solid var(--c8y-palette-gray-30);

  &--small{
    height: auto;
    font-size: 87.5%;
    padding: .5rem 1rem;
  }
 &__wrapper{
    display: flex;
    width: 100%;
    max-width: 1350px;
    margin: 0 auto;
    display: flex;
    gap: 1rem;
    padding: .5rem 1rem .5rem 1.5rem;
    &::before{
      content: '';
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      right: 0;
      background: $main-navbar-bg;
      height: 64px;
      z-index: 10;
    }
  }
}

.navbar-brand{
  position: relative;
  z-index: 10;
  flex-grow: 1;
  display: flex;
  align-items: center;
  >a{
    display: block;
    width: 100%;
    text-decoration: none;
    color: var(--c8y-palette-yellow-60);
  }
  svg{
    max-height: 40px;
    max-width: 185px;
  }
}
.nav-right{
  position: relative;
  z-index: 10;
}

.main-nav{
  padding: 8rem 0 2rem;
  list-style: none;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  height: 100vh;
  overflow: hidden;
  background-color: $main-navbar-bg;
  transition: transform .35s ease-out, border-radius .25s ease-out .075s;
  transform: translate3d(0,calc((100% + 64px) * -1),0);
  position: absolute;
  left:0;
  top:0;
  right:0;
  border-bottom-left-radius: 450px;
  border-bottom-right-radius: 450px;
  color: $main-navbar-color;
  max-width: 100vw;
  > li{
    opacity: 0;
    margin-top: -100px;
    transition: all .25s ease-out var(--delay);
    width: 100%;
    &.cta{
      order: 10;
    }
    >.nav-btn{
      padding: 1rem;
      display: flex;
      gap: .5rem;
      min-width: 100vw;
      justify-content: center;
      align-items: center;
      transition: all .25s linear;
      white-space: nowrap;
      border:0;
      color: inherit;
      background: none;
      -webkit-appearance: none;
      appearance: none;
      cursor: pointer;
      text-decoration: none;
      &:hover, &:focus{
        background: var(--c8y-palette-gray-20);
        text-decoration: none;
      }
      .chevron{
        transition: transform .25s ease;
        font-size: 110%;
        pointer-events: none;
        transform: rotate(-90deg);
      }
    }
    &.active{
      >.nav-btn{
        font-weight: 600;
      }
    }
    &.sub-open{
      --delay:0s!important;
      > .nav-btn{
          .chevron{
            transform: rotate(180deg);
          }
      }
    }
  }
  .nav-open &{
    transform: translate3d(0,0,0);
    border-radius: 0;

    > li{
      margin-top: 0;
      opacity: 1;
      font-size: $h4-font-size;
      &:nth-child(5){
        --delay: 0s;
      }
      &:nth-child(4){
        --delay: .1s;
      }
      &:nth-child(3){
        --delay: .2s;
      }
      &:nth-child(2){
        --delay: 0.3s;
      }
      &:nth-child(1){
        --delay: 0.4s;
      }
    }
  }
  .nav-open--sub & {
    > li{
      transition: all .25s ease-in-out !important;
      transform: none;
      position: static;
      margin-left: -200vw;
      &.sub-open{
        .sub-nav{
          left:0;
        }
      }
    }
  }
}

.sub-nav{
  position: absolute;
  margin: 0;
  padding: 0 2rem 2rem;
  list-style: none;
  font-size: $font-size-default;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: calc(100vh - 4rem);
  transition: all .25s ease-out;
  padding-bottom: 2rem;
  z-index: 8;
  top: 4rem;
  width: 100vw!important;
  left: 100vw;
  display: block;
  // outline: 1px solid var(--c8y-palette-gray-50);  
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    transition: all 0.5s ease;
    
  }
  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 1rem;
  }
  &::-webkit-scrollbar-thumb {
    background: $text-muted;
    border-radius: 16px;
  }
  .back-btn{
    position: sticky;
    top: 0;
    z-index: 10;
    padding: .5rem 1.5rem;
    background-color:  $main-navbar-bg;
    color: var(--c8y-palette-gray-60);
    box-shadow: inset 0 -1px 0 var(--c8y-palette-gray-30);
    margin: 0 -1.5rem;
  }
  &__intro{
    padding: 1.5rem 0;
    h4{
      margin-top:0;
      font-family: var(--headings-font-family);
    }
    &__menu{
      padding-top: 1rem;
      margin: 0;
    }
  }

  &__menu{
    --c8y-text-muted: var(--c8y-palette-gray-60);
    &__label{
      color: var(--c8y-text-muted);
      text-transform: uppercase;
      font-weight: normal;
      margin: 0 0 .5rem;
    }

    &--left, &--right{
      display: flex;
      flex-direction: column;
      gap: 1rem;
      border-top: 1px solid var(--c8y-palette-gray-20);
      padding-top: 1rem;
      
    }
    &--right{
      gap: .5rem;
      margin-top: 2rem;
      .nav-btn.active{
        font-weight: bold;
      }
    }
  
  }
  .nav-btn{
    color: inherit;
    padding: 1rem;
    border-radius: .5rem;
    transition: all .25s linear;
    text-decoration: none;
    box-shadow: inset 0 0 0 var(--c8y-palette-gray-20);
    border: 1px solid transparent;
    .text-muted{
      transition: all .25s linear;
    }
    h6{
      margin-bottom: .25rem;
    }
    
    &:hover, &:focus-visible{ 
      text-decoration: none;
      background: var(--subOpenBg);
      border-color: var(--c8y-palette-gray-60);
      outline-color: var(--c8y-palette-gray-50);
    }
    &.active{
      text-decoration: none;
      pointer-events: none;
      border-color: var(--c8y-brand-light);
      background: var(--subOpenBg);
    }
  }
}

.navbar__btn--toggle{
  margin-left: auto;
  position: relative;
  z-index: 50;
}


@include media-breakpoint-up('lg'){

  .main-navbar{
    --subOpenBg: var(--c8y-palette-gray-90);
    --c8y-brand-dark: var(--c8y-palette-gray-20);
  }
  .main-navbar__wrapper{
    padding: 0 $global-padding;
    align-items: center;
    &::before{
      display: none;
    }
  }

  .navbar-brand{
    min-width: 160px;
  }
  .main-nav{
    transition: none!important;
    position: static!important;
    transform: none;
    flex-direction: row;
    gap: 0;
    border-radius: 0;
    height: auto;
    min-height: 64px;
    width: auto!important;
    padding: 0;
    overflow: visible;
    
    &::after{
      position: absolute;
      background-color: $main-navbar-bg;
      left:0;
      right:0;
      top: 0;
      height: 64px;
      z-index: 8;
      content: '';
    }
    > li{
      transform: none!important;
      opacity: 1!important;
      width: auto;
      margin: 0!important;
      > .nav-btn{
        padding: 20px 1rem;
        background: $main-navbar-bg;
        position: relative;
        z-index: 10;
        min-width: auto;
        font-size: $font-size-default;
        border-radius: 0;
        
        &:hover, &:focus-visible, &:active{
          text-decoration: none;
          // box-shadow: inset 0 -4px 0 0 var(--section-color);
        }
        .chevron{
          transform: rotate(0);
        }
      }
      &.sub-open{
        > .nav-btn{
          text-decoration: none;
          box-shadow: inset 0 -4px 0 0 var(--c8y-palette-yellow-70);

          // box-shadow: inset 0 -4px 0 0 var(--c8y-palette-yellow-50)!important;
          color: var(--c8y-palette-gray-100);
        }
      }

      &.active{
        > .nav-btn{
          border-radius: 0;
          color: var(--c8y-palette-high);
          box-shadow: inset 0 -4px 0 0 var(--c8y-palette-yellow-60)!important;
        }
      }

    }
  }

  .sub-nav{
    color: $body-text-color;
    background-color: var(--c8y-palette-gray-100);
    position: absolute;
    padding: 0;
    z-index: 7;
    top:0;
    left: 50%!important;
    translate: -50% -100%;
    scale: .9;
    opacity: 1;
    width: calc(100vw - 4rem)!important;
    max-width: 1350px;
    max-height: calc(100vh - 200px);
    transition: all .25s ease-in;
    display: flex!important;
    transform: none;
    border-radius: 1rem;
    height: auto;
    box-shadow:var(--c8y-elevation-sm);
    &__menu{
      --c8y-text-muted: var(--c8y-palette-gray-30);
    }
    .btn--accent{
      font-family: inherit;
      text-transform: none;
    }
    .sub-open &{
      transition: translate .35s cubic-bezier(0, 0.96, 0.61, 1), scale .25s ease-in-out .15s, box-shadow 0.3s cubic-bezier(0, 0, 0, 1.06) .15s, opacity .25s linear;
      box-shadow: var(--c8y-elevation-lg);
      z-index: 8;
      translate: -50% 4.5rem;
      scale: 1;
      opacity: 1;
    }

    &__intro{
      max-width: 28%;
      width: 100%;
      position: relative;
      padding: 2rem;
      background: var(--c8y-palette-yellow-60);
      align-self: auto;
      // color: 
      h5{
        margin-top:0;
      }
      &__content{
        display: flex;
        flex-direction: column;
        height: 100%;
        
        .bits-line{
          margin-top: 1rem;
          flex-grow: 1;
          flex-shrink: 1;
          // color: var(--c8y-brand-light);

        }
      }
    }
    &__list{
      display: flex;
      flex-direction: row;
      gap: 2rem;
      flex-grow: 1;
      height: 100%;
    }
    &__menu{
      border-top:0;
      padding: 2rem;
      gap:.5rem;
      &__label{
        padding-left: 1rem;
        .sub-nav__menu--right &{
          padding-left: .5rem;
        }
      }
      &--left, &--right{
        .nav-btn{
          margin: 0;
        }
      }
      &--left{
        width: 70%;
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-template-rows: min-content;
        position: relative;
        gap: 1rem;
        &::after{
          content: '';
          display: block;
          position: absolute;
          right: 0;
          top: 2rem;
          bottom: 2rem;
          border-right: 1px solid var(--c8y-palette-gray-60);
        }
        .nav-btn{
          flex-grow: 1;
          flex-basis: 45%;
          h6{
            margin-bottom: .25rem;
          }
        }
      }
      &--right{
        padding-left: 0;
        width: 30%;
        margin: 0;
        justify-content: flex-start;
      
        .nav-btn{
          padding: .5rem;
          font-size: $font-size-small;
        }
      }
      &__label{
        grid-column: 1 / -1;
      }
    }
  }
  .navbar__btn--toggle, .close-subnav, .back-btn{
    display: none;
  }
}

// Hide try for free in the signup page

// body:has(.signupform) .main-navbar {
//   // TODO: display the .navbar__btn--toggle button when we have the full navigation in use
//   .cta, .navbar__btn--toggle{
//     display: none;
//   }
// }


.close-subnav{
  background: transparent;
  border: 0;
  padding: .5rem .5rem .5rem 0;
  cursor: pointer;
  transition: outline .25s ease;
  border-radius: .25rem;
  color: inherit;
  &:hover{
    outline: 2px solid var(--c8y-palette-yellow-60);
    color: var(--c8y-palette-yellow-60);
  }
}
