// Spacing
$sizes: (
 "0": 0,
 "4": 0.25rem,
 "8": 0.5rem,
 "16": 1rem,
 "24": 1.5rem,
 "32": 2rem,
 "40": 2.5rem,
 "48": 3rem,
 "56": 3.5rem,
 "64": 4rem,
 "72": 5.5rem,
 "80": 5rem,
 "88": 5.5rem,
 "96": 6rem
);

// 12 Columns
$colsize: (100% / 12);

$columns: (
  "1": $colsize,
  "2": ($colsize * 2),
  "3": ($colsize * 3),
 "4": ($colsize * 4),
 "5": ($colsize * 5),
 "6": ($colsize * 6),
 "7": ($colsize * 7),
 "8": ($colsize * 8),
 "9": ($colsize * 9),
 "10": ($colsize * 10),
 "11": ($colsize * 11),
 "12": 100%
 );
 
$grid-breakpoints: (
 xs: 0,
 sm: 576px,
 md: 768px,
 lg: 992px,
 xl: 1200px,
 xxl: 1400px
);

@function breakpoint-min($name, $breakpoints: $grid-breakpoints) {
 $min: map-get($breakpoints, $name);
 @return if($min != 0, $min, null);
}

@function breakpoint-infix($name, $breakpoints: $grid-breakpoints) {
 @return if(breakpoint-min($name, $breakpoints) == null, "", "-#{$name}");
}


// Only display content to screen readers
// See: https://a11yproject.com/posts/how-to-hide-content/
// See: https://hugogiraudel.com/2016/10/13/css-hide-and-seek/

@mixin sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

// Use in conjunction with .sr-only to only display content when it's focused.
// Useful for "Skip to main content" links; see https://www.w3.org/TR/2013/NOTE-WCAG20-TECHS-20130905/G1

@mixin sr-only-focusable {

  &:active,
  &:focus {
    position: static;
    width: auto;
    height: auto;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }
}


// Media of at least the minimum breakpoint width. No query for the smallest breakpoint.
// Makes the @content apply to the given breakpoint and wider.
@mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
 $min: breakpoint-min($name, $breakpoints);
 @if $min {
  @media (min-width: $min) {
   @content;
  }
 } @else {
  @content;
 }
}

// margins and paddings
@mixin spacer($prefix: m, $property: margin, $breakpoints: $grid-breakpoints) {
 @each $breakpoint in map-keys($breakpoints) {
  $infix: breakpoint-infix($breakpoint, $breakpoints);

  @each $name, $size in $sizes {
   @include media-breakpoint-up($breakpoint) {
    .#{$prefix}-#{$name}#{$infix} {
     #{$property}: map-get($sizes, $name) !important;
    }
    .#{$prefix}-t-#{$name}#{$infix} {
     #{$property}-top: map-get($sizes, $name) !important;
    }
    .#{$prefix}-r-#{$name}#{$infix} {
     #{$property}-right: map-get($sizes, $name) !important;
    }
    .#{$prefix}-b-#{$name}#{$infix} {
     #{$property}-bottom: map-get($sizes, $name) !important;
    }
    .#{$prefix}-l-#{$name}#{$infix} {
     #{$property}-left: map-get($sizes, $name) !important;
    }
   }
  }

  @include media-breakpoint-up($breakpoint) {
   .#{$prefix}-auto#{$infix} {
    #{$property}: auto !important;
   }
   .#{$prefix}-t-auto#{$infix} {
    #{$property}-top: auto !important;
   }
   .#{$prefix}-r-auto#{$infix} {
    #{$property}-right: auto !important;
   }
   .#{$prefix}-b-auto#{$infix} {
    #{$property}-bottom: auto !important;
   }
   .#{$prefix}-l-auto#{$infix} {
    #{$property}-left: auto !important;
   }
  }
 }
}
@include spacer();
@include spacer(p, padding);

// Gap
@mixin gaper($prefix: gap, $property: gap, $breakpoints: $grid-breakpoints) {
  @each $breakpoint in map-keys($breakpoints) {
   $infix: breakpoint-infix($breakpoint, $breakpoints);
 
   @each $name, $size in $sizes {
    @include media-breakpoint-up($breakpoint) {
     .#{$prefix}-#{$name}#{$infix} {
      #{$property}: map-get($sizes, $name) !important;
     }
    }
   }
  }
 }


 // Grid columns
@mixin makeColumns($prefix: col, $breakpoints: $grid-breakpoints) {
  @each $breakpoint in map-keys($breakpoints) {
   $infix: breakpoint-infix($breakpoint, $breakpoints);
 
   @each $name, $unit in $columns {
    @include media-breakpoint-up($breakpoint) {
     .#{$prefix}#{$infix}-#{$name} {
      width: map-get($columns, $name) !important;
     }
    }
   }
  }
 }


 
 @mixin make-col-offset($prefix: col, $breakpoints: $grid-breakpoints) {
  @each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
      $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
      @each $name, $unit in $columns {
        @include media-breakpoint-up($breakpoint) {
          .#{$prefix}-offset#{$infix}-#{$name} {
            margin-left: map-get($columns, $name) !important;
          }
        }
       }
      
    }
  }
}

 @include makeColumns();
 @include make-col-offset();

 @include gaper();

/* *********************
* Visibility
***********************/

@mixin responsive-invisibility(){
  display: none !important;
}


@mixin responsive-visibility(){
  display: block !important;
  table &  { display: table; }
  tr &     { display: table-row !important; }
  th &,
  td &     { display: table-cell !important; }
}


@mixin invisible($visibility) {
  visibility: $visibility !important;
}


/* *********************
* Displays
***********************/

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .d-none#{$infix}         { display: none !important; }
    .d-inline#{$infix}       { display: inline !important; }
    .d-inline-block#{$infix} { display: inline-block !important; }
    .d-block#{$infix}        { display: block !important; }
    .d-table#{$infix}        { display: table !important; }
    .d-table-row#{$infix}    { display: table-row !important; }
    .d-table-cell#{$infix}   { display: table-cell !important; }
    .d-flex#{$infix}         { display: flex !important; flex-direction: row!important; }
    .d-col#{$infix}          { display: flex !important; flex-direction: column!important;}
    .d-inline-flex#{$infix}  { display: inline-flex !important; }
    .d-grid#{$infix}         { display: grid !important; }
    .sr-only#{$infix}         { @include sr-only();}
  }
}


/* *********************
* Flex utilities
***********************/
//
// Custom styles for additional flex alignment options.

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .flex#{$infix}-row            { flex-direction: row !important; }
    .flex#{$infix}-column         { flex-direction: column !important; }
    .flex#{$infix}-row-reverse    { flex-direction: row-reverse !important; }
    .flex#{$infix}-column-reverse { flex-direction: column-reverse !important; }

    .flex#{$infix}-wrap         { flex-wrap: wrap !important; }
    .flex#{$infix}-nowrap       { flex-wrap: nowrap !important; }
    .flex#{$infix}-wrap-reverse { flex-wrap: wrap-reverse !important; }
    .flex#{$infix}-fill         { flex: 1 1 auto !important; }
    .flex#{$infix}-grow-0       { flex-grow: 0 !important; }
    .flex#{$infix}-grow-1       { flex-grow: 1 !important; }
    .flex#{$infix}-shrink-0     { flex-shrink: 0 !important; }
    .flex#{$infix}-shrink-1     { flex-shrink: 1 !important; }

    .j-c#{$infix}-start   { justify-content: flex-start !important; }
    .j-c#{$infix}-end     { justify-content: flex-end !important; }
    .j-c#{$infix}-center  { justify-content: center !important; }
    .j-c#{$infix}-between { justify-content: space-between !important; }
    .j-c#{$infix}-around  { justify-content: space-around !important; }

    .a-i#{$infix}-start    { align-items: flex-start !important; }
    .a-i#{$infix}-end      { align-items: flex-end !important; }
    .a-i#{$infix}-center   { align-items: center !important; }
    .a-i#{$infix}-baseline { align-items: baseline !important; }
    .a-i#{$infix}-stretch  { align-items: stretch !important; }

    .a-c#{$infix}-start   { align-content: flex-start !important; }
    .a-c#{$infix}-end     { align-content: flex-end !important; }
    .a-c#{$infix}-center  { align-content: center !important; }
    .a-c#{$infix}-between { align-content: space-between !important; }
    .a-c#{$infix}-around  { align-content: space-around !important; }
    .a-c#{$infix}-stretch { align-content: stretch !important; }

    .a-s#{$infix}-auto     { align-self: auto !important; }
    .a-s#{$infix}-start    { align-self: flex-start !important; }
    .a-s#{$infix}-end      { align-self: flex-end !important; }
    .a-s#{$infix}-center   { align-self: center !important; }
    .a-s#{$infix}-baseline { align-self: baseline !important; }
    .a-s#{$infix}-stretch  { align-self: stretch !important; }
  }
}


/* *********************
* Text alignment
***********************/

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    .text-left#{$infix}    { text-align: left !important; }
    .text-center#{$infix}  { text-align: center !important; }
    .text-right#{$infix} { text-align: right !important; }
  }
}


//Grid columns for 2, 3 and 4 slots

@mixin grid-generator($grid-columns, $gap) {
  @each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
      $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
      @for $i from 1 through $grid-columns {
        @for $j from 1 through $grid-columns - $i + 1 {
          $sum: $i + $j;
          @if $sum == $grid-columns {
            .grid--#{inspect($i)}-#{inspect($j)}#{$infix} {
              grid-template-columns: #{$i}fr #{$j}fr;
              gap: $gap;
            }
          }
        }
     }
    }
  }

 
  @each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
      $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
  
      @for $i from 1 through $grid-columns {
        @for $j from 1 through $grid-columns - $i + 1 {
          @for $k from 1 through $grid-columns - $i - $j + 1 {
            $sum: $i + $j + $k;
            @if $sum == $grid-columns {
              .grid--#{inspect($i)}-#{inspect($j)}-#{inspect($k)}#{$infix} {
                grid-template-columns: #{$i}fr #{$j}fr #{$k}fr;
                gap: $gap;
              }
            }
          }
        }
     }
    }
  }

 
  @each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
      $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
  
      @for $i from 1 through $grid-columns {
        @for $j from 1 through $grid-columns - $i + 1 {
          @for $k from 1 through $grid-columns - $i - $j + 1 {
            @for $l from 1 through $grid-columns - $i - $j - $k + 1 {
              $sum: $i + $j + $k + $l;
              @if $sum == $grid-columns {
                .grid--#{inspect($i)}-#{inspect($j)}-#{inspect($k)}-#{inspect($l)}#{$infix} {
                  grid-template-columns: #{$i}fr #{$j}fr #{$k}fr #{$l}fr;
                  gap: $gap;
                }
              }
            }
          }
        }
     }
    }
  }
 
 }

 @include grid-generator(12, 1.5rem);
