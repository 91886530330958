/**
 * Do not edit directly
 * Generated on Tue, 01 Oct 2024 08:54:33 GMT
 */

:root, .c8y-light-theme {
  --c8y-elevation-color: 1, 31, 61; /* The rgb values of #212121 (the palette-gray-10) */
  --c8y-main-header-border-color: transparent;
  --c8y-main-header-background-hover: inherit;
  --c8y-link-hover-color: #1481b8;
  --c8y-link-color: #1481b8;
  --c8y-headings-color: inherit;
  --c8y-palette-yellow-80: #fff6db;
  --c8y-palette-yellow-70: #ffe392;
  --c8y-palette-yellow-68: #FBD462;
  --c8y-palette-yellow-65: #FDC931;
  --c8y-palette-yellow-60: #ffbe00;
  --c8y-palette-yellow-50: #ffaa00;
  --c8y-palette-yellow-40: #b35f00;
  --c8y-palette-yellow-30: #794000;
  --c8y-palette-yellow-20: #5e3200;
  --c8y-palette-yellow-10: #4e3a00;
  

  --c8y-palette-orange-80: #fff5e9;
  --c8y-palette-orange-70: #ffe0bd;
  --c8y-palette-orange-60: #ffc17a;
  --c8y-palette-orange-50: #ff8800;
  --c8y-palette-orange-40: #e57a00;
  --c8y-palette-orange-30: #b35f00;
  --c8y-palette-orange-20: #794000;
  --c8y-palette-orange-10: #5e3200;
  --c8y-palette-red-80: #fdf4f4;
  --c8y-palette-red-70: #fadfdf;
  --c8y-palette-red-60: #f5c0c0;
  --c8y-palette-red-50: #ee9797;
  --c8y-palette-red-40: #e87373;
  --c8y-palette-red-30: #d70f0f;
  --c8y-palette-red-20: #a00b0b;
  --c8y-palette-red-10: #7e0909;
  --c8y-palette-blue-80: #f1f7fd;
  --c8y-palette-blue-70: #d7e7f8;
  --c8y-palette-blue-60: #b0d0f2;
  --c8y-palette-blue-50: #7fb3ea;
  --c8y-palette-blue-40: #559ae3;
  --c8y-palette-blue-30: #056ad6;
  --c8y-palette-blue-20: #044d9c;
  --c8y-palette-blue-10: #033c79;
  --c8y-palette-green-80: #eff9ef;
  --c8y-palette-green-75: #e1f4e1;
  --c8y-palette-green-70: #d1ecd1;
  --c8y-palette-green-68: #c1e6c1;
  --c8y-palette-green-60: hsl(120, 42%, 75%);
  --c8y-palette-green-50: #6ac26a;
  --c8y-palette-green-40: #119d11;
  --c8y-palette-green-30: #0f880f;
  --c8y-palette-green-20: #0a5c0a;
  --c8y-palette-green-10: #084708;
  --c8y-palette-fixed-light: #fff;
  --c8y-palette-fixed-dark: #212121;
  --c8y-palette-status-realtime: #00bb00;
  --c8y-palette-status-success-dark: #007700; /* suitable for text */
  --c8y-palette-status-success-light: #f4fce3;
  --c8y-palette-status-success: #71A112;
  --c8y-palette-gray-100: #F9FAFB;
  --c8y-palette-gray-90: #F0F2F4;
  --c8y-palette-gray-80: #E8EBED;
  --c8y-palette-gray-70: #E3E5E8;
  --c8y-palette-gray-60: #D4D9DE;
  --c8y-palette-gray-50: #8B96A0;
  --c8y-palette-gray-40: #657381;
  --c8y-palette-gray-30: #4C5967;
  --c8y-palette-gray-20: #303841;
  --c8y-palette-gray-10: #212121;
  --c8y-palette-low: black;
  --c8y-palette-high: white;
  --c8y-global-disabled-opacity: .55;
  --c8y-dl-horizontal-offset: 180;
  --c8y-code-border-color: transparent;
  --c8y-code-border-radius: 0;
  --c8y-pre-border-radius: 0;
  --c8y-blockquote-border-color: transparent;
  --c8y-line-height-small: 1;
  --c8y-line-height-large: 1.3333333; /* extra decimals for Win 8.1 Chrome */
  --c8y-line-height-headings: 1.1;
  --c8y-line-height-base: 1.42857142857;
  --c8y-font-weight-headings: 600;
  --c8y-font-weight-base: 400;
  --c8y-font-size-base: 14px;
  --c8y-font-family-mono-spaced: Menlo, Monaco, Consolas, 'Courier New', monospace;
  --c8y-font-family-serif: Georgia, 'Times New Roman', Times, sans-serif;
  --c8y-font-family-sans-serif: 'Public Sans', Helvetica, Arial, sans-serif;
  --c8y-tooltip-opacity: .95;
  --c8y-tooltip-arrow-width: 5px;
  --c8y-tooltip-width: 200px;
  --c8y-tooltip-border-radius: 3px;
  --c8y-table-cell-padding-default: 13px;
  --c8y-unit-base: 8px;
  --c8y-root-component-scrollbar-thumb-default: rgba(57, 72, 82, .2);
  --c8y-root-component-color-expanded: inherit;
  --c8y-root-component-background-disabled: rgba(0,0,0,.1); /* Don't use when changing opacity */
  --c8y-root-component-border-caret-width: 6px;
  --c8y-root-component-border-radius-focus: 4px;
  --c8y-root-component-border-radius-small: 0;
  --c8y-root-component-border-radius-large: 2px;
  --c8y-root-component-border-radius-base: 0;
  --c8y-root-component-border-style: solid;
  --c8y-root-component-border-width: 0;
  --c8y-root-component-padding-xs-horizontal: 5px;
  --c8y-root-component-padding-xs-vertical: 1px;
  --c8y-root-component-padding-small-vertical: 5px;
  --c8y-root-component-padding-large-vertical: 9px;
  --c8y-popover-arrow-width: 10px;
  --c8y-popover-width: 276px;
  --c8y-popover-border-radius: 4px;
  --c8y-navigator-active-border-width: 4px;
  --c8y-navigator-app-icon-size: 46px;
  --c8y-navigator-app-name-size: 16px;
  --c8y-navigator-platform-logo-height: 32;
  --c8y-nav-tabs-background-hover: transparent;
  --c8y-nav-tabs-background-default: transparent;
  --c8y-nav-tabs-vertical-width: 170px;
  --c8y-nav-tabs-border-width-active: 4px;
  --c8y-nav-tabs-border-width-default: 1px;
  --c8y-navlink-background-focus: transparent;
  --c8y-navlink-background-active: transparent;
  --c8y-navlink-background-hover: transparent;
  --c8y-navbar-border-color: transparent;
  --c8y-modal-backdrop-opacity: 0.5;
  --c8y-modal-border-radius: 4px;
  --c8y-modal-inner-scroll-height-sm: 35vh;
  --c8y-modal-inner-scroll-height-default: 62vh;
  --c8y-modal-size-sm: 450px;
  --c8y-modal-size-md: 600px;
  --c8y-modal-size-lg: 900px;
  --c8y-form-validation-bottom-margin: 24px;
  --c8y-form-control-height-lg: 40px;
  --c8y-form-control-height-sm: 28px;
  --c8y-form-control-height-base: 32px;
  --c8y-form-control-placeholder-font-style: italic;
  --c8y-form-control-border-width: 1px;
  --c8y-form-control-line-height: 1.42857143;
  --c8y-form-control-font-weight: normal;
  --c8y-form-legend-font-weight: 400;
  --c8y-form-legend-text-transform: uppercase;
  --c8y-form-label-font-weight: 600;
  --c8y-form-label-text-transform: none;
  --c8y-btn-shadow-default: none;
  --c8y-btn-transition-default: all 0.15s ease-out;
  --c8y-btn-padding-icon-horizontal: 9px;
  --c8y-btn-padding-xs-vertical: 1px;
  --c8y-btn-padding-xs-horizontal: 10px;
  --c8y-btn-padding-small-vertical: 5px;
  --c8y-btn-padding-small-horizontal: 14px;
  --c8y-btn-padding-large-vertical: 9px;
  --c8y-btn-padding-large-horizontal: 16px;
  --c8y-btn-border-radius-pill: 24px;
  --c8y-btn-border-radius-xs: 12px;
  --c8y-btn-border-radius-small: calc(var(--btn-border-radius-base, 0) * 0.75px);
  --c8y-btn-border-radius-large: calc(var(--btn-border-radius-base, 0) * 1.25px);
  --c8y-btn-border-radius-base: var(--btn-border-radius-base, 2px);
  --c8y-btn-line-height-xs: 1;
  --c8y-btn-line-height-small: 1.2;
  --c8y-btn-line-height-large: 1.2;
  --c8y-btn-group-border-radius: 0;
  --c8y-badge-line-height: 1;
  --c8y-badge-font-weight: bold;
  --c8y-badge-padding: 0.25em 0.4em 0.15em;
  --c8y-badge-border-radius: 10px;
  --c8y-alert-status-symbol-font-family: 'dlt-c8y-icons';
  --c8y-alert-status-symbol-size: 24px;
  --c8y-alert-padding: 16px 16px 16px 48px;
  --c8y-alert-border-style: solid;
  --c8y-alert-border-width: 4px;
  --c8y-alert-max-width: 600px;
  --c8y-elevation-hover: 0 0 0 1px rgba(var(--c8y-elevation-color), .05), 0 4px 4px rgba(var(--c8y-elevation-color), 0.01), 0 8px 8px rgba(var(--c8y-elevation-color), 0.01), 0 16px 16px rgba(var(--c8y-elevation-color), 0.03), 0 32px 32px rgba(var(--c8y-elevation-color), 0.05), 0 16px 64px rgba(var(--c8y-elevation-color), .1);
  --c8y-elevation-sm: 0 0 1px 1px rgba(var(--c8y-elevation-color), .05), 0 2px 2px rgba(var(--c8y-elevation-color), .15); /* Elevation for Cards, Select dropdowns, Date pickers, Time pickers */
  --c8y-elevation-md-bottom: 0 2px 12px 2px rgba(var(--c8y-elevation-color), .08), 0 2px 18px 4px rgba(var(--c8y-elevation-color), .1); /* Elevation md bottom */
  --c8y-elevation-md-top: 0 -2px 12px 2px rgba(var(--c8y-elevation-color), .08), 0 -4px 18px 4px rgba(var(--c8y-elevation-color), .1); /* Elevation md rop */
  --c8y-elevation-md-left: -4px 0 12px 2px rgba(var(--c8y-elevation-color), .08), -4px 0 18px 4px rgba(var(--c8y-elevation-color), .1); /* Elevation md right */
  --c8y-elevation-md-right: 4px 0 12px 2px rgba(var(--c8y-elevation-color), .08), 4px 0 18px 4px rgba(var(--c8y-elevation-color), .1); /* Elevation md right */
  --c8y-elevation-lg: 0 0 4px rgba(var(--c8y-elevation-color), .10), 0 8px 16px rgba(var(--c8y-elevation-color), .12), 0 0 16px rgba(var(--c8y-elevation-color), .04), 0 36px 52px rgba(var(--c8y-elevation-color), .05), 0 8px 36px rgba(var(--c8y-elevation-color), .16); /* Elevation for Modals and full screen overlaying elements */
  --c8y-elevation-border: inset 0 0 0 1px var(--c8y-palette-gray-80); /* For App switchers, Overflow menus, Tooltips */
  --c8y-right-drawer-separator-color: var(--c8y-palette-gray-70);
  --c8y-right-drawer-text-color-default: var(--c8y-palette-gray-10);
  --c8y-right-drawer-background-selected: var(--c8y-palette-gray-80);
  --c8y-right-drawer-background-default: var(--c8y-palette-gray-100);
  --c8y-main-header-background-default: var(--c8y-palette-high);
  --c8y-abbr-border-color: var(--c8y-palette-gray-40);
  --c8y-text-muted: var(--c8y-palette-gray-40);
  --c8y-text-color: var(--c8y-palette-gray-10);
  --c8y-level-4: var(--c8y-palette-gray-70);
  --c8y-level-3: var(--c8y-palette-gray-80);
  --c8y-level-2: var(--c8y-palette-gray-90);
  --c8y-level-1: var(--c8y-palette-gray-100);
  --c8y-brand-accent-light: var(--c8y-palette-blue-80);
  --c8y-brand-accent-dark: var(--c8y-palette-blue-10);
  --c8y-brand-accent: var(--c8y-palette-blue-30);
  --c8y-brand-80: var(--c8y-palette-green-80);
  --c8y-brand-70: var(--c8y-palette-green-70);
  --c8y-brand-60: var(--c8y-palette-green-60);
  --c8y-brand-50: var(--c8y-palette-green-50);
  --c8y-brand-40: var(--c8y-palette-green-40);
  --c8y-brand-30: var(--c8y-palette-green-30);
  --c8y-brand-20: var(--c8y-palette-green-20);
  --c8y-brand-10: var(--c8y-palette-green-10);
  --c8y-body-background-color: var(--c8y-palette-high);
  --c8y-palette-status-system: var(--c8y-palette-gray-70);
  --c8y-palette-status-info-dark: var(--c8y-palette-blue-10);
  --c8y-palette-status-info-light: var(--c8y-palette-blue-70);
  --c8y-palette-status-info: var(--c8y-palette-blue-30);
  --c8y-palette-status-danger-dark: var(--c8y-palette-red-10); /* suitable for text */
  --c8y-palette-status-danger-light: var(--c8y-palette-red-80);
  --c8y-palette-status-danger: var(--c8y-palette-red-30);
  --c8y-palette-status-warning-high: var(--c8y-palette-orange-80);
  --c8y-palette-status-warning-light: var(--c8y-palette-yellow-60);
  --c8y-palette-status-warning-dark: var(--c8y-palette-orange-10);
  --c8y-palette-status-warning: var(--c8y-palette-orange-50);
  --c8y-global-separator: var(--c8y-palette-gray-80);
  --c8y-global-odd: linear-gradient(to top,var(--c8y-palette-gray-90), var(--c8y-palette-gray-90));
  --c8y-code-color: var(--c8y-palette-blue-30);
  --c8y-code-background: var(--c8y-palette-blue-80);
  --c8y-code-font-size: calc(var(--c8y-font-size-base) - 1px);
  --c8y-code-font-family: var(--c8y-font-family-mono-spaced);
  --c8y-pre-color: var(--c8y-palette-gray-30);
  --c8y-pre-background: var(--c8y-palette-gray-90);
  --c8y-pre-border-color: var(--c8y-palette-gray-70);
  --c8y-font-size-h6: calc(var(--c8y-font-size-base) * 0.85714285714); /* 12px */
  --c8y-font-size-h5: var(--c8y-font-size-base); /* 14px */
  --c8y-font-size-h4: calc(var(--c8y-font-size-base) * 1.285714285715); /* 18px */
  --c8y-font-size-h3: calc(var(--c8y-font-size-base) * 1.714285714285714); /* 24px */
  --c8y-font-size-h2: calc(var(--c8y-font-size-base) * 2.142857142854); /* 30px */
  --c8y-font-size-h1: calc(var(--c8y-font-size-base) * 2.57142857143); /* 36px */
  --c8y-font-size-xs: calc(var(--c8y-font-size-base) * 0.7142857143); /* 10px */
  --c8y-font-size-small: calc(var(--c8y-font-size-base) * 0.85714285714); /* 12px */
  --c8y-font-size-large: calc(var(--c8y-font-size-base) * 1.214285714285); /* 17px */
  --c8y-font-family-base: var(--c8y-font-family-sans-serif);
  --c8y-tooltip-color-default: var(--c8y-palette-gray-100);
  --c8y-tooltip-background-default: var(--c8y-palette-gray-10);
  --c8y-switch-handle-background: var(--c8y-palette-high);
  --c8y-switch-background-default: var(--c8y-palette-gray-50);
  --c8y-switch-radius: calc(var(--c8y-unit-base) * 3.75);
  --c8y-switch-height: calc(var(--c8y-unit-base) * 3.75);
  --c8y-switch-width: calc(var(--c8y-unit-base) * 7.5);
  --c8y-table-cell-padding-condensed: var(--c8y-unit-base);
  --c8y-root-component-scrollbar-thumb-hover: var(--c8y-palette-gray-40);
  --c8y-root-component-icon-color: var(--c8y-palette-gray-30);
  --c8y-root-component-icon-white-color-dark: var(--c8y-palette-high);
  --c8y-root-component-pulse-color: var(--c8y-palette-status-realtime);
  --c8y-root-component-disabled-opacity: var(--c8y-global-disabled-opacity);
  --c8y-root-component-actions-opacity: var(--c8y-global-disabled-opacity);
  --c8y-root-component-color-disabled: var(--c8y-palette-gray-70); /* Don't use when changing opacity */
  --c8y-root-component-color-actions: var(--c8y-palette-gray-30);
  --c8y-root-component-color-link-hover: var(--c8y-link-hover-color);
  --c8y-root-component-color-link: var(--c8y-link-color);
  --c8y-root-component-background-expanded: var(--c8y-palette-gray-100);
  --c8y-root-component-background-default: var(--c8y-palette-high);
  --c8y-root-component-padding: calc(var(--c8y-unit-base) * 2);
  --c8y-root-component-padding-small-horizontal: var(--c8y-unit-base);
  --c8y-root-component-padding-large-horizontal: calc(var(--c8y-unit-base) * 2);
  --c8y-root-component-padding-base-horizontal: var(--c8y-unit-base);
  --c8y-root-component-padding-base-vertical: calc(var(--c8y-unit-base) * 0.625);
  --c8y-page-sticky-header-color-active: var(--c8y-link-color);
  --c8y-page-sticky-header-color-hover: var(--c8y-link-hover-color);
  --c8y-page-sticky-header-color-default: var(--c8y-palette-gray-10);
  --c8y-page-sticky-header-background-default: var(--c8y-palette-gray-100);
  --c8y-page-sticky-header-height: calc(var(--c8y-unit-base) * 6);
  --c8y-navigator-scrollbar-thumb-default: var(--c8y-root-component-scrollbar-thumb-default);
  --c8y-navigator-separator-color: var(--c8y-palette-gray-70);
  --c8y-navigator-text-color-default: var(--c8y-palette-gray-10);
  --c8y-navigator-background-selected: var(--c8y-palette-gray-80);
  --c8y-navigator-background-default: var(--c8y-palette-gray-100);
  --c8y-navigator-font-size: var(--c8y-font-size-base);
  --c8y-nav-pills-background-focus: var(--c8y-navlink-background-focus);
  --c8y-nav-pills-background-active: var(--c8y-navlink-background-active);
  --c8y-nav-pills-background-hover: var(--c8y-navlink-background-hover);
  --c8y-nav-pills-border-radius: calc(var(--c8y-unit-base) * 2);
  --c8y-nav-tabs-background-focus: var(--c8y-navlink-background-focus);
  --c8y-nav-tabs-vertical-padding-vertical: calc(var(--c8y-unit-base) + 2px);
  --c8y-nav-tabs-padding-horizontal: calc(var(--c8y-unit-base) * 2);
  --c8y-nav-tabs-icon-color-default: var(--c8y-palette-gray-40);
  --c8y-nav-tabs-icon-size: calc(var(--c8y-unit-base) * 2);
  --c8y-navlink-padding-horizontal: calc(var(--c8y-unit-base) * 2);
  --c8y-navlink-padding-vertical: calc(var(--c8y-unit-base) * 1.5 + 2px);
  --c8y-navbar-margin-bottom: calc(var(--c8y-unit-base) * 2);
  --c8y-navbar-height: calc(var(--c8y-unit-base) * 6);
  --c8y-modal-backdrop-background: var(--c8y-palette-low);
  --c8y-form-validation-color-success: var(--c8y-palette-status-success);
  --c8y-form-control-disabled-opacity: var(--c8y-global-disabled-opacity);
  --c8y-form-control-background-disabled: var(--c8y-component-background-disabled, var(--c8y-root-component-background-disabled));
  --c8y-form-control-placeholder-color: var(--c8y-palette-gray-40);
  --c8y-form-control-border-color-default: var(--c8y-palette-gray-60);
  --c8y-form-control-border-radius: var(--c8y-root-component-border-radius-base);
  --c8y-form-legend-color: var(--c8y-palette-gray-40);
  --c8y-form-label-color: var(--c8y-palette-gray-20);
  --c8y-form-label-font-size: var(--c8y-font-size-base);
  --c8y-btn-danger-color-active: var(--c8y-palette-high);
  --c8y-btn-primary-color-active: var(--c8y-palette-high);
  --c8y-btn-primary-color-default: var(--c8y-palette-high);
  --c8y-btn-line-height-base: var(--c8y-line-height-base);
  --c8y-btn-font-size-base: var(--c8y-font-size-base);
  --c8y-btn-font-weight: var(--c8y-font-weight-base);
  --c8y-alert-status-success: var(--c8y-palette-status-success);
  --c8y-dropdown-background-default: var(--c8y-root-component-background-default);
  --c8y-action-bar-icon-color: var(--c8y-root-component-icon-color);
  --c8y-action-bar-disabled-opacity: var(--c8y-root-component-disabled-opacity);
  --c8y-action-bar-actions-opacity: var(--c8y-root-component-actions-opacity);
  --c8y-action-bar-color-actions: var(--c8y-root-component-color-actions);
  --c8y-action-bar-background-default: var(--c8y-root-component-background-default);
  --c8y-right-drawer-text-color-active: var(--c8y-right-drawer-text-color-default);
  --c8y-right-drawer-text-color-selected: var(--c8y-right-drawer-text-color-default);
  --c8y-right-drawer-background-active: var(--c8y-brand-70);
  --c8y-main-header-shadow: var(--c8y-elevation-md-bottom);
  --c8y-main-header-text-color-default: var(--c8y-text-color);
  --c8y-main-header-background-active: var(--c8y-brand-70);
  --c8y-level-0: var(--c8y-body-background-color);
  --c8y-brand-realtime-added: var(--c8y-brand-80);
  --c8y-brand-dark: var(--brand-dark, var(--c8y-brand-10));
  --c8y-brand-light: var(--brand-light, var(--c8y-brand-60));
  --c8y-brand-primary: var(--brand-primary, var(--c8y-brand-40));
  --c8y-blockquote-font-size: var(--c8y-font-size-h4);
  --c8y-switch-handle-color: var(--c8y-text-color);
  --c8y-switch-background-active: var(--c8y-brand-40);
  --c8y-root-component-form-control-placeholder-color: var(--c8y-form-control-placeholder-color);
  --c8y-root-component-form-control-border-color-default: var(--c8y-form-control-border-color-default);
  --c8y-root-component-form-control-background-disabled: var(--c8y-form-control-background-disabled);
  --c8y-root-component-form-legend-color: var(--c8y-form-legend-color);
  --c8y-root-component-form-label-color: var(--c8y-form-label-color);
  --c8y-root-component-scrollbar-track: var(--c8y-component-background-default, var(--c8y-root-component-background-default));
  --c8y-root-component-icon-dark-color-dark: var(--c8y-text-color);
  --c8y-root-component-separator-color: var(--c8y-global-separator);
  --c8y-root-component-color-text-muted: var(--c8y-text-muted);
  --c8y-root-component-color-odd: var(--c8y-text-color);
  --c8y-root-component-color-default: var(--c8y-text-color);
  --c8y-root-component-background-hover: var(--c8y-brand-80);
  --c8y-root-component-background-focus: var(--c8y-root-component-background-default);
  --c8y-root-component-background-expanded-gradient: linear-gradient(0deg, rgba(0, 0, 0, 0.03) 0px, var(--c8y-root-component-background-expanded) 12px, var(--c8y-root-component-background-expanded) calc(100% - 12px), rgba(0, 0, 0, 0.03) 100%);
  --c8y-root-component-background-active: var(--c8y-root-component-background-default);
  --c8y-root-component-background-odd: var(--c8y-global-odd); /* use for alternate stripes for example */
  --c8y-root-component-border-color: var(--c8y-global-separator);
  --c8y-popover-icon-color: var(--c8y-root-component-icon-color);
  --c8y-popover-color-link-hover: var(--c8y-root-component-color-link-hover);
  --c8y-popover-color-link: var(--c8y-root-component-color-link);
  --c8y-popover-background-default: var(--c8y-root-component-background-default);
  --c8y-popover-padding: var(--c8y-root-component-padding);
  --c8y-navigator-scrollbar-thumb-hover: var(--c8y-root-component-scrollbar-thumb-hover);
  --c8y-navigator-header-color: var(--c8y-navigator-text-color-default);
  --c8y-navigator-header-background: var(--c8y-navigator-background-default);
  --c8y-navigator-text-color-active: var(--c8y-navigator-text-color-default);
  --c8y-navigator-text-color-selected: var(--c8y-navigator-text-color-default);
  --c8y-navigator-background-active: var(--c8y-brand-70);
  --c8y-navigator-font-family: var(--c8y-font-family-base);
  --c8y-nav-pills-padding-horizontal: var(--c8y-navlink-padding-horizontal);
  --c8y-nav-pills-padding-vertical: var(--c8y-navlink-padding-vertical);
  --c8y-nav-tabs-background-active: var(--c8y-root-component-background-default);
  --c8y-nav-tabs-vertical-padding-horizontal: var(--c8y-navlink-padding-horizontal);
  --c8y-nav-tabs-padding-vertical: var(--c8y-navlink-padding-vertical);
  --c8y-navlink-color-default: var(--c8y-text-color);
  --c8y-navlink-background-default: var(--c8y-root-component-background-default);
  --c8y-navbar-color-default: var(--c8y-text-color);
  --c8y-navbar-background-default: var(--c8y-root-component-background-default);
  --c8y-form-validation-color-info: var(--c8y-palette-status-info);
  --c8y-form-validation-color-warning: var(--c8y-palette-status-warning);
  --c8y-form-validation-color-error: var(--c8y-palette-status-danger);
  --c8y-form-control-color-disabled: var(--c8y-component-color-disabled, var(--c8y-root-component-color-disabled));
  --c8y-form-control-background-focus: var(--c8y-component-background-default, var(--c8y-root-component-background-default));
  --c8y-form-control-background-default: var(--c8y-component-background-default, var(--c8y-root-component-background-default));
  --c8y-form-control-padding-base-horizontal: var(--c8y-root-component-padding-base-horizontal);
  --c8y-form-control-padding-base-vertical: var(--c8y-root-component-padding-base-vertical);
  --c8y-form-control-font-family: var(--c8y-font-family-base);
  --c8y-form-legend-font-size: var(--c8y-font-size-small);
  --c8y-btn-danger-border-color-active: var(--c8y-palette-status-danger-dark);
  --c8y-btn-danger-border-color-focus: var(--c8y-palette-status-danger);
  --c8y-btn-danger-border-color-hover: var(--c8y-palette-status-danger);
  --c8y-btn-danger-border-color-default: var(--c8y-palette-status-danger);
  --c8y-btn-danger-background-active: var(--c8y-palette-status-danger-dark);
  --c8y-btn-danger-background-focus: var(--c8y-root-component-background-default);
  --c8y-btn-danger-background-hover: var(--c8y-root-component-background-default);
  --c8y-btn-danger-background-default: var(--c8y-root-component-background-default);
  --c8y-btn-danger-color-focus: var(--c8y-palette-status-danger);
  --c8y-btn-danger-color-hover: var(--c8y-palette-status-danger);
  --c8y-btn-danger-color-default: var(--c8y-palette-status-danger);
  --c8y-btn-accent-border-color-active: var(--c8y-brand-accent-dark);
  --c8y-btn-accent-border-color-focus: var(--c8y-brand-accent-dark);
  --c8y-btn-accent-border-color-hover: var(--c8y-brand-accent-dark);
  --c8y-btn-accent-border-color-default: var(--c8y-brand-accent);
  --c8y-btn-accent-background-active: var(--c8y-brand-accent-dark);
  --c8y-btn-accent-background-focus: var(--c8y-brand-accent-dark);
  --c8y-btn-accent-background-hover: var(--c8y-brand-accent-dark);
  --c8y-btn-accent-background-default: var(--c8y-brand-accent);
  --c8y-btn-accent-color-active: var(--c8y-text-color);
  --c8y-btn-accent-color-focus: var(--c8y-text-color);
  --c8y-btn-accent-color-hover: var(--c8y-text-color);
  --c8y-btn-accent-color-default: var(--c8y-text-color);
  --c8y-btn-primary-border-color-active: var(--c8y-brand-20);
  --c8y-btn-primary-background-active: var(--c8y-brand-10);
  --c8y-btn-primary-background-focus: var(--c8y-root-component-background-default);
  --c8y-btn-primary-background-hover: var(--c8y-root-component-background-default);
  --c8y-btn-default-background-active: var(--c8y-brand-70);
  --c8y-btn-default-background-focus: var(--c8y-root-component-background-default);
  --c8y-btn-default-background-hover: var(--c8y-root-component-background-default);
  --c8y-btn-default-background-default: var(--c8y-root-component-background-default);
  --c8y-btn-padding-icon-vertical: var(--c8y-root-component-padding-base-vertical);
  --c8y-btn-padding-base-vertical: var(--c8y-root-component-padding-base-vertical);
  --c8y-btn-padding-base-horizontal: var(--c8y-root-component-padding-base-horizontal);
  --c8y-btn-font-size-xs: var(--c8y-font-size-xs);
  --c8y-btn-font-size-small: var(--c8y-font-size-small);
  --c8y-btn-font-size-large: var(--c8y-font-size-large);
  --c8y-btn-font-family: var(--c8y-font-family-base);
  --c8y-badge-font-size: var(--c8y-font-size-small);
  --c8y-alert-background-default: var(--c8y-root-component-background-default);
  --c8y-alert-status-info: var(--c8y-palette-status-info);
  --c8y-alert-status-danger: var(--c8y-palette-status-danger);
  --c8y-alert-status-warning: var(--c8y-palette-status-warning);
  --c8y-action-bar-border-color: var(--c8y-root-component-border-color);
  --c8y-action-bar-color-text-muted: var(--c8y-root-component-color-text-muted);
  --c8y-action-bar-color-default: var(--c8y-root-component-color-default);
  --c8y-right-drawer-border-selected: var(--brand-primary, var(--c8y-brand-primary));
  --c8y-right-drawer-text-muted: var(--c8y-root-component-color-text-muted);
  --c8y-main-header-text-color-hover: var(--brand-primary, var(--c8y-brand-primary));
  --c8y-tooltip-border-color: var(--c8y-root-component-border-color);
  --c8y-root-component-form-control-color-disabled: var(--c8y-form-control-color-disabled);
  --c8y-root-component-form-control-background-focus: var(--c8y-form-control-background-focus);
  --c8y-root-component-form-control-background-default: var(--c8y-form-control-background-default);
  --c8y-root-component-icon-dark-color-light: var(--c8y-brand-light);
  --c8y-root-component-icon-white-color-light: var(--c8y-brand-light);
  --c8y-root-component-brand-primary: var(--brand-primary, var(--c8y-brand-primary));
  --c8y-root-component-spinner-color: var(--brand-primary, var(--c8y-brand-primary));
  --c8y-root-component-realtime-added: var(--c8y-brand-realtime-added);
  --c8y-root-component-color-focus: var(--brand-primary, var(--c8y-brand-primary));
  --c8y-root-component-color-accent: var(--brand-primary, var(--c8y-brand-primary));
  --c8y-root-component-color-actions-hover: var(--brand-primary, var(--c8y-brand-primary));
  --c8y-root-component-color-active: var(--brand-primary, var(--c8y-brand-primary));
  --c8y-popover-label-color: var(--c8y-root-component-form-label-color);
  --c8y-popover-color-default: var(--c8y-root-component-color-default);
  --c8y-popover-border-color: var(--c8y-root-component-border-color);
  --c8y-navigator-scrollbar-track: var(--c8y-root-component-scrollbar-track);
  --c8y-navigator-border-selected: var(--brand-primary, var(--c8y-brand-primary));
  --c8y-nav-pills-color-default: var(--c8y-navlink-color-default);
  --c8y-nav-pills-background-default: var(--c8y-navlink-background-default);
  --c8y-nav-tabs-color-default: var(--c8y-navlink-color-default);
  --c8y-nav-tabs-icon-color-active: var(--brand-primary, var(--c8y-brand-primary));
  --c8y-nav-tabs-icon-color-focus: var(--brand-primary, var(--c8y-brand-primary));
  --c8y-nav-tabs-icon-color-hover: var(--brand-primary, var(--c8y-brand-primary));
  --c8y-nav-tabs-border-color-default: var(--c8y-root-component-border-color);
  --c8y-navlink-color-focus: var(--brand-primary, var(--c8y-brand-primary));
  --c8y-navlink-color-active: var(--brand-primary, var(--c8y-brand-primary));
  --c8y-navlink-color-hover: var(--brand-primary, var(--c8y-brand-primary));
  --c8y-form-control-icon-color: var(--brand-primary,var(--c8y-brand-primary));
  --c8y-form-control-color-focus: var(--c8y-component-color-default, var(--c8y-root-component-color-default));
  --c8y-form-control-color-default: var(--c8y-component-color-default, var(--c8y-root-component-color-default));
  --c8y-form-control-border-color-focus: var(--brand-primary,var(--c8y-brand-primary));
  --c8y-btn-primary-border-color-focus: var(--brand-primary, var(--c8y-brand-primary));
  --c8y-btn-primary-border-color-hover: var(--brand-primary, var(--c8y-brand-primary));
  --c8y-btn-primary-border-color-default: var(--brand-primary, var(--c8y-brand-primary));
  --c8y-btn-primary-background-default: var(--brand-primary, var(--c8y-brand-primary));
  --c8y-btn-primary-color-focus: var(--brand-primary, var(--c8y-brand-primary));
  --c8y-btn-primary-color-hover: var(--brand-primary, var(--c8y-brand-primary));
  --c8y-btn-default-border-color-active: var(--brand-primary, var(--c8y-brand-primary));
  --c8y-btn-default-border-color-focus: var(--brand-primary, var(--c8y-brand-primary));
  --c8y-btn-default-border-color-hover: var(--brand-primary, var(--c8y-brand-primary));
  --c8y-btn-default-border-color-default: var(--brand-primary, var(--c8y-brand-primary));
  --c8y-btn-default-color-active: var(--brand-primary, var(--c8y-brand-primary));
  --c8y-btn-default-color-focus: var(--brand-primary, var(--c8y-brand-primary));
  --c8y-btn-default-color-hover: var(--brand-primary, var(--c8y-brand-primary));
  --c8y-btn-default-color-default: var(--brand-primary, var(--c8y-brand-primary));
  --c8y-alert-color-default: var(--c8y-root-component-color-default);
  --c8y-alert-status-system: var(--brand-primary, var(--c8y-brand-primary));
  --c8y-action-bar-color-focus: var(--c8y-root-component-color-focus);
  --c8y-action-bar-color-actions-hover: var(--c8y-root-component-color-actions-hover);
  --c8y-right-drawer-link-color-hover: var(--c8y-root-component-brand-primary);
  --c8y-right-drawer-link-color-default: var(--c8y-root-component-brand-primary);
  --c8y-root-component-form-control-border-color-focus: var(--c8y-form-control-border-color-focus);
  --c8y-root-component-form-control-color-focus: var(--c8y-form-control-color-focus);
  --c8y-root-component-form-control-color-default: var(--c8y-form-control-color-default);
  --c8y-nav-pills-color-focus: var(--c8y-navlink-color-focus);
  --c8y-nav-pills-color-active: var(--c8y-navlink-color-active);
  --c8y-nav-pills-color-hover: var(--c8y-navlink-color-hover);
  --c8y-nav-tabs-color-focus: var(--c8y-navlink-color-focus);
  --c8y-nav-tabs-color-active: var(--c8y-navlink-color-active);
  --c8y-nav-tabs-color-hover: var(--c8y-navlink-color-hover);
  --c8y-nav-tabs-border-color-active: var(--c8y-root-component-brand-primary);
  --c8y-navbar-link: var(--c8y-root-component-brand-primary);
}
