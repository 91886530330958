.customer-logo-60{
  display: inline-block;
  img{
    max-height: 60px;
    width: auto;
  }
}

.customer-link{
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  transition: all .25s linear;
  outline: 0 solid transparent;
  border-radius: 1rem;
  img{
   object-fit: contain;
    object-position: center;
    max-height: 120px;
    width: 100%;
  }
  &:hover{
   outline: 4px solid var(--c8y-palette-yellow-60);
   outline-offset: 4px;
  }
}
