@font-face {
  font-family: 'Euclid Square';
  src: url('./fonts/EuclidSquare-Bold.eot');
  src: url('./fonts/EuclidSquare-Bold.eot?#iefix') format('embedded-opentype'),
    url('./fonts/EuclidSquare-Bold.woff2') format('woff2'),
    url('./fonts/EuclidSquare-Bold.woff') format('woff'),
    url('./fonts/EuclidSquare-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Euclid Square';
  src: url('./fonts/EuclidSquare-Regular.eot');
  src: url('./fonts/EuclidSquare-Regular.eot?#iefix') format('embedded-opentype'),
    url('./fonts/EuclidSquare-Regular.woff2') format('woff2'),
    url('./fonts/EuclidSquare-Regular.woff') format('woff'),
    url('./fonts/EuclidSquare-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: $font-family;
  font-size: $font-size-default;
  font-weight: $font-weight-default;
}

h1,
h2,
h3,
h4,
h5,
.h1,
.h2,
.h3,
.h4,
.h5 {
  font-family: $headings-font-family;
  margin-top: 0;


  >small {
    font-size: 65%!important;
    font-weight: 400;
    text-transform: none;
  }

  +p {
    margin-top: 1rem;
  }
}
h1,h2,.h1,.h2{
  text-transform: uppercase;
}

.h1,
.h2,
.h3,
.h4,
.h5 {
  display: inline;
}

h1,
.h1 {
  font-size: $h1-font-size;
  line-height: $h1-line-height;
}

h2,
.h2 {
  font-size: $h2-font-size;
  line-height: $h2-line-height;
}

h3,
.h3 {
  font-size: $h3-font-size;
  line-height: $h3-line-height;
}

h4,
.h4 {
  font-size: $h4-font-size;
  line-height: $h4-line-height;
}

h5,
.h5 {
  font-size: $h5-font-size;
  line-height: $h5-line-height;
  margin-bottom: .5rem;
}

h6,
.h6 {
  font-size: $h6-font-size;
  line-height: $h6-line-height;
  margin: 0 0 .5em;
}

p {
  margin: 0 0 1rem;

  &:last-child {
    margin-bottom: 0;
  }
}

.pre-title {
  font-family: $headings-font-family;
  font-size: $h5-font-size;
}

.card-title {
  text-transform: none !important;
  font-size: $h2-font-size !important;
}

.lead {
  font-size: $font-size-lead;
  text-wrap: pretty;
}

.text-pretty {
  text-wrap: pretty;
}

.text-balance {
  text-wrap: balance;
}

.text-pre {
  white-space: pre-wrap;
}

small,
.text-small {
  font-size: $font-size-small!important;
  line-height: 1.5 !important;
}

.text-normal{
  &, h1, h2, h3, h4, h5{
    font-weight: 400;
  }

}

.text-xs {
  font-size: $font-size-xs;
  line-height: 1.3;
}

.text-large {
  font-size: 2rem;
}

.text--grid{
  color: var(--grid-border-color, var(--c8y-palette-gray-60));
  mix-blend-mode: var(--grid-blend-mode);
}

.text-muted {
  color: $text-muted;
}

.text-fade {
  opacity: 0.75;
}


.text-uppercase {
  text-transform: uppercase;
}

.text-truncate {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

a:not([class]):not([data-ps2id-api]):not(:has(>button)),
a.__mPS2id:not(.wp-block-navigation-item__content) {

  &,
  &:visited {
    color: currentColor!important;
    text-decoration: underline 1px;
    text-underline-offset: .5ch;
    position: relative;
    display: inline-block;

    &::after {
      content: '';
      transform-origin: 100% 50%;
      transform: scale3d(0, 1, 1);
      transition: transform 0.3s;
      position: absolute;
      width: clamp(3ch, 100%, 100%);
      height: .2em;
      background: var(--link-underline, var(--c8y-palette-yellow-60));
      top: 1.35em;
      left: 0;
      pointer-events: none;
      z-index: 10;
    }

    &:hover,
    &:focus {
      z-index: 1;
      &::after {
        transform-origin: 0% 50%;
        transform: scale3d(1, 1, 1);
      }
    }
    .bg-yellow--60 &{
      &::after{
        background: var(--c8y-palette-yellow-40);
      }
    }
  }

}

.link-none{
  text-decoration: none;
  color: inherit
}



blockquote {
  font-size: $font-size-lead;
  font-style: italic;
  position: relative;
}

blockquote.testimonial, .blockquote-testimonial {
  &::before {
    position: absolute;
    display: inline-block;
    content: '“';
    font-size: 200px;
    opacity: 1;
    font-family: Georgia, 'Times New Roman', Times, serif;
    transform: translate(-118%, -30%);
  }
  .bg-yellow--60 &{
    &::before{
      color: var(--c8y-palette-yellow-70);
      // opacity: .3;
    }
  }
}




.label {
  color: var(--tech-color);
  background: var(--tech-bg);
  padding: .2rem .5rem;
  border-radius: 2px;
}

.text-light {
  font-weight: lighter;
}

.text-normal {
  font-weight: 400;
}

.text-bold {
  font-weight: 600;
}

.text-underline {
  text-decoration: underline;
}

.text-label{
  text-transform: uppercase;
  font-weight: normal;
  letter-spacing: .2em;
}

.first-large {
  p:first-child {
    font-size: 1.75rem;
    font-weight: lighter;
  }
}

.lead {
  font-size: $font-size-lead;
  &--32{
    font-size: 2rem;
  }
}


.text-white {
  color: var(--c8y-palette-high) !important;
}

.text-color{
  color: $body-text-color!important;
}


.text-shadow-dark {
  text-shadow: 0 1px 2px rgba(var(--c8y-elevation-color), 1);
}

.small,
small {
  font-size: 87.5%;
}




.long-copy{
  h1,h2{
    padding: 5rem 25% 2rem 0;
    position: relative;
    text-shadow: 0 -4px 0 white, 0 4px 0 white, -4px 0 0 white, 4px 0 0 white;
    &::before{
      content: '';
      position: absolute;
      display: block;
      height: 1px;
      background-color: var(--grid-border-color, var(--c8y-palette-gray-60));
      top: 3rem;
      left: -3rem;
      right: -3rem;
    }
    &:first-child{
      padding-top: 0!important;
      &::before{
        display: none;
      }
    }
  }
  h3{
    font-size: $h4-font-size;
  }
  h4{
    font-size: $h5-font-size;
  }
  h5{
    font-size: $h6-font-size;
  }
  h3,h4,h5{
    margin: 2rem auto 1rem;
  }

  h2{
    + h3, + h4, + h5{
      margin-top: 0;
    }
  }

  blockquote {
    @extend .blockquote-testimonial;
    color: var(--c8y-palette-gray-40);
    margin: 2rem 0;
    &::before{
      font-size: 110px;
    }
  }
  >p, >ul, >ol{
    margin: 0 auto 1rem;
  }
  ul>li, ol>li{
    margin-bottom: 1rem;
  }

  div:has(>iframe){
    margin: 0 0 2rem;
  }

  @include media-breakpoint-up('lg'){
    >p, >ul, >ol, >h3, >h4, >h5, .cookieconsent-optout-marketing{
      max-width: 50% ;

    }
    p:has(>img){
      max-width: 75%;
      margin: 2rem auto;
      display: flex;
      justify-content: center;
    }
    blockquote {
      &::before{
       transform: translate(-135%, -30%);
       font-size: 150px;
       color: var(--c8y-palette-yellow-60);
      }
      max-width: 75%;
      margin: 2rem 12.5% 2rem 24.5%;
      text-shadow: 1px 1px 0 white;
      p{
        max-width: unset;
      }
    }
  }
}


.first-lead{
  >p:first-child{
    @extend .lead;
  }
}
