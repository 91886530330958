body .breadcrumbs {
  list-style: none;
  margin: 0;
  font-size: $font-size-small;
  max-width: 1350px;
  margin: 0 auto;
  display: flex;

  li {
    display: flex;
    align-items: center;
  }

  li:not(:last-child) {
    &:after {
      display: inline-block;
      @extend .dlt-c8y-icon;
      content: $dlt-c8y-icon-forward;
      font-size: inherit !important;
    }
  }

  a:not([aria-current]) {
    color: $text-muted;
    text-decoration: none !important;

    &:hover {
      color: var(--c8y-link-color);
    }
  }

  [aria-current] {
    color: $text-muted !important;
    text-decoration: none !important;
  }
}


nav[aria-label="breadcrumbs"] {
  padding: .75rem $global-padding;
  background-color: rgba(255, 255, 255, 0.65);
  backdrop-filter: blur(2px);
  position: sticky;
  top: 64px;
  z-index: 999;
  transition: background .25s ease;
  line-height: 1.231;
  margin-bottom: -42px;
  display: none;

  &:hover,
  &:focus-within {
    background-color: white;
  }

  @include media-breakpoint-up('lg') {
    display: block;
  }
}
