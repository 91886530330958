%btn-over-colors{
  --btn-background-default: var(--c8y-palette-gray-100);
  --btn-border-color-default: var(--c8y-palette-gray-10);
  --btn-color-default: var(--c8y-palette-gray-10);

  --btn-background-hover: var(--c8y-palette-yellow-70);
  --btn-border-color-hover: var(--c8y-palette-yellow-10);
  --btn-color-hover: var(--c8y-palette-gray-10);
  
  --btn-background-active: var(--c8y-palette-yellow-65);
  --btn-border-color-active: var(--c8y-palette-yellow-10);
  --btn-color-active: var(--c8y-palette-gray-10);
  
  --btn-border-color-focus: var(--c8y-palette-yellow-10);
  --btn-background-focus: var(--c8y-palette-yellow-50);
  --btn-color-focus: var(--c8y-palette-gray-10);
}


.bg {
  &-green {
    &--10 {
      background-color: var(--c8y-palette-green-10);
    }

    &--20 {
      background-color: var(--c8y-palette-green-20);
    }

    &--30 {
      background-color: var(--c8y-palette-green-30);
    }

    &--40 {
      background-color: var(--c8y-palette-green-40);
      --grid-border-color: var(--c8y-palette-green-50);
      --section-title-color: var(--c8y-palette-gray-90);
    }

    &--50 {
      background-color: var(--c8y-palette-green-50);
    }

    &--60 {
      background-color: var(--c8y-palette-green-60);
    }

    &--70 {
      background-color: var(--c8y-palette-green-70);
    }

    &--80 {
      background-color: var(--c8y-palette-green-80);
    }
  }

  &-blue {
    &--10 {
      background-color: var(--c8y-palette-blue-10);
      --grid-border-color: var(--c8y-palette-blue-40);
      --section-title-color: var(--c8y-palette-gray-90);
      --grid-blend-mode: lighten;
      color: var(--c8y-palette-gray-90);
    }

    &--20 {
      background-color: var(--c8y-palette-blue-20);
      --grid-border-color: var(--c8y-palette-blue-40);
      --section-title-color: var(--c8y-palette-gray-90);
      --grid-blend-mode: lighten;
      color: var(--c8y-palette-gray-90);
      box-shadow: var(--c8y-elevation-md--top);
    }

    &--30 {
      background-color: var(--c8y-palette-blue-30);
      --grid-border-color: var(--c8y-palette-blue-50);
      --section-title-color: var(--c8y-palette-gray-90);
      --grid-blend-mode: lighten;
      color: var(--c8y-palette-gray-90);
    }

    &--40 {
      background-color: var(--c8y-palette-blue-40);
      --grid-border-color: var(--c8y-palette-blue-20);
    }

    &--50 {
      background-color: var(--c8y-palette-blue-50);
    }

    &--60 {
      background-color: var(--c8y-palette-blue-60);
      --grid-border-color:  var(--c8y-palette-gray-60);
      --section-title-color: var(--c8y-palette-blue-30);
      --grid-blend-mode: multiply;
      .section-separator{
        color: var(--c8y-palette-blue-60);
      }
    }

    &--70 {
      background-color: var(--c8y-palette-blue-70);
    }

    &--80 {
      background-color: var(--c8y-palette-blue-80);
    }
  }

  &-red {
    &--10 {
      background-color: var(--c8y-palette-red-10);
    }

    &--20 {
      background-color: var(--c8y-palette-red-20);
    }

    &--30 {
      background-color: var(--c8y-palette-red-30);
    }

    &--40 {
      background-color: var(--c8y-palette-red-40);
    }

    &--50 {
      background-color: var(--c8y-palette-red-50);
    }

    &--60 {
      background-color: var(--c8y-palette-red-60);
    }

    &--70 {
      background-color: var(--c8y-palette-red-70);
    }

    &--80 {
      background-color: var(--c8y-palette-red-80);
    }
  }

  &-yellow {
    &--10 {
      background-color: var(--c8y-palette-yellow-10);
    }

    &--20 {
      background-color: var(--c8y-palette-yellow-20);
    }

    &--30 {
      background-color: var(--c8y-palette-yellow-30);
    }

    &--40 {
      background-color: var(--c8y-palette-yellow-40);
    }

    &--50 {
      background-color: var(--c8y-palette-yellow-50);
    }

    &--60 {
      background-color: var(--c8y-palette-yellow-60);
      --grid-border-color: var(--c8y-palette-gray-60);
      --grid-blend-mode: multiply;
      @extend %btn-over-colors;
      --link-underline: var(--c8y-palette-gray-10);

    }

    &--70 {
      background-color: var(--c8y-palette-yellow-70);
    }

    &--80 {
      background-color: var(--c8y-palette-yellow-80);
      --grid-border-color: var(--c8y-palette-gray-60);
      --grid-blend-mode: multiply;
    }
  }

  &-orange {
    &--10 {
      background-color: var(--c8y-palette-orange-10);
    }

    &--20 {
      background-color: var(--c8y-palette-orange-20);
    }

    &--30 {
      background-color: var(--c8y-palette-orange-30);
    }

    &--40 {
      background-color: var(--c8y-palette-orange-40);
      --grid-border-color: var(--c8y-palette-orange-30);
      --section-title-color: var(--c8y-palette-gray-100);
      color: var(--c8y-palette-gray-100);
    }

    &--50 {
      background-color: var(--c8y-palette-orange-50);
      --grid-border-color: var(--c8y-palette-orange-40);
      --section-title-color: var(--c8y-palette-gray-10);
    }

    &--60 {
      background-color: var(--c8y-palette-orange-60);
      --grid-border-color: var(--c8y-palette-orange-50);
      --grid-blend-mode: luminosity;
    }

    &--70 {
      background-color: var(--c8y-palette-orange-70);
      --grid-border-color: var(--c8y-palette-orange-60);
      --grid-blend-mode: luminosity;
    }

    &--80 {
      background-color: var(--c8y-palette-orange-80);
    }
  }

  &-white {
    background-color: var(--c8y-palette-high);
    --grid-border-color: var(--c8y-palette-gray-60);
  }

  &-gray {
    &--10 {
      background-color: var(--c8y-palette-gray-10);
      --grid-border-color: var(--c8y-palette-gray-20);
      --section-title-color: var(--c8y-palette-yellow-60);

      --btn-background-default: var(--c8y-palette-gray-10);
      --btn-color-default: var(--c8y-palette-yellow-60);
    }

    &--20 {
      background-color: var(--c8y-palette-gray-20);
    }

    &--30 {
      background-color: var(--c8y-palette-gray-30);
    }

    &--40 {
      background-color: var(--c8y-palette-gray-40);
    }

    &--50 {
      background-color: var(--c8y-palette-gray-50);
    }

    &--60 {
      background-color: var(--c8y-palette-gray-60);
    }

    &--70 {
      background-color: var(--c8y-palette-gray-70);
    }

    &--80 {
      background-color: var(--c8y-palette-gray-80);
    }

    &--90 {
      background-color: var(--c8y-palette-gray-90);
    }

    &--100 {
      background-color: var(--c8y-palette-gray-100);
    }
  }
}



.grad-sections{

  &--yellow{
    --btn-background-default: var(--c8y-palette-high);
    --btn-border-color-default: var(--c8y-palette-gray-10);
    --btn-color-default: var(--c8y-palette-gray-10);
    --btn-background-focus: var(--c8y-palette-high);
    --btn-border-color-focus: var(--c8y-palette-gray-10);
    --btn-color-focus: var(--c8y-palette-gray-10);
    --btn-background-hover: var(--c8y-palette-yellow-70);
    --btn-border-color-hover: var(--c8y-palette-gray-10);
    --btn-color-hover: var(--c8y-palette-gray-10);
    --btn-background-active: var(--c8y-palette-yellow-60);
    --btn-border-color-active:var(--c8y-palette-gray-10);
    --btn-color-active:var(--c8y-palette-gray-10);


    .panel{
      min-height: calc(100vh - 64px);
      display: flex;
      justify-content: center;
      color: $body-text-color;
      background-color: var(--panel-bg);
      --grid-blend-mode: darken;
      --section-separator-color: var(--grid-border-color);

      .section-separator{
        color: var(--panel-bg);
      }
      &:last-child{
        .section-separator{
          display: none;
        }
      }
      &:nth-child(1){
        --panel-bg: var(--c8y-palette-yellow-80);
        --grid-border-color: var(--c8y-palette-gray-70);
        --grid-blend-mode: multiply;
        --section-separator-color: var(--c8y-palette-yellow-60);
      }
      &:nth-child(2){
        --panel-bg: var(--c8y-palette-yellow-70);
        --grid-border-color: var(--c8y-palette-gray-60);
        --grid-blend-mode: multiply;
        --section-separator-color: var(--c8y-palette-yellow-60);
      }
      &:nth-child(3){
        --panel-bg: var(--c8y-palette-yellow-68);
        --grid-border-color: var(--c8y-palette-gray-60);
        --grid-blend-mode: multiply;
        --section-separator-color: var(--c8y-palette-high);
      }
      &:nth-child(4){
        --panel-bg: var(--c8y-palette-yellow-65);
        --grid-border-color: var(--c8y-palette-gray-60);
        --grid-blend-mode: multiply;
        --section-separator-color: var(--c8y-palette-high);
      }
      &:nth-child(5){
        --panel-bg: var(--c8y-palette-yellow-60);
        --grid-border-color: var(--c8y-palette-gray-60);
        --grid-blend-mode: multiply;
        // --c8y-text-color: var(--c8y-palette-high);
        --section-separator-color: var(--c8y-palette-high);
      }
      &:nth-child(6){
        --panel-bg: var(--c8y-palette-yellow-50);
        --grid-border-color: var(--c8y-palette-gray-60);
        --grid-blend-mode: multiply;
        --section-separator-color: var(--c8y-palette-high);
      }
      &:nth-child(7){
        --panel-bg: var(--c8y-palette-yellow-40);
        --grid-border-color: var(--c8y-palette-gray-40);
        --c8y-text-color: var(--c8y-palette-high);
        --grid-blend-mode: screen;
        --section-separator-color: var(--c8y-palette-yellow-60);
      }
      &:nth-child(8){
        --panel-bg: var(--c8y-palette-yellow-30);
        --grid-border-color: var(--c8y-palette-gray-10);
        --c8y-text-color: var(--c8y-palette-high);
        --grid-blend-mode: unset;
        --section-separator-color: var(--c8y-palette-yellow-60);
      }
      &:nth-child(9){
        --panel-bg: var(--c8y-palette-gray-100);
        --grid-border-color: var(--c8y-palette-gray-30);
        --c8y-text-color: var(--c8y-palette-high);
        --grid-blend-mode: unset;
        --section-separator-color: var(--c8y-palette-yellow-60);
      }
    }
  }

  &--yellow--reversed{
    --btn-background-default: var(--c8y-palette-high);
    --btn-border-color-default: var(--c8y-palette-gray-10);
    --btn-color-default: var(--c8y-palette-gray-10);
    --btn-background-focus: var(--c8y-palette-high);
    --btn-border-color-focus: var(--c8y-palette-gray-10);
    --btn-color-focus: var(--c8y-palette-gray-10);
    --btn-background-hover: var(--c8y-palette-yellow-70);
    --btn-border-color-hover: var(--c8y-palette-gray-10);
    --btn-color-hover: var(--c8y-palette-gray-10);
    --btn-background-active: var(--c8y-palette-yellow-60);
    --btn-border-color-active:var(--c8y-palette-gray-10);
    --btn-color-active:var(--c8y-palette-gray-10);
  
    --btn-primary-background-default: var(--c8y-palette-gray-10);
    --btn-primary-border-color-default: var(--c8y-palette-gray-10);
    --btn-primary-color-default: var(--c8y-palette-yellow-60);

    --btn-primary-background-focus: var(--c8y-palette-gray-10);
    --btn-primary-border-color-focus: var(--c8y-palette-gray-10);
    --btn-primary-color-focus: var(--c8y-palette-yellow-60);

    --btn-primary-background-hover: var(--c8y-palette-yellow-60);
    --btn-primary-border-color-hover: var(--c8y-palette-gray-10);
    --btn-primary-color-hover: var(--c8y-palette-gray-10);

    --btn-primary-background-active: var(--c8y-palette-yellow-60);
    --btn-primary-border-color-active:var(--c8y-palette-gray-10);
    --btn-primary-color-active:var(--c8y-palette-gray-10);

    .panel{
      display: flex;
      justify-content: center;
      --grid-blend-mode: luminosity;
      h3{
        font-size: $h4-font-size;
      }
      
      // &:not(.panel--fit){
      //   min-height: calc(100vh - 64px);
      // }
      
      &:nth-child(1){
        background: var(--c8y-palette-yellow-65);
        --grid-border-color:  var(--c8y-palette-yellow-50);
        --section-separator-color: var(--c8y-palette-high);
        .section-separator{
          color: var(--c8y-palette-yellow-65);
          left:auto;
          right: 0;
        }
      }
      &:nth-child(2){
        background: var(--c8y-palette-yellow-68);
        --grid-border-color:  var(--c8y-palette-yellow-50);
        --section-separator-color: var(--c8y-palette-high);
        .section-separator{
          color: var(--c8y-palette-yellow-68);
        }
      }
      &:nth-child(3){
        background: var(--c8y-palette-yellow-70);
        --grid-border-color:  var(--c8y-palette-yellow-60);
        --section-separator-color: var(--c8y-palette-yellow-60);
        .section-separator{
          color: var(--c8y-palette-yellow-70);
          left:auto;
          right: 0;
        }
      }
      &:nth-child(4){
        background: var(--c8y-palette-yellow-80);
        --grid-border-color:  var(--c8y-palette-yellow-65);
        --section-separator-color: var(--c8y-palette-yellow-60);
        .section-separator{
          color: var(--c8y-palette-yellow-80);
        }
      }
      &:nth-child(5){
        background: var(--c8y-palette-high);
        --grid-border-color:  var(--c8y-palette-yellow-68);
        --section-separator-color: var(--c8y-palette-yellow-60);
        .section-separator{
          color: var(--c8y-palette-high);
        }
      }
      &:nth-child(6){
        background: var(--c8y-palette-gray-90);
        --grid-border-color:  var(--c8y-palette-gray-60);
        --section-separator-color: var(--c8y-palette-yellow-60);
        .section-separator{
          color: var(--c8y-palette-gray-90);
        }
      }
      &:nth-child(7){
        background: var(--c8y-palette-gray-80);
        --grid-border-color:  var(--c8y-palette-gray-60);
        --section-separator-color: var(--c8y-palette-yellow-60);
        .section-separator{
          color: var(--c8y-palette-gray-80);
        }
      }
      &:nth-child(8){
        background: var(--c8y-palette-gray-70);
        --grid-border-color:  var(--c8y-palette-yellow-60);
        --section-separator-color: var(--c8y-palette-yellow-60);
        .section-separator{
          color: var(--c8y-palette-gray-70);
        }
      }
      &:last-child:not(.show-separator){
        .section-separator{
          display: none;
        }
      }
    }
  }
}


.text {
  &-white {
    color: var(--c8y-palette-high);
  }

  &-gray {
    &--10 {
      color: var(--c8y-palette-gray-10);
    }

    &--20 {
      color: var(--c8y-palette-gray-20);
    }

    &--30 {
      color: var(--c8y-palette-gray-30);
    }

    &--40 {
      color: var(--c8y-palette-gray-40);
    }

    &--50 {
      color: var(--c8y-palette-gray-50);
    }

    &--60 {
      color: var(--c8y-palette-gray-60);
    }

    &--70 {
      color: var(--c8y-palette-gray-70);
    }

    &--80 {
      color: var(--c8y-palette-gray-80);
    }

    &--90 {
      color: var(--c8y-palette-gray-90);
    }

    &--100 {
      color: var(--c8y-palette-gray-100);
    }
  }

  &-green {
    &--10 {
      color: var(--c8y-palette-green-10);
    }

    &--20 {
      color: var(--c8y-palette-green-20);
    }

    &--30 {
      color: var(--c8y-palette-green-30);
    }

    &--40 {

      color: var(--c8y-palette-green-40);
    }

    &--50 {
      color: var(--c8y-palette-green-50);
    }

    &--60 {
      color: var(--c8y-palette-green-60);
    }

    &--70 {
      color: var(--c8y-palette-green-70);
    }

    &--80 {
      color: var(--c8y-palette-green-80);
    }
  }

  &-blue {
    &--10 {
      color: var(--c8y-palette-blue-10);
    }

    &--20 {
      color: var(--c8y-palette-blue-20);
    }

    &--30 {
      color: var(--c8y-palette-blue-30);
    }

    &--40 {
      color: var(--c8y-palette-blue-40);
    }

    &--50 {
      color: var(--c8y-palette-blue-50);
    }

    &--60 {
      color: var(--c8y-palette-blue-60);
    }

    &--70 {
      color: var(--c8y-palette-blue-70);
    }

    &--80 {
      color: var(--c8y-palette-blue-80);
    }
  }

  &-red {
    &--10 {
      color: var(--c8y-palette-red-10);
    }

    &--20 {
      color: var(--c8y-palette-red-20);
    }

    &--30 {
      color: var(--c8y-palette-red-30);
    }

    &--40 {
      color: var(--c8y-palette-red-40);
    }

    &--50 {
      color: var(--c8y-palette-red-50);
    }

    &--60 {
      color: var(--c8y-palette-red-60);
    }

    &--70 {
      color: var(--c8y-palette-red-70);
    }

    &--80 {
      color: var(--c8y-palette-red-80);
    }
  }

  &-yellow {
    &--10 {
      color: var(--c8y-palette-yellow-10);
    }

    &--20 {
      color: var(--c8y-palette-yellow-20);
    }

    &--30 {
      color: var(--c8y-palette-yellow-30);
    }

    &--40 {
      color: var(--c8y-palette-yellow-40);
    }

    &--50 {
      color: var(--c8y-palette-yellow-50);
    }

    &--60 {
      color: var(--c8y-palette-yellow-60);
    }

    &--70 {
      color: var(--c8y-palette-yellow-70);
    }

    &--80 {
      color: var(--c8y-palette-yellow-80);
    }
  }

  &-orange {
    &--10 {
      color: var(--c8y-palette-orange-10);
    }

    &--20 {
      color: var(--c8y-palette-orange-20);
    }

    &--30 {
      color: var(--c8y-palette-orange-30);
    }

    &--40 {
      color: var(--c8y-palette-orange-40);
    }

    &--50 {
      color: var(--c8y-palette-orange-50);
    }

    &--60 {
      color: var(--c8y-palette-orange-60);
    }

    &--70 {
      color: var(--c8y-palette-orange-70);
    }

    &--80 {
      color: var(--c8y-palette-orange-80);
    }
  }
}



.section-title {
  margin: 0;
  hyphens: auto;
  // color: $body-text-color;

  &-symbol{
    color: var(--section-separator-color);
    // mix-blend-mode: var(--grid-blend-mode);
    >svg {
      height: 1.5rem;
    }
  }

}
